import { AppCredentials } from '@/config/enum';
import { IApiResponse } from '@/interface';
import { axios } from '@/lib/axios';

import { TUser } from '../types';

export const createUser = (data: TUser): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios.post('/users', toSend);
};

export const updateUser = (data: TUser): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios.put('/users', toSend);
};

export const getAllUsers = (): Promise<IApiResponse> => {
  return axios.get('/users');
};
