import React from "react";
import { FormikErrors, FormikTouched, getIn } from "formik";

type TProps<T> = {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  name: string;
  [x: string]: any;
};
const WithFormikErrorMessage =
  <FormValuesType,>(WrappedComponent: React.ElementType) =>
  ({ errors, touched, name, ...otherProps }: TProps<FormValuesType>) => {
    const error = getIn(errors, name);
    const touch = getIn(touched, name);

    return touch && error && typeof error === "string" ? (
      <WrappedComponent message={error} {...otherProps} />
    ) : null;
  };

export default WithFormikErrorMessage;
