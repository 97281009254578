import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import { TActor, TTypeActor } from "../../types";
import "./Actor.form.style.scss";
import { getAllTypeActor } from "../../api/typeActor.api";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";

export type TActorFormsValues = {
  libelle: string;
  actor_type_model: string;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TActorFormsValues>(AlertMessage);

type ActorFormProps = {
  closeSidePanel: () => void;
  refreshActor: () => void;
  handleSubmit: (
    values: TActorFormsValues,
    FormikHelpers: FormikHelpers<TActorFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  ruccentActor?: TActor;
};

const ActorForm = ({
  handleSubmit,
  ruccentActor,
}: ActorFormProps): JSX.Element => {
  // console.log("Simple acteur ", ruccentActor);

  const [typeActorData, setTypeActorData] = useState<TTypeActor[]>([]);

  const validationSchema: any = Yup.object({
    libelle: Yup.string().required("Le libellé est requis"),
    actor_type_model: Yup.string().required("Le libellé est requis"),
  });
  const formik = useFormik({
    initialValues: {
      libelle: ruccentActor?.libelle || "",
      actor_type_model: ruccentActor?.actor_type_model_id || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  // La liste des types d'acteurs
  const getTypeActor = async () => {
    const { data } = await getAllTypeActor();
    const dataWithNewObjectKeys: TTypeActor[] = data.map((typeActor: any) => {
      return {
        _id: typeActor._id,
        libelle: upperFirstWordLetter(typeActor.libelle),
        is_actif: typeActor.is_actif === 1 ? "Actif" : "Inactif",
      };
    });
    setTypeActorData(dataWithNewObjectKeys);
  };

  useEffect(() => {
    getTypeActor();
  }, []);

  return (
    <>
      <form className="form-utilisateur" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Type acteur <sup>*</sup>
            </label>
            <select
              id="inputState"
              name="actor_type_model"
              className="form-select bs-input-custom"
              placeholder="Type acteur"
              onChange={formik.handleChange}
              value={formik.values.actor_type_model}
            >
              <option value={""}>Choisissez</option>
              {typeActorData.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.libelle}
                </option>
              ))}
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="actor_type_model"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Libellé Acteur <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="libelle"
              autoComplete="off"
              placeholder="Libellé de l'acteur"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.libelle}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="libelle"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            {!ruccentActor && (
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                disableElevation
              >
                Annuler
              </Button>
            )}

            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {ruccentActor ? "Modifier" : "Enregistrer"}
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default ActorForm;
