import { IApiResponse } from '@/interface';
import { axios2 } from '@/lib/axios';

export const getAllMouvement = (id: string): Promise<IApiResponse> => {
  return axios2.get(`accountant/${id}`);
};

export const getTypeMouvement = (): Promise<IApiResponse> => {
  return axios2.get('/type-mvt');
};

export const addNewComptaLine = (dataToSend: any): Promise<IApiResponse> => {
  return axios2.post('/accountant/add', { ...dataToSend });
};

export const addNewComptaLineByClient = (dataToSend: any): Promise<IApiResponse> => {
  return axios2.post('/compta/add', { ...dataToSend });
};



export const getComptaByFolder = (id?: string): Promise<IApiResponse> => {
  return axios2.get(`/compta/${id}`);
};

export const cancelAccountant = (
  id: string,
  dataToSend: any
): Promise<IApiResponse> => {
  return axios2.put(`/compta/update/${id}`, { ...dataToSend });
};
