import { AuthRoutes } from '@/features/auth';
import { Navigate, RouteObject } from 'react-router-dom';

import { routePaths } from '../config';

import PortailRoutes from '@/features/portail/routes/portail.routes';

export const publicRoutes: RouteObject[] = [
  {
    path: routePaths.auth + '/*',
    element: <AuthRoutes />,
  },
  {
    path: routePaths.portail + '/*',
    element: <PortailRoutes />,
  },
  { path: '*', element: <Navigate to={routePaths.auth} /> },
];
