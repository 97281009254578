import { Button } from "@mui/material";
import { useFormik } from "formik";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { TFolderInitialInformation, TUser } from "../../types";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { createFolder } from "../../api/folder.api";
import React, { useEffect, useState } from "react";
import { Loader } from "@/components/Loader/Loader";
import { UserJobTitle } from "@/config/enum";
import { getAllUsers } from "../../api/user.api";
import * as _ from "lodash";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import CustomSelect from "@/components/UICs/Select/CustomSelect";
// import "./Partenaire.style.scss";

const FormErrorDisplayer = WithFormikErrorMessage<any>(AlertMessage);

type TProjectFolder = {
  setIdFolder: React.Dispatch<React.SetStateAction<string>>;
  setFolderCode: React.Dispatch<React.SetStateAction<string>>;
  setReferenceFolder: React.Dispatch<React.SetStateAction<string>>;
  setRefreshDataCurrentFolder: React.Dispatch<React.SetStateAction<string>>;
  seeDetailsInformation: boolean;
  currentFolder: any;
};
const ProjectFolder: React.FC<TProjectFolder> = ({
  setIdFolder,
  setFolderCode,
  setReferenceFolder,
  setRefreshDataCurrentFolder,
  seeDetailsInformation,
  currentFolder,
}) => {
  const [listUserNotaire, setListUserNotaire] = useState<TUser[]>([]);
  const [listeUserClercOrAssistant, setListeUserClercOrAssistant] = useState<
    TUser[]
  >([]);

  // State to create options data for custom select base on react select
  const [optionListUserNotaire, setOptionListUserNotaire] = useState<any[]>([]);
  const [optionListUserAssistant, setOptionListUserAssistant] = useState<any[]>(
    []
  );

  // state to handle react select onChange  and use that in formik
  // rs : react select
  const [rsCurrentNotaire, setCurrentNotaire] = useState<any>({});
  const [rsCcurrentClerc, setCurrentClerc] = useState<any>({});

  const [isLoading, setIsLoading] = useState(false);

  /** Traitement creation du premier dossier */
  // const validationSchemaEtudeDossier: any = Yup.object({
  //   folder_name: Yup.string().required('Le nom du dossier est obligatoire !'),
  //   clerc: Yup.string().required('Veuillez sélectionner le clerc/assistant !'),
  //   notaire: Yup.string().required('Veuillez sélectionner un notaire !'),
  // });

  const formikEtudeDossier = useFormik({
    initialValues: {
      folder_name: "",
      clerc_initiale: "",
      clerc_id: "",
      notaire_initiale: "",
      notaire_id: "",
      notaire: "",
      clerc: "",
    },
    // validationSchema: validationSchemaEtudeDossier,
    onSubmit: (values: any) => {
      let currentNotaire = JSON.parse(values?.notaire);
      let currentClerc = JSON.parse(values?.clerc);

      let request: TFolderInitialInformation = {
        folder_name: values.folder_name,
        clerc_initiale: currentClerc?.initials,
        clerc_id: currentClerc?._id,
        notaire_initiale: currentNotaire?.initials,
        notaire_id: currentNotaire?._id,
        folder_type: 1,
        folder_reference: currentFolder?.reference || "",
      };

      confirmSaveFolder(request);
    },
  });

  /** Confirmation premiere création de dossier */
  const confirmSaveFolder = (item: any) => {
    Swal.fire({
      title: "Dossier",
      text:
        "Vous êtes sur le point de " +
        (currentFolder?.reference ? "modifier " : "enregistrer") +
        " un dossier ! " +
        "\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        saveFolder(item);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const saveFolder = (itemFolder: TFolderInitialInformation) => {
    createFolder(itemFolder)
      .then(response => {
        setIsLoading(false);

        if (!response.error) {
          ToastGeneric(
            "Opération effectuée avec succès !",
            "success",
            9000
          ).fire();
          setIdFolder(response.data.id);
          setFolderCode(response.data.folder_code);
          setReferenceFolder(response.data.reference);
          setRefreshDataCurrentFolder?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  const getUsers = async () => {
    setIsLoading(true);
    await getAllUsers().then(response => {
      setIsLoading(false);
      let userListe: any[] = _.orderBy(response.data, ["first_name"], ["asc"]);
      /** On recupere les notaires */
      let listUserNotaire: any = _.filter(userListe, o => {
        return (
          o.job_title &&
          o.job_title?.toLowerCase() === UserJobTitle?.notaire?.toLowerCase()
        );
      });
      let listUserAssistant: any = _.filter(userListe, o => {
        return (
          o.job_title &&
          (o.job_title?.toLowerCase() ===
            UserJobTitle?.assisant?.toLowerCase() ||
            o.job_title?.toLowerCase() === UserJobTitle?.clerc?.toLowerCase())
        );
      });
      const optionListUserNotaire = listUserNotaire.map((notaire: any) => {
        const { first_name, last_name } = notaire;
        return {
          label: `${first_name} ${last_name}`,
          value: JSON.stringify(notaire),
        };
      });
      const optionListUserAssistant = listUserAssistant.map((assisant: any) => {
        const { first_name, last_name } = assisant;
        return {
          label: `${first_name} ${last_name}`,
          value: JSON.stringify(assisant),
        };
      });
      setOptionListUserNotaire(optionListUserNotaire);
      setOptionListUserAssistant(optionListUserAssistant);

      /** On recupere les clerc et les assistants */
      setListUserNotaire(listUserNotaire!);
      setListeUserClercOrAssistant(listUserAssistant!);

      /** On actualise le dossier courant au cas ou nous sommes en modification */
      setRefreshDataCurrentFolder(new Date().getTime?.toString());
    });
  };

  // Refresh User for Custom Select

  /** Fin traitement creation de dossier  */
  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    if (currentFolder && currentFolder.folder_code) {
      setFolderCode(currentFolder.folder_code!);
      let currentNotaire = _.find(listUserNotaire, (o: any) => {
        return o._id === currentFolder?.notaire_id;
      });
      let currentClerc = _.find(listeUserClercOrAssistant, (o: any) => {
        return o._id === currentFolder?.assistant_id;
      });
      /** Je prerempli les valeurs des input de etude */
      formikEtudeDossier.setValues({
        folder_name: currentFolder.folder_name,
        clerc_initiale: currentFolder?.assistant_initials,
        clerc_id: currentFolder?.assistant_id,
        notaire_initiale: currentFolder?.notaire_initials,
        notaire_id: currentFolder?.notaire_id,
        notaire: JSON.stringify(currentNotaire),
        clerc: JSON.stringify(currentClerc),
      });

      setCurrentNotaire({ value: JSON.stringify(currentNotaire) });
      setCurrentClerc({ value: JSON.stringify(currentClerc) });
    }
    //eslint-disable-next-line
  }, [currentFolder]);

  return (
    <>
      <div className='projet-folder-container'>
        <form className='row fs-row' onSubmit={formikEtudeDossier.handleSubmit}>
          <Loader isLoading={isLoading} />
          <div className='col-lg-4 col-md-4 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Libellé <sup>*</sup>
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name='folder_name'
              onChange={formikEtudeDossier.handleChange}
              value={formikEtudeDossier.values.folder_name}
              autoComplete='off'
              disabled={seeDetailsInformation}
              placeholder='Libellé'
            />
            <FormErrorDisplayer
              touched={formikEtudeDossier.touched}
              errors={formikEtudeDossier.errors}
              name='folder_name'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Notaire <sup>*</sup>
            </label>
            <div className='m-top'></div>
            {!isLoading && (
              <CustomSelect
                placeholder='Choisissez'
                defaultValue={formikEtudeDossier.values.notaire}
                name='notaire'
                options={optionListUserNotaire}
                isDisabled={seeDetailsInformation}
                handleChange={({ value }, select) => {
                  const e = {
                    target: {
                      name: select.name,
                      value,
                    },
                  };
                  formikEtudeDossier.handleChange(e);
                }}
                fontSize='16px'
              />
            )}

            <FormErrorDisplayer
              touched={formikEtudeDossier.touched}
              errors={formikEtudeDossier.errors}
              name='notaire'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Clerc / Assisant <sup>*</sup>
            </label>
            <div className='m-top'></div>
            {!isLoading && (
              <CustomSelect
                placeholder='Choisissez'
                name='clerc'
                defaultValue={formikEtudeDossier.values.clerc}
                options={optionListUserAssistant}
                isDisabled={seeDetailsInformation}
                handleChange={({ value }, select) => {
                  const e = {
                    target: {
                      name: select.name,
                      value,
                    },
                  };
                  formikEtudeDossier.handleChange(e);
                }}
                fontSize='16px'
              />
            )}

            <FormErrorDisplayer
              touched={formikEtudeDossier.touched}
              errors={formikEtudeDossier.errors}
              name='clerc'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-2 col-md-2 col-sm-12 mb-3'>
            <Button
              variant='contained'
              type='submit'
              disabled={seeDetailsInformation}
              color='primary'
              sx={{ marginTop: "30px", width: "fit-content" }}
            >
              {currentFolder?.reference
                ? "Modifier le projet"
                : "Créer le projet"}
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ProjectFolder;
