import { TFolderCreate } from "@/features/admin/types";
import folderTypes from "./folder.type";

interface ICurrentFolder {
  type: folderTypes.CURRENT_FOLDER;
  payload: TFolderCreate;
}

export type CurrentFolderAction = ICurrentFolder;

export const updateCurrentFolder = (folder: TFolderCreate) => ({
  type: folderTypes.CURRENT_FOLDER,
  payload: folder,
});
