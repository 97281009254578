import { getAllTypePiece } from "@/features/admin/api/typePiece.api";
import { TTypePiece } from "@/features/admin/types";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { Icon } from "@iconify/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";

interface IDocuementContainerProps {
  checkBuyerOrSellerFile: any;
  setFileViewer: any;
  confirmDeleteDocument: any;
  listFiles: any[];
}
export function DocumentContainer({
  checkBuyerOrSellerFile,
  setFileViewer,
  confirmDeleteDocument,
  listFiles,
}: IDocuementContainerProps) {
  const [ListTypePiece, setListTypePiece] = useState<any[]>([]);
  // const [labelTypePiece, setLabelTypePiece] = useState<any[]>([]);

  const getTypePiece = async () => {
    const { data } = await getAllTypePiece();

    const dataWithNewObjectKeys: TTypePiece[] = data.map((type: any) => {
      return {
        id: type._id,
        name: upperFirstWordLetter(type?.name!),
      };
    });
    setListTypePiece(dataWithNewObjectKeys);
  };

  useEffect(() => {
    getTypePiece();
  }, []);

  return (
    <div className='document-form-container'>
      <TableContainer elevation={0} component={Paper}>
        <Table
          sx={{
            Width: "100%",
            paddingBottom: "30px",
            marginTop: "30px",
          }}
          size='small'
          aria-label='a dense table'
        >
          <TableBody
            sx={{
              marginTop: 20,
            }}
          >
            {listFiles.map((row, idx) => {
              let TypePiece = ListTypePiece.find(
                (el: any) => el?.id === row?.piece_type
              );
              return (
                <TableRow
                  key={idx}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    component='th'
                    scope='row'
                    sx={{
                      fontWeight: 200,
                    }}
                  >
                    {TypePiece?.name}
                  </TableCell>
                  <TableCell
                    component='th'
                    scope='row'
                    sx={{
                      fontWeight: 200,
                    }}
                  >
                    {row.fileName}
                  </TableCell>
                  <TableCell align='right' className='action-btns'>
                    {row.fileType === "pdf" ? (
                      <a
                        style={{
                          padding: 5,
                          margin: "0px 7px",
                        }}
                        href={row.path}
                        rel='noopener noreferrer'
                        target='_blank'
                      >
                        <Icon width={20} icon='mdi:eye-circle-outline' />
                      </a>
                    ) : (
                      <button
                        type='button'
                        onClick={e => {
                          checkBuyerOrSellerFile(row);
                          setFileViewer(true);
                        }}
                      >
                        <Icon width={20} icon='mdi:eye-circle-outline' />
                      </button>
                    )}

                    <button
                      type='button'
                      onClick={e => {
                        confirmDeleteDocument(row);
                      }}
                    >
                      <Icon width={20} icon='ic:baseline-delete' />
                    </button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
