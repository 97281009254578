import {
  deleteProperty,
  getPropertiesByFolderId,
} from "@/features/admin/api/Property.api";
import PropertyForm from "@/features/admin/forms/Property/Property.form";
import { TProperty } from "@/features/admin/types";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { Icon } from "@iconify/react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./Property.styles.scss";
import { tryCatchWrapper } from "@/utils/tryCatchWrapper";
import { Loader } from "@/components/Loader/Loader";
import PropertyFileForm from "@/features/admin/forms/Property/PropertyFile/PropertyFile.form";

export type TPropertyViewProps = {
  folder_id: string;
  currentFolder: any;
  isConsultation?: boolean;
};

const PropertyView: React.FC<TPropertyViewProps> = ({
  folder_id,
  currentFolder,
  isConsultation,
}) => {
  // console.log("folder_id", folder_id);

  const [open, setOpen] = React.useState(false);
  const [propertylist, setPropertylist] = useState<TProperty[]>([]);
  const [refreshPropertyList, setRefreshPropertyList] = useState("");
  const [propertySelected, setPropertySelected] = useState<any>();
  const [actionSelected, setActionSelected] = useState<number>();
  // const [isRefreshFileList, setIsRefreshFileList] = useState<boolean>(false);
  // const [isOnlyReading, setIsonlyReading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  /** Ouverture du modake de modification */
  const handlePropertyActions = (
    item: any,
    action: number | undefined
    // onlyReading: boolean
  ) => {
    setOpen(true);
    setPropertySelected(item);
    setActionSelected(action);
    // setIsUpdated(isUpdated);
    // setIsonlyReading(onlyReading);
  };

  // console.log("propertySelected: ", propertySelected);
  /**Confirmation Suppression d'un acquereur ou d'un vendeur */
  const confirmDeleteBuyerOrSeller = (item: any, isBuyer: boolean) => {
    Swal.fire({
      title: " Suppression",
      text: "Vous êtes sur le point de supprimer un bien !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deletedProperty(item);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  /** Suppression vendeur ou acquereur */
  const deletedProperty = async (item: any) => {
    const response = await deleteProperty(item.id);
    const { error, message } = response;
    setIsLoading(false);
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(`Bien supprimé avec succès`, "success", 3000).fire();
      setRefreshPropertyList?.(new Date().getTime().toString());
      // setRefreshPropertyList(new Date().getTime.toString());
    }
  };

  const handleClose = () => {
    setOpen(false);
    setPropertySelected(null);
  };

  // La liste des types d'acteurs
  const getPropertyList = () => {
    tryCatchWrapper(async () => {
      setIsLoading(true);
      console.log({ currentFolder });
      const { data } = await getPropertiesByFolderId(folder_id);
      // console.log(" data ", data);
      setIsLoading(false);
      const dataWithNewObjectKeys: TProperty[] = data.map(
        (property: any, index: any) => {
          return {
            index: index + 1,
            id: property?.id,
            type_bien_id: property?.type_bien?._id,
            type_bien_label: property?.type_bien?.libelle,
            commune_id: property?.commune_id?._id || "",
            commune_label: property?.commune_id?.libelle || "",
            libelle: property?.libelle,
            quartier: property?.quartier,
            nom_lotissement: property?.nom_lotissement,
            num_lot: property?.numero_lot,
            num_ilot: property?.numero_ilot,
            num_foncier: property?.num_titre_foncier,
            situation_hypot: property?.situation_hypothecaire,
            effet_relatif: property?.effet_relatif,
            bureau: property?.bureau_conservation,
            created_date: property?.created_date,
            num_etat_foncier: property?.etat_foncier.num_etat_foncier,
            date_etatblissement: property?.etat_foncier?.date_etatblissement,
            autorite: property?.etat_foncier?.autorite,
            prix_vente: property?.prix_vente || "",
            files: property?.files,
          };
        }
      );

      setPropertylist(dataWithNewObjectKeys);
    });
  };

  const refreshPropertyDataList = () => {
    getPropertyList();
  };

  useEffect(() => {
    // console.log("enter 1");
    getPropertyList();
    refreshPropertyDataList();

    // console.log("property Liste soualio: ", propertylist);

    //eslint-disable-next-line
  }, [refreshPropertyList]);

  useEffect(() => {
    let isPropertySelected = propertylist.find(
      (el: any) => el?.id === propertySelected?.id
    );
    setPropertySelected(isPropertySelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertylist]);

  return (
    <>
      {propertylist.map((bien: any, index: any) => (
        <>
          {
            /* Dans le cas ou c'est une personne physique */
            bien.type_bien_id && (
              <div className='menu-item details' key={index}>
                <div className='situation-container'>
                  <div
                    className={`situation ${
                      bien?.type_bien_label === "célibataire"
                        ? "single"
                        : bien?.type_bien_label === "marié"
                        ? "maried"
                        : bien?.type_bien_label === "divorcé"
                        ? "divorced"
                        : bien?.type_bien_label === "veuf"
                        ? "veuf"
                        : ""
                    }`}
                  >
                    {bien?.type_bien_label === "Bien construit" ? (
                      <Icon
                        className='icon'
                        width={20}
                        icon='material-symbols:home-work-outline-rounded'
                      />
                    ) : (
                      <Icon
                        className='icon'
                        width={20}
                        icon='mdi:land-plots-marker'
                      />
                    )}
                    <span>{bien.type_bien_label}</span>
                  </div>
                </div>

                <ul>
                  <li>
                    <span>Commune/quartier : </span>
                    <span>{bien?.commune_label + " / " + bien?.quartier}</span>
                  </li>
                  <li>
                    <span>Numéro du lot : </span>
                    <span>{bien?.num_lot}</span>
                  </li>
                  <li>
                    <span> Numéro de l'îlot : </span>
                    <span>{bien?.num_ilot}</span>
                  </li>
                  <li>
                    <span>Le numéro du Titre Foncier :</span>
                    <span>{bien?.num_foncier}</span>
                  </li>
                </ul>

                <div className='file'>
                  {/* <div className='property-files-btn'>
                    <button
                      className='btn-view-doc'
                      title='Voir les documents'
                      onClick={e => handlePropertyActions(bien, 2)}
                    >
                      4 fichiers
                    </button>
                  </div> */}
                  <div className='actions'>
                    {!isConsultation && (
                      <button
                        className='btn-add-doc'
                        title='Ajouter document'
                        onClick={e => {
                          handlePropertyActions(bien, 1);
                        }}
                      >
                        <Icon icon='mdi:paperclip-plus' />
                      </button>
                    )}
                    <button
                      type='button'
                      className='btn-detail'
                      title='Détails'
                      onClick={e => {
                        handlePropertyActions(bien, 3);
                      }}
                    >
                      <Icon icon='ph:eye' />
                    </button>
                    {!isConsultation && (
                      <>
                        {" "}
                        <button
                          type='button'
                          className='btn-edited'
                          title='Modifier'
                          // disabled={seeDetailsInformation}
                          onClick={e => {
                            handlePropertyActions(bien, 4);
                          }}
                        >
                          <Icon icon='fluent:text-bullet-list-square-edit-24-regular' />
                        </button>
                        <button
                          type='button'
                          className='btn-deleted'
                          title='Supprimer'
                          // disabled={seeDetailsInformation}
                          onClick={e => {
                            confirmDeleteBuyerOrSeller(bien, true);
                          }}
                        >
                          <Icon icon='jam:trash-alt-f' />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
          }
        </>
      ))}

      {!isConsultation && (
        <div
          className={`menu-item border-dashed`}
          onClick={e => handlePropertyActions(null, 0)}
        >
          <Icon
            className='icon-add'
            icon='material-symbols:real-estate-agent'
          />
          <span>Ajouter un nouveau bien</span>
        </div>
      )}

      <div>
        <Dialog
          open={open}
          onClose={(e, reason) => {
            if (!(reason === "backdropClick")) handleClose();
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='lg'
          className='buyerSeller-modal'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ padding: "0px 0px 0px 0px" }}
          >
            <div className='fs-modal-header'>
              <span className='fs-modal-title'>
                {actionSelected === 0 && "Ajout d'un nouveau bien"}
                {actionSelected === 1 && "Ajout d'un document"}
                {actionSelected === 2 && "Liste des documents"}
                {actionSelected === 3 && "Détail du bien"}
                {actionSelected === 4 && "Modification d'un bien"}
              </span>
              <button className='fs-modal-close-button' onClick={handleClose}>
                <Icon className='icon' icon='clarity:times-line' />
              </button>
            </div>
          </DialogTitle>
          <DialogContent sx={{ padding: "20px", paddingTop: "20px!important" }}>
            {actionSelected === 1 ? (
              <PropertyFileForm
                idProperty={propertySelected?.id}
                setRefreshFilesData={setRefreshPropertyList}
                currentProperty={propertySelected}
              />
            ) : (
              <>
                <Loader isLoading={isLoading} />
                <PropertyForm
                  refreshPropertyList={setRefreshPropertyList}
                  property={propertySelected}
                  folder_id={folder_id}
                  handleClose={handleClose}
                  action={actionSelected}
                />
              </>
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};
export default PropertyView;
