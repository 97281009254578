export function formatMillier(number: number) {
  // Convertir le nombre en chaîne de caractères
  const numberString = number?.toString();

  // Séparer les chiffres en groupes de trois à partir de la droite
  const parts = [];
  for (let i = numberString?.length - 1; i >= 0; i -= 3) {
    const part = numberString?.substring(Math.max(0, i - 2), i + 1);
    parts?.unshift(part);
  }

  // Joindre les groupes avec une virgule et retourner le résultat
  return parts.join(".");
}
