import CustomSelect from "@/components/UICs/Select/CustomSelect";
// import { Button } from "@mui/material";
import { useFormik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import {
  addNewComptaLine,
  addNewComptaLineByClient,
} from "../../api/comptabily.api";
import * as Yup from "yup";

import "./ComptableViewForm.styles.scss";
import { LoadingButton } from "@mui/lab";
interface IComptableViewFormProps {
  currentFolder: any;
  readOnlyInput: any;
  refreshFolder?: React.Dispatch<React.SetStateAction<string>>;
  optionsTypeMvt: any[];
}
interface IComptableViewFormValues {
  bill_sale_id?: string;
  client_id?: string;
  description: string;
  montant: string;
  typeMouvement: string;
}

const ComptableViewForm = ({
  currentFolder,
  readOnlyInput,
  refreshFolder,
  optionsTypeMvt,
}: IComptableViewFormProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const billSales = currentFolder?.bill_sale;
  const buyerList = currentFolder?.buyers_ids;
  const sellerList = currentFolder?.seller_ids;

  const optionsBillSale = billSales.map((billSale: any) => {
    return {
      value: billSale._id,
      label: billSale.reference,
    };
  });

  const buyerSellerList = buyerList.concat(sellerList || []);
  console.log("Liste des clients: ", buyerSellerList);

  const optionsClients = buyerSellerList.map((client: any) => {
    console.log(client);
    return {
      id: client?.client,
      clientfullname: !client?.company_infos
        ? client?.personal_infos?.last_name.toUpperCase() +
          " " +
          client?.personal_infos?.first_name?.toUpperCase()
        : client?.company_infos?.denomination?.toUpperCase(),
    };
  });

  const validationSchema: any = Yup.object({
    client_id: Yup.string().required("Veuillez sélectionner un client !"),
    description: Yup.string().required("la description est obligatoire !"),
    montant: Yup.string().required("Veuillez entrez le montant du mouvement!"),
    typeMouvement: Yup.string().required(
      "Veuillez sélectionner le type de mouvement !"
    ),
  });

  const formik = useFormik<IComptableViewFormValues>({
    initialValues: {
      client_id: "",
      description: "",
      montant: "",
      typeMouvement: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values: IComptableViewFormValues) => {
      const { client_id, description, montant, typeMouvement } = values;
      setIsLoading(true);
      const dataToSend = {
        client_id: client_id,
        folder_id: currentFolder._id,
        dossier_id: currentFolder._id,
        montant: montant,
        description: description,
        // type_mvt_id: typeMouvement,
        type_compta: "ENTREE",
      };

      console.log(" dataToSend ", dataToSend);

      try {
        const response = await addNewComptaLineByClient(dataToSend);
        if (response.error) {
          toast.error(response.message);
          return;
        }
        toast.success(response.message);
        refreshFolder?.(new Date().getTime().toString());
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    },
  });
  console.log(formik.errors);

  return (
    <div className='select-container form-wrapper  '>
      <header>
        <span>Ajout de ligne comptable</span>
      </header>
      <form className='comptable-form' onSubmit={formik.handleSubmit}>
        <div>
          <label className='bs-label-form'>
            Client <sup>*</sup>
          </label>
          <select
            id='inputState'
            name='client_id'
            className='form-select bs-input-custom'
            placeholder='Client'
            onChange={formik.handleChange}
            value={formik.values.client_id}
            disabled={readOnlyInput}
          >
            <option value={""}>Choisissez</option>
            {optionsClients.map((item: any) => (
              <option key={item.id} value={item.id}>
                {item.clientfullname}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className='bs-label-form'>
            Description <sup>*</sup>
          </label>
          <input
            type='text'
            className='form-control bs-input-custom'
            name='description'
            onChange={formik.handleChange}
            value={formik.values.description}
            disabled={readOnlyInput}
            //   autoComplete="off"
            //   disabled={seeDetailsInformation}
            placeholder='Description'
          />
          {/* <FormErrorDisplayer
              touched={formikEtudeDossier.touched}
              errors={formikEtudeDossier.errors}
              name="folder_name"
              simple={true}
              mt={5}
              mb={0}
            /> */}
        </div>
        <div>
          <label className='bs-label-form'>
            Montant <sup>*</sup>
          </label>
          <input
            type='text'
            className='form-control bs-input-custom'
            name='montant'
            onChange={formik.handleChange}
            value={formik.values.montant}
            disabled={readOnlyInput}
            //   autoComplete="off"
            //   disabled={seeDetailsInformation}
            placeholder='Montant'
          />
          {/* <FormErrorDisplayer
              touched={formikEtudeDossier.touched}
              errors={formikEtudeDossier.errors}
              name="folder_name"
              simple={true}
              mt={5}
              mb={0}
            /> */}
        </div>
        <div>
          <label className='bs-label-form'>
            Type de mouvement <sup>*</sup>
          </label>
          <CustomSelect
            placeholder='Choisissez'
            name='typeMouvement'
            options={optionsTypeMvt}
            isDisabled={readOnlyInput}
            handleChange={({ value }, select) => {
              const e = {
                target: {
                  name: select.name,
                  value,
                },
              };
              formik.handleChange(e);
            }}
            fontSize='16px'
          />
        </div>
        <LoadingButton
          variant='contained'
          loading={isLoading}
          type='submit'
          disabled={readOnlyInput}
          color='primary'
          sx={{ width: "fit-content" }}
        >
          Enregistrer
        </LoadingButton>
      </form>
    </div>
  );
};
export default ComptableViewForm;
