import { createGlobalStyle, DefaultTheme } from "styled-components";
import convertThemeColorsToRootColors, {
  TThemeColors,
} from "../utils/convertThemeColorsToRootColors";

const GlobalStyle = createGlobalStyle`
   
    *, body{
        font-family: 'Raleway', sans-serif;
        box-sizing: border-box;
        /* color: var(--ui-tertiary-shade); */
    }


    *, ::after, ::before {
        box-sizing: border-box;
        /* font-family: 'Open Sans', sans-serif; */
        font-family: 'Raleway', sans-serif;
        padding: 0;
        margin: 0;
    }

    h1{
        font-family: 'Raleway', sans-serif;
        font-weight: normal ;
    }
    h2{
        font-family: 'Raleway', sans-serif;
        font-weight: 400 ;
    }
    h3{
        /* font-family: 'Lato', sans-serif; */
        font-family: 'Raleway', sans-serif;
        font-weight: normal;
    }
    h4{
        /* font-family: 'Lato', sans-serif; */
        font-family: 'Raleway', sans-serif;
        font-weight: 200;
    }
    h5{
        /* font-family: 'Lato', sans-serif; */
        font-family: 'Raleway', sans-serif;
        font-weight: 400;
    }
    p,a,span, button{
        /* font-family: 'Lato', sans-serif; */
        font-family: 'Raleway', sans-serif;
    }
    small{
        /* font-family: 'Lato', sans-serif; */
        font-family: 'Raleway', sans-serif;
        font-weight: 200;
    }

    .MuiButton-containedPrimary, .MuiButton-containedPrimary{
        border-radius: 0px;
        width: fit-content;
        padding: 10px 50px;
        /* margin-top: 20%; */
    }

    .fs-row{
        width: 100%;
        margin-left: 0px;
    }

    a.link-primary{
        color: #070E3B !important;
        font-weight: 700;
        font-size: 13.2px;
        text-decoration: underline;
    }

    .form-control:focus {
        border-color : 0.4px solid var(--ui-primary);
        box-shadow : none;
    }

    label.bs-label-form, .bs-input-custom {
        font-size: 12px;
        width: 100%;
        sup {
            color: var(--ui-danger);
            font-size: 17px;
            top: 0;
            // font-size: large;
        }
     }


    :root {
        --ui-primary-rgb: 238, 126, 5;
        --ui-secondary-rgb: 254,248,236;
        --ui-tertiary-rgb: 0, 144, 118;
        --ui-tertiary-shade-rgb: 21, 104, 89;
        --ui-dark-rgb: 37,37,37;
        --ui-medium-rgb: 54,56,83;
        --ui-light-rgb: 249,249,249;
        --ui-black-rgb: 0,0,0;
        --ui-white-rgb: 255,255,255;
        --ui-success-rgb: 117,216,115;
        --ui-error-rgb: 135,45,45;
        --ui-gray-rgb: 135, 144, 161;
        --ui-warning-rgb: 245, 181, 68;

        --title-size: 1.4rem;
        --subtitle-size: 1.1rem;
        --table-sub-title: 13px;
        --normal-size: 0.9rem;
        --small-size: 0.8rem;
        
        ${({ theme }: { theme: DefaultTheme & { colors: TThemeColors } }) => {
          return convertThemeColorsToRootColors(theme.colors);
        }}
    }

    a {
        text-decoration: none;
    }

    .m-top {
        margin-top: 0.65rem;
    }
    .table-preview-parent {
        position : relative;
    }
`;

export default GlobalStyle;
