import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { routePaths } from '@/config';

import LAdministration from '../layouts/Administration/Administration.layout';

import MainParametersPage from '../pages/Parameters/MainParameters.page';
//import ParametreTextesPage from "../pages-content/Administration-content/components/ParametreTextes---/ParametreTexte";

import ManageUsersPage from '../pages/Parameters/ManageUsers.page';
import ManageTextsPage from '../pages/Parameters/ManageTexts.page';



const AdministrationRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<LAdministration />}>
        <Route index element={<MainParametersPage />} />
        <Route
          path={routePaths.adminChildren.administrationChildren.user}
          element={<ManageUsersPage />}
        />
        <Route
          path={routePaths.adminChildren.administrationChildren.parametresBase}
          element={<ManageTextsPage />}
        />
      </Route>
    </Routes>
  );
};

export default AdministrationRoutes;
