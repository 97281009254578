import { Box } from "@mui/material";
import React, { useRef } from "react";
import { useFormik } from "formik";
import "./Folders.style.scss";
import FolderDocumentForm from "../Buyer/Steps/Documents/Document.form";
import * as Yup from "yup";
import { addFileToFolder } from "../../api/folder.api";
import { Loader } from "@/components/Loader/Loader";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { msgConnexionInterrompue } from "@/config/enum";

type TFileFoldersProps = {
  idFolder: string;
  currentFolder?: any;
  readOnlyInput?: boolean;
  setRefreshFilesData?: React.Dispatch<React.SetStateAction<string>>;
};
const FoldersForm: React.FC<TFileFoldersProps> = ({
  idFolder,
  currentFolder,
  readOnlyInput,
  setRefreshFilesData,
}) => {
  /// REF DECLARATION
  const inputTypeFileRef = useRef<HTMLInputElement>(null);
  const formFileRef = useRef<HTMLFormElement>(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const validationSchemaFileManagement: any = Yup.object({
    files: Yup.string().required(
      "Veuillez sélectionner un fichier (*png,jpg,jpeg) !"
    ),
    piece_type: Yup.string().required(
      "Veuillez sélectionner le type de pièce !"
    ),
  });

  // console.log(" currentFolder folder ", currentFolder);
  const formikFileManagement = useFormik({
    initialValues: {
      files: "",
      piece_type: "",
      folder_id: idFolder,
    },

    validationSchema: validationSchemaFileManagement,
    onSubmit: (values: any, { resetForm }) => {
      console.log("Les valeurs formik", values);
      setIsLoading(true);
      let formData = new FormData();

      if (values?.files && values?.piece_type) {
        formData.append("files", values?.files);
        formData.append("piece_type", values?.piece_type);
      }
      console.log("formData: ", formData);
      addFileToFolder(idFolder, formData)
        .then(response => {
          console.log({ formData });
          setIsLoading(false);
          formFileRef!.current!.reset();
          // inputTypeFileRef!.current!.value = '';
          console.log({ input: inputTypeFileRef.current });
          if (!response.error) {
            resetForm();
            formData.delete(values?.piece_type);
            ToastGeneric(
              "Opération effectuée avec succès !",
              "success",
              9000
            ).fire();
            setRefreshFilesData?.(new Date().getTime().toString());
          } else {
            ToastError.fire({ text: response.message, timer: 9000 });
          }
        })
        .catch(error => {
          setIsLoading(false);
          if (error?.response?.data?.message) {
            ToastError.fire({
              text: error?.response?.data?.message,
              timer: 9000,
            });
          } else {
            ToastError.fire({
              text: msgConnexionInterrompue.connexionInterrompue,
              timer: 9000,
            });
          }
        });
    },
  });
  // console.log({ errors: formikFileManagement.errors });

  return (
    <div className='folders-container'>
      <div className='folders-body'>
        <div className='folders-body-container'>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <div className='row d-flex justify-content-center align-items-center'>
              <div className='col-12'>
                <Loader isLoading={isLoading} />
                <FolderDocumentForm
                  inputTypeFileRef={inputTypeFileRef}
                  formFileRef={formFileRef}
                  formik={formikFileManagement}
                  readOnlyInput={readOnlyInput}
                  typeFile='folder'
                  setRefreshFilesList={setRefreshFilesData}
                  filesList={currentFolder?.files || []}
                />
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default FoldersForm;
