import React, { useEffect, useRef, useState } from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";
import { InlineIcon } from "@iconify/react";
import arrowDown from "@iconify/icons-ep/arrow-down"; 
import { TMenu } from "../../../types";
import useToggle from "@/hooks/useToggleWithTransition.hooks";
import {
  SideMenuContainer,
  SideMenuHeader,
  SideMenuLinkContainer,
} from "./SideMenu.styles";
import { Badge, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

type TSideMenuProps = {
  menu: TMenu;
  indexMenuOpen: number | null;
  myIndex: number;
  setIndexMenuOpen: React.Dispatch<React.SetStateAction<number | null>>;
};

type TSideMenuLink = {
  menu: TMenu;
  isActive: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
};

const SideMenuLink: React.FC<TSideMenuLink> = ({ menu, isActive, onClick }) => {
  const [active, setActive] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  return (
    menu && (
      <SideMenuLinkContainer
        down_lg = {matches.toString()}
        active={active.toString()}
        to={menu.route}
        onClick={onClick}
      >
        <>
        <InlineIcon
          icon={menu.icon}
        />
        {menu.label}
        {
            (menu.label === 'Notifications') &&
                <Badge  badgeContent={3} 
                        sx={{marginLeft:'20px'}}
                        color={ active ? "primary" :"warning" }/>
          }
        </>
      </SideMenuLinkContainer>
    )
  );
};

const SideMenu: React.FC<TSideMenuProps> = ({
  menu,
  indexMenuOpen,
  myIndex,
  setIndexMenuOpen,
}) => {
  let route = "none";

  if (typeof menu.route === "string") {
    route = menu.route;
  }

  const sideMenuContainerRef = useRef<HTMLDivElement>(null);
  const resolved = useResolvedPath(route);
  const match = useMatch({ path: resolved.pathname, end: !!menu.exact });

  const { setToggle, toggle, state, STATES, timeout, setState } = useToggle();

  useEffect(() => {
    if (!!match) {
      setIndexMenuOpen(myIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (myIndex === indexMenuOpen && !toggle) {
      setToggle(true);
    } else if (myIndex !== indexMenuOpen && toggle) {
      setToggle(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myIndex, indexMenuOpen]);

  useEffect(() => {
    if (state === STATES.CLOSING && sideMenuContainerRef.current) {
      sideMenuContainerRef.current.style.height = "";
      const timer = setTimeout(() => {
        setState(STATES.CLOSE);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    } else if (state === STATES.OPENING && sideMenuContainerRef.current) {
      // At same time
      sideMenuContainerRef.current.style.height = "unset"; //1 => remove height,
      const height =
        sideMenuContainerRef.current.getBoundingClientRect().height; //2 => get the height before DOM recalculate (repaint),
      sideMenuContainerRef.current.style.height = ""; // 3 => remove height property for easily create animation after DOM recalculate

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      document.body.offsetHeight; // Force DOM recalculate

      sideMenuContainerRef.current.style.height = height + "px";

      const timer = setTimeout(() => {
        setState(STATES.OPEN);
      }, timeout);

      return () => {
        clearTimeout(timer);
      };
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, timeout]);

  if (menu.exact === true) {
    return (
      <SideMenuLink
        menu={menu}
        isActive={!!match}
        onClick={() => setIndexMenuOpen(null)}
      />
    );
  }

  return (
    <SideMenuContainer
      duration={timeout}
      open={state !== STATES.CLOSE || !!match}
      ref={sideMenuContainerRef}
    >
      <SideMenuHeader
        onClick={() =>
          setIndexMenuOpen(myIndex !== indexMenuOpen ? myIndex : null)
        }
      >
        {
          menu.icon &&
          <InlineIcon
            className="start-icon"
            icon={menu.icon}
          />
        }
        {menu.label}{""}
        <InlineIcon
          className="end-icon"
          icon={arrowDown}
          rotate={!toggle && state === STATES.CLOSE ? 3 : 0}
        />
      </SideMenuHeader>
      {menu.children && (
        <ul>
          {menu.children.map((submenu, idx) => (
            <li key={`submenu-${idx}`}>
              <NavLink to={`${menu.route}${submenu.route}`}>
                {submenu.label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </SideMenuContainer>
  );
};

export default SideMenu;
