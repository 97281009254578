import {
  DataGrid,
  GridColDef,
  frFR,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import _ from "lodash";
import { Chip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// import { AccountantStatut } from '@/config/enum';
import DeleteFolderModal from "@/components/UICs/DeleteFolderModal/DeleteFolderModal";
import { getComptaByFolder } from "@/features/admin/api/comptabily.api";
import { tryCatchWrapper } from "@/utils/tryCatchWrapper";
import { formatMillier } from "@/utils/formatMillier";
import OwnerTable from "@/components/UICs/OwnerTable/OwnerTable";

interface IComptableViewTableProps {
  accountant: any;
  billSale: any;
  typeMvt: any;
  currentFolder: any;
  refreshFolder?: React.Dispatch<React.SetStateAction<string>>;
}

const ComptableViewTable = ({
  accountant,
  billSale,
  typeMvt,
  refreshFolder,
  currentFolder,
}: IComptableViewTableProps) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<any>({});
  const [accountantRows, setAccountantRows] = useState([]);
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "#",
      width: 90,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "clientName",
      headerName: "Clients",
      flex: 1,
      sortable: true,
    },
    {
      field: "mouvementType",
      headerName: "Type de Mouvement",
      flex: 1,
      sortable: true,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "montant",
      headerName: "Montant",
      flex: 1,
      sortable: true,
      headerAlign: "center",
      align: "center",
      renderCell: (params: GridRenderCellParams) =>
        formatMillier(params.row.montant),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,

      sortable: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Statut",
      //   flex: 1,
      width: 120,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={`${params.row.status ? "Validé" : "Annulée"}`}
          className={`chip-item ${params.row.status ? "valide" : "annule"}`}
        />
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.status ? (
          <IconButton
            title='Annuler une ligne comptable'
            color='error'
            aria-label='upload picture'
            component='label'
            onClick={() => {
              setOpenModal(true);
              setCurrentRow(params.row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ) : (
          ""
        ),
    },
  ];

  console.log("currentFolder comptable: ", currentFolder);

  const getAccountant = () => {
    tryCatchWrapper(async () => {
      const repsonse = await getComptaByFolder(currentFolder?._id);
      if (repsonse?.data) {
        const listAccountant = repsonse?.data.map(
          (accountantLine: any, index: number) => {
            console.log("account id", accountantLine?.id);
            console.log("account is_actc", accountantLine?.is_actif);
            return {
              id: index + 1,
              accountantId: accountantLine?.id,
              clientName: accountantLine?.client_id["personal_infos"].first_name
                ? accountantLine?.client_id["personal_infos"].first_name +
                  " " +
                  accountantLine?.client_id["personal_infos"].last_name
                : accountantLine?.client_id["legal_infos"]?.denomination,
              mouvementType: accountantLine?.type_compta,
              description: accountantLine?.description,
              montant: accountantLine?.montant,
              status: accountantLine?.is_actif,
            };
          }
        );

        setAccountantRows(listAccountant);
      }
    });
  };

  const refreshAccountant = () => {
    getAccountant();
  };

  useEffect(() => {
    getAccountant();
    // getLineComptaByFolder();
  }, [refreshFolder]);

  return (
    <div>
      <OwnerTable columns={columns} rows={accountantRows} />
      <DeleteFolderModal
        openDialog={openModal}
        accountant={currentRow}
        isDeleteAccountant={true}
        handleCloseDialog={() => {
          setOpenModal(false);
          refreshAccountant();
        }}
      />
    </div>
  );
};
export default ComptableViewTable;
