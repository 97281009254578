import React, { useState } from 'react';
import { routePaths } from '@/config';

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import './SigninForm.styles.scss';
import logo from '@/assets/images/png/logo_app.png';

import { loginUser } from '@/store/reducers/user/user.reducer';
import { store } from '@/store';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import WithFormikErrorMessage from '@/components/HOCs/WithFormikErrorMessage.hoc';
import AlertMessage from '@/components/UICs/AlertMessage/AlertMessage.uic';
import { LoadingButton } from '@mui/lab';
import { apiResetPasswordSendMail, logUser } from '../../api/auth.api';
import { ToastError, ToastGeneric } from '@/utils/toast';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type TLoginForm = {
  login: string;
  password: string;
};

const FormErrorDisplayer = WithFormikErrorMessage<TLoginForm>(AlertMessage);

const SigninForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const validationSchema: any = Yup.object({
    login: Yup.string()
      .max(100, 'La limite de caractère est ateinte')
      .trim()
      .required('Votre email est réquis'),
    password: Yup.string()
      .max(50, 'La limite de caractère est atteinte')
      .trim()
      .required('Votre mot de passe est réquis'),
  });

  const dispatch = store.dispatch;
  const navigate = useNavigate();

  const handleSubmit = async (values: TLoginForm) => {
    setIsloading(true);
    const user = {
      login: values.login,
      password: values.password,
    };

    logUser(user).then(async (resultat: any) => {
      // console.log("resultat",resultat);
      if (resultat.error) {
        setIsloading(false);
        // Toast error login mot de passe incorect
        ToastError.fire({
          text: resultat?.message,
          timer: 9000,
        });
        return;
      } else {
        // Verifier si c'est sa premiere connexion
        if (resultat.data && resultat.data.user) {
          if (values.password === 'AfriNotariat#2020') {
            // Ici on envoie le mail du code OTP
            apiResetPasswordSendMail({ email: values.login }).then(response => {
              if (!response.error) {
                ToastGeneric(
                  `Code de modification de mot passe envoyé, veuillez consulter votre adresse email ${values.login} `,
                  'success',
                  5000
                ).fire();
                localStorage.setItem('email', values.login);
                navigate(routePaths.authChildren.resetPasswordAbsolute);
              } else {
                ToastError.fire({ text: response.message, timer: 9000 });
              }
            });
          } else {
            // On dispatch et on faire la redirection
            await dispatch(loginUser(user));
            setIsloading(false);
            navigate(routePaths.admin);
          }
        }
      }
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit: handleSubmit,
  });

  return (
    <div className="signin-container">
      <div className="logo-part">
        <img src={logo} alt="Logo" />
      </div>
      <div className="fs-form-container">
        {/* <div className='return-container'>
          <Button className='return-button'>
            <Icon icon="clarity:times-line" className='icon-return'/>
          </Button>
        </div> */}
        <div className="header-part">
          <h1 className="title">Se connecter</h1>
          <p className="subtitle"> Bon retour </p>
          <small>Connectez-vous pour accédez à e-Notaire</small>
        </div>
        <div className="form-part">
          <form onSubmit={formik.handleSubmit}>
            <div className="row fs-row">
              <div className="col-12 mb-3">
                <label className="bs-label-form">
                  Adresse mail <sup>*</sup>
                </label>
                <input
                  type="email"
                  className="form-control bs-input-custom"
                  name="login"
                  autoComplete="off"
                  placeholder="Votre adresse mail"
                  value={formik.values.login}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <FormErrorDisplayer
                  touched={formik.touched}
                  errors={formik.errors}
                  name="login"
                  simple={true}
                  mt={5}
                  mb={0}
                />
              </div>
            </div>

            <div className="row fs-row">
              <div className="col-12 mb-3">
                <label className="bs-label-form">
                  Mot de passe <sup>*</sup>
                </label>
                <div className="input-with-show-password">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="form-control bs-input-custom"
                    name="password"
                    placeholder="Votre mot de passe"
                    autoComplete="off"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />

                  <IconButton
                    color="primary"
                    aria-label="toggle show password"
                    component="label"
                    className="toggle-show-password"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon
                        sx={{ width: '16px', height: '16px' }}
                      />
                    ) : (
                      <VisibilityIcon sx={{ width: '16px', height: '16px' }} />
                    )}
                  </IconButton>
                </div>

                <FormErrorDisplayer
                  touched={formik.touched}
                  errors={formik.errors}
                  name="password"
                  simple={true}
                  mt={5}
                  mb={0}
                />
              </div>
            </div>
            <div className="remember-me">
              <FormGroup>
                <FormControlLabel
                  color="quartiary"
                  className="remember-me-text"
                  control={<Checkbox />}
                  label="Se souvenir de moi"
                />
              </FormGroup>
              <Link
                className="link-primary"
                to={routePaths.authChildren.forgottenPasswordAbsolute}
              >
                Mot de passe oublié ?
              </Link>
            </div>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              sx={{ marginTop: '30px' }}
              loading={isLoading}
              disabled={!formik.values.login || !formik.values.password}
            >
              SE CONNECTER
            </LoadingButton>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SigninForm;
