import React, { FC } from "react";
import "./DetailsMoral.scss";

type TDatailProps = {
  user: any;
};

const DetailsMoral: FC<TDatailProps> = ({ user }) => {
  console.log("user moral: ", user);
  return (
    <div className='user-details-container'>
      <div className='user-datail-control'>
        <span className='label'>Dénomination : </span>
        <span className='value'>{user?.legal_infos?.denomination}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>E-mail : </span>
        <span className='value'>{user?.legal_infos?.email}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Forme Juridique : </span>
        <span className='value'>{user?.legal_infos?.legal_form}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Fontion : </span>
        <span className='value'>{user?.legal_infos?.capital} Fcfa</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Bureau d'enregistrement : </span>
        <span className='value'>{user?.legal_infos?.registered_office}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>N° Régistre de commerce : </span>
        <span className='value'> {user?.legal_infos?.registration}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Téléphone : </span>
        <span className='value'>{user?.legal_infos?.phone_number}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Infos complémentaires : </span>
        <span className='value'>{user?.legal_infos?.further_informations}</span>
      </div>

      <div className='infos-dirigeant'>
        <div className='title-container'>
          <b className='title'>Infos dirigéant</b>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Civilité : </span>
          <span className='value'>{user?.personal_infos?.civility}</span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Nom et Prénoms : </span>
          <span className='value'>
            {user?.personal_infos?.last_name} {user?.personal_infos?.first_name}
          </span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>E-mail : </span>
          <span className='value'>{user?.personal_infos?.email}</span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Fontion : </span>
          <span className='value'>{user?.personal_infos?.job}</span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Nationnalité : </span>
          <span className='value'>{user?.personal_infos?.nationality}</span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Date et lieu de naissance : </span>
          <span className='value'>
            {user?.personal_infos?.birth_date} à{" "}
            {user?.personal_infos?.birth_place}
          </span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Téléphone : </span>
          <span className='value'>{user?.personal_infos?.phone_number}</span>
        </div>
        <div className='user-datail-control'>
          <span className='label'>Numéro pièce : </span>
          <span className='value'>{user?.personal_infos?.piece_number}</span>
        </div>
      </div>
    </div>
  );
};

export default DetailsMoral;
