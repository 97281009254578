import React from 'react';
import FSHeader from '../Elements/FSHeader/FSHeader.element';
import UpdatePasswordForm from '../forms/UpdatePasswordForm/UpdatePasswordForm';


const UpdatePassword: React.FC = () => {
  return (
    <div className='fs-container'>
      <FSHeader title="Modification du mot de passe" icon="ic:baseline-lock-person" breadscrumbTitle="Prospects"/>
      <UpdatePasswordForm />
    </div>
  )
}

export default UpdatePassword;
