import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";

// import BuyerStep1Form from "./Steps/StepPersonalInfo.form";

import "./Buyer.container.style.scss";
import { FILE_SIZE, SUPPORTED_FORMATS } from "@/config";
import {
  TBuyerPersonalInfoFormValues,
  TSeller,
  TSellerMaritalStatusInfo,
  TSellerPersonnalInfo,
} from "../../types";
import * as _ from "lodash";
import {
  createBuyer,
  createSeller,
  updatePersonneInfo,
  updatePersonnePhysicInfo,
  updateWithDataFiles,
} from "../../api/folder.api";
import { Loader } from "@/components/Loader/Loader";
import moment from "moment";
import { ToastError, ToastGeneric } from "@/utils/toast";
import BuyerStepPersonnalInfoForm from "./Steps/StepPersonalInfo.form";
import StepMaritalStatusForm from "./Steps/StepMaritalStatus.form";
import { formatDateInIsoDate } from "@/utils/formatDate";
import {
  AppCredentials,
  msgConnexionInterrompue,
  StatutMatrimonial,
} from "@/config/enum";
import FolderDocumentForm from "./Steps/Documents/Document.form";
import { usePersonSelectedIdContext } from "../../contexts/selectedPerson.context";
import { store } from "@/store";
// import { setCurrentFolder } from "@/store/reducers/folder/folder.action";

export type TBuyerFormStep1Values = {
  sellerCivility: string;
  sellerFirstName: string;
  sellerEmail: string | null;
  sellerLastName: string;
  sellerTelephone: string;
  sellerPrefession: string;
  sellerNationality: string;
  sellerLieuNaissance: string;
  sellerDateNaissance: string | null;
  sellerNaturePiece: string;
  sellerNumeroPiece: string;
  sellerLieuEtablissement: string;
  sellerDateExpiration: string | null;
  sellerDateEtablissement: string | null;
  sellerInfosPlus?: string | undefined;
};

export type TBuyerFormStep1WithObjectValues = {
  personnalInfo?: TBuyerFormStep1Values;
  martialPersonalInfo?: TBuyerFormStep1Values;
};

export type TBuyerFormStep3Values = {
  sellerFolderFileName?: string;
  sellerFolderFileDescription?: string;
  sellerFolderFile?: File | null;
};

export type TBuyerFormValues = TBuyerFormStep1WithObjectValues &
  TBuyerPersonalInfoFormValues &
  TBuyerFormStep3Values;

interface CBuyerFormProps {
  // folderType: number;
  folderReference: string;
  operation: "Create" | "Update";
  entity: "Seller" | "Buyer";
  setIdFolder?: React.Dispatch<React.SetStateAction<string>>;
  setReferenceFolder?: React.Dispatch<React.SetStateAction<string>>;
  setRefreshDataCurrentFolder?: React.Dispatch<React.SetStateAction<string>>;
  currentPersonInfoToUpdate?: TSellerPersonnalInfo;
  currentMatialStatusToUpdate?: TSellerMaritalStatusInfo;
  readOnlyInput?: boolean;
  currentDataPersonne?: any;
}
const CBuyerForm: React.FC<CBuyerFormProps> = ({
  // folderType,
  folderReference,
  entity,
  operation,
  currentPersonInfoToUpdate,
  setIdFolder,
  setReferenceFolder,
  setRefreshDataCurrentFolder,
  readOnlyInput,
  currentMatialStatusToUpdate,
  currentDataPersonne,
}) => {
  const { personSelectedId, setPersonSelectedId } =
    usePersonSelectedIdContext();
  const [oldFilesPerson, setOldFilesPerson] = useState<any[]>([]);
  const [operationValue, setOperationValue] = useState(operation);
  const [refreshCurrentFolder, setRefreshCurrentFolder] = useState<string>("");
  const currentEntryPersonalInfo = "personnalInfo";

  const [value, setValue] = React.useState("1");
  const [step2Opened, setStep2Opened] = React.useState(false);
  const [celibataire, setCelibataire] = React.useState(true);
  const [marie, setMarie] = React.useState(false);
  const [divorce, setDivorce] = React.useState(false);
  const [veuf, setVeuf] = React.useState(false);
  const [currentIdFolder, setCurrentIdFolder] = useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isFileFormOpen, setIsFileFormOpen] = React.useState(false);

  const initialMaritalAndPersonnalInfo: TBuyerFormStep1Values = {
    sellerCivility: "",
    sellerFirstName: "",
    sellerLastName: "",
    sellerTelephone: "",
    sellerPrefession: "",
    sellerNationality: "",
    sellerLieuNaissance: "",
    sellerDateNaissance: null,
    sellerNaturePiece: "",
    sellerNumeroPiece: "",
    sellerLieuEtablissement: "",
    sellerDateExpiration: null,
    sellerInfosPlus: "",
    sellerDateEtablissement: null,
    sellerEmail: "",
  };

  const initialValues: TBuyerFormValues = {
    personnalInfo: {
      ...initialMaritalAndPersonnalInfo,
    },

    martialPersonalInfo: {
      ...initialMaritalAndPersonnalInfo,
    },

    // Situation matrimoniale célibataire
    sellerCelibaDomicile: "",
    sellerCelibaInfosPlus: "",
    // Situation matrimoniale Marié
    sellerMarieRegime: "",
    sellerMarieDate: null,
    sellerMarieLieu: "",
    sellerMarieCivility: "",
    sellerMarieFirstName: "",
    sellerMarieLastName: "",
    sellerMarieTelephone: "",
    sellerMarieProfession: "",
    sellerMarieNationality: "",
    // Situation matrimoniale Divorcé
    sellerDivorceRemarie: "",
    sellerDivorceLieu: "",
    sellerDivorceDate: null,
    sellerDivorceInforPlus: "",
    // Situation matrimoniale Veuf(ve)
    sellerVeufInfosPlus: "",

    sellerFolderFileName: "",
    sellerFolderFileDescription: "",
    sellerFolderFile: null,
  };

  const step1Validation = {
    sellerCivility: Yup.string().required("La civilité est requise"),
    sellerFirstName: Yup.string().required("Le nom est requis"),
    sellerLastName: Yup.string().required("Le prénom est requis"),
    sellerTelephone: Yup.string().required("Le numéro de téléphone est requis"),
    sellerPrefession: Yup.string().required("La profession est requise"),
    sellerEmail: Yup.string().required("L'email est requis"),
    sellerNationality: Yup.string().required("La nationalité est requise"),
    sellerLieuNaissance: Yup.string().required(
      "Le lieu de naissance est requis"
    ),
    sellerDateNaissance: Yup.date()
      .required("La date de naissance est requise")
      .transform((value, originalValue) => {
        return originalValue === null ? undefined : originalValue;
      }),
    sellerNaturePiece: Yup.string().required(
      "Veuillez choisir une nature de pièce"
    ),
    sellerNombrePiece: Yup.string().required(
      "Le numéro de la pièce est requis"
    ),
    sellerLieuEtablissement: Yup.string().required(
      "Le lieu d'établissement est requis"
    ),
    sellerDateExpiration: Yup.date()
      .required("La date est requise")
      .transform((value, originalValue) => {
        return originalValue === null ? undefined : originalValue;
      }),
    sellerDateEtablissement: Yup.date()
      .required("La date est requise")
      .transform((value, originalValue) => {
        return originalValue === null ? undefined : originalValue;
      }),
    sellerInfosPlus: Yup.string(),
  };

  const step2Validation = () => {
    if (celibataire) {
      return {
        sellerCelibaDomicile: Yup.string().required("Le domicile est requis"),
        sellerCelibaInfosPlus: Yup.string(),
      };
    } else if (marie) {
      return {
        sellerMarieRegime: Yup.string().required(
          "Le régime matrimonial est requis"
        ),
        sellerMarieDate: Yup.date()
          .required("La date de mariage est requis")
          .transform((value, originalValue) => {
            return originalValue === null ? undefined : originalValue;
          }),
        sellerMarieLieu: Yup.string().required(
          "Le lieu d'anniversaire est requis"
        ),
        sellerMarieCivility: Yup.string().required("La civilité est requise"),
        sellerMarieFirstName: Yup.string().required(
          "Un prénom au moins est requis"
        ),
        sellerMarieLastName: Yup.string().required(
          "Un nom au moins est requis"
        ),
        sellerMarieTelephone: Yup.string().required(
          "Le numéro de tel est requis"
        ),
        sellerMarieProfession: Yup.string().required(
          "La profession est requise"
        ),
        sellerMarieNationality: Yup.string().required(
          "La nationalité est requise"
        ),
      };
    } else if (divorce) {
      return {
        sellerDivorceRemarie: Yup.string().required("Faites un choix"),
        sellerDivorceLieu: Yup.string().required(
          "Le lieu de divorce est requis"
        ),
        sellerDivorceDate: Yup.date()
          .required("La date de divorce est requis")
          .transform((value, originalValue) => {
            return originalValue === null ? undefined : originalValue;
          }),
        sellerDivorceInforPlus: Yup.string(),
      };
    } else if (veuf) {
      return {
        sellerVeufInfosPlus: Yup.string(),
      };
    }
  };

  const step3Valition = {
    sellerFolderFileName: Yup.string().required(
      "Le nom de la pièce est requis"
    ),
    sellerFolderFileDescription: Yup.string().required(
      "La description de la pièce est requise"
    ),
    sellerFolderFile: Yup.mixed()
      .required("Veuillez importer un fichier.")
      .test(
        "fileFormat",
        "Format non supporté (envoyé une image ou un pdf)",
        value => value && SUPPORTED_FORMATS.includes(value.type)
      )
      .test("fileSize", "Votre fichier est trop volumineux", value =>
        value ? value.size <= FILE_SIZE : true
      ),
  };

  const [validation, setValidation] = React.useState(
    Yup.object().shape(step1Validation)
  );

  const formatMartialStatusPersonnalInfo = (
    maritalInfo: TSellerMaritalStatusInfo,
    formikValue: any
  ) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    maritalInfo.partenaire_civility =
      formikValue?.martialPersonalInfo?.sellerCivility || "";
    maritalInfo.partenaire_last_name =
      formikValue?.martialPersonalInfo?.sellerLastName || "";
    maritalInfo.partenaire_first_name =
      formikValue?.martialPersonalInfo?.sellerFirstName || "";
    maritalInfo.partenaire_phone_number =
      formikValue?.martialPersonalInfo?.sellerTelephone || "";
    maritalInfo.partenaire_job =
      formikValue?.martialPersonalInfo?.sellerPrefession || "";
    maritalInfo.partenaire_nationality =
      formikValue?.martialPersonalInfo?.sellerNationality || "";
    maritalInfo.partenaire_birth_place =
      formikValue?.martialPersonalInfo?.sellerLieuNaissance || "";
    maritalInfo.partenaire_birth_date = formikValue?.martialPersonalInfo
      ?.sellerDateNaissance
      ? moment(formikValue?.martialPersonalInfo?.sellerDateNaissance).format(
          "DD/MM/YYYY"
        )
      : "";
    maritalInfo.partenaire_piece_type =
      formikValue?.martialPersonalInfo?.sellerNaturePiece || "";
    maritalInfo.partenaire_piece_number =
      formikValue?.martialPersonalInfo?.sellerNumeroPiece || "";
    maritalInfo.partenaire_piece_establishment_date = formikValue
      ?.martialPersonalInfo?.sellerDateEtablissement
      ? moment(
          formikValue?.martialPersonalInfo?.sellerDateEtablissement
        ).format("DD/MM/YYYY")
      : "";
    maritalInfo.partenaire_piece_expiration_date = formikValue
      ?.martialPersonalInfo?.sellerDateExpiration
      ? moment(formikValue?.sellerDateExpiration).format("DD/MM/YYYY")
      : "";
    maritalInfo.partenaire_further_informations =
      formikValue?.martialPersonalInfo?.sellerInfosPlus || "";
    maritalInfo.partenaire_email =
      formikValue?.martialPersonalInfo?.sellerEmail || "";
    maritalInfo.partenaire_piece_establishment_place =
      formikValue?.martialPersonalInfo?.sellerLieuEtablissement || "";
  };

  /** Reference du dossier */
  const [referenceFolderAfterCreate, setReferenceFolderAfterCreate] =
    useState(folderReference);
  const [currentIdPersonneSaved, setCurrentIdPersonneSaved] = useState<
    string | null | undefined
  >("");

  React.useEffect(() => {
    if (isFileFormOpen) handleStep3RegimeChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFileFormOpen]);

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: validation,
    // validationSchema: step1Validation,
    onSubmit: async (values: any) => {
      console.log(" values ", values);

      /** FAIRE DES VERIFICATIONS MANUELLES AU VU DE LA COMPLEXITE DU FORMULAIRE */
      if (
        !values[currentEntryPersonalInfo].sellerCivility ||
        !values[currentEntryPersonalInfo].sellerLastName ||
        !values[currentEntryPersonalInfo].sellerFirstName ||
        !values[currentEntryPersonalInfo].sellerTelephone ||
        !values[currentEntryPersonalInfo].sellerPrefession ||
        !values[currentEntryPersonalInfo].sellerNationality ||
        !values[currentEntryPersonalInfo].sellerEmail ||
        !values[currentEntryPersonalInfo].sellerLieuNaissance ||
        !values[currentEntryPersonalInfo].sellerDateNaissance ||
        !values[currentEntryPersonalInfo].sellerNaturePiece ||
        !values[currentEntryPersonalInfo].sellerNumeroPiece ||
        !values[currentEntryPersonalInfo].sellerDateEtablissement
      ) {
        ToastError.fire({
          text: "Veuillez renseigner les champs obligatoires à l'étape Informations personnelles !",
          timer: 9000,
        });
        setValue("1");
        return;
      }

      setIsLoading(true);

      /** Formater les informations personnelles */
      let personalInfo: TSellerPersonnalInfo = {
        civility: values.personnalInfo.sellerCivility,
        last_name: values.personnalInfo.sellerLastName,
        first_name: values.personnalInfo.sellerFirstName,
        phone_number: values.personnalInfo.sellerTelephone,
        job: values.personnalInfo.sellerPrefession,
        nationality: values.personnalInfo.sellerNationality,
        birth_place: values.personnalInfo.sellerLieuNaissance,
        birth_date: values.personnalInfo.sellerDateNaissance
          ? moment(values.personnalInfo.sellerDateNaissance).format(
              "DD/MM/YYYY"
            )
          : "",
        piece_type: values.personnalInfo.sellerNaturePiece,
        piece_number: values.personnalInfo.sellerNumeroPiece,
        piece_establishment_date: values.personnalInfo.sellerDateEtablissement
          ? moment(values.personnalInfo.sellerDateEtablissement).format(
              "DD/MM/YYYY"
            )
          : "",
        piece_expiration_date: values.personnalInfo.sellerDateExpiration
          ? moment(values.sellerDateExpiration).format("DD/MM/YYYY")
          : "",
        further_informations: values.personnalInfo.sellerInfosPlus,
        email: values.personnalInfo.sellerEmail,
        piece_establishment_place: values.personnalInfo.sellerLieuEtablissement,
      };

      /** formater les informations marital  */
      let maritalInfo: TSellerMaritalStatusInfo = {
        marital_status: celibataire
          ? StatutMatrimonial.celibataire
          : marie
          ? StatutMatrimonial.marie
          : divorce
          ? StatutMatrimonial.divorce
          : veuf
          ? StatutMatrimonial.veuf
          : "",
        place: celibataire ? values.sellerCelibaDomicile : "",
        marital_regime: values.sellerMarieRegime,
        marital_date: values.sellerMarieDate
          ? moment(values.sellerMarieDate).format("DD/MM/YYYY")
          : "",
        marital_place: values.sellerMarieLieu,

        /*Information partenaire marié ou divorce remarié */
        wife_civility: values.sellerMarieCivility,
        wife_first_name: values.sellerMarieFirstName,
        wife_last_name: values.sellerMarieLastName,
        wife_phone_number: values.sellerMarieTelephone,
        wife_job: values.sellerMarieProfession,
        wife_nationality: values.sellerMarieNationality,

        /* Fin information partenaire marié divorcé */

        remarried: divorce ? values.sellerDivorceRemarie : "",
        divorce_instance: divorce ? values.sellerDivorceLieu : "",
        divorce_date: divorce
          ? values.sellerDivorceDate
            ? moment(values.sellerDivorceDate).format("DD/MM/YYYY")
            : ""
          : "",
        further_informations: celibataire
          ? values.sellerCelibaInfosPlus
          : divorce
          ? values.sellerDivorceInforPlus
          : veuf
          ? values.sellerVeufInfosPlus
          : "",
      };

      /** Dans le cas ou il est marié ou divorcé et remarié on envoi les info du conjoint */
      if (
        maritalInfo.marital_status.toLowerCase() === StatutMatrimonial.marie ||
        values.sellerDivorceRemarie?.toLowerCase() === "oui"
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        formatMartialStatusPersonnalInfo(maritalInfo, values);
      } else {
        formatMartialStatusPersonnalInfo(maritalInfo, {});
      }

      let folderInfo: TSeller = {
        folder_reference: referenceFolderAfterCreate || folderReference,
        folder_type: "1",
        person_type: "1",
        personal_infos: personalInfo,
        marital_infos: maritalInfo,
        client_id: personSelectedId?.physique || "",
      };

      console.log("currentIdPersonneSaved values: ", currentIdPersonneSaved);

      if (currentIdPersonneSaved) {
        /** En ce moment nous sommes en modification des information d'une personne (vendeur ou acquereur) */
        const userId = store.getState().user.userConnected._id;

        let formData = new FormData();

        let documentFormData = new FormData();

        formData.append("id", currentIdPersonneSaved);
        formData.append("personal_infos", JSON.stringify(personalInfo));
        formData.append("marital_infos", JSON.stringify(maritalInfo));

        console.log("Values files: ", values?.files);
        console.log("Values piece_type: ", values?.piece_type);

        if (values?.files && values?.piece_type) {
          // formData.append(
          //   values?.sellerFolderFileName,
          //   values?.sellerFolderFile
          // );
          documentFormData.append("files", values?.files);
          documentFormData.append("piece_type", values?.piece_type);
        }

        console.log(
          "documentFormData: ",
          documentFormData,
          currentIdPersonneSaved
        );

        // Ajout des documents des VENDEURS et ACQUEREURS
        if (
          documentFormData.has("files") &&
          documentFormData.has("piece_type")
        ) {
          const result = await updateWithDataFiles(
            currentIdPersonneSaved,
            documentFormData
          );
          setIsLoading(false);
          if (!result.error) {
            ToastGeneric(
              "Opération effectuée avec succès !",
              "success",
              9000
            ).fire();
            setRefreshDataCurrentFolder?.(new Date().getTime().toString());
          } else {
            ToastError.fire({ text: result.message, timer: 9000 });
          }
          return;
        }

        formData.append("app_secret", AppCredentials.APP_SECRET);
        formData.append("app_id", AppCredentials.APP_ID);
        formData.append("user_id", userId);
        formData.append(
          "folder_id",
          currentPersonInfoToUpdate?.folder! || currentIdFolder
        );

        updatePersonneInfo(formData, currentIdPersonneSaved)
          .then(response => {
            setIsLoading(false);
            if (!response.error) {
              ToastGeneric(
                "Opération effectuée avec succès !",
                "success",
                9000
              ).fire();
              setRefreshDataCurrentFolder?.(new Date().getTime().toString());
            } else {
              ToastError.fire({ text: response.message, timer: 9000 });
            }
          })
          .catch(error => {
            console.log(" error ", error);
            setIsLoading(false);
            if (error?.response?.data?.message) {
              ToastError.fire({
                text: error?.response?.data?.message,
                timer: 9000,
              });
            } else {
              ToastError.fire({
                text: msgConnexionInterrompue.connexionInterrompue,
                timer: 9000,
              });
            }
          });
      } else {
        /** Il s'agit de la premiere création d'un vendeur ou d'un acquerreur */

        let createData =
          entity?.toLowerCase() === "seller"
            ? createSeller(folderInfo)
            : createBuyer(folderInfo);

        createData
          .then(response => {
            setIsLoading(false);

            if (response.error) {
              ToastError.fire({ text: response.message, timer: 9000 });
            } else {
              ToastGeneric(
                "Opération effectuée avec succès !",
                "success",
                9000
              ).fire();

              /** Actualiser la liste des vendeurs en faisant un dispatch de la reference */
              setIdFolder?.(response.data?.id || response.data?._id);
              setCurrentIdFolder(response.data?.id || response.data?._id);
              setReferenceFolder?.(response.data?.reference);

              if (entity?.toLowerCase() === "seller") {
                if (response.data?.seller_ids) {
                  setCurrentIdPersonneSaved(
                    response.data?.seller_ids[
                      response.data?.seller_ids.length - 1
                    ]?.personal_infos?.person_id
                  );
                }
              } else {
                /** Il s'agit d'un acquereur */
                if (response.data?.buyers_ids) {
                  setCurrentIdPersonneSaved(
                    response.data?.buyers_ids[
                      response.data?.buyers_ids.length - 1
                    ]?.personal_infos?.person_id
                  );
                }
              }

              setReferenceFolderAfterCreate?.(response.data?.reference);
              setRefreshDataCurrentFolder?.(new Date().getTime().toString());
              setOperationValue("Update");
            }
          })
          .catch(error => {
            console.log(" error ", error);
            setIsLoading(false);
            if (error?.response?.data?.message) {
              ToastError.fire({
                text: error?.response?.data?.message,
                timer: 9000,
              });
            } else {
              ToastError.fire({ text: error, timer: 9000 });
            }
          });
      }
    },
  });

  const handleStep2RegimeChange = () => {
    const step2Val: any = step2Validation();
    let step3Val: typeof step3Valition | {} = {};
    if (isFileFormOpen) {
      step3Val = step3Valition;
    }
    // setValidation(
    //   Yup.object().shape({ ...step1Validation, ...step2Val, ...step3Val })
    // );
  };

  const handleStep3RegimeChange = () => {
    const step2Val: any = step2Validation();
    if (step2Opened) {
      setValidation(
        Yup.object().shape({
          ...step1Validation,
          ...step2Val,
          ...step3Valition,
        })
      );
    } else {
      // setValidation(
      //   Yup.object().shape({ ...step1Validation, ...step3Valition })
      // );
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // function createData(label: string, value: string) {
  //   return { label, value };
  // }

  const setDefautValuePersonnalInfoModify = (
    itemPersonalInfo: TSellerPersonnalInfo,
    itemMaritalStatus?: TSellerMaritalStatusInfo
  ) => {
    if (
      itemPersonalInfo &&
      (itemPersonalInfo.person_id || itemPersonalInfo._person_id)
    ) {
      formik.setValues({
        personnalInfo: {
          sellerCivility: itemPersonalInfo.civility,
          sellerFirstName: itemPersonalInfo.first_name,
          sellerLastName: itemPersonalInfo.last_name,
          sellerTelephone: itemPersonalInfo.phone_number,
          sellerPrefession: itemPersonalInfo.job,
          sellerNationality: itemPersonalInfo.nationality,
          sellerLieuNaissance: itemPersonalInfo.birth_place,
          sellerDateNaissance: formatDateInIsoDate(itemPersonalInfo.birth_date),
          sellerNaturePiece: itemPersonalInfo.piece_type,
          sellerNumeroPiece: itemPersonalInfo.piece_number,
          sellerDateExpiration: formatDateInIsoDate(
            itemPersonalInfo.piece_expiration_date
          ),
          sellerInfosPlus: itemPersonalInfo.further_informations,
          sellerDateEtablissement: formatDateInIsoDate(
            itemPersonalInfo.piece_establishment_date
          ),
          sellerEmail: itemPersonalInfo.email || "",
          sellerLieuEtablissement:
            itemPersonalInfo.piece_establishment_place || "",
        },
        martialPersonalInfo: {
          sellerCivility: itemMaritalStatus?.partenaire_civility!,
          sellerFirstName: itemMaritalStatus?.partenaire_first_name!,
          sellerLastName: itemMaritalStatus?.partenaire_last_name!,
          sellerTelephone: itemMaritalStatus?.partenaire_phone_number!,
          sellerPrefession: itemMaritalStatus?.partenaire_job!,
          sellerNationality: itemMaritalStatus?.partenaire_nationality!,
          sellerLieuNaissance: itemMaritalStatus?.partenaire_birth_place!,
          sellerDateNaissance: formatDateInIsoDate(
            itemMaritalStatus?.partenaire_birth_date
          ),
          sellerNaturePiece: itemMaritalStatus?.partenaire_piece_type!,
          sellerNumeroPiece: itemMaritalStatus?.partenaire_piece_number!,
          sellerDateExpiration: formatDateInIsoDate(
            itemMaritalStatus?.partenaire_piece_expiration_date
          ),
          sellerInfosPlus: itemMaritalStatus?.partenaire_further_informations,
          sellerDateEtablissement: formatDateInIsoDate(
            itemMaritalStatus?.partenaire_piece_establishment_date
          ),
          sellerEmail: itemMaritalStatus?.partenaire_email || "",
          sellerLieuEtablissement:
            itemMaritalStatus?.partenaire_piece_establishment_place || "",
        },
        sellerMarieRegime: itemMaritalStatus?.marital_regime,
        sellerMarieDate: formatDateInIsoDate(itemMaritalStatus?.marital_date),
        sellerMarieLieu: itemMaritalStatus?.marital_place!,
        sellerMarieCivility: itemMaritalStatus?.wife_civility,
        sellerMarieFirstName: itemMaritalStatus?.wife_first_name,
        sellerMarieLastName: itemMaritalStatus?.wife_last_name,
        sellerMarieTelephone: itemMaritalStatus?.wife_phone_number,
        sellerMarieProfession: itemMaritalStatus?.wife_job,
        sellerMarieNationality: itemMaritalStatus?.wife_nationality,
        sellerDivorceRemarie: itemMaritalStatus?.remarried,
        sellerDivorceLieu: itemMaritalStatus?.divorce_instance,
        sellerDivorceDate: formatDateInIsoDate(itemMaritalStatus?.divorce_date),
        sellerDivorceInforPlus:
          itemMaritalStatus?.marital_status.toLowerCase() ===
          StatutMatrimonial.divorce
            ? itemMaritalStatus?.further_informations
            : "",
        sellerCelibaInfosPlus:
          itemMaritalStatus?.marital_status.toLowerCase() ===
          StatutMatrimonial.celibataire
            ? itemMaritalStatus?.further_informations
            : "",
        sellerVeufInfosPlus:
          itemMaritalStatus?.marital_status.toLowerCase() ===
          StatutMatrimonial.veuf
            ? itemMaritalStatus?.further_informations
            : "",
        sellerCelibaDomicile: itemMaritalStatus?.place!,
      });

      /** Setter la situation matrimoniale */
      if (itemMaritalStatus && itemMaritalStatus.marital_status) {
        switch (itemMaritalStatus.marital_status.toLowerCase()) {
          case StatutMatrimonial.celibataire:
            isCelibataire();
            break;

          case StatutMatrimonial.marie:
            isMarie();
            break;

          case StatutMatrimonial.veuf:
            isVeuf();
            break;

          case StatutMatrimonial.divorce:
            isDivorce();
            break;

          default:
            break;
        }
      }
    }
  };

  function isCelibataire() {
    if (!step2Opened) {
      setStep2Opened(true);
    }
    if (!celibataire) {
      setMarie(false);
      setDivorce(false);
      setVeuf(false);
      setCelibataire(true);
    }
  }
  function isMarie() {
    if (!step2Opened) {
      setStep2Opened(true);
    }
    if (!marie) {
      setDivorce(false);
      setVeuf(false);
      setCelibataire(false);
      setMarie(true);
    }
  }
  function isDivorce() {
    if (!step2Opened) {
      setStep2Opened(true);
    }
    if (!divorce) {
      setMarie(false);
      setVeuf(false);
      setCelibataire(false);
      setDivorce(true);
    }
  }
  function isVeuf() {
    if (!step2Opened) {
      setStep2Opened(true);
    }
    if (!veuf) {
      setMarie(false);
      setDivorce(false);
      setCelibataire(false);
      setVeuf(true);
    }
  }

  const getOldFilesPersonnalInfo = () => {
    if (currentDataPersonne) {
      //On verifie le type si c'est un acquereur ou un vendeur
      let listeSellers = currentDataPersonne?.seller_ids;
      let listBuyers = currentDataPersonne?.buyers_ids;

      /** Je verifie dans la liste des buyers en fonction de l'id personne */
      let currentPersonne = _.find(
        entity?.toLowerCase() === "buyer" ? listBuyers : listeSellers,
        o => {
          return (
            o?.personal_infos?.person_id === currentIdPersonneSaved ||
            o?.personal_infos?.person_id ===
              currentPersonInfoToUpdate?.person_id
          );
        }
      );

      if (currentPersonne) {
        setOldFilesPerson(currentPersonne?.files);
      }
    }
  };

  // useEffect(() => {
  //   console.log({ personSelectedId });
  // }, [personSelectedId]);

  useEffect(() => {
    if (currentPersonInfoToUpdate) {
      // On modifie le current personnal info en fonction de la personne en cours de traitement
      let currentPersonne = _.find(
        entity?.toLowerCase() === "buyer"
          ? currentDataPersonne?.buyers_ids
          : currentDataPersonne?.seller_ids,
        o => {
          return (
            o?._id === currentIdPersonneSaved ||
            o?._id === currentPersonInfoToUpdate?.person_id
          );
        }
      );

      setDefautValuePersonnalInfoModify(
        currentPersonne?.personal_infos,
        currentPersonne?.marital_status
      );

      if (currentPersonInfoToUpdate?.person_id) {
        setCurrentIdPersonneSaved(currentPersonInfoToUpdate?.person_id);
      }

      getOldFilesPersonnalInfo();
    }
    //eslint-disable-next-line
  }, [currentDataPersonne]);

  useEffect(() => {
    setPersonSelectedId({ moral: " ", physique: "" });
  }, []);

  useEffect(() => {
    setRefreshDataCurrentFolder?.(new Date().getDate().toString());
    // eslint-disable-next-line
  }, [refreshCurrentFolder]);

  /** Update info personne physique à l'initial */

  return (
    <div className='fs-modal-container'>
      <div className='fs-modal-body'>
        {value !== "1" && (
          <div className='select-container'>
            <header>
              <span>Informations sur la personne</span>
            </header>
            <div className='row fs-row'>
              <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                <label className='bs-label-form'>
                  Nom : <b>{formik.values?.personnalInfo?.sellerLastName}</b>
                </label>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                <label className='bs-label-form'>
                  Prénoms :{" "}
                  <b>{formik.values?.personnalInfo?.sellerFirstName}</b>
                </label>
              </div>
            </div>
          </div>
        )}
        <div className='fs-modal-body-container'>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                className='offre-box'
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <TabList
                  className='offre-tabs'
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label='Informations personnelles'
                >
                  <Tab label='Informations personnelles' value='1' />
                  <Tab
                    label='Situation matrimoniale'
                    value='2'
                    disabled={operationValue === "Create"}
                  />
                  <Tab
                    label='Documents'
                    value='3'
                    disabled={operationValue === "Create"}
                  />
                </TabList>
              </Box>

              <TabPanel value='1'>
                <Loader isLoading={isLoading} />
                <>
                  <form
                    onSubmit={formik.handleSubmit}
                    name={currentEntryPersonalInfo}
                  >
                    <BuyerStepPersonnalInfoForm
                      formik={formik}
                      typeFile='physic'
                      currentEntryPersonalInfo={currentEntryPersonalInfo}
                      currentIdPersonne={currentIdPersonneSaved!}
                      readOnlyInput={readOnlyInput}
                      displayErrorIcon={true}
                    />
                  </form>
                </>
              </TabPanel>

              <TabPanel value='2'>
                <Loader isLoading={isLoading} />
                <StepMaritalStatusForm
                  formik={formik}
                  isCelibataire={isCelibataire}
                  currentIdPersonne={currentIdPersonneSaved!}
                  isDivorce={isDivorce}
                  isMarie={isMarie}
                  isVeuf={isVeuf}
                  matrimonialStatus={{ celibataire, divorce, marie, veuf }}
                  changeValidation={handleStep2RegimeChange}
                  readOnlyInput={readOnlyInput}
                />
              </TabPanel>

              <TabPanel value='3'>
                <Loader isLoading={isLoading} />
                <FolderDocumentForm
                  formik={formik}
                  typeFile='physic'
                  formOpen={isFileFormOpen}
                  setRefreshFilesList={setRefreshCurrentFolder}
                  filesList={oldFilesPerson}
                  setFormOpen={value => {
                    setIsFileFormOpen(value);
                  }}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <div className='fs-modal-footer'></div>
    </div>
  );
};

export default CBuyerForm;
