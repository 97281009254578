import { IApiResponse } from '@/interface';
import { axios } from '@/lib/axios';
import { TSignIn } from '../types';

export const logUser = (data: TSignIn): Promise<IApiResponse> => {
  return axios.post('/auth', data);
};

export const  apiResetPasswordSendMail = ( email: any ):Promise<IApiResponse> => {
  return axios.post('/users/send-mail',email);
}

export const  apiResetPasswordByUserId = (endPoint:string,data:any ):Promise<IApiResponse> => {
  return axios.post(endPoint,data);
}
 