import CListColleagues from "../../containers/Community/ListColleagues.container";

import FSHeader from "../../Elements/FSHeader/FSHeader.element";

const ColleaguesPage = () => {
  return (
    <div className="fs-container">
      <FSHeader title="Confrères" icon="fa6-solid:people-roof" breadscrumbTitle="Communauté"/>

      <CListColleagues />
    </div>
  );
};

export default ColleaguesPage;
