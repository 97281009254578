import { useState } from "react";
import {
  Alert,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import _ from "lodash";
import iconeTribunaux from "@/assets/images/svg/scales_of_justice.svg";
import iconeTaxi from "@/assets/images/svg/tax.svg";
import iconeHouse from "@/assets/images/svg/houses.svg";
import iconeAgentImmobilier from "@/assets/images/svg/architec.svg";

import Panel from "@/components/UICs/Panel/Panel.uic";

import PartenaireForms from "../../forms/Partenaire/Partenaire.form";
import TypeActor from "../Parameters/TypeActor/TypeActor.container";
import Actor from "../Parameters/Actor/Actor.container";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


let rows = [
  {
    libelle: "Tribunaux",
    linkImage: iconeTribunaux,
  },
  {
    libelle: "Bureau des impôts",
    linkImage: iconeTaxi,
  },
  {
    libelle: "Géomètre expert",
    linkImage: iconeAgentImmobilier,
  },
  {
    libelle: "Agence immobilière",
    linkImage: iconeHouse,
  },
];

const CListPartners = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  
  return (
    <>
      <div className="partenaire-content">
        <Box>
          <Alert className="alert-info" severity="success" icon={false}>
            <b> Retrouvez tous vos partenaires</b>
            <br />
            <span>
              {" "}
              Rechercher une étude à partir de son nom ou de sa situation
              géographique.
            </span>
          </Alert>
        </Box>
        <Box className="admin-tabs" sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'background.paper' }}>
          <Tabs
            className="setting-tabs"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Acteurs / Partenaires" />
            <Tab label="Type Acteur / Partenaire" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <Actor />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TypeActor />
          </TabPanel>
        </Box>

        {/* <Box>
          <Grid container rowSpacing={4} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <div className="partenaire-header">
                <TextField
                  id="input-with-icon-textfield"
                  label=""
                  placeholder="Rechercher"
                  className="search-input"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant="standard"
                />

                <Button
                  color="primary"
                  variant="contained"
                  title="Créer un partenaire"
                  onClick={(e) => setOpenPanel(true)}
                  disableElevation
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Créer un partenaire
                </Button>
              </div>

              <div className="partenaire-body">
                {_.map(rows, (item: any, index: any) => (
                  <BoxPartenaireInfo
                    key={index}
                    libelle={item?.libelle}
                    linkImage={item?.linkImage}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
        </Box> */}
      </div>

      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création de partenaire"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <PartenaireForms />
      </Panel>
    </>
  );
};

export default CListPartners;
