import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";

import {
  TBillSale,
  TFolderActor,
  TFolderCreate,
  TFolderInitialInformation,
  TSeller,
  TTypePiece,
} from "../types";

export const createSeller = (data: TSeller): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/add-seller", toSend);
};

export const createFolder = (
  data: TFolderInitialInformation
): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/create", toSend);
};

export const updateSeller = (
  data: TTypePiece,
  docId: string
): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/type-pieces/update/${docId}`, toSend);
};

export const deleteSeller = (
  docId: string,
  data: any
): Promise<IApiResponse> => {
  const datatoSend = {
    ...data,
  };
  return axios2.delete(`/type-pieces/${docId}`, datatoSend);
};

export const getFolder = (): Promise<IApiResponse> => {
  return axios2.get("/folders");
};

export const getFolderById = (id: string): Promise<IApiResponse> => {
  return axios2.get("/folders/" + id);
};

export const getFolderByReference = (
  reference: string
): Promise<IApiResponse> => {
  return axios2.get("/folders/" + reference);
};

export const removeFolder = (
  folderId: string,
  toSend: any
): Promise<IApiResponse> => {
  const datatoSend = {
    ...toSend,
  };

  return axios2.delete(`/folders/${folderId}`, datatoSend);
};

export const updatePersonneInfo = (
  data: any,
  personId: string
): Promise<IApiResponse> => {
  return axios2.put(`/persons/update/${personId}`, data);
};

/**
 * Mise à jour des personnes morales quelles soient Acquéreur ou vendeur
 * @param data : les données des dirigeants des entreprises
 * @param personId : Id de l'entreprise (vendeur ou acquereur)
 * @returns
 */
export const updatePersonneMoralInfo = (
  data: any,
  personId: string
): Promise<IApiResponse> => {
  return axios2.put(`/personne-morale/update/${personId}`, data);
};

/**
 * Mise à jour des personnes Physiques quelles soient Acquéreur ou vendeur
 * @param data : les données des personnes physiques
 * @param personId : Id de la personne (vendeur ou acquereur)
 * @returns
 */
export const updatePersonnePhysicInfo = (
  data: any,
  personId: string
): Promise<IApiResponse> => {
  return axios2.put(`/personne-phyqique/update/${personId}`, data);
};

/**
 * Ajout des documents des VENDEURS et ACQUEREURS
 * @param personId : id du vendeur ou acquereur
 * @param data : fichier ajouter
 * @returns
 */
export const updateWithDataFiles = (
  personId: string,
  data: any
): Promise<IApiResponse> => {
  return axios2.post(`/person/${personId}/add-files`, data);
};

/** Buyer Acquereur */
export const createBuyer = (data: TSeller): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/add-buyer", toSend);
};

/** BillSale create */
export const createBillSale = (data: TBillSale): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/add-billsale", toSend);
};

export const createActorFolder = (
  data: TFolderActor
): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/add-actor", toSend);
};

export const deleteActorBuyerOrSellerInFolder = (
  data: any,
  idModeSuppression: string
): Promise<IApiResponse> => {
  const toSend = {
    ...data,
  };

  return axios2.post(`/folders/remove-element/${idModeSuppression}`, toSend);
};

export const deleteBillSale = (data?: any): Promise<IApiResponse> => {
  const toSend = {
    ...data,
  };
  return axios2.post(`/folders/remove-billsale`, toSend);
};

export const validBillsale = (data: TBillSale): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/valid-billsale", toSend);
};

export const clotureFolder = (data: TFolderCreate): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/valid", toSend);
};

export const cancelFolder = (data: any): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/cancel", toSend);
};

/**
 * SUSPENSION D'UN DOSSIER
 * @param id :ID du dossier
 * @returns
 */
export const suspendedFolder = (id: string): Promise<IApiResponse> => {
  return axios2.post(`/folders/suspend/${id}`);
};

/**
 * REACTIVATION D'UN DOSSIER
 * @param id : ID du dossier
 * @returns
 */
export const reactivedFolder = (id: string): Promise<IApiResponse> => {
  return axios2.post(`/folders/reactive/${id}`);
};

export const deletePersonalInfo = (data?: any): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/folders/cancel", toSend);
};

export const getFolderStat = (): Promise<IApiResponse> => {
  return axios2.get(`/dashboard`);
};

export const getHistoryTraitementFolder = (
  idFolder: string
): Promise<IApiResponse> => {
  return axios2.get(`/folder-history/${idFolder}`);
};

export const deleteFiles = (
  owner_id: string,
  file_id: string
): Promise<IApiResponse> => {
  return axios2.put(`/files/remove/${owner_id}/${file_id}`);
};

export const deleteFile = (
  // owner_id: string,
  file_id: string
): Promise<IApiResponse> => {
  return axios2.delete(`/files/delete/${file_id}`);
};

export const addFileToFolder = (
  folder_id: string,
  data?: any
): Promise<IApiResponse> => {
  // const toSend = {
  //   ...data,
  //   app_secret: AppCredentials.APP_SECRET,
  //   app_id: AppCredentials.APP_ID,
  //   user_id: user_id,
  // };
  return axios2.post(`/folders/${folder_id}/add-files`, data);
};
