import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";
import { TTypeActor } from "../types/types";

export const createTypeActor = (data: TTypeActor): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/actor-type/add", toSend);
};

export const updateTypeActor = (data: TTypeActor, docId: string): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/actor-type/update/${docId}`, toSend);
};

export const getAllTypeActor = (): Promise<IApiResponse> => {
  return axios2.get("/actor-type");
};

export const deleteTypeActor = (docId: string): Promise<IApiResponse> => {
  return axios2.delete(`/actor-type/${docId}`);
};