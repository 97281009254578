import { FC, useEffect, useState } from 'react';
import Select from 'react-select';

interface ISelectProps {
  id?: string;
  placeholder: string;
  name: string;
  options: any;
  handleChange(option: any, select: any): void;
  isDisabled: boolean;
  isMulti?: boolean;
  isSimpleStringDefaultValue?: boolean;
  defaultValue?: string;
  values?: any[];

  refValue?: any;
  index?: number; // allow us to definie multiple ref value.

  height?: string;
  fontSize?: string;
}

const CustomSelect: FC<ISelectProps> = ({
  id,
  options,
  handleChange,
  placeholder,
  isSimpleStringDefaultValue = false,
  isDisabled,
  isMulti = false,
  name,
  height = '37px',
  fontSize = '12px',
  defaultValue,
  refValue,
  values,
  index,
}) => {
  const [currentDefault, setCurrentDefault] = useState<any>({});
  const [currentMultiDefault, setCurrentMultiDefault] = useState<any>({});
  const intHeight = parseInt(height);

  const reactSelectDefaultStyles = {
    valueContainer: (styles: any) => ({
      ...styles,
      flexWrap: 'initial',
      flexDirection: 'row',
    }),
    control: (styles: any, { isDisabled }: any) => ({
      ...styles,
      width: '100%',
      minHeight: 'auto',
      height,
      fontSize,
      fontWeight: '400',
      lineHeight: 1.5,
      color: '#212529',
      backgroundColor: !isDisabled ? '#fff' : '#e9ecef',
      border: '1px solid #ced4da',
      borderRadius: '.375rem',
      overflow: 'hidden',
    }),
    singleValue: (styles: any, { isDisabled }: any) => ({
      ...styles,
      color: isDisabled && '#212529',
      marginTop: intHeight === 32 ? '-0.1rem' : 'unset',
    }),
    dropdownIndicator: (styles: any, { isDisabled }: any) => ({
      ...styles,
      marginTop: intHeight === 32 ? '-0.15rem' : 'unset',
    }),

    indicatorSeparator: (styles: any) => ({
      ...styles,
      display: 'none',
    }),
  };
  useEffect(() => {
    if (isSimpleStringDefaultValue) return;
    let parseValue = defaultValue ? JSON.parse(defaultValue) : null;
    if (parseValue) {
      const { first_name, last_name } = parseValue;
      setCurrentDefault({ first_name, last_name });
    }
  }, [defaultValue, isSimpleStringDefaultValue, values]);
  const { first_name, last_name } = currentDefault!;
  const label = isSimpleStringDefaultValue
    ? defaultValue!
    : `${first_name} ${last_name}`;

  return (
    <>
      {first_name && (
        <Select
          id={id}
          className="custom-select"
          styles={reactSelectDefaultStyles}
          defaultValue={{
            label,
            value: defaultValue!,
          }}
          options={options}
          isMulti={isMulti}
          name={name}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isSearchable={true}
          onChange={handleChange!}
        />
      )}
      {!first_name && !isSimpleStringDefaultValue && (
        <Select
          ref={element => refValue && (refValue!.current[index!] = element)}
          value={values!}
          id={id}
          className="custom-select"
          styles={reactSelectDefaultStyles}
          options={options}
          isMulti={isMulti}
          name={name}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isSearchable={true}
          onChange={handleChange!}
        />
      )}
      {isSimpleStringDefaultValue && !label && (
        <Select
          id={id}
          className="custom-select"
          styles={reactSelectDefaultStyles}
          options={options}
          isMulti={isMulti}
          name={name}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isSearchable={true}
          onChange={handleChange!}
        />
      )}
      {isSimpleStringDefaultValue && label && (
        <Select
          id={id}
          className="custom-select"
          styles={reactSelectDefaultStyles}
          defaultValue={{
            label,
            value: label,
          }}
          options={options}
          isMulti={isMulti}
          name={name}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isSearchable={true}
          onChange={handleChange!}
        />
      )}
    </>
  );
};

export default CustomSelect;
