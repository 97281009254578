import { logUser } from '@/features/auth/api/auth.api';
import { Toastify } from '@/utils/toast';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

interface IUserState {
  isLoading: boolean;
  userConnected: any;
  token: string;
}

type TSignIn = {
  login: string;
  password: string;
};

const initialState: IUserState = {
  isLoading: false,
  userConnected: null,
  token: '',
};

export const loginUser = createAsyncThunk(
  'user/loginUser',
  async (user: TSignIn, { rejectWithValue }) => {
    const response = await logUser(user);
    if (response.error) {
      Toastify('error', response.message!);
      return;
    }
    return response.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser: state => {
      state.userConnected = '';
      state.token = '';
    },
  },
  extraReducers: builder => {
    builder.addCase(loginUser.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const { user } = payload;
      Toastify(
        'success',
        `Bienvenue ${user?.first_name} ${user?.last_name}  , vous êtes connecté`
      );
      state.isLoading = false;
      state.token = payload?.token;
      state.userConnected = user;

      console.log("user liste ", user);
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      payload && Toastify('error', `${payload}`);
    });
  },
});

export const { logoutUser } = userSlice.actions;

export default userSlice.reducer;
