import React, { FC } from "react";
import styled from "styled-components";

type TAlertMessage = {
  type: "danger" | "warning" | "success" | "error" | "hint";
  message: string;
  simple: boolean;
  mt: number;
  mb?: number;
};
const AlertMessage: FC<TAlertMessage> = ({
  type = "danger",
  message,
  simple = false,
  mt = 0,
  mb = 0,
}) => (
  <AlertContainer type={type} role="alert" simple={simple} mt={mt} mb={mb}>
    <MessageContainer simple={simple}>
      {typeof message === "string"
        ? message
        : "Une erreur inconnue s'est produite!!"}
    </MessageContainer>
  </AlertContainer>
);

export default AlertMessage;

export const AlertContainer = styled.div<{
  type: "danger" | "warning" | "success" | "error" | "hint";
  simple: boolean;
  mt: number;
  mb?: number;
}>`
  border-radius: 3px;
  padding: ${(props) =>
    props.type === "hint" ? 0 : !props.simple ? "5px 15px" : 0};
  ${(props) => `margin-top: ${props.mt}px`};
  ${(props) => `margin-bottom: ${props.mb ? props.mb : "15px"}px`};
  ${(props) =>
    !props.simple ? `background-color: var(--ui-${props.type})` : "none"};
  ${(props) =>
    !props.simple ? `border: 1px solid var(--ui-${props.type})` : "none"};
  ${(props) => `color: var(--ui-${props.type})`};
`;

export const MessageContainer = styled.p<{ simple: boolean }>`
  margin: 0;
  text-align: ${(props) => (!props.simple ? "center" : "unset")};
  font-size: 13px;
`;
