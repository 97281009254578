import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import { getAllTypePiece } from "@/features/admin/api/typePiece.api";
import { convert_Date_to_inputDateFormate } from "@/utils";
// import { ToastError } from "@/utils/toast";
import { Button } from "@mui/material";
import { FormikProps } from "formik";
// import { useEffect, useState } from "react";
// import * as _ from "lodash";
// import { TBuyerFormValues } from "../Buyer.form.container";
// import { TTypePiece } from "@/features/admin/types";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import WithFormikErrorMessage from '@/components/HOCs/WithFormikErrorMessage.hoc';
// import AlertMessage from '@/components/UICs/AlertMessage/AlertMessage.uic';
// import { getAllTypePiece } from '@/features/admin/api/typePiece.api';
// import { convert_Date_to_inputDateFormate } from '@/utils';
import { ToastError, Toastify } from "@/utils/toast";
// import { Button } from '@mui/material';
// import { FormikProps } from 'formik';
import { useEffect, useState, useCallback } from "react";
import * as _ from "lodash";
import { TBuyerFormValues } from "../Buyer.form.container";
import { TNationality, TTypePiece } from "@/features/admin/types";
import CustomSelect from "@/components/UICs/Select/CustomSelect";
import { getAllNationality } from "@/features/admin/api/nationality.api";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import TablePreview from "@/features/admin/Elements/TablePreview/TablePreview";
import { getPersonnePhysique } from "@/features/admin/api/client.api";
import { tablePreviewDomFunc } from "@/utils/tablePreviewDomFunc";
import { usePersonSelectedIdContext } from "@/features/admin/contexts/selectedPerson.context";

const FormErrorDisplayer =
  WithFormikErrorMessage<TBuyerFormValues>(AlertMessage);

interface BuyerStep1FormProps {
  // formik: FormikProps<TBuyerFormValues>;
  formik: FormikProps<any>;
  currentIdPersonne?: string | undefined;
  readOnlyInput?: boolean;
  typeFile?: "moral" | "physic" | "property" | "folder";
  displayErrorIcon?: boolean;
  currentEntryPersonalInfo?: string;
  hiddenBoutonSubmit?: boolean;
}
const BuyerStepPersonnalInfoForm: React.FC<BuyerStep1FormProps> = ({
  formik,
  currentIdPersonne,
  readOnlyInput,
  typeFile,
  displayErrorIcon,
  currentEntryPersonalInfo,
  hiddenBoutonSubmit,
}) => {
  const [listeTypesPieces, setListeTypesPieces] = useState<TTypePiece[]>([]);
  const [listNationality, setListNationality] = useState<TNationality[]>([]);
  const [optionsListNationality, setOptionsListNationality] =
    useState<any[]>(listNationality);
  const [optionsListeTypesPieces, setOptionsListeTypesPieces] = useState<any[]>(
    []
  );
  const [value, setValue] = useState<any>();
  // state for data to pass into table view
  const [physicalPersonInfo, setPhysicalPersonInfo] = useState<any>({});
  const [currentPhysicalPersonInfo, setCurrentPhysicalPersonInfo] =
    useState<any>({});
  const [physicalPersonRows, setPhysicalPersonRows] = useState<any>([]);
  // const [personSelectedId, setPersonSelectedId] = useState<string>('');
  const { personSelectedId, setPersonSelectedId } =
    usePersonSelectedIdContext();

  const getTypePieces = () => {
    getAllTypePiece()
      .then(response => {
        if (response.error) {
          ToastError.fire({ text: response.message, timer: 9000 });
        } else {
          let typePieces: TTypePiece[] = response.data as TTypePiece[];
          typePieces = _.orderBy(typePieces, ["name"], ["asc"]);
          const optionsListTypePiecesData = typePieces.map(
            (piece: TTypePiece) => {
              return {
                label: piece.name,
                value: piece._id,
              };
            }
          );
          setOptionsListeTypesPieces(optionsListTypePiecesData);
          setListeTypesPieces(typePieces);
        }
      })
      .catch(error => {
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({
            text: "Connexion momentanément interronpue !",
            timer: 9000,
          });
        }
      });
  };

  const retirieveAllNationality = useCallback(async () => {
    const response = await getAllNationality();

    if (response.error) {
      Toastify("error", response.message!);
      return;
    }

    let { data } = response;
    data = _.orderBy(data, ["nationalite"], ["asc"]);
    const optionsListNationalityData = data.map((nationality: TNationality) => {
      return {
        label: upperFirstWordLetter(nationality.nationalite),
        value: nationality.nationalite,
      };
    });

    setOptionsListNationality(optionsListNationalityData);
    setListNationality(data);
  }, []);

  const getInfoForPhysicalPerson = async (queryText: string) => {
    const response = await getPersonnePhysique(queryText);
    const { data: physicalPerson } = response;
    const createTablePreviewRow = physicalPerson.map(
      (person: any, index: number) => {
        const { _id, personal_infos } = person;
        const { last_name, first_name, phone_number } = personal_infos;

        return {
          id: index + 1,
          person_id: _id,
          nom: last_name,
          prenoms: first_name,
          telephone: phone_number,
        };
      }
    );
    setPhysicalPersonRows(createTablePreviewRow);
    setPhysicalPersonInfo(physicalPerson);
  };
  // function fix input write block
  const allowWrittingInsideInput = () => {
    setPersonSelectedId({ moral: "", physique: "" });
  };

  useEffect(() => {
    getTypePieces();

    //eslint-disable-next-line
  }, [!listeTypesPieces]);

  useEffect(() => {
    retirieveAllNationality();
  }, [retirieveAllNationality]);

  useEffect(() => {
    // retrieve the person selected
    // tablePreviewDomFunc({
    //   inputParentWrapperSelector: '.information-body-container',
    //   getFonction: getInfoForPhysicalPerson,
    // });

    const selectedPerson =
      personSelectedId.physique &&
      _.find(physicalPersonInfo, { _id: personSelectedId.physique });

    setCurrentPhysicalPersonInfo(selectedPerson);
    if (selectedPerson) {
      formik.values[currentEntryPersonalInfo!].sellerCivility =
        selectedPerson["personal_infos"]?.civility;
      formik.values[currentEntryPersonalInfo!].sellerLastName =
        selectedPerson["personal_infos"]?.last_name;
      formik.values[currentEntryPersonalInfo!].sellerFirstName =
        selectedPerson["personal_infos"]?.first_name;
      formik.values[currentEntryPersonalInfo!].sellerEmail =
        selectedPerson["personal_infos"]?.email;
      formik.values[currentEntryPersonalInfo!].sellerTelephone =
        selectedPerson["personal_infos"]?.phone_number;
      formik.values[currentEntryPersonalInfo!].sellerPrefession =
        selectedPerson["personal_infos"]?.job;
      formik.values[currentEntryPersonalInfo!].sellerNationality =
        selectedPerson["personal_infos"]?.nationality;
      formik.values[currentEntryPersonalInfo!].sellerLieuNaissance =
        selectedPerson["personal_infos"]?.birth_place;
      formik.values[currentEntryPersonalInfo!].sellerDateNaissance = new Date(
        selectedPerson["personal_infos"]?.birth_date
      );
      formik.values[currentEntryPersonalInfo!].sellerNaturePiece =
        selectedPerson["personal_infos"]?.piece_type;
      formik.values[currentEntryPersonalInfo!].sellerNumeroPiece =
        selectedPerson["personal_infos"]?.piece_number;
      formik.values[currentEntryPersonalInfo!].sellerLieuEtablissement =
        selectedPerson["personal_infos"]?.piece_establishment_place;
      formik.values[currentEntryPersonalInfo!].sellerDateEtablissement =
        new Date(selectedPerson["personal_infos"]?.piece_establishment_date);
      formik.values[currentEntryPersonalInfo!].sellerDateExpiration = new Date(
        selectedPerson["personal_infos"]?.piece_expiration_date
      );
      formik.values[currentEntryPersonalInfo!].sellerInfosPlus =
        selectedPerson["personal_infos"]?.further_informations;
    }
  }, [
    personSelectedId,
    physicalPersonInfo,
    currentEntryPersonalInfo,
    formik.values,
  ]);

  return (
    <>
      {/* <form onSubmit={formik.handleSubmit} name={currentEntryPersonalInfo}> */}
      <div className='information-body-container'>
        <div className='row fs-row'>
          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Civilité {displayErrorIcon && <sup>*</sup>}
            </label>
            <select
              id='inputState'
              name={[currentEntryPersonalInfo!] + "[sellerCivility]"}
              className='form-select bs-input-custom'
              placeholder='Civilité'
              onChange={formik.handleChange}
              disabled={readOnlyInput}
              value={formik.values[currentEntryPersonalInfo!].sellerCivility}
            >
              <option value={""}>Choisissez</option>
              <option value={"Monsieur"}>Monsieur</option>
              <option value={"Madame"}>Madame</option>
              <option value={"Mademoiselle"}>Mademoiselle</option>
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerCivility]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Nom {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerLastName]"}
              autoComplete='off'
              placeholder='Nom'
              disabled={readOnlyInput}
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              value={formik.values[currentEntryPersonalInfo!].sellerLastName}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerLastName]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>

          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 table-preview-parent'>
            <label className='bs-label-form'>
              Prénoms {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              onKeyUp={e => {
                const element = e.target as HTMLInputElement;
                tablePreviewDomFunc({
                  topLevelParentWrapperSelector: ".fs-modal-container",
                  element,
                  getFonction: getInfoForPhysicalPerson,
                });
              }}
              onFocus={allowWrittingInsideInput}
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerFirstName]"}
              autoComplete='off'
              placeholder='Prénoms'
              onChange={formik.handleChange}
              value={formik.values[currentEntryPersonalInfo!].sellerFirstName}
              disabled={readOnlyInput}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerFirstName]"}
              simple={true}
              mt={5}
              mb={0}
            />
            <TablePreview rows={physicalPersonRows} />
          </div>
        </div>

        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Email {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerEmail]"}
              autoComplete='off'
              placeholder='Email'
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              disabled={readOnlyInput}
              value={formik.values[currentEntryPersonalInfo!].sellerEmail}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerEmail]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Numéro de téléphone{displayErrorIcon && <sup>*</sup>}
            </label>
            {/* <PhoneInput
                placeholder="Numéro de téléphone"
                country={"ci"}
                regions={['africa', 'america', 'asia', 'europe', 'oceania']}
                enableSearch
                inputProps={{
                  name: currentEntryPersonalInfo! + '[sellerTelephone]',
                  required: true,
                }}
                value={formik.values[currentEntryPersonalInfo!].sellerTelephone}
                onChange={formik.handleChange}
                disabled={readOnlyInput}
              /> */}

            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerTelephone]"}
              autoComplete='off'
              placeholder='Numéro de téléphone'
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              disabled={readOnlyInput}
              value={formik.values[currentEntryPersonalInfo!].sellerTelephone}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerTelephone]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Profession {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerPrefession]"}
              autoComplete='off'
              placeholder='Profession'
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              disabled={readOnlyInput}
              value={formik.values[currentEntryPersonalInfo!].sellerPrefession}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerPrefession]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Nationalité {displayErrorIcon && <sup>*</sup>}
            </label>

            <CustomSelect
              placeholder='Nationalité'
              options={optionsListNationality}
              isSimpleStringDefaultValue={true}
              defaultValue={
                formik.values[currentEntryPersonalInfo!].sellerNationality
              }
              name={[currentEntryPersonalInfo!] + "[sellerNationality]"}
              handleChange={({ value }, select) => {
                const e = {
                  target: {
                    name: select.name,
                    value,
                  },
                };
                formik.handleChange(e);
              }}
              isDisabled={readOnlyInput!}
              height='32px'
            />
            {/* <input
              type="text"
              className="form-control bs-input-custom"
              name={[currentEntryPersonalInfo!] + '[sellerNationality]'}
              autoComplete="off"
              placeholder="Nationalité"
              onChange={formik.handleChange}
              disabled={readOnlyInput}
              value={formik.values[currentEntryPersonalInfo!].sellerNationality}
            /> */}
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerNationality]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Lieu de naissance {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerLieuNaissance]"}
              autoComplete='off'
              placeholder='Lieu de naissance'
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              value={
                formik.values[currentEntryPersonalInfo!].sellerLieuNaissance
              }
              disabled={readOnlyInput}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerLieuNaissance]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Date de naissance {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type={"date"}
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerDateNaissance]"}
              autoComplete='off'
              placeholder='Date de naissance'
              disabled={readOnlyInput}
              onChange={e => {
                formik.setFieldValue(
                  [currentEntryPersonalInfo!] + "[sellerDateNaissance]",
                  new Date(e.currentTarget.value)
                );
              }}
              onFocus={allowWrittingInsideInput}
              value={
                formik.values[currentEntryPersonalInfo!].sellerDateNaissance
                  ? convert_Date_to_inputDateFormate(
                      formik.values[currentEntryPersonalInfo!]
                        .sellerDateNaissance
                    )
                  : ""
              }
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerDateNaissance]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Nature de la pièce {displayErrorIcon && <sup>*</sup>}
            </label>
            {/* <CustomSelect
              placeholder='Nature de la pièce'
              options={optionsListeTypesPieces}
              name={[currentEntryPersonalInfo!] + "[sellerNaturePiece]"}
              isSimpleStringDefaultValue={true}
              defaultValue={
                listeTypesPieces.find(
                  piece =>
                    piece._id ===
                    formik.values[currentEntryPersonalInfo!].sellerNaturePiece
                )?.name
              }
              handleChange={({ value }, select) => {
                const e = {
                  target: {
                    name: select.name,
                    value,
                  },
                };
                formik.handleChange(e);
              }}
              isDisabled={readOnlyInput!}
              height='32px'
            /> */}
            <select
              id='inputState'
              name={[currentEntryPersonalInfo!] + "[sellerNaturePiece]"}
              className='form-select bs-input-custom'
              placeholder='Nature de la pièce'
              onChange={formik.handleChange}
              value={formik.values[currentEntryPersonalInfo!].sellerNaturePiece}
              disabled={readOnlyInput}
            >
              <option value={""}> Choisissez </option>
              {listeTypesPieces.map(item => {
                if (typeFile === "property" && item.bien) {
                  return (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  );
                }
                if (typeFile === "moral" && item.moral_person) {
                  return (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  );
                }
                if (typeFile === "physic" && item.physic_person) {
                  return (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  );
                }
                if (typeFile === "folder" && item.folder) {
                  return (
                    <option key={item._id} value={item._id}>
                      {item.name}
                    </option>
                  );
                }
                return null; // Renvoyer null si aucune condition n'est remplie
              })}
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerNaturePiece]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Numéro de la pièce {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerNumeroPiece]"}
              autoComplete='off'
              placeholder='Numéro de la pièce'
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              value={formik.values[currentEntryPersonalInfo!].sellerNumeroPiece}
              disabled={readOnlyInput}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerNumeroPiece]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Lieu d'établissement {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type='text'
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerLieuEtablissement]"}
              autoComplete='off'
              placeholder="Lieu d'établissement"
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              value={
                formik.values[currentEntryPersonalInfo!].sellerLieuEtablissement
              }
              disabled={readOnlyInput}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerLieuEtablissement]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>

          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Date d'établissement {displayErrorIcon && <sup>*</sup>}
            </label>

            <input
              type={"date"}
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerDateEtablissement]"}
              autoComplete='off'
              placeholder="Date d'établissement"
              disabled={readOnlyInput}
              onChange={e => {
                formik.setFieldValue(
                  [currentEntryPersonalInfo!] + "[sellerDateEtablissement]",
                  new Date(e.currentTarget.value)
                );
              }}
              onFocus={allowWrittingInsideInput}
              value={
                formik.values[currentEntryPersonalInfo!].sellerDateEtablissement
                  ? convert_Date_to_inputDateFormate(
                      formik.values[currentEntryPersonalInfo!]
                        .sellerDateEtablissement
                    )
                  : ""
              }
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerDateEtablissement]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>

          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Date d'expiration {displayErrorIcon && <sup>*</sup>}
            </label>
            <input
              type={"date"}
              className='form-control bs-input-custom'
              name={[currentEntryPersonalInfo!] + "[sellerDateExpiration]"}
              autoComplete='off'
              placeholder="Date d'expiration"
              disabled={readOnlyInput}
              onChange={e => {
                formik.setFieldValue(
                  [currentEntryPersonalInfo!] + "[sellerDateExpiration]",
                  new Date(e.currentTarget.value)
                );
              }}
              onFocus={allowWrittingInsideInput}
              value={
                formik.values[currentEntryPersonalInfo!].sellerDateExpiration
                  ? convert_Date_to_inputDateFormate(
                      formik.values[currentEntryPersonalInfo!]
                        .sellerDateExpiration
                    )
                  : ""
              }
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name={[currentEntryPersonalInfo!] + "[sellerDateExpiration]"}
              simple={true}
              mt={5}
              mb={0}
            />
          </div>

          <div className='col-12 mb-3'>
            <label className='bs-label-form'>Information complémentaires</label>
            <textarea
              name={[currentEntryPersonalInfo!] + "[sellerInfosPlus]"}
              className='form-control bs-input-custom'
              placeholder='Information complémentaires'
              disabled={readOnlyInput}
              rows={4}
              onChange={formik.handleChange}
              onFocus={allowWrittingInsideInput}
              value={formik.values[currentEntryPersonalInfo!].sellerInfosPlus}
            />
          </div>
        </div>
        {!readOnlyInput && !hiddenBoutonSubmit && (
          <div className='modify-button'>
            {!currentIdPersonne && (
              <Button type='submit' variant='contained' color='primary'>
                ENREGISTRER & CONTINUER
              </Button>
            )}
            {currentIdPersonne && (
              <Button type='submit' variant='contained' color='primary'>
                ENREGISTRER
              </Button>
            )}
          </div>
        )}
      </div>
      {/* </form> */}
    </>
  );
};

export default BuyerStepPersonnalInfoForm;
