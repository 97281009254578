import React, { FC } from "react";
import "./DetailsPhysic.scss";

type TDatailProps = {
  user: any;
};

const DetailsPhysic: FC<TDatailProps> = ({ user }) => {
  return (
    <div className='user-details-container'>
      <div className='user-datail-control'>
        <span className='label'>Civilité : </span>
        <span className='value'>{user?.personal_infos?.civility}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Nom et Prénoms : </span>
        <span className='value'>
          {user?.personal_infos?.last_name} {user?.personal_infos?.first_name}
        </span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>E-mail : </span>
        <span className='value'>{user?.personal_infos?.email}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Fontion : </span>
        <span className='value'>{user?.personal_infos?.job}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Nationnalité : </span>
        <span className='value'>{user?.personal_infos?.nationality}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Date et lieu de naissance : </span>
        <span className='value'>
          {user?.personal_infos?.birth_date} à{" "}
          {user?.personal_infos?.birth_place}
        </span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Téléphone : </span>
        <span className='value'>{user?.personal_infos?.phone_number}</span>
      </div>
      <div className='user-datail-control'>
        <span className='label'>Numéro pièce : </span>
        <span className='value'>{user?.personal_infos?.piece_number}</span>
      </div>
    </div>
  );
};

export default DetailsPhysic;
