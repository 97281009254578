import { DocumentContainer } from "./DocumentContainer/DocumentContainer";
import { FormikProps } from "formik";

import { Button } from "@mui/material";

import Swal from "sweetalert2";
import { TBuyerFormValues } from "../../Buyer.form.container";
import * as _ from "lodash";
import FileUploadImg from "@/assets/images/png/paper.png";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import { TFilesBuyerOrSeller, TTypePiece } from "@/features/admin/types";
import { useEffect, useRef, useState } from "react";
import { getTypeDocument } from "@/features/admin/api/documentType.api";
import { deleteFile } from "@/features/admin/api/folder.api";
import { ToastError, ToastGeneric } from "@/utils/toast";
import "./Document.form.style.scss";
import FileViewer from "./File_viewer/fileViewer";
// import CustomSelect from "@/components/UICs/Select/CustomSelect";
import { getFile } from "@/features/admin/api/files.api";
import LinearProgressBar from "@/components/UICs/LinearProgressBar/LinearProgressBar";
import { FILE_URL } from "@/config";
import * as Yup from "yup";
import path from "path";

const FormErrorDisplayer =
  WithFormikErrorMessage<TBuyerFormValues>(AlertMessage);

interface BuyerStep3FormProps {
  formik: FormikProps<any>;
  formOpen?: boolean;
  setFormOpen?: (value: boolean) => void;
  readOnlyInput?: boolean;
  typeFile?: "moral" | "physic" | "property" | "folder";
  currentPersonalId?: string;
  setRefreshFilesList?: React.Dispatch<React.SetStateAction<string>>;
  filesList?: any;

  inputTypeFileRef?: React.RefObject<HTMLInputElement>;
  formFileRef?: React.RefObject<HTMLFormElement>;
}
const FolderDocumentForm: React.FC<BuyerStep3FormProps> = ({
  formik,
  // rows,
  formOpen,
  setFormOpen,
  readOnlyInput,
  currentPersonalId,
  typeFile,
  filesList,
  inputTypeFileRef,
  formFileRef,
  setRefreshFilesList,
}) => {
  /// STATE DECLARATION
  const [listTypeDocuments, setListTypeDocuments] = useState<TTypePiece[]>([]);
  const [listFiles, setListFiles] = useState<any[]>([]);
  const [currentFolderName, setCurrentFolderName] = useState<string>();
  const [fileViewer, setFileViewer] = useState<boolean>(false);

  const getDataTypePieces = async () => {
    const response = await getTypeDocument();

    if (!response.error) {
      let typePieces: TTypePiece[] = response.data as TTypePiece[];
      typePieces = _.orderBy(
        typePieces,
        ["name", "moral_person", "physic_person", "bien", "folder"],
        ["asc"]
      );
      setListTypeDocuments(typePieces);
    }
  };

  // console.log("filesList: ", filesList);

  const checkBuyerOrSellerFile = (currentFile: TFilesBuyerOrSeller) => {
    // console.log(" currentFile ", currentFile);
    getFile(currentFile?.id!)
      .then(response => {
        // console.log(" response ", response);
      })
      .catch(error => {
        console.log(" error ", error);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  const confirmDeleteDocument = (currentFile: any) => {
    // console.log(" currentFile ", currentFile.id);
    Swal.fire({
      title: "Supprimer document",
      text:
        "Vous êtes sur le point de supprimer un document. " +
        "\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        deleteDocument(currentFile);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteDocument = (currentFile: any) => {
    deleteFile(currentFile?.id!)
      .then(response => {
        // setIsLoading?.(false);
        if (!response.error) {
          ToastGeneric(
            "Suppression effectuée avec succès !",
            "success",
            9000
          ).fire();

          // On rafraichir la liste des acteurs
          setRefreshFilesList?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        console.log(" error ", error);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  const filesImagesLink = filesList?.filter(
    (file: any) => file?.name?.split(".").pop()?.toLowerCase() !== "pdf"
  );

  // console.log("filesImagesLink", filesImagesLink);

  useEffect(() => {
    getDataTypePieces();
  }, []);

  // console.log("listTypeDocuments: ", listTypeDocuments);

  useEffect(() => {
    if (filesList) {
      let rowsFiles: any = [];
      filesList.forEach((file: any) => {
        let currentFile: any = {
          id: file?._id,
          fileName: file?.name,
          fileType: extractFilesByExtension(file?.name),
          path: file?.path,
          link: FILE_URL + `/${file?._id}`,
          piece_type: file?.piece_type,
          // name: file?.doc_type_id?.name,
          // description: file?.description,
          // doc_type_id: file?.doc_type_id?._id,
          // sharing_download_link: file?.sharing_download_link,
          // sharing_file_id: file?.sharing_file_id,
          // owner_id: file?.owner_id,
          // _id: file?._id,
        };
        rowsFiles.push(currentFile);
      });

      setListFiles(rowsFiles);
    }
  }, [filesList]);

  // console.log("listFiles global: ", filesList);

  // Fonction pour extraire des fichiers par leur extension
  const extractFilesByExtension = (fileName: string) => {
    const extension = fileName?.split(".").pop()?.toLowerCase(); // Obtenir l'extension du fichier en minuscules
    return extension;
  };

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      const timer = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress === 100) {
            clearInterval(timer);
          }
          return prevProgress >= 100 ? 100 : prevProgress + 10;
        });
        // console.log("prevProgress", progress)
      }, 100);
    }, 300);
  }, [currentFolderName]);

  return (
    <>
      {!fileViewer && (
        <form onSubmit={formik.handleSubmit} ref={formFileRef!}>
          <div className='document-body-container'>
            <div className='documentAddForm'>
              <div className='row fs-row'>
                <div className='col-md-5 col-lg-5 col-sm-12 mb-3'>
                  <label className='bs-label-form'> Type de document </label>
                  <select
                    id='inputState'
                    name='piece_type'
                    className='form-select bs-input-custom'
                    placeholder='Nature de la pièce'
                    onChange={formik.handleChange}
                    value={formik.values.piece_type}
                    disabled={readOnlyInput}
                  >
                    <option value={""}> Choisissez </option>
                    {listTypeDocuments.map(item => {
                      if (typeFile === "property" && item.bien) {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        );
                      }
                      if (typeFile === "moral" && item.moral_person) {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        );
                      }
                      if (typeFile === "physic" && item.physic_person) {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        );
                      }
                      if (typeFile === "folder" && item.folder) {
                        return (
                          <option key={item._id} value={item._id}>
                            {item.name}
                          </option>
                        );
                      }
                      return null; // Renvoyer null si aucune condition n'est remplie
                    })}
                  </select>

                  <FormErrorDisplayer
                    touched={formik.touched}
                    errors={formik.errors}
                    name='piece_type'
                    simple={true}
                    mt={5}
                    mb={0}
                  />
                </div>

                <div className='col-md-4 col-lg-4 col-sm-12 mb-3'>
                  <label className='bs-label-form'> Joindre le fichier </label>
                  <Button
                    disabled={readOnlyInput}
                    className='upload-input'
                    component='label'
                  >
                    Selectionner le fichier ici ((png,jpg,jpeg,dwg,pdf))
                    <input
                      ref={inputTypeFileRef!}
                      disabled={readOnlyInput}
                      name='files'
                      hidden
                      accept='image/x-png,image/jpg,image/jpeg,.dwg,application/pdf'
                      multiple
                      type='file'
                      onChange={e => {
                        if (e.currentTarget.files) {
                          formik.setFieldValue(
                            "files",
                            e.currentTarget.files[0]
                          );
                          setProgress(0);
                          setCurrentFolderName(e?.currentTarget?.files[0].name);
                        }
                      }}
                    />
                    <img src={FileUploadImg} alt='' />
                  </Button>
                  <FormErrorDisplayer
                    touched={formik.touched}
                    errors={formik.errors}
                    name='sellerFolderFileDescription'
                    simple={true}
                    mt={5}
                    mb={0}
                  />
                </div>

                <div className='col-lg-3 col-md-3 col-sm-12 modify-button'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    disabled={
                      readOnlyInput ||
                      (!formik.values.piece_type && !formik.values.files)
                    }
                  >
                    Enregistrer
                  </Button>
                </div>
              </div>
              {currentFolderName && (
                <div className='col-md-12 col-lg-12 col-sm-12'>
                  <Button
                    className='upload-input file-uploaded'
                    component='label'
                  >
                    <div className='progress-element progress-element--html'>
                      <p className='progress-labelk'>{currentFolderName}</p>
                      <LinearProgressBar value={progress} />
                      {/* <div className="progress-container">
                        <progress max="100" value="100"></progress>
                      </div> */}
                    </div>
                  </Button>
                </div>
              )}
            </div>

            <DocumentContainer
              checkBuyerOrSellerFile={checkBuyerOrSellerFile}
              setFileViewer={setFileViewer}
              confirmDeleteDocument={confirmDeleteDocument}
              listFiles={listFiles}
            />
          </div>
        </form>
      )}

      {fileViewer && (
        <FileViewer
          // currentFile={current}
          files={filesImagesLink}
          setFileViewer={setFileViewer}
        />
      )}
    </>
  );
};

export default FolderDocumentForm;
