import { useEffect, useState } from "react";
import _ from "lodash";
import { Box, Button, Grid } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Panel from "@/components/UICs/Panel/Panel.uic";
import ParameterTextForm, { TTextTemplateFormsValues } from "@/features/admin/forms/ParameterText/ParameterText.form";
import BlockText from "@/features/admin/Elements/BlockText/BlockText.element";
import confrereBalanceIcon from "@/assets/images/svg/confrere_balance_icon.svg";
import confrereHatIcon from "@/assets/images/svg/confrere_hat_icon.svg";
import confrerePlumeIcon from "@/assets/images/svg/confrere_plume_icon.svg";
import "./ListTexts.styles.scss";
import React from "react";
import { TTextTemplate } from "@/features/admin/types/types";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { createTextTemplate, getAllTextTemplate } from "@/features/admin/api/TextTemplate.api";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";
import draftToHtml from "draftjs-to-html";
import { convertToRaw, EditorState } from "draft-js";
import { moveSelectionToEndWithSpace } from "@/lib/draftJsEditor";


const CListTexts = () => {
  
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [textTemplateData, seTTextTemplateData] = useState<TTextTemplate[]>([]);
  const [currentTextTemplate, setCurrentTextTemplate] = useState<TTextTemplate>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  
  const getTextTemplate = async () => {
    const { data } = await getAllTextTemplate();
    const dataWithNewObjectKeys: TTextTemplate[] = data.map((textTemplate: any) => {
      return {
        id: textTemplate._id,
        name: upperFirstWordLetter(textTemplate.name),
        description: textTemplate.description,
      };
    });
    seTTextTemplateData(dataWithNewObjectKeys);
  };

  const refreshTextTemplate = async () => {
    await getTextTemplate();
  };

  useEffect(() => {
    getTextTemplate();
  }, []);


  const saveItem = async (
    textTemplate: TTextTemplateFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    // console.log("textTemplate ", textTemplate);
    const response = await createTextTemplate(textTemplate);

    if (response.error) {
        ToastError.fire({ text: response.message, timer: 9000 });
      } else {
        ToastGeneric("modèle d'acte créée avec succès", "success", 3000).fire();
        setOpenPanel(false);
        refreshTextTemplate();
      }
      setSubmitting(false);
  };

  const handleSubmit = (
    values: TTextTemplateFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TTextTemplateFormsValues>
  ) => {
    // alert(JSON.stringify(values, null, 2));
    // console.log(" values ", values);
    saveItem(values, setSubmitting);
  };

  return (
    <>
      <div className="c-list-texts">
        <div className="parametre-text-content">
          <Box sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'background.paper' }}>
            <Box>
              <Grid container rowSpacing={4} columnSpacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                  <div className="parametre-text-table-part">
                    <div className="parametre-text-table-header">
                      <Button
                        color="primary"
                        onClick={(e) => setOpenPanel(true)}
                        variant="contained"
                        title="Ajouter un modèle d'acte"
                        disableElevation
                        startIcon={<AddCircleOutlineIcon />}
                      >
                        Ajouter un modèle d'acte
                      </Button>
                    </div>
                    <div className="parametre-text-table-body">
                      {_.map(textTemplateData, (item: any, index: any) => (
                        <BlockText rows={item} key={index} onClick={() =>{ setIsOpenDetail(true); setCurrentTextTemplate(item) }} refreshTextTemplate={refreshTextTemplate} />
                      ))}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </div>
      </div>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        minWidth='1000px'
        title={"Création du modèle d'acte"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <ParameterTextForm 
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshTextTemplate={refreshTextTemplate}
          handleSubmit={handleSubmit}
        />
      </Panel>

      <Panel
        toggleOpen={isOpenDetail}
        hasBackDrop={true}
        minWidth='1000px'
        title={"Détails d'un modèle d'acte"}
        closeSidePanel={() => {
          setIsOpenDetail(false);
        }}
      >
        <div className="detail-container">
            {/* <div className="detail-logo">
                <img src={confrereBalanceIcon} alt="" />
            </div> */}
            <div className="detail-body">
                <div className="detail-header">
                    <span className='detail-title'>{currentTextTemplate?.name}</span>
                </div>
                <div className="localisation">
                    <span>Description</span>
                    <small>{currentTextTemplate?.html_content} {currentTextTemplate?.description}</small>
                </div>

                <div className="description">
                    <span>Description HTML</span>
                    <small>{currentTextTemplate?.html_content}</small>
                </div>

            </div>
        </div>
      </Panel>
    </>
  );
};

export default CListTexts;
