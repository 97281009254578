import React from "react";
import { Outlet } from "react-router-dom";

import "./Communautes.styles.scss";

const LCommunautes: React.FC = () => {
  return (
    <div className="ct-communaute">
      <Outlet />
    </div>
  );
};

export default LCommunautes;
