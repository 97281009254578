import React, { FC, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, frFR, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  // Pagination,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import Panel from "@/components/UICs/Panel/Panel.uic";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import "./Nationality.style.scss";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { TNationality } from "@/features/admin/types";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";
import { formatDate } from "@/utils/formatDate";
import { createNationality, deleteNationality, getAllNationality, updateNationality } from "@/features/admin/api/nationality.api";
import NationalityForm, { TNationalityFormsValues } from "@/features/admin/forms/Nationality/Nationality.form";
// import DeleteDialog from "@/components/UICs/DeleteDialog/DeleteDialog.uix";
import Swal from "sweetalert2";

const rowsPerPage = 7;

type DropdownMenuProps = {
  ruccentNationality: any;
  refreshNationality: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({ ruccentNationality, refreshNationality }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**Confirmation Suppression d'une Nationalité  */
  const confirmDeleteNationality = () => {
    handleClose();
    Swal.fire({
      title: " Suppression",
      text:
        "Vous êtes sur le point de supprimer une nationalité !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        deleteNationalityFuntion();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteNationalityFuntion= async () => {
    const response = await deleteNationality(ruccentNationality.id);
    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `Nationalité "${ruccentNationality.nationalite}" a été supprimée avec succès`,
        'success',
        3000
      ).fire();
      refreshNationality();
    }
  };

  const updateItem = async (
    values: TNationalityFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const toSend = {
      libelle: values.libelle,
      nationalite: values.nationalite,
    };
    const response = await updateNationality(toSend, ruccentNationality.id);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Nationalité modifiée avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshNationality();
      // console.table(response);
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TNationalityFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TNationalityFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonSearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Consulter</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem onClick={confirmDeleteNationality}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Mise à jour de la nationalité"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <NationalityForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          ruccentNationality={ruccentNationality}
          isForUpdate={true}
          refreshNationality={refreshNationality}
          handleSubmit={handleSubmit}
        />
      </Panel>
      {/* <DeleteDialog
        alertTitle={`Voulez-vous supprimer la nationalité`}
        elementToDelete={` ${ruccentNationality.nationalite}`}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        performedFunction={deleteNationalityFuntion}
      /> */}
    </>
  );
};

const Nationality = () => {
  const columns: GridColDef[] = [
    {
      field: "libelle",
      headerName: "Libelle",
      flex: 1,
      sortable: true,
    },
    {
      field: "nationalite",
      headerName: "Nationalité",
      flex: 1,
      sortable: true,
    },
    { 
      field: "created_date",
      headerName: "Date de création",
      flex: 1,
      sortable: true
    },
    {
      field: "is_actif",
      headerName: "Statut",
      flex: 1,
      sortable: true,
    },
    // {
    //   field: "actions",
    //   headerName: "",
    //   width: 60,
    //   renderCell: (params: GridRenderCellParams) => {
    //     const { row } = params;
    //     return <DropdownMenu ruccentNationality={row} refreshNationality={refreshNationality} />;
    //   }, //TODO : check params
    // },
  ];

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  // const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [typeNationalityData, setTypeNationalityData] = useState<TNationality[]>([]);
  const [loadUser, setLoadUser] = useState(true);

  // const pageChange = (event: any, value: any) => {
  //   setPage(value);
  // };

  const getNationality = async () => {
    const { data } = await getAllNationality();
    const dataWithNewObjectKeys: TNationality[] = data.map((nationality: any) => {
      return {
        id: nationality._id,
        libelle: upperFirstWordLetter(nationality.libelle),
        nationalite: upperFirstWordLetter(nationality.nationalite),
        created_date: formatDate(nationality.created_date),
        is_actif: nationality.is_actif === 1 ? "Actif" : "Inactif",
      };
    });
    setTypeNationalityData(dataWithNewObjectKeys);
  };

  const refreshNationality = async () => {
    setLoadUser(true);
    await getNationality();
    setLoadUser(false);
  };

  useEffect(() => {
    getNationality();
  }, [loadUser]);

  const saveItem = async (
    typeNationality: TNationalityFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const response = await createNationality(typeNationality);

    if (response.error) {
        ToastError.fire({ text: response.message, timer: 9000 });
      } else {
        ToastGeneric("Nationalité créée avec succès", "success", 3000).fire();
        setOpenPanel(false);
        refreshNationality();
      }
      setSubmitting(false);
  };
  const handleSubmit = (
    values: TNationalityFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TNationalityFormsValues>
  ) => {
    // alert(JSON.stringify(values, null, 2));
    console.log(" values ", values);
    saveItem(values, setSubmitting);
  };

  return (
    <>
      <div className="c-list-users">
        <div className="utilisateur-content">
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div className="utilisateur-table-part">
                  {/* <div
                    className="utilisateur-table-header"
                    style={{ height: "55px" }}
                  >
                    <Button
                      color="primary"
                      onClick={(e) => setOpenPanel(true)}
                      variant="contained"
                      title="Créer une nationalité"
                      disableElevation
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Créer une nationalité
                    </Button>
                  </div> */}
                  <div className="utilisateur-table-body">
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        rows={typeNationalityData}
                        columns={columns}
                        // hideFooterPagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        disableColumnMenu
                        pageSize={pageSize}
                        rowsPerPageOptions={[7, 15, 30]}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        sx={{
                          fontFamily: "'Raleway',sans-serif !important",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#ececec",
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-columnHeaderTitle": {
                            color: "var(--ui-primary)",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
                            {
                              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
                            },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            minHeight: "5px",
                          },
                          "& .MuiTablePagination-toolbar": {
                            "& > p": {
                              marginBottom: 0,
                            },
                          },
                        }}
                      />
                      {/* <div className="pagination">
                        <Pagination
                          count={Math.ceil(10 / rowsPerPage)}
                          page={page}
                          onChange={pageChange}
                          showFirstButton
                          showLastButton
                          variant="outlined"
                          shape="rounded"
                        />
                      </div> */}
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création de nationalité"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <NationalityForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshNationality={refreshNationality}
          handleSubmit={handleSubmit}
        />
      </Panel>
    </>
  );
};

export default Nationality;
