export const upperFirstWordLetter = (str: string): string => {
  const firstWord = str.split(" ")[0];
  const firstWordLetter = firstWord.charAt(0);
  const firstWordUpper = firstWord.replace(
    firstWordLetter,
    firstWordLetter.toUpperCase()
  );
  return firstWordUpper + str.slice(firstWord.length);
};

export const capitalizeEachWordInSentence = (str: string): string => {
  let wordToReturn = "";
  if (str) {
    /** On split par espace pour avoir tous les mots */

    let wordToReturn = str?.split(" ");

    for (let i = 0; i < wordToReturn.length; i++) {
      wordToReturn[i] =
        wordToReturn[i][0].toUpperCase() + wordToReturn[i].substr(1);
    }

    wordToReturn.join(" ");

    console.log(" wordToReturn ", wordToReturn);
  }

  return wordToReturn;
};
