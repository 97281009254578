import sidebarTypes from "./sidebar.types";

interface IToggleSidebar{
  type: sidebarTypes.TOGGLE_SIDEBAR;
}


export type SideBarAction = IToggleSidebar;


export const toggleSidebar = () => ({
  type: sidebarTypes.TOGGLE_SIDEBAR,
});

