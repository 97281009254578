import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  // convertToRaw,
  ContentState,
  ContentBlock,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import ListIcon from '@mui/icons-material/List';
import CloseIcon from '@mui/icons-material/Close';
import './EditorText.style.scss';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import * as _ from 'lodash';

import {
  insertVariable,
  moveSelectionToEndWithSpace,
} from '@/lib/draftJsEditor';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { TBillSale } from '../../types';
import draftToHtml from 'draftjs-to-html';

const compositeDecorator = [
  {
    strategy: findCustomEntities,
    component: CustomVariable,
  },
];

type TPropsEditor = {
  dataCkeditor?: any;
  currentFolder?: any;
  setBillSaleDataToSend?: React.Dispatch<
    React.SetStateAction<TBillSale | undefined>
  >;
  isClearEditorText?: boolean;
  isReadOnly?: boolean;

  setIsClearEditorText?: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditorText: React.FC<TPropsEditor> = ({
  dataCkeditor,
  currentFolder,
  setBillSaleDataToSend,
  isReadOnly,
  isClearEditorText,
  setIsClearEditorText,
}) => {
  const [editorVariablesList, setEditorVariablesList] = useState<any[]>([]);
  // const [requestDataToSend, setRequestDataToSend] = useState<TBillSale>();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [billSaleInitialeValue, setBillSaleInitialeValue] = useState<any>();

  const onEditorStateChange = (value: EditorState) => {
    // console.log(" value ",value);
    // console.log(" draftToHtml(convertToRaw(editorState.getCurrentContent())) ",draftToHtml(convertToRaw(editorState.getCurrentContent())));

    setEditorState(value);
    console.log('ids', billSaleInitialeValue?.actors_ids);
    setBillSaleDataToSend?.({
      reference: currentFolder?.reference,
      folder_reference: currentFolder?.reference,
      bill_sale_model_id: dataCkeditor.modeleText
        ? JSON.parse(dataCkeditor.modeleText)?._id
        : '',
      description: JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      ),
      content_html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      seller_ids: billSaleInitialeValue?.seller_ids,
      buyers_ids: billSaleInitialeValue?.buyers_ids,
      actors_ids: billSaleInitialeValue?.actors_ids,
      confreres_ids: billSaleInitialeValue?.confreres_ids,
    });
  };

  const handleClick =
    (info: { label: string; value: string }) => (e: SyntheticEvent) => {
      console.log(info);

      const contentStateWithEntity = editorState
        .getCurrentContent()
        .createEntity('VARIABLE', 'IMMUTABLE', {
          info,
          handleDelete,
        });

      const contentState = insertVariable(
        contentStateWithEntity,
        editorState,
        info
      );

      const { editorState: newEditorState, contentState: newContentState } =
        moveSelectionToEndWithSpace(contentState, editorState);

      setEditorState(
        EditorState.set(newEditorState, {
          currentContent: newContentState,
        })
      );
    };

  const handleDelete = () => {
    console.info('You clicked the delete icon.');
  };

  const getPersonalInfo = (buyerOrSeller: any) => {
    let listDataToClick: any = [];

    if (buyerOrSeller) {
      if (buyerOrSeller.company_infos) {
        listDataToClick.push({
          value: 'Raison sociale',
          label: buyerOrSeller?.company_infos?.denomination,
        });
        listDataToClick.push({
          value: 'Forme juridique',
          label: buyerOrSeller?.company_infos?.legal_form,
        });
        listDataToClick.push({
          value: 'N°Télephone',
          label: buyerOrSeller?.company_infos?.phone_number,
        });
        listDataToClick.push({
          value: 'Régistre de commerce',
          label: buyerOrSeller?.company_infos?.registration,
        });
        listDataToClick.push({
          value: 'Capital',
          label: buyerOrSeller?.company_infos?.capital,
        });
        listDataToClick.push({
          value: 'Email entreprise',
          label: buyerOrSeller?.company_infos?.email,
        });
      } else {
        /** Il s'agit d'une personne physique */
        listDataToClick.push({
          value: 'Nom',
          label: buyerOrSeller?.personal_infos?.last_name,
        });
        listDataToClick.push({
          value: 'Prénoms',
          label: buyerOrSeller?.personal_infos?.first_name,
        });
        listDataToClick.push({
          value: 'Date de naissance',
          label: buyerOrSeller?.personal_infos?.birth_date,
        });
        listDataToClick.push({
          value: 'Lieu de naissance',
          label: buyerOrSeller?.personal_infos?.birth_place,
        });
        listDataToClick.push({
          value: 'Civilité',
          label: buyerOrSeller?.personal_infos?.civility,
        });
        listDataToClick.push({
          value: 'Profession',
          label: buyerOrSeller?.personal_infos?.job,
        });
        listDataToClick.push({
          value: 'N°Télephone',
          label: buyerOrSeller?.personal_infos?.phone_number,
        });
        listDataToClick.push({
          value: 'Date établissement pièce',
          label: buyerOrSeller?.personal_infos?.piece_establishment_date,
        });
        listDataToClick.push({
          value: "Lieu d'établissement pièce",
          label: buyerOrSeller?.personal_infos?.piece_establishment_place,
        });
        listDataToClick.push({
          value: 'Date expiration pièce',
          label: buyerOrSeller?.personal_infos?.piece_expiration_date,
        });
        listDataToClick.push({
          value: 'N° Pièce',
          label: buyerOrSeller?.personal_infos?.piece_number,
        });
      }
    }

    return listDataToClick;
  };

  /** Formater le tableau des variables */
  const formatVariablesListe = () => {
    /** Je formate une liste d'acuereur, de vendeurs, de confrere et d'acteurs */
    let finalVariables = [];

    let sellerData: any = {}; /** On format un objet vendeur */
    if (currentFolder.seller_ids && currentFolder?.seller_ids?.length > 0) {
      let listSeller: any = [];
      let seller_ids: any = [];

      _.forEach(currentFolder?.seller_ids, seller => {
        let libelleNameSeller = !seller?.company_infos
          ? seller?.personal_infos?.last_name +
          ' ' +
          seller?.personal_infos?.first_name
          : seller.company_infos?.denomination;

        listSeller.push({
          libelle: libelleNameSeller,
          value: libelleNameSeller,
          label: libelleNameSeller,
          id: seller?._id,
          dataPuces: getPersonalInfo(seller),
        });

        seller_ids.push(seller?._id);
      });
      sellerData = {
        seller_ids: seller_ids,
        subItems: listSeller,
      };

      finalVariables.push({
        type: 'Vendeurs',
        items: listSeller,
      });
    }

    let buyerData: any = {}; /** On format un objet buyer */
    if (dataCkeditor.buyers && dataCkeditor?.buyers?.length > 0) {
      let listBuyer: any = [];
      let buyers_ids: any = [];
      console.log({ buyers: dataCkeditor.buyers });
      let buyersArray = JSON.parse(dataCkeditor.buyers);

      _.forEach(buyersArray, buyer => {
        try {
          let buyerName = !buyer?.company_infos
            ? buyer?.personal_infos?.last_name +
            ' ' +
            buyer?.personal_infos?.first_name
            : buyer.company_infos?.denomination;

          listBuyer.push({
            libelle: buyerName,
            value: buyerName,
            label: buyerName,
            dataPuces: getPersonalInfo(buyer),
            id: buyer?._id,
            // infosPerso :
          });
          buyers_ids.push(buyer?._id);
        } catch (error) {
          console.log('error', error);
        }
      });

      buyerData = {
        buyers_ids: buyers_ids,
        subItems: listBuyer,
      };
      console.log({ buyerData });

      finalVariables.push({
        type: 'Acquéreurs',
        items: listBuyer,
      });
    }
    console.log('editor', currentFolder);
    // Property Variable Editor
    let propertyData: any = {}; /** On format un objet buyer */
    if (currentFolder.biens && currentFolder?.biens?.length > 0) {
      let listProperty: any = [];
      let property_ids: any = [];
      let dataPucesData: any = [];
      console.log({ biens: currentFolder.biens });

      let properties = currentFolder.biens;

      _.forEach(properties, property => {
        try {
          if (property?.type_bien) {
            let propertyType = `${property.libelle}`;

            listProperty.push({
              libelle: propertyType,
              value: propertyType,
              label: propertyType,
              id: property?._id,
              dataPuces: [
                {
                  label: property.num_titre_foncier,
                  value: 'Numéro Titre Foncier'
                },
                {
                  label: `${property.prix_vente} FCFA`,
                  value: "Prix de vente"
                },
                {
                  label: property.commune_id.libelle,
                  value: 'Commune'
                },
                {
                  label: property.quartier,
                  value: 'Quartier'
                },
                {
                  label: property.numero_lot,
                  value: "Numéro du lot"
                },
                {
                  label: property.numero_ilot,
                  value: "Numéro de l'ilot"
                },

                {
                  label: property.etat_foncier.num_etat_foncier,
                  value: "Numéro Etat Foncier"
                },
                {
                  label: property.etat_foncier.autorite,
                  value: "Autorité"
                },
                {
                  label: property.etat_foncier.date_etatblissement,
                  value: "Date d'établissement"
                },
                {
                  label: property.situation_hypothecaire,
                  value: "Situation Hypothecaire"
                },
                {
                  label: property.bureau_conservation,
                  value: 'Bureau Conservation',
                }

              ]

              // infosPerso:
            });
            property_ids.push(property?._id);
          }
        } catch (error) {
          console.log('error', error);
        }
      });

      propertyData = {
        property_ids,
        subItems: listProperty,
      };

      finalVariables.push({
        type: 'Biens',
        items: listProperty,
      });
    }

    let actorData: any =
      {}; /** On format un objet actor qui a les meême propriete que le backend demande */
    if (dataCkeditor.actors && dataCkeditor?.actors?.length > 0) {
      let listActor: any = [];
      let actors_ids: any = [];
      console.log({ actors: dataCkeditor.actors });
      let actorsArray = JSON.parse(dataCkeditor.actors);

      _.forEach(actorsArray, (actor, key) => {
        listActor.push({
          libelle: actor?.actor_libelle,
          label: actor?.actor_libelle,
          value: actor?.actor_libelle,
          id: actor?._id,
        });
        actors_ids.push(actor?._id);
      });
      actorData = {
        actors_ids: actors_ids,
        subItems: listActor,
      };

      finalVariables.push({
        type: 'Acteurs',
        items: listActor,
      });
    }

    let dataColleague: any = {}; /** On format un objet confrere */
    if (dataCkeditor.colleagues && dataCkeditor?.colleagues?.length > 0) {
      let listColleague: any = [];
      let confreres_ids: any = [];

      let ColleguesArray = JSON.parse(dataCkeditor.colleagues);

      _.forEach(ColleguesArray, confrere => {
        listColleague.push({
          libelle: confrere?.libelle,
          label: confrere?.libelle,
          value: confrere?.libelle,
          id: confrere?._id,
        });
        confreres_ids.push(confrere?._id);
      });

      dataColleague = {
        confreres_ids: confreres_ids,
        subItems: listColleague,
      };

      finalVariables.push({
        type: 'Confrères',
        items: listColleague,
      });
    }

    setEditorVariablesList(finalVariables);

    let dataFormatedBillSale = {
      // reference: billSaleInitialeValue?.reference,
      folder_reference: currentFolder?.reference,
      bill_sale_model_id: dataCkeditor.modeleText
        ? JSON.parse(dataCkeditor.modeleText)?._id
        : '',
      description:
        dataCkeditor.modeleText &&
        JSON.parse(dataCkeditor.modeleText)?.description,

      content_html: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      seller_ids: sellerData?.seller_ids,
      buyers_ids: buyerData?.buyers_ids,
      actors_ids: actorData?.actors_ids,
      confreres_ids: dataColleague?.confreres_ids,
    };
    console.log({ dataFormatedBillSale });
    setBillSaleDataToSend?.(dataFormatedBillSale);
    setBillSaleInitialeValue(dataFormatedBillSale);
  };

  useEffect(() => {
    let newEditorState: EditorState;

    if (dataCkeditor && dataCkeditor.modeleText) {
      let contentState: any;
      if (dataCkeditor?.description && isClearEditorText) {
        // En ce moment nous sommes en modification
        contentState = convertFromRaw(JSON.parse(dataCkeditor?.description));
      } else {
        if (dataCkeditor?.modeleText) {
          console.log(JSON.parse(dataCkeditor?.modeleText));
          contentState = convertFromRaw(
            JSON.parse(JSON.parse(dataCkeditor?.modeleText)?.description)
          );
        }
      }

      // const contentState = ContentState.createFromText(text);

      newEditorState = EditorState.createWithContent(contentState);

      // const newContentState = Modifier.insertText(
      //   editorState.getCurrentContent(),
      //   editorState.getSelection(),
      //   text
      // );

      // const newEditorState = EditorState.createWithContent(newContentState);

      // const newEditorState = EditorState.set(editorState, {
      //   currentContent: newContentState,
      // });

      setEditorState(newEditorState);
    }
  }, [dataCkeditor, isClearEditorText]);

  useEffect(() => {
    dataCkeditor && formatVariablesListe();
    // eslint-disable-next-line
  }, [dataCkeditor]);

  useEffect(() => {
    if (isClearEditorText) {
      const contentState = EditorState.createEmpty();
      setEditorState(contentState);
      setIsClearEditorText!(false);
    }
  }, [isClearEditorText, setIsClearEditorText]);

  return (
    <div className="app-editor">
      <div className="editor-container">
        <Editor
          editorState={editorState}
          onEditorStateChange={onEditorStateChange}
          toolbarClassName="rw-toolbar-custom"
          wrapperClassName="rw-wrapper"
          editorClassName="rw-editor"
          customDecorators={compositeDecorator}
          placeholder="Saisissez votre texte ici"
          toolbar={{
            options: [
              'blockType',
              'inline',
              'fontSize',
              'fontFamily',
              'textAlign',
              'list',
              'remove',
              'history',
            ],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough'],
            },
            list: {
              options: ['unordered', 'ordered'],
            },
          }}
        />
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
          className="textarea-html"
          style={{ width: "calc(100% - 420px)" }}
        /> */}
      </div>
      <div className="variables-text-container">
        <Typography sx={{ fontSize: '14px' }} component="p">
          {editorVariablesList &&
            ' Vous pouvez cliquer sur les variables pour les ajouter dans le texte'}
        </Typography>

        {editorVariablesList &&
          editorVariablesList.map((item, index) => (
            <div className="app-actions" key={index}>
              <Typography variant="h6" sx={{ fontSize: '16px' }}>
                {item?.type}
              </Typography>
              <div className="accordion-container">
                {item.items &&
                  (item?.type?.toLowerCase() === 'acquéreurs' ||
                    item?.type?.toLowerCase() === 'vendeurs' || item?.type?.toLowerCase() === 'biens') &&
                  item.items.map((subItem: any, indexSub: any) => (
                    <div key={indexSub}>
                      <Accordion className="accordion-item">
                        <AccordionSummary
                          className="accordion-sammary"
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          sx={{
                            width: '100% !important',
                            padding: '1px',
                          }}
                        >
                          <Typography>{subItem?.libelle}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack
                            direction="row"
                            flexWrap="wrap"
                            gap={1}
                            sx={{
                              '@media screen and (max-width: 1141px)': {
                                flexWrap: 'nowrap',
                                overflow: 'hidden',
                                overflowX: 'auto',
                              },
                            }}
                          >
                            {subItem &&
                              subItem.dataPuces  &&
                              subItem.dataPuces.map((info: any, idx: any) => (
                                <>
                                {info.label && ( <div className="puce-content" key={idx}>
                                  <small>{info?.value}</small>
                                  <Chip
                                    key={idx}
                                    disabled={isReadOnly}
                                    label={info.label}
                                    onClick={handleClick(info)}
                                    // icon={<ListIcon />}
                                    sx={{
                                      borderRadius: 0,
                                    }}
                                  />
                                </div> )}
                                </>
                              ))}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  ))}
              </div>
              <>
                {item?.type?.toLowerCase() !== 'acquéreurs' &&
                  item?.type?.toLowerCase() !== 'vendeurs' && item?.type?.toLowerCase() !== 'biens' &&
                  item.items &&
                  item.items.map((info: any, subIndex: any) => (
                    <div key={subIndex}>
                      <Chip
                        key={subIndex}
                        label={info.label}
                        onClick={handleClick(info)}
                        // icon={<ListIcon />}
                        sx={{
                          borderRadius: 0,
                        }}
                      />
                    </div>
                  ))}
              </>
            </div>
          ))}
      </div>
    </div>
  );
};

function findCustomEntities(
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === 'VARIABLE'
    );
  }, callback);
}

function CustomVariable(props: {
  contentState: ContentState;
  entityKey: string;
  offsetKey: string;
}) {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');

      if (ctx) {
        const width = ctx.measureText(info.label).width;
        ctx.font = '16px sans-serif';
        ctx.canvas.width = width * 1.4;
        ctx.canvas.height = 20;
        ctx.scale(1.4, 1.4);
        ctx.fillText(info.label, 0, 12);
      }
    }

    // eslint-disable-next-line
  }, []);

  const { info, handleDelete } = props.contentState
    .getEntity(props.entityKey)
    .getData();

  return (
    <span data-offset-key={props.offsetKey} contentEditable={false}>
      <Chip
        label={
          <>
            <canvas ref={canvasRef}></canvas>
            <span hidden>{info.value}</span>
          </>
        }
        component={'span'}
        onDelete={handleDelete}
        icon={<ListIcon />}
        deleteIcon={<CloseIcon />}
        sx={{
          borderRadius: 0,
        }}
      />
    </span>
  );
}

export default EditorText;
