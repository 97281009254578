export const routePaths = {
  root: "/",
  portail: "/portail",
  portailChildren: {
    offres: "/offres",
    offresAbsolute: "/portail/offres",
  },

  auth: "/auth",
  authChildren: {
    signIn: "/sign-in",
    signInAbsolute: "/auth/sign-in",
    signUp: "/sign-up",
    signUpAbsolute: "/auth/sign-up",
    forgottenPassword: "/forgot-password",
    forgottenPasswordAbsolute: "/auth/forgot-password",
    resetPassword: "/reset-password",
    resetPasswordAbsolute: "/auth/reset-password",
  },
  admin: "/admin",
  adminChildren: {
    home: "/home",
    homeAbsolute: "/admin/home",
    prospects: "/prospects",
    prospectsAbsolute: "/admin/prospects",

    search: "/search",
    searchAbsolute: "/admin/search",
    myAccount: "/my-account",
    myAccountAbsolute: "/admin/my-account",
    notification: "/notification",
    notificationAbsolute: "/admin/notification",
    subscription: "/subscription",
    subscriptionAbsolute: "/admin/subscription",
    subscriptionChildren: {
      desactivateSubscription: "/desactivate",
      desactivateSubscriptionAbsolute: "/admin/subscription/desactivate",
      deleteAccount: "/delete",
      deleteAccountAbsolute: "/admin/subscription/delete",
    },

    folder: "/folder",
    folderAbsolute: "/admin/folder",
    folderChildren: {
      createFolder: "/create-folder",
      createFolderAbsolute: "/admin/folder/create-folder",
      createFolderBienImmobilier: "/bien-immobilier",
      createFolderBienImmobilierAbsolute: "/admin/folder/bien-immobilier",
    },

    client: "/client",
    clientAbsolute: "/admin/client",
    clientChildren: {},

    files: "/files",
    filesAbsolute: "/admin/files",
    filesChildren: {},

    communautes: "/communautes",
    communautesAbsolute: "/admin/communautes",
    communautesChildren: {
      confrereCommunaute: "/confrere",
      confrereCommunauteAbsolute: "/admin/communautes/confrere",
      partenaireCommunaute: "/partenaires",
      partenaireCommunauteAbsolute: "/admin/communautes/partenaires",
    },

    administration: "/administration",
    administrationAbsolute: "/admin/administration",
    administrationChildren: {
      user: "/user",
      userAbsolute: "/admin/administration/user",
      parametresBase: "/parametres",
      parametresBaseAbsolute: "/admin/administration/parametres",
    },

    updatePassword: "/update-password",
    updatePasswordAbsolute: "/admin/update-password",
  },
};
