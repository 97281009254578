import styled from "styled-components";

export const FSHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  //margin: 25px 0;
  align-items: center;

  .search-input {
    width: 380px;
    margin-bottom: 0;
  }

  button {
    width: 200px;
    text-transform: inherit;
    height: 33px;
  }
`;
