export enum AppCredentials {
  // // DEV ENVIRONEMENT
  // APP_ID = '64186bc3458fbf5440a02793',
  // APP_SECRET = 'x!A%D*G-KaPdSgVkXp2s5v8y/B?E(H+MbQeThWmZq3t6w9z$C&F)J@NcRfUjXn2r5u7x!A%D*G-KaPdSgVkYp3s6v9y/B?E(H+MbQeThWmZq4t7w!z%C&F)J@NcRfUjX',

  // NOTAIRE ENVIRONEMENT
  APP_ID = "636a3fa4cfeaa4b3ec933e4e",
  APP_SECRET = "WEZi7TlZY/kfZS9MzePWqD2Qrw1ottB5BqpqjjckkUTURDBTN04eXMwVuWZDyuS9r1pN+utjlVtdPKkDXjEQyv40O+hvolZrfoh7YEmjY4gYU1oBfH1QDLxxwUxIPlBmJcnd2GJ9TUglpVvOVIkDfqE0cWAlc6XmHdwiW3ANcdEDNkPMiVZ3d4MxO7phBnw6AMF23h87ih7DVLNZxQwJarasPWCUYUO+nPvxfkyoXCGGLv4c2i94IUrJ0NtuSn6gnTGDD0uwB8TYc8VThDs9J9yRyQ/KrQLqDdGLqCrJWWHlOq2TwsWlo5ueRj462cLs9yyQ64sTAhdXRLmBTlUbK/4=",
}

export enum StatutMatrimonial {
  celibataire = "célibataire",
  marie = "marié",
  divorce = "divorcé",
  veuf = "veuf",
}

export enum UserJobTitle {
  clerc = "clerc",
  assisant = "assisant",
  notaire = "notaire",
}

export enum NameFolderType {
  bienImmobilier = "Biens immobiliers",
  affaire = "Affaire",
  famille = "Famille",
}

export enum FolderStatut {
  EnCours = 1,
  Cloture = 2,
  Cancel = 3,
  Suspendu = 4,
}

export enum AccountantStatut {
  EnCours = 1,
  Cancel = 0,
}

export enum BillSaleStatut {
  Brouillon = 0,
  Valide = 2,
}

export enum PersonneTypeId {
  PersonneMorale = "64aff5e59922c087df08846c",
  PersonnePhysique = "640a02e9097761f13e77e96f",
}

export enum msgConnexionInterrompue {
  connexionInterrompue = "La connexion est momentanent interrompue. Veuillez réessayer ultérieurement.",
}
