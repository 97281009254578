import React from "react";
import { Outlet } from "react-router-dom";

import "./Subscription.styles.scss";

const LSubscription: React.FC = () => {
  return (
    <div className="ct-subscription">
      <Outlet />
    </div>
  );
};

export default LSubscription;
