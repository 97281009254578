import { AppCredentials } from '@/config/enum';
import { IApiResponse } from '@/interface';
import { axios2 } from '@/lib/axios';
import { TProspect } from '../types/types';

export const createProspect = (data: TProspect): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post('/prospect/add', toSend);
};

export const updateProspect = (data: TProspect, docId: string): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/prospect/update/${docId}`, toSend);
};

export const getAllProspect = (): Promise<IApiResponse> => {
  return axios2.get('/prospect');
};

export const deleteProspect = (docId: string): Promise<IApiResponse> => {
  return axios2.delete(`/prospect/${docId}`);
};
