import { Box, Button, Tab } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { TabPanel } from '@mui/lab';
import logoWhite from "@/assets/images/png/logo_white_app.png";
import offreBasicImg from "@/assets/images/png/offre_basic_img.png";
import offreMoynImg from "@/assets/images/png/offre_moyenne_img.png";
import offrePremuImg from "@/assets/images/png/offre_premium_img.png";
import offreImg from "@/assets/images/svg/offre_img.svg";
import React from 'react';
import './Offres.content.style.scss';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/config';

const CtOffres:React.FC = () => {

  const navigate = useNavigate();
  const goTo = (route: string) => {
    navigate(route, { replace: true });
  }

  const [value, setValue] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className='offre-container'>
      <div className="offre-header-container">
        <div className="offre-header-content">
          <img src={logoWhite} alt="" />
          <div className="breadcrumbs">
            Accueil / Tarification
          </div>
          <div className="offre-header">
            <h1 className="offre-title">Tarification transparente et compétitive</h1>
            <p>Obtenez des prix compétitifs sur des services comparables et payez uniquement pour les ressources que vous utilisez.</p>
          </div>
        </div>
        <div className="offre-img">
          <img src={offreImg} alt="" />
        </div>
      </div>
      <div className="offre-body">
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box className='offre-box' sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList 
                      className='offre-tabs'
                      onChange={handleChange}
                      aria-label="lab API tabs example">
                <Tab label="Offre basique" value="1" />
                <Tab label="Offre moyenne" value="2" />
                <Tab label="Offre premium" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <div className="offre-body-container">
                <div className="offre-item">
                  <h4 className="item-title">Economisez</h4>
                  <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                  <h3 className="item-montant">10 000 <sup>Fcfa / mois</sup></h3>
                  <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                  <img src={offreBasicImg} alt="" />
                </div>

                <div className="offre-item">
                  <div className='offre-item-value'>
                    <h5 className="item-title">Facturation mensuelle</h5>
                    <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                    <h3 className="item-montant">10 000 <sup>Fcfa / mois</sup></h3>
                    <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                    <Button 
                            className='return-button'
                            variant="contained"
                            color='primary'
                            type='button' 
                            onClick={(e) => {goTo(routePaths.adminChildren.homeAbsolute)}}>
                      Choisir cette offre
                    </Button>
                  </div>
                  <div className='offre-detail'>
                    <ul>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                    </ul>
                  </div>
                </div>

                <div className="offre-item">
                  <div className='offre-item-value'>
                      <h5 className="item-title">Facturation mensuelle</h5>
                      <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                      <h3 className="item-montant">10 000 <sup>Fcfa / mois</sup></h3>
                      <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                      <Button 
                              className='return-button'
                              variant="contained"
                              color='primary'
                              type='button' 
                              onClick={(e) => {goTo(routePaths.adminChildren.homeAbsolute)}}>
                        Choisir cette offre
                      </Button>
                    </div>
                    <div className='offre-detail'>
                      <ul>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li  className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      </ul>
                    </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel value="2">
              <div className="offre-body-container">
                <div className="offre-item">
                  <h4 className="item-title">Economisez</h4>
                  <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                  <h3 className="item-montant">30 000 <sup>Fcfa / mois</sup></h3>
                  <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                  <img src={offreMoynImg} alt="" />
                </div>

                <div className="offre-item">
                  <div className='offre-item-value'>
                    <h5 className="item-title">Facturation mensuelle</h5>
                    <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                    <h3 className="item-montant">30 000 <sup>Fcfa / mois</sup></h3>
                    <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                    <Button 
                            className='return-button'
                            variant="contained"
                            color='primary'
                            type='button' 
                            onClick={(e) => {goTo(routePaths.adminChildren.homeAbsolute)}}>
                      Choisir cette offre
                    </Button>
                  </div>
                  <div className='offre-detail'>
                    <ul>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                    </ul>
                  </div>
                </div>

                <div className="offre-item">
                  <div className='offre-item-value'>
                      <h5 className="item-title">Facturation mensuelle</h5>
                      <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                      <h3 className="item-montant">30 000 <sup>Fcfa / mois</sup></h3>
                      <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                      <Button 
                              className='return-button'
                              variant="contained"
                              color='primary'
                              type='button' 
                              onClick={(e) => {goTo(routePaths.adminChildren.homeAbsolute)}}>
                        Choisir cette offre
                      </Button>
                    </div>
                    <div className='offre-detail'>
                      <ul>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      </ul>
                    </div>
                </div>
              </div>
            </TabPanel>

            <TabPanel value="3">
              <div className="offre-body-container">
                <div className="offre-item">
                  <h4 className="item-title">Economisez</h4>
                  <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                  <h3 className="item-montant">50 000 <sup>Fcfa / mois</sup></h3>
                  <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                  <img src={offrePremuImg} alt="" />
                </div>

                <div className="offre-item">
                  <div className='offre-item-value'>
                    <h5 className="item-title">Facturation mensuelle</h5>
                    <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                    <h3 className="item-montant">50 000 <sup>Fcfa / mois</sup></h3>
                    <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                    <Button 
                            className='return-button'
                            variant="contained"
                            color='primary'
                            type='button' 
                            onClick={(e) => {goTo(routePaths.adminChildren.homeAbsolute)}}>
                      Choisir cette offre
                    </Button>
                  </div>
                  <div className='offre-detail'>
                    <ul>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                    </ul>
                  </div>
                </div>

                <div className="offre-item">
                  <div className='offre-item-value'>
                      <h5 className="item-title">Facturation mensuelle</h5>
                      <p>Répondez aux besoins de votre organisation en termes d'activité et de budget avec une tarification compétitive</p>
                      <h3 className="item-montant">50 000 <sup>Fcfa / mois</sup></h3>
                      <Icon icon="fluent:arrow-right-12-filled" className='icon-offre'/>
                      <Button 
                              className='return-button'
                              variant="contained"
                              color='primary'
                              type='button' 
                              onClick={(e) => {goTo(routePaths.adminChildren.homeAbsolute)}}>
                        Choisir cette offre
                      </Button>
                    </div>
                    <div className='offre-detail'>
                      <ul>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li><Icon icon="ant-design:check-outlined" className='icon-offre-yes'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                        <li className='li-offre-not'><Icon icon="clarity:times-line" className='icon-offre-not'/>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec</li>
                      </ul>
                    </div>
                </div>
              </div>
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  )
}

export default CtOffres