import { TFolderCreate } from "@/features/admin/types";
import { CurrentFolderAction } from "./folder.action";
import folderTypes from "./folder.type";

interface IFolderReducerState {
  currentFolder: TFolderCreate | null;
}

const INITIAL_STATE = {
  currentFolder: {
    _id: "",
    reference: "",
    id: "",
  },
};

const folderReducer = (
  state: IFolderReducerState = INITIAL_STATE,
  action: CurrentFolderAction
): IFolderReducerState => {
  switch (action.type) {
    case folderTypes.CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: action.payload,
      };
    default:
      return state;
  }
};

export default folderReducer;
