export const API_URL = process.env.REACT_APP_API_URL as string;
export const API_URL2 = process.env.REACT_APP_API_URL2 as string;
export const FILE_URL = API_URL2 + 'files/view';

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export const FILE_SIZE = 5 * 1024 * 1024;
