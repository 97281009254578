import React, { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteIcon from '@mui/icons-material/Delete';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import ModeIcon from '@mui/icons-material/Mode';
import CheckIcon from '@mui/icons-material/Check';
import {
  Box,
  Button,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { GridColDef, GridRenderCellParams, DataGrid } from '@mui/x-data-grid';
import { formatDate } from '@/utils/formatDate';
import './ListBillSaleByFolder.styles.scss';
import { deleteBillSale, validBillsale } from '../../api/folder.api';
import { ToastError, ToastGeneric } from '@/utils/toast';
import { Loader } from '@/components/Loader/Loader';
import { TFolderCreate } from '../../types';
import { BillSaleStatut } from '@/config/enum';
import { convertFromRaw } from 'draft-js';

type TListBillProps = {
  readOnlyInput?: boolean;
  billSales: any;
  folder_reference?: string;
  setRefreshDataCurrentFolder?: React.Dispatch<React.SetStateAction<string>>;
  setBillSaleToUpdate?: React.Dispatch<React.SetStateAction<string>>;
};
const ListBillSaleByFolder: React.FC<TListBillProps> = ({
  readOnlyInput,
  billSales,
  setRefreshDataCurrentFolder,
  setBillSaleToUpdate,
  folder_reference,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rowBillSale, setRowBillSale] = useState<any>();

  const columns: GridColDef[] = [
    {
      field: 'reference',
      headerName: 'N° Acte',
      flex: 1,
      sortable: true,
    },
    {
      field: 'created_date',
      headerName: 'Dernière modification',
      flex: 1,
      sortable: true,
    },
    {
      field: 'created_by',
      headerName: 'Effectuée par',
      flex: 1,
      sortable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      sortable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={`${params.row.status || ''}`}
          className={`chip-item ${
            params.row.state === BillSaleStatut.Brouillon
              ? 'brouillon'
              : 'valide'
          }`}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 60,
      renderCell: (params: GridRenderCellParams) =>
        !readOnlyInput && (
          <DropdownMenu billSale={params.row} setIsLoading={setIsLoading} />
        ),
    },
  ];

  type TDropActeVenteProps = {
    billSale: any;
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  };

  const DropdownMenu: React.FC<TDropActeVenteProps> = ({
    billSale,
    setIsLoading,
  }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const confirmDeleteBillSale = () => {
      Swal.fire({
        title: ' Acte de vente',
        text:
          "Vous êtes sur le point de supprimer l'acte de vente " +
          billSale?.reference +
          '\nDésirez-vous poursuivre cette action ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: '#006D77',
        cancelButtonColor: '#91949c',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          setIsLoading?.(true);
          deleteItem();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    };

    const deleteItem = () => {
      deleteBillSale({
        reference: billSale?.reference,
        folder_reference: folder_reference,
      })
        .then(response => {
          setIsLoading?.(false);
          if (!response.error) {
            ToastGeneric(
              'Opération effectuée avec succès !',
              'success',
              9000
            ).fire();

            // On rafraichir la liste des acteurs
            setRefreshDataCurrentFolder?.(new Date().getTime().toString());
          } else {
            ToastError.fire({ text: response.message, timer: 9000 });
          }
        })
        .catch(error => {
          console.log(' error ', error);
          setIsLoading?.(false);
          if (error?.response?.data?.message) {
            ToastError.fire({
              text: error?.response?.data?.message,
              timer: 9000,
            });
          } else {
            ToastError.fire({ text: error, timer: 9000 });
          }
        });
    };

    const confirmValidateBillSale = () => {
      Swal.fire({
        title: ' Acte de vente',
        text:
          "Vous êtes sur le point de valider l'acte de vente " +
          billSale?.reference +
          ' Cette opération est irreversible ! \nDésirez-vous poursuivre cette action ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        confirmButtonColor: '#006D77',
        cancelButtonColor: '#91949c',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          setIsLoading?.(true);
          validateBilleSale();
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    };

    const validateBilleSale = () => {
      validBillsale({
        reference: billSale?.reference,
        folder_reference: folder_reference,
      })
        .then(response => {
          setIsLoading?.(false);
          if (!response.error) {
            ToastGeneric(
              'Opération effectuée avec succès !',
              'success',
              9000
            ).fire();

            // On rafraichir la liste des acteurs
            setRefreshDataCurrentFolder?.(new Date().getTime().toString());
          } else {
            ToastError.fire({ text: response.message, timer: 9000 });
          }
        })
        .catch(error => {
          console.log(' error ', error);
          setIsLoading?.(false);
          if (error?.response?.data?.message) {
            ToastError.fire({
              text: error?.response?.data?.message,
              timer: 9000,
            });
          } else {
            ToastError.fire({ text: error, timer: 9000 });
          }
        });
    };

    const gotToUpdateBillSale = (isVisualisation?: boolean) => {
      billSale.isVisualisation = isVisualisation;
      console.log({ billSale });
      setBillSaleToUpdate?.(billSale);
    };

    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {billSale.state === 1 && (
            <MenuItem
              onClick={e => {
                gotToUpdateBillSale(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <ModeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Visualiser</ListItemText>
            </MenuItem>
          )}

          {billSale.state === 0 && (
            <MenuItem
              onClick={e => {
                gotToUpdateBillSale();
                handleClose();
              }}
            >
              <ListItemIcon>
                <ModeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Modifier</ListItemText>
            </MenuItem>
          )}

          {billSale.state === 0 && (
            <MenuItem
              onClick={e => {
                confirmValidateBillSale();
                handleClose();
              }}
            >
              <ListItemIcon>
                <CheckIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Valider l'acte de vente</ListItemText>
            </MenuItem>
          )}

          {billSale.state === 0 && (
            <MenuItem
              onClick={e => {
                confirmDeleteBillSale();
                handleClose();
              }}
            >
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Supprimer</ListItemText>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  };

  useEffect(() => {
    let rowBill: any = [];

    billSales.map((bill: any, index: number) => {
      rowBill.push({
        id: index + 1,
        description: convertFromRaw(
          JSON.parse(bill.description)
        ).getPlainText(),
        reference: bill.reference,
        _id: bill?._id,
        bill_sale_model_id: bill?.bill_sale_model_id,
        created_by: bill?.created_by,
        created_date: formatDate(bill?.created_date),
        status: bill?.state === 0 ? 'Brouillon' : 'Validé',
        state: bill?.state,
        seller_ids: bill?.seller_ids,
        confreres_ids: bill?.confreres_ids,
        buyers_ids: bill?.buyers_ids,
      });
    });

    setRowBillSale(rowBill);
  }, [billSales]);

  return (
    <div className="list-bill-sale-container">
      <Loader isLoading={isLoading} />
      <Box sx={{ width: '100%', height: '380px', padding: '0px 13px' }}>
        <DataGrid
          rows={rowBillSale || []}
          columns={columns}
          hideFooterPagination={true}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          disableColumnMenu
          sx={{
            fontFamily: "'Raleway',sans-serif !important",
            border: 'none',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#ececec',
              borderBottom: 'none',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: 'var(--ui-primary)',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)':
              {
                backgroundColor: 'rgba(var(--ui-light-rgb), 0.7)',
              },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              minHeight: '5px',
            },
          }}
        />
      </Box>
    </div>
  );
};

export default ListBillSaleByFolder;
