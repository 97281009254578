import { TThemeColors } from '../utils/convertThemeColorsToRootColors';

export const colors: TThemeColors = {
  ui: {
    primary: '#006D77',
    'primary-shade': '#42738A',
    'primary-tint': '#DBE9EB',

    secondary: '#AAA59A',
    'secondary-shade': '#B19E37',
    'secondary-light': '#A99A77',

    tertiary: '#F5F3EF',
    'tertiary-shade': '#402F07',
    dark: '#252525',
    'dark-shade': '#070E3B',
    gray: '#8790a1',
    medium: '#363853',
    light: '#F9F9F9',
    'light-shade': '#EDEDED',
    black: '#000000',
    white: '#FFFFFF',
    success: '#75D873',
    warning: '#F5B544',
    'tertiary-light': '#F5F3EF',
    infos: '#6DB5FC',
    'infos-shade': '#F2FBFF',
    'medium-shade': '#ECECEC',
    danger: '#e63838',
    error: '#872D2D',
  },
};
