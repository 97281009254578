import React, { FC, useState } from "react";
import { InlineIcon } from "@iconify/react";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Swal from "sweetalert2";
import {
  deleteTextTemplate,
  updateTextTemplate,
} from "../../api/TextTemplate.api";
import Panel from "@/components/UICs/Panel/Panel.uic";
import { ToastError, ToastGeneric } from "@/utils/toast";
import ParameterTextForm, {
  TTextTemplateFormsValues,
} from "../../forms/ParameterText/ParameterText.form";
import { FormikHelpers } from "formik";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import "./BlockText.styles.scss";
import { convertFromRaw, convertToRaw } from "draft-js";

type DropdownMenuProps = {
  currentTextTemplate: any;
  refreshTextTemplate: () => void;
  onClick: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  currentTextTemplate,
  refreshTextTemplate,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**Confirmation Suppression d'un Type d'acteur  */
  const confirmDeleteTextTemplate = () => {
    handleClose();
    Swal.fire({
      title: " Suppression",
      text: "Vous êtes sur le point de supprimer un modèle d'acte !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        deleteTextTemplateFuntion();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteTextTemplateFuntion = async () => {
    const response = await deleteTextTemplate(currentTextTemplate.id);
    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `modèle d'acte "${currentTextTemplate.libelle}" a été supprimé avec succès`,
        "success",
        3000
      ).fire();
      refreshTextTemplate();
    }
  };

  const updateItem = async (
    values: TTextTemplateFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    // console.log({ currentConfrere });
    const toSend = {
      id: values.id,
      name: values.name,
      description: values.description,
    };
    // console.log("currentConfrere ", toSend);
    const response = await updateTextTemplate(toSend, currentTextTemplate.id);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("modèle d'acte modifié avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshTextTemplate();
      // console.table(response);
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TTextTemplateFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TTextTemplateFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color='primary'
        variant='contained'
        title="Créer un modèle d'acte"
        endIcon={<ExpandMoreIcon sx={{ width: 20, height: 20 }} />}
      >
        Actions
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={() => { onClick(); handleClose()}}>
          <ListItemIcon>
            <PersonSearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Détails</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem onClick={confirmDeleteTextTemplate}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        minWidth='1000px'
        title={"Mise à jour du modèle d'acte"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <ParameterTextForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          currentTextTemplate={currentTextTemplate}
          isForUpdate={true}
          refreshTextTemplate={refreshTextTemplate}
          handleSubmit={handleSubmit}
        />
      </Panel>
      {/* <DeleteDialog
        alertTitle={`Voulez-vous supprimer ce type d'acteur ?`}
        elementToDelete={` ${currentTextTemplate.libelle}`}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        performedFunction={deleteConfrereFuntion}
      /> */}
    </>
  );
};

type BoxConfrereInfoProps = {
  onClick: () => void /** Item est une valeur de test pour l'envoi des données en parametre */;
  refreshTextTemplate: () => void /** Item est une valeur de test pour l'envoi des données en parametre */;
  rows: any;
};

const BlockText: React.FC<BoxConfrereInfoProps> = ({
  onClick,
  refreshTextTemplate,
  rows,
}) => {
  const formatedDescriptionText =
    rows?.description &&
    convertFromRaw(JSON.parse(rows?.description)).getPlainText();

  return (
    <>
      <div className='BoxTextInfoContent'>
        <div className='body-part'>
          <div className='icon-part'>
            <InlineIcon className='icon' icon={"bi:body-text"} />
          </div>

          <div className='info-part'>
            <b title={rows?.name}> {rows?.name}</b>

            <span className='description'>{formatedDescriptionText}</span>
          </div>
        </div>

        <div className='footer-part'>
          <DropdownMenu
            currentTextTemplate={rows}
            refreshTextTemplate={refreshTextTemplate}
            onClick={onClick}
          />
        </div>
      </div>
    </>
  );
};

export default BlockText;
