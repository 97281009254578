import {
  getComptaByFolder,
  // getAllMouvement,
  getTypeMouvement,
} from "@/features/admin/api/comptabily.api";
import ComptableViewForm from "@/features/admin/forms/ComptableViewForm/ComptableView.form";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "./ComptableView.styles.scss";
import ComptableViewTable from "../ComptableViewTable/ComptableViewTable";
import { tryCatchWrapper } from "@/utils/tryCatchWrapper";
interface IComptableViewProps {
  currentFolder: any;
  readOnlyInput?: boolean;
  refreshFolder?: React.Dispatch<React.SetStateAction<string>>;
}
const ComptableView = ({
  currentFolder,
  readOnlyInput,
  refreshFolder,
}: IComptableViewProps) => {
  const [optionsTypeMvt, setOptionsTypeMvt] = useState<any[]>([]);
  const [accountantLine, setAccountantLine] = useState<any[]>([]);
  const accountant = currentFolder?.accountant;
  const billSale = currentFolder?.bill_sale;

  const getAllTypeMvt = async () => {
    try {
      const response = await getTypeMouvement();
      if (response.error) {
        toast.error(response.message);
        return;
      }
      const typeMvt = response?.data;
      console.log(typeMvt);
      const transformedDataForSelect = typeMvt.map((mouvementType: any) => {
        const { _id, name } = mouvementType;
        console.log(_id, name);
        return {
          value: _id,
          label: name,
        };
      });

      setOptionsTypeMvt(transformedDataForSelect);
    } catch (error) {}
  };

  useEffect(() => {
    getAllTypeMvt();
  }, []);
  return (
    <section className='comptable-view'>
      <div className='comptable-view__header'>
        <ComptableViewForm
          optionsTypeMvt={optionsTypeMvt}
          currentFolder={currentFolder}
          refreshFolder={refreshFolder}
          readOnlyInput={readOnlyInput}
        />
      </div>

      <div className='comptable-view__body'>
        <ComptableViewTable
          accountant={accountant}
          currentFolder={currentFolder}
          billSale={billSale}
          typeMvt={optionsTypeMvt}
          refreshFolder={refreshFolder}
        />
      </div>
    </section>
  );
};
export default ComptableView;
