import DeleteFolderModal from "@/components/UICs/DeleteFolderModal/DeleteFolderModal";
import { routePaths } from "@/config";
import { formatDate } from "@/utils/formatDate";
import {
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  clotureFolder,
  getFolder,
  reactivedFolder,
  suspendedFolder,
} from "../../api/folder.api";
import "./ListFolder.styles.scss";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { store } from "@/store";
import { updateCurrentFolder } from "@/store/reducers/folder/folder.action";
import {
  FolderStatut,
  msgConnexionInterrompue,
  NameFolderType,
} from "@/config/enum";
import Swal from "sweetalert2";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { Icon } from "@iconify/react";
import HistoryTreatmentFolder from "../HistoryTreatmentFolder/HistoryTreatmentFolder.element";
import { Loader } from "@/components/Loader/Loader";
import OwnerTable from "@/components/UICs/OwnerTable/OwnerTable";

interface IDropdownProps {
  currentFolder: any /** Pour recuperer l'element courant */;
  setRefreshDataFolder?: React.Dispatch<
    React.SetStateAction<string>
  > /** Pour l'actualisation des données */;
  isLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refreshFolderList: React.Dispatch<React.SetStateAction<string>>;
  isDashBoradPage?: boolean /** Pour signifier que nous sommes sur la page de dashbord */;
}

// const rowsPerPage = 5;

const DropdownMenu: FC<IDropdownProps> = ({
  currentFolder,
  setRefreshDataFolder,
  isLoading,
  refreshFolderList,
  isDashBoradPage,
}) => {
  const dispatch = store.dispatch;

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleOpenHistoryDialog = () => {
    setOpenHistoryDialog(true);
  };

  const handleCloseHistoryDialog = () => {
    setOpenHistoryDialog(false);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const gotoCreateFolder = (isConsultationDossier?: boolean) => {
    isConsultationDossier
      ? (currentFolder.isConsultationFolder = true)
      : (currentFolder.isConsultationFolder = false);

    dispatch(updateCurrentFolder(currentFolder));
    if (isDashBoradPage) {
      navigate(
        routePaths.adminChildren.homeAbsolute +
          "/create-folder/" +
          (currentFolder.folder_type === 1 ? "bien-immobilier" : "")
      );
    } else {
      navigate(
        routePaths.adminChildren.folderChildren.createFolderAbsolute +
          "/" +
          (currentFolder.folder_type === 1 ? "bien-immobilier" : "")
      );
    }

    console.log("isConsultationDossier: ", isConsultationDossier);
  };

  const confirmCloseFolder = (action: number, actionText: string) => {
    Swal.fire({
      title:
        action === 1
          ? "Clôture de dossier"
          : action === 2
          ? "Suspension de dossier"
          : "Réactivation de dossier",
      text: `Vous êtes sur le point de ${actionText} le dossier N° ${
        currentFolder?.folder_code
      }. ${
        action === 1 ? "Cette opération est irreversible !" : ""
      } \nDésirez-vous poursuivre cette action ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        if (action === 1) {
          // Cloture du dossier
          closeFolder();
        } else if (action === 2) {
          // Suspension du dossier
          suspendFolder();
        } else {
          reactiveFolder();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const closeFolder = () => {
    currentFolder.folder_reference = currentFolder?.reference;
    isLoading(true);
    clotureFolder(currentFolder)
      .then(response => {
        isLoading(false);
        if (!response.error) {
          ToastGeneric(
            "Opération effectuée avec succès !",
            "success",
            9000
          ).fire();

          // On rafraichir la liste des acteurs
          setRefreshDataFolder?.(new Date().getTime().toString());
          refreshFolderList?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        console.log(" error ", error);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  const suspendFolder = () => {
    // currentFolder.folder_reference = currentFolder?.reference;
    isLoading(true);
    suspendedFolder(currentFolder._id!)
      .then(response => {
        isLoading(false);
        // setIsLoading?.(false);
        if (!response.error) {
          ToastGeneric(
            "Opération effectuée avec succès !",
            "success",
            9000
          ).fire();

          // On rafraichir la liste des acteurs
          setRefreshDataFolder?.(new Date().getTime().toString());
          refreshFolderList?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        console.log(" error ", error);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  const reactiveFolder = () => {
    // currentFolder.folder_reference = currentFolder?.reference;
    isLoading(true);
    reactivedFolder(currentFolder._id!)
      .then(response => {
        isLoading(false);
        if (!response.error) {
          ToastGeneric(
            "Opération effectuée avec succès !",
            "success",
            9000
          ).fire();

          // On rafraichir la liste des acteurs
          setRefreshDataFolder?.(new Date().getTime().toString());
          refreshFolderList?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        console.log(" error ", error);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={e => {
            handleClose();
            gotoCreateFolder(true);
          }}
        >
          <ListItemIcon>
            {/* <PersonSearchIcon fontSize='small' /> */}
            <Icon className='dropdown-icon' icon='mdi:folder-eye-outline' />
          </ListItemIcon>
          <ListItemText>Consulter</ListItemText>
        </MenuItem>

        {currentFolder.statutId === 1 && (
          <MenuItem
            onClick={e => {
              handleClose();
              gotoCreateFolder(false);
            }}
          >
            <ListItemIcon>
              {/* <ModeIcon fontSize='small' /> */}
              <Icon className='dropdown-icon' icon='mdi:folder-edit-outline' />
            </ListItemIcon>
            <ListItemText>Modifier</ListItemText>
          </MenuItem>
        )}

        {currentFolder.statutId === 1 && (
          <MenuItem
            onClick={e => {
              handleClose();
              confirmCloseFolder(1, "clôturer");
            }}
          >
            <ListItemIcon>
              {/* <CheckIcon fontSize='small' /> */}
              <Icon className='dropdown-icon' icon='mdi:folder-check-outline' />
            </ListItemIcon>
            <ListItemText>Clôturer le dossier </ListItemText>
          </MenuItem>
        )}

        <MenuItem
          onClick={e => {
            handleOpenHistoryDialog();
            handleClose();
          }}
        >
          <ListItemIcon>
            {/* <HistoryIcon fontSize='small' /> */}
            <Icon className='dropdown-icon' icon='mdi:folder-clock-outline' />
          </ListItemIcon>
          <ListItemText>Historique traitement </ListItemText>
        </MenuItem>

        {currentFolder.statutId === 1 && (
          <MenuItem
            onClick={e => {
              handleClose();
              confirmCloseFolder(2, "suspendre");
            }}
          >
            <ListItemIcon>
              {/* <Icon icon='ic:twotone-not-interested' /> */}
              <Icon
                className='dropdown-icon'
                icon='mdi:folder-cancel-outline'
              />
            </ListItemIcon>
            <ListItemText>Suspendre</ListItemText>
          </MenuItem>
        )}

        {currentFolder.statutId === 4 && (
          <MenuItem
            onClick={e => {
              handleClose();
              confirmCloseFolder(3, "réactiver");
            }}
          >
            <ListItemIcon>
              {/* <Icon icon='ic:twotone-not-interested' /> */}
              <Icon className='dropdown-icon' icon='mdi:folder-play-outline' />
            </ListItemIcon>
            <ListItemText>Réactiver</ListItemText>
          </MenuItem>
        )}

        {currentFolder.statutId === 1 && (
          <MenuItem
            className='deleted-link'
            onClick={e => {
              handleOpenDialog();
              handleClose();
            }}
          >
            <ListItemIcon>
              {/* <DeleteIcon fontSize='small' /> */}
              <Icon
                className='dropdown-icon'
                icon='mdi:folder-remove-outline'
              />
            </ListItemIcon>
            <ListItemText>Désister</ListItemText>
          </MenuItem>
        )}
      </Menu>

      <DeleteFolderModal
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        setRefreshDataFolder={setRefreshDataFolder}
        elementToDelete={currentFolder}
      />

      <Dialog
        open={openHistoryDialog}
        onClose={handleCloseHistoryDialog}
        className='history-popup'
      >
        <DialogTitle className='dialog-title'>
          Historique de traitement
          <IconButton onClick={() => handleCloseHistoryDialog()}>
            <Icon icon='clarity:window-close-line' fontSize={36} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <HistoryTreatmentFolder idFolder={currentFolder?._id} />
        </DialogContent>
      </Dialog>
    </>
  );
};

type ListeFolderProps = {
  isDashBoradPage?: boolean;
  rowsPerPage?: number;
  refreshData?: React.Dispatch<React.SetStateAction<string>>;
};

const ListFolder: React.FC<ListeFolderProps> = ({
  isDashBoradPage,
  rowsPerPage,
  refreshData,
}) => {
  const [folderInfos, setFolderInfos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshFolderList, setRefreshFolderList] = useState("");

  const columns: GridColDef[] = [
    { field: "id", headerName: "#", width: 90, sortable: true },
    {
      field: "folder_code",
      headerName: "N° Dossier",
      flex: 1,
      sortable: true,
    },
    {
      field: "label",
      headerName: "Libellé du projet",
      flex: 1,
      sortable: true,
    },

    {
      field: "seller",
      headerName: "Vendeur",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <p style={{ margin: 0 }}>{params.row.seller || ""}</p>
      ),
      sortable: true,
    },
    {
      field: "buyer",
      headerName: "Acquéreur",
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => (
        <p style={{ whiteSpace: "normal", wordWrap: "break-word", margin: 0 }}>
          {params.row.buyer || ""}
        </p>
      ),
    },
    {
      field: "created_at",
      headerName: "Date de création",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_by",
      headerName: "Enregistré par",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={`${params.row.status || ""}`}
          className={`chip-item ${
            params.row.statutId === 1
              ? "encours"
              : params.row.statutId === 2
              ? "cloture"
              : params.row.statutId === 3
              ? "annule"
              : params.row.statutId === 4
              ? "suspendu"
              : "brouillon"
          }`}
        />
      ),
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params: GridRenderCellParams) => (
        <DropdownMenu
          isLoading={setIsLoading}
          currentFolder={params.row}
          setRefreshDataFolder={refreshData}
          refreshFolderList={setRefreshFolderList}
          isDashBoradPage={isDashBoradPage}
        />
      ),
    },
  ];

  const getCurrentFolderType = (folder: any) => {
    let folder_type = 0;

    if (folder && folder.folder_type_id) {
      switch (folder?.folder_type_id?.name?.toLowerCase()) {
        case NameFolderType.bienImmobilier.toLowerCase():
          folder_type = 1;
          break;

        case NameFolderType.famille.toLowerCase():
          folder_type = 2;
          break;

        case NameFolderType.affaire.toLowerCase():
          folder_type = 3;
          break;

        default:
          break;
      }
    }

    return folder_type;
  };

  const createStringForSellerBuyerName = (data: any) => {
    const newDataString =
      data &&
      data.map((dataInfo: any) => {
        let stringToReturn = "";
        if (dataInfo.personal_infos) {
          const { company_infos } = dataInfo;

          const {
            personal_infos: { last_name, first_name },
          } = dataInfo;

          if (company_infos) {
            const { denomination } = company_infos;
            return denomination.toUpperCase();
          }
          stringToReturn = `${last_name} ${first_name}`.toUpperCase();
        }
        return stringToReturn;
      });

    let shortDataToReturn = [];
    let finalStringToReturn = "";
    let seeMoreString = "";
    for (const [index, value] of newDataString.entries()) {
      if (newDataString.length > 2) {
        shortDataToReturn.push(value);
        if (index === 1) {
          seeMoreString = `... ${newDataString.length - 2} de plus`;
          break;
        }
      } else {
        shortDataToReturn.push(value);
      }
    }

    finalStringToReturn = shortDataToReturn.join(",");

    return {
      finalStringToReturn,
      seeMoreString,
      completeData: newDataString.join(",\n"),
    };
  };

  const retrieveFolder = async () => {
    // let { data: folderData } = await getFolder();
    setIsLoading(true);
    getFolder()
      .then(response => {
        setIsLoading(false);
        if (!response.error) {
          if (response.data && response.data?.length > 0) {
            const folderInfoResume = response.data.map(
              (folderInfos: any, index: number) => {
                const {
                  _id,
                  folder_name,
                  created_date,
                  status,
                  seller_ids,
                  buyers_ids,
                  biens,
                  comptas,
                } = folderInfos;
                const sellerInfo = createStringForSellerBuyerName(seller_ids);
                const buyersInfo = createStringForSellerBuyerName(buyers_ids);
                const FormatedDate = formatDate(created_date);

                // const listSellerName = sellerInfo.map((seller: any) => {});
                return {
                  id: index + 1,
                  folder_id: _id,
                  _id: _id,
                  reference: folderInfos.reference,
                  folder_code: folderInfos.folder_code,
                  label: folder_name,
                  statutId: status,
                  status:
                    status === FolderStatut?.EnCours
                      ? "En cours"
                      : status === FolderStatut?.Cloture
                      ? "Cloturé"
                      : status === FolderStatut?.Cancel
                      ? "Désisté"
                      : status === FolderStatut?.Suspendu
                      ? "Suspendu"
                      : "Brouillon",
                  seller: sellerInfo.finalStringToReturn,
                  buyer: buyersInfo.finalStringToReturn,
                  comptas,
                  biens,
                  created_at: FormatedDate,
                  created_by: folderInfos?.updated_by
                    ? folderInfos.updated_by
                    : folderInfos?.created_by,
                  completeSellerName: sellerInfo.completeData,
                  buyerComplete: buyersInfo.completeData,
                  folder_type_libelle: folderInfos.folder_type_id?.name,
                  folder_type: getCurrentFolderType(folderInfos),
                };
              }
            );

            console.log("folderInfoResume", folderInfoResume);
            setFolderInfos(folderInfoResume);
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({
            text: msgConnexionInterrompue.connexionInterrompue,
            timer: 9000,
          });
        }
      });
  };

  useEffect(() => {
    retrieveFolder();
    //eslint-disable-next-line
  }, [refreshFolderList]);

  console.log("refreshFolderList: ", refreshFolderList);

  return (
    <>
      <Loader isLoading={isLoading} />
      <OwnerTable rows={folderInfos} columns={columns} searchFilter={true} />
    </>
  );
};

export default ListFolder;
