import React from 'react'
import CtHome from '../pages-content/Home-content/Home.content'

const Home:React.FC = () => {
  return (
    <>
      <CtHome />
    </>
  )
}

export default Home