import React from "react";
import "./ChartEvolution.style.scss";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const ChartEvolution: React.FC = () => {
  const barColumnWidth = 22;

  const perShapeGradient = {
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 1,
  };

  const optionHighcharts = {
    chart: {
      type: "column",
      backgroundColor: null,
      heigth: "100%",
    },

    colors: [
      {
        linearGradient: perShapeGradient,
        stops: [
          [0, "#006D77"],
          // [0.5, '##487F98'],
          [4, "#e9ecdc"],
        ],
      },
    ],

    title: {
      text: "",
      align: "left",
    },
    xAxis: {
      gridLineWidth: 0,
      categories: [
        "09/09/2022",
        "10/09/2022",
        "11/09/2022",
        "12/09/2022",
        "13/09/2022",
        "14/09/2022",
        "15/09/2022",
        "16/09/2022",
        "17/09/2022",
        "18/09/2022",
        "19/09/2022",
        "20/09/2022",
        "21/09/2022",
        "22/09/2022",
        "23/09/2022",
        "24/09/2022",
      ],
    },
    yAxis: {
      min: 0,
      gridLineWidth: 0,
      title: {
        text: "Nombre",
      },
      stackLabels: {
        enabled: true,
        // style: {
        //     fontWeight: 'bold',
        //     color: ( // theme
        //         Highcharts.defaultOptions.title.style &&
        //         Highcharts.defaultOptions.title.style.color
        //     ) || 'gray',
        //     textOutline: 'none'
        // }
      },
    },
    legend: {
      enabled: false,
      // align: 'left',
      // x: 70,
      // verticalAlign: 'top',
      // y: 70,
      // // floating: true,
      // // backgroundColor:
      // //     Highcharts.defaultOptions.legend.backgroundColor || 'white',
      // borderColor: '#CCC',
      // // borderWidth: 1,
      // shadow: false
    },
    tooltip: {
      headerFormat: "<b>{point.x}</b><br/>",
      pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
    },
    plotOptions: {
      column: {
        stacking: "normal",
        dataLabels: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: "Dossiers ouverts",
        pointWidth: barColumnWidth,
        // borderRadius: 5,
        // borderColor:'#006D77',
        // color: '#006D77',
        data: [3, 5, 1, 13, 10, 13, 1, 13, 1, 23, 13, 1, 13, 1, 23],
      },
    ],
  };

  return (
    <div className='chart-evolution-container'>
      <div className='header-chart-evolution'>
        <b> Dossiers ouverts par période </b>
      </div>

      <div className='filter-chart-evolution'></div>

      <div className='body-chart-evolution'>
        <HighchartsReact highcharts={Highcharts} options={optionHighcharts} />
      </div>
    </div>
  );
};

export default ChartEvolution;
