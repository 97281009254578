import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Grow from '@mui/material/Grow';
import { Button, IconButton } from '@mui/material';
import { HighlightOffRounded } from '@mui/icons-material';
import './Modal.styles.scss';



type TModalProps = {
    open: boolean,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined , 
    closeClick: () => void,
}

const CModal:React.FC<TModalProps> = ({
    open,
    onClose,
    closeClick,
}) => {
  return (
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grow in={open}>
          <div className='c-modal '>
            <IconButton className='close-icon'
                        size="large" 
                        color='inherit'
                        onClick={closeClick}>
                <HighlightOffRounded />
            </IconButton>
            <img
                src={require("@/assets/images/png/avatar.png")}
                alt="sidebar-logo"
                className="modal-avatar"
            />
            <p>
                <b> BESOIN D'AIDE ? </b>
            </p>

            <Button
                className='btn-modal'
                variant="contained"
                color="tertiary"
                // endIcon={
                //     <InlineIcon icon="logos:whatsapp-icon" /> 
                // }
            >
              VALIDER
            </Button>

          </div>
        </Grow>
      </Modal>
  )
}

export default CModal;