import { FC, ReactNode, createContext, useState, useContext } from 'react';

interface IPersonSelectedId {
  personSelectedId: {
    moral: string;
    physique: string;
  };
}
interface ISelectedPersonContext extends IPersonSelectedId {
  setPersonSelectedId: React.Dispatch<React.SetStateAction<object>>;
}

interface IPersonSelectedContextProvider {
  children: ReactNode;
}

export const PersonSelectedContext =
  createContext<ISelectedPersonContext | null>(null);
const { Provider } = PersonSelectedContext;

export const PersonSelectedContextProvider: FC<
  IPersonSelectedContextProvider
> = ({ children }) => {
  const [personSelectedId, setPersonSelectedId] = useState<any>({
    moral: '',
    physique: '',
  });
  return (
    <Provider value={{ personSelectedId, setPersonSelectedId }}>
      {children}
    </Provider>
  );
};

export const usePersonSelectedIdContext = () => {
  const context = useContext(PersonSelectedContext);

  if (!context)
    throw new Error(
      'Ce context doit être utiliser dans un composant enveloppé dans sont provider'
    );

  return context;
};
