import { store } from '@/store';
import Axios from 'axios';
import { API_URL, API_URL2 } from '../config';

export const axios = Axios.create({
  baseURL: API_URL,
});

axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const message = error.response?.data?.message || error.message;

    console.log(message);

    return Promise.reject(error);
  }
);

export const axios2 = Axios.create({
  baseURL: API_URL2,
});

axios2.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const message = error.response?.data?.message || error.message;
    return Promise.reject(error);
  }
);

axios2.interceptors.request.use(req => {
  const userId = store.getState().user.userConnected._id;

  // if (req.data && !req.url?.includes('/persons/update/')) {
  //   /** En modification la donnée est envoyé en formData */
  //   req.data = {
  //     ...req.data,
  //     user_id: userId,
  //   };
  // }
  return req;
});

// export const axios_base_url_seller_buyer = Axios.create({
//   baseURL: API_URL2,
// });

// axios_base_url_seller_buyer.interceptors.response.use(
//   (response) => {
//     return response.data;
//   },
//   (error) => {
//     const message = error.response?.data?.message || error.message;

//     console.log(message);

//     return Promise.reject(error);
//   }
// );
