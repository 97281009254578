import { Button } from '@mui/material';
import React from 'react';
import { FormikHelpers, useFormik } from 'formik';
import './Prospect.styles.scss';
import WithFormikErrorMessage from '@/components/HOCs/WithFormikErrorMessage.hoc';
import AlertMessage from '@/components/UICs/AlertMessage/AlertMessage.uic';
import { TProspect } from '../../types/types';
import * as Yup from 'yup';

export type TProspectFormsValues = {
  nom: string;
  prenoms: string;
  email: string;
  telephone: string;
  address: string;
  objet_visite: string;
  commentaire: string;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TProspectFormsValues>(AlertMessage);

type ProspectFormProps = {
  closeSidePanel: () => void;
  refreshProspect: () => void;
  handleSubmit: (
    values: TProspectFormsValues,
    FormikHelpers: FormikHelpers<TProspectFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  currentProspect?: TProspect;
};

const ProspectForm: React.FC<ProspectFormProps> = ({
  handleSubmit,
  currentProspect,
}) => {
  const validationSchema: any = Yup.object({
    nom: Yup.string().required('Le nom est requis'),
    prenoms: Yup.string().required('Les prénoms sont requis'),
    email: Yup.string().email().required('Le email est requis'),
    telephone: Yup.string().required('Le téléphone est requis'),
    address: Yup.string().required("L'addresse est requise"),
    objet_visite: Yup.string().required("L'objet de visite est requis"),
    // commentaire: Yup.string().required("Le commentaire est requis"),
  });

  const formik = useFormik({
    initialValues: {
      nom: currentProspect?.nom || '',
      prenoms: currentProspect?.prenoms || '',
      email: currentProspect?.email || '',
      telephone: currentProspect?.telephone || '',
      address: currentProspect?.address || '',
      objet_visite: currentProspect?.objet_visite || '',
      commentaire: currentProspect?.commentaire || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className="form-prospect" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              Nom <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="nom"
              autoComplete="off"
              placeholder="Nom"
              onChange={formik.handleChange}
              value={formik.values.nom}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="nom"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              Prénoms <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="prenoms"
              placeholder="Prénoms"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.prenoms}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="prenoms"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              Adresse email <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="email"
              placeholder="Adresse email"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.email}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="email"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              Numéro de télephone <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="telephone"
              placeholder="Numéro de télephone"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.telephone}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="telephone"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              Adresse géographique <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="address"
              placeholder="Adresse géographique"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.address}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="address"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              Objet de la visite <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="objet_visite"
              placeholder="Objet de la visite"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.objet_visite}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="libelle"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form"> Commentaire </label>
            <textarea
              rows={3}
              className="form-control bs-input-custom"
              name="commentaire"
              placeholder="Commentaire"
              onChange={formik.handleChange}
              value={formik.values.commentaire}
            ></textarea>
            {/* <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="libelle"
              simple={true}
              mt={5}
              mb={0}
            /> */}
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            <Button
              type="reset"
              color="secondary"
              variant="outlined"
              disableElevation
            >
              Annuler
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProspectForm;
