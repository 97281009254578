import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";

export const getFile = (idFile: string): Promise<IApiResponse> => {
  return axios2.get(`files/${idFile}/view`);
};

export const getFilesListe = (): Promise<IApiResponse> => {
  return axios2.get("files");
};
