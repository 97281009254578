import * as React from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { store } from "../store";
import { theme } from "../theme";
import { SuspenseFallback } from "@/components/SuspenseFallback/SuspenseFallback";
import { muiCustomTheme } from "@/theme/muiCustomTheme";


type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  return (
    <React.Suspense
      fallback={
        <SuspenseFallback />
      }
    >
        <HelmetProvider>
            <ThemeProvider theme={theme}>
              <MuiThemeProvider theme={muiCustomTheme} >
                  <Provider store={store}>
                      <Router>{children}</Router>
                  </Provider>
                </MuiThemeProvider>
            </ThemeProvider>
        </HelmetProvider>
    </React.Suspense>
  );
};
