import CChooseParametersType from "../../containers/Parameters/ChooseParametersType/ChooseParametersType.container";

import FSHeader from "../../Elements/FSHeader/FSHeader.element";

const MainParametersPage = () => {
  return (
    <div className="fs-container">
      <FSHeader title="Administration" icon="fa-solid:users-cog" breadscrumbTitle="Administration"/>

      <CChooseParametersType />
    </div>
  );
};

export default MainParametersPage;
