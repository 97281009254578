// import { InlineIcon } from '@iconify/react';
import React from "react";

// import logoChart from "@/assets/images/svg/iconChart.svg";
// import logoChartInactif from "@/assets/images/svg/iconChartInactif.svg";

import "./BlockStatByFolder.scss";
import ChartCircle from "@/components/ChartCircleFS/ChartCircleFS";

type TStatFolderByTypeProps = {
  iconStatut?: string;
  numberItem?: number;
  statut?: string;
  title?: string;
  imgIcon?: string;
  itemCloture?: number;
  itemAnnule?: number;
  itemEnCours?: number;
  itemSuspendu?: number;
  enabledBlock?: boolean;
};

const BlockStatByFolder: React.FC<TStatFolderByTypeProps> = ({
  iconStatut,
  numberItem,
  statut,
  title,
  imgIcon,
  itemCloture,
  itemAnnule,
  itemEnCours,
  itemSuspendu,
  enabledBlock,
}) => {
  return (
    <div
      className={`content-block-stat-folder ${!enabledBlock ? "inactif" : ""}`}
    >
      <div className='block-stat-by-folder-header'>
        <span> {title} </span>
        <img src={imgIcon} title='Image' alt='logo-icon' />
      </div>

      <div className='block-stat-folder-body'>
        <b> {numberItem} </b>
      </div>

      <div className='block-stat-folder-footer'>
        <div className='stat'>
          <div className='stat-header'>
            <span>Cloturé</span>
            <b>{itemCloture || 0}</b>
          </div>
          <ChartCircle
            value={Math.round(((itemCloture || 0) / (numberItem || 1)) * 100)}
            border={"4px"}
            color='#00ae55'
            width={"40px"}
          />
        </div>

        <div className='stat'>
          <div className='stat-header'>
            <span>En cours</span>
            <b>{itemEnCours || 0}</b>
          </div>
          <ChartCircle
            value={Math.round(((itemEnCours || 0) / (numberItem || 1)) * 100)}
            border={"4px"}
            color='#F5B544'
            width={"40px"}
          />
        </div>

        <div className='stat'>
          <div className='stat-header'>
            <span>Suspendu</span>
            <b>{itemSuspendu || 0}</b>
          </div>
          <ChartCircle
            value={Math.round(((itemSuspendu || 0) / (numberItem || 1)) * 100)}
            border={"4px"}
            color='#9b5de6'
            width={"40px"}
          />
        </div>

        <div className='stat'>
          <div className='stat-header'>
            <span>Désisté</span>
            <b>{itemAnnule || 0}</b>
          </div>
          <ChartCircle
            value={Math.round(((itemAnnule || 0) / (numberItem || 1)) * 100)}
            border={"4px"}
            color='#e63838'
            width={"40px"}
          />
        </div>
      </div>
    </div>
  );
};

export default BlockStatByFolder;
