import React from 'react';
import CFiles from '../containers/Files/Files.container';
import FSHeader from '../Elements/FSHeader/FSHeader.element';


const Files: React.FC = () => {
  return (
    <div className='fs-container'>
      <FSHeader title="Gestion des Documents" icon="ph:files-fill" breadscrumbTitle="Documents"/>
      <CFiles/>
    </div>
  )
}

export default Files;
