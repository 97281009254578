import { useNavigate } from "react-router-dom";

import { routePaths } from "@/config";

// import note from "@/assets/images/svg/note.svg";
import team from "@/assets/images/svg/team.svg";

import "./ChooseParametersType.styles.scss";
import { Icon } from "@iconify/react";

const CChooseParametersType = () => {
  const navigate = useNavigate();

  const gotoPage = (route: string) => {
    navigate(route, { replace: true });
  };
  return (
    <div className="c-choose-parameters-type">
      <div className="administration-content">
        <div className="administration-header">
          <span> Vous désirez administrer la plateforme ? </span>
          <span>
            {" "}
            Modifier les textes ou octroyer les droits d'administration ?{" "}
          </span>
          <p>Faites votre choix dans l’ensemble des types suivants</p>
        </div>

        <div className="administration-body">
          <div
            className="menu-item"
            onClick={(e) =>
              gotoPage(
                routePaths.adminChildren.administrationChildren
                  .parametresBaseAbsolute
              )
            }
          >
            <span>Paramétrages</span>
            {/* <img src={note} alt="" /> */}
            <Icon className="cogs-icon" icon="el:cogs" />
          </div>
          <div
            className="menu-item"
            onClick={(e) =>
              gotoPage(
                routePaths.adminChildren.administrationChildren.userAbsolute
              )
            }
          >
            <span>Gestion des utilisateurs</span>
            <img src={team} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CChooseParametersType;
