import React, { FC, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, frFR, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  // Pagination,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import Panel from "@/components/UICs/Panel/Panel.uic";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import "./TypeActor.style.scss";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { TTypeActor } from "@/features/admin/types";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";
import { formatDate } from "@/utils/formatDate";
import { createTypeActor, deleteTypeActor, getAllTypeActor, updateTypeActor } from "@/features/admin/api/typeActor.api";
import TypeActorForm, { TTypeActorFormsValues } from "@/features/admin/forms/TypeActor/TypeActor.form";
import Swal from "sweetalert2";

const rowsPerPage = 7;

type DropdownMenuProps = {
  ruccentTypeActor: any;
  refreshTypeActor: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({ ruccentTypeActor, refreshTypeActor }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**Confirmation Suppression d'un Type d'acteur  */
  const confirmDeleteTypeActor = () => {
    handleClose();
    Swal.fire({
      title: " Suppression",
      text:
        "Vous êtes sur le point de supprimer un type d'acteur !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        deleteTypeActorFuntion();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteTypeActorFuntion= async () => {
    const response = await deleteTypeActor(ruccentTypeActor.id);
    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `Type d'acteur "${ruccentTypeActor.libelle}" a été supprimé avec succès`,
        'success',
        3000
      ).fire();
      refreshTypeActor();
    }
  };

  const updateItem = async (
    values: TTypeActorFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log({ ruccentTypeActor });
    console.log("ruccentTypeActor ", ruccentTypeActor);
    const toSend = {
      libelle: values.libelle,
    };
    const response = await updateTypeActor(toSend, ruccentTypeActor.id);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Type d'acteur modifié avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshTypeActor();
      // console.table(response);
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TTypeActorFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TTypeActorFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonSearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Consulter</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem onClick={confirmDeleteTypeActor}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Mise à jour du type d'acteur"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <TypeActorForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          ruccentTypeActor={ruccentTypeActor}
          isForUpdate={true}
          refreshTypeActor={refreshTypeActor}
          handleSubmit={handleSubmit}
        />
      </Panel>
      {/* <DeleteDialog
        alertTitle={`Voulez-vous supprimer ce type d'acteur ?`}
        elementToDelete={` ${ruccentTypeActor.libelle}`}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        performedFunction={deleteTypeActorFuntion}
      /> */}
    </>
  );
};

const TypeActor = () => {
  const columns: GridColDef[] = [
    {
      field: "libelle",
      headerName: "Libelle",
      flex: 1,
      sortable: true,
    },
    { 
      field: "created_date",
      headerName: "Date de création",
      flex: 1,
      sortable: true
    },
    {
      field: "is_actif",
      headerName: "Statut",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return <DropdownMenu ruccentTypeActor={row} refreshTypeActor={refreshTypeActor} />;
      }, //TODO : check params
    },
  ];

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  // const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [typeActorData, setTypeActorData] = useState<TTypeActor[]>([]);
  const [loadUser, setLoadUser] = useState(true);

  // const pageChange = (event: any, value: any) => {
  //   setPage(value);
  // };

  const getTypeActor = async () => {
    const { data } = await getAllTypeActor();
    const dataWithNewObjectKeys: TTypeActor[] = data.map((typeActor: any) => {
      return {
        id: typeActor._id,
        libelle: upperFirstWordLetter(typeActor.libelle),
        created_date: formatDate(typeActor?.created_date),
        is_actif: typeActor.is_actif === 1 ? "Actif" : "Inactif",
      };
    });
    setTypeActorData(dataWithNewObjectKeys);
  };

  const refreshTypeActor = async () => {
    setLoadUser(true);
    await getTypeActor();
    setLoadUser(false);
  };

  useEffect(() => {
    getTypeActor();
  }, [loadUser]);

  const saveItem = async (
    typeActor: TTypeActorFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const response = await createTypeActor(typeActor);

    if (response.error) {
        ToastError.fire({ text: response.message, timer: 9000 });
      } else {
        ToastGeneric("Type d'acteur créée avec succès", "success", 3000).fire();
        setOpenPanel(false);
        refreshTypeActor();
      }
      setSubmitting(false);
  };
  const handleSubmit = (
    values: TTypeActorFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TTypeActorFormsValues>
  ) => {
    // alert(JSON.stringify(values, null, 2));
    console.log(" values ", values);
    saveItem(values, setSubmitting);
  };

  return (
    <>
      <div className="c-list-users">
        <div className="utilisateur-content">
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div className="utilisateur-table-part">
                  <div
                    className="utilisateur-table-header"
                    style={{ height: "55px" }}
                  >
                    <Button
                      color="primary"
                      onClick={(e) => setOpenPanel(true)}
                      variant="contained"
                      title="Créer un type d'acteur"
                      disableElevation
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Créer un type d'acteur
                    </Button>
                  </div>
                  <div className="utilisateur-table-body">
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        rows={typeActorData}
                        columns={columns}
                        // hideFooterPagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        disableColumnMenu
                        pageSize={pageSize}
                        rowsPerPageOptions={[7, 15, 30]}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        sx={{
                          fontFamily: "'Raleway',sans-serif !important",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#ececec",
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-columnHeaderTitle": {
                            color: "var(--ui-primary)",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
                            {
                              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
                            },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            minHeight: "5px",
                          },
                          "& .MuiTablePagination-toolbar": {
                            "& > p": {
                              marginBottom: 0,
                            },
                          },
                        }}
                      />
                      {/* <div className="pagination">
                        <Pagination
                          count={Math.ceil(10 / rowsPerPage)}
                          page={page}
                          onChange={pageChange}
                          showFirstButton
                          showLastButton
                          variant="outlined"
                          shape="rounded"
                        />
                      </div> */}
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création de Type d'acteur"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <TypeActorForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshTypeActor={refreshTypeActor}
          handleSubmit={handleSubmit}
        />
      </Panel>
    </>
  );
};

export default TypeActor;
