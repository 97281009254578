import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import "./fileViewer.style.scss";
import classNames from "classnames";
import PdfViewer from "@/components/PdfViewer/PadfViewer";

type TFileViewerProps = {
  setFileViewer: React.Dispatch<React.SetStateAction<boolean>>;
  files: any;
};

interface IZoomFunction {
  type: string;
}

interface IRotateFunction {
  direction: string;
}

const FileViewer: React.FC<TFileViewerProps> = ({ setFileViewer, files }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [imageSelected, setImageSelected] = useState(files[0]?.path);

  //ZOOM LEVEL CONSTANT
  let DefaultZoomLevel: number = 1;
  const ZOOMLEVEL: number = 0.1;
  const MAX_ZOOM: number = 2.0;
  const MIN_ZOOM: number = 0.8;

  const fullScreenFunction = () => {
    setIsFullScreen(!isFullScreen);
  };

  const zoomFunction = ({ type }: IZoomFunction) => {
    const imageView = document.querySelector(
      ".document-viewer-square img"
    ) as HTMLDivElement;

    if (type === "zoom-in") {
      if (!(+DefaultZoomLevel.toFixed(1) !== MAX_ZOOM)) return;
      DefaultZoomLevel += ZOOMLEVEL;
      // console.log();
      imageView.style.transform = `scale(${DefaultZoomLevel.toFixed(1)})`;

      console.log("enter into image zoom in");
    }
    if (type === "zoom-out") {
      if (!(+DefaultZoomLevel.toFixed(1) !== MIN_ZOOM)) return;
      DefaultZoomLevel -= ZOOMLEVEL;
      // console.log();
      imageView.style.transform = `scale(${DefaultZoomLevel.toFixed(1)})`;
      console.log("enter zoom out ");
    }
    console.log({ imageView, type });
  };

  let INITIAL_ROTATION = 360;

  const rotateFuntion = ({ direction }: IRotateFunction) => {
    INITIAL_ROTATION = INITIAL_ROTATION === 90 ? 360 : INITIAL_ROTATION - 90;
    console.log(INITIAL_ROTATION);
    const imageView = document.querySelector(
      ".document-viewer-square img"
    ) as HTMLDivElement;

    imageView.style.rotate = `${INITIAL_ROTATION}deg`;
  };

  console.log("files viewer: ", files);
  return (
    <>
      <div
        className={`document-viewer-container " ${
          isFullScreen ? "full-screen" : ""
        }`}
      >
        <div className='document-viewer-header'>
          <span>Document joint (Demande n° 202302071216)</span>
          <button
            type='button'
            title='Fermer'
            onClick={e => setFileViewer(false)}
          >
            <Icon icon='humbleicons:times' />
          </button>
        </div>
        <div className='document-viewer-body'>
          <div className='document-viewer-sidebar'>
            {files &&
              files.map(({ name, path, id }: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={classNames("document-viewer-sidebar-item", {
                      active: path === imageSelected,
                    })}
                    onClick={() => setImageSelected(path)}
                  >
                    <img src={path} alt={name} />
                    <div className='overlay'></div>
                  </div>
                );
              })}
          </div>
          <div className='document-viewer-content'>
            <div className='document-viewer-tools'>
              <button onClick={() => zoomFunction({ type: "zoom-in" })}>
                <Icon icon='iconoir:zoom-in' />
              </button>
              <button onClick={() => zoomFunction({ type: "zoom-out" })}>
                <Icon icon='iconoir:zoom-out' />
              </button>
              <button onClick={() => rotateFuntion({ direction: "reverse" })}>
                <Icon icon='ic:round-rotate-90-degrees-ccw' />
              </button>
              <button>
                <Icon icon='material-symbols:cloud-download-outline-rounded' />
              </button>
              <button>
                <Icon icon='fluent:globe-32-regular' />
              </button>
              {/* <button className="full-screen"></button> */}
              <button onClick={fullScreenFunction} className='full-screen-btn'>
                {!isFullScreen && (
                  <Icon icon='fluent:full-screen-maximize-20-filled' />
                )}
                {isFullScreen && (
                  <Icon icon='fluent:full-screen-minimize-20-filled' />
                )}
              </button>
            </div>
            <div className='document-viewer-square'>
              <img src={imageSelected} alt='' />
              <div className='next-btns'>
                <button>
                  <Icon icon='ic:round-navigate-before' />
                </button>
                <button>
                  <Icon icon='ic:round-navigate-next' />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileViewer;
