import { Loader } from "@/components/Loader/Loader";
import { formatDate } from "@/utils/formatDate";
import { ToastError } from "@/utils/toast";
import { Box } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { getHistoryTraitementFolder } from "../../api/folder.api";
import "./HistoryTreatmentFolder.styles.scss";

type HistoryTraitementProps = {
  idFolder: string;
};
const HistoryTreatmentFolder: React.FC<HistoryTraitementProps> = ({
  idFolder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listHistoryFolder, setListHistoryFolder] = useState<any[]>([]);

  const columns: GridColDef[] = [
    {
      field: "created_date",
      headerName: "Date opération",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_by",
      headerName: "Effectuée par",
      flex: 1,
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      sortable: true,
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    getHistoryTraitementFolder(idFolder)
      .then(response => {
        console.log(response);
        setIsLoading(false);
        if (!response.error) {
          let rowHistory: any = [];

          if (response?.data) {
            response?.data.map((rows: any, index: number) => {
              rowHistory.push({
                id: index + 1,
                description: rows.description,
                _id: rows?._id,
                created_by: rows?.created_by,
                created_date: formatDate(rows?.created_date),
              });
            });

            setListHistoryFolder(rowHistory);
          }
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        setIsLoading(false);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  }, []);

  return (
    <div>
      <div className='history-treatment-container'>
        <Loader isLoading={isLoading} />
        <Box sx={{ width: "100%", height: "500px", padding: "0px 13px" }}>
          <DataGrid
            rows={listHistoryFolder || []}
            columns={columns}
            hideFooterPagination={true}
            checkboxSelection={false}
            disableSelectionOnClick={true}
            disableColumnMenu
            sx={{
              fontFamily: "'Raleway',sans-serif !important",
              border: "none",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#ececec",
                borderBottom: "none",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: "var(--ui-primary)",
                fontWeight: "bold",
              },
              "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
                {
                  backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
                },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                minHeight: "5px",
              },
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default HistoryTreatmentFolder;
