/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";

import BlockStatByFolder from "@/features/admin/Elements/BlockStatByFolder/BlockStatByFolder.element";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import iconImmobilier from "@/assets/images/svg/iconImmobilier.svg";
import iconFamille from "@/assets/images/svg/iconFamille.svg";
import iconAffaires from "@/assets/images/svg/iconAffaires.svg";

import { routePaths } from "@/config";

import "./FolderInfos.styles.scss";
import ListFolder from "@/features/admin/Elements/ListFolder/ListFolder.element";
import { useEffect, useState } from "react";
import { getFolderStat } from "@/features/admin/api/folder.api";
import { ToastError } from "@/utils/toast";
import { msgConnexionInterrompue } from "@/config/enum";
import { Loader } from "@/components/Loader/Loader";

const CFolderInfos = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [folderstats, setFolderStats] = useState<any>();
  const [refreshData, setRefreshData] = useState("");

  const gotoCreateFolder = () => {
    navigate(routePaths.adminChildren.folderChildren.createFolderAbsolute, {
      replace: true,
    });
  };

  const getFolderStats = async () => {
    setIsLoading(true);
    await getFolderStat()
      .then((response: any) => {
        let data: any = response.data;
        setIsLoading(false);
        setFolderStats({
          en_cours: data?.dossier?.en_cours,
          cloture: data?.dossier?.cloture,
          annule: data?.dossier?.annule,
          suspendu: data?.dossier?.suspendu,
          total: data?.dossier?.total,
          percent_en_cours: (data.dossier.en_cours / data.dossier.tatal) * 100,
          percent_cloture: (data.dossier.cloture / data.dossier.tatal) * 100,
        });
      })
      .catch(error => {
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({
            text: msgConnexionInterrompue.connexionInterrompue,
            timer: 9000,
          });
        }
      });
  };

  useEffect(() => {
    getFolderStats();
    //eslint-disable-next-line
  }, [refreshData]);

  return (
    <>
      <div className='dossier-content'>
        <Box>
          {/* <div className='block-stat-part'> */}
          <Grid container rowSpacing={0} columnSpacing={2}>
            {/* <Loader isLoading={isLoading} /> */}
            <Grid item xs={12} md={4} lg={4} sx={{ mt: 2, mb: 2 }}>
              <Loader isLoading={isLoading} />
              <BlockStatByFolder
                title={"Dossiers immobiliers"}
                imgIcon={iconImmobilier}
                numberItem={folderstats?.total ? folderstats?.total : 0}
                itemEnCours={folderstats?.en_cours ? folderstats?.en_cours : 0}
                itemAnnule={folderstats?.annule ? folderstats?.annule : 0}
                itemCloture={folderstats?.cloture ? folderstats?.cloture : 0}
                itemSuspendu={folderstats?.suspendu ? folderstats?.suspendu : 0}
                enabledBlock={true}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4} sx={{ mt: 2, mb: 2 }}>
              <BlockStatByFolder
                title={"Dossiers Familles"}
                imgIcon={iconFamille}
                numberItem={0}
              />
            </Grid>

            <Grid item xs={12} md={4} lg={4} sx={{ mt: 2, mb: 2 }}>
              <BlockStatByFolder
                title={"Dossiers Affaires"}
                imgIcon={iconAffaires}
                numberItem={0}
              />
            </Grid>
          </Grid>
          {/* </div> */}

          <Grid container rowSpacing={4} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <div className='dossier-table-part'>
                <div
                  className='dossier-table-header'
                  style={{ height: "55px" }}
                >
                  <Button
                    color='primary'
                    variant='contained'
                    title='Créer un dossier'
                    disableElevation
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={gotoCreateFolder}
                  >
                    Créer un dossier
                  </Button>
                </div>
                <div className='dossier-table-body'>
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <ListFolder refreshData={setRefreshData} />
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default CFolderInfos;
