import { Route, Routes } from "react-router-dom";

import { routePaths } from "@/config";

import LSubscription from "../layouts/Subscription/Subscription.layout";

import DeleteAccountPage from "../pages/Subscription/DeleteAccount.page";
import DesactivateSubscriptionPage from "../pages/Subscription/DesactivateSubscription.page";

export const SubscriptionRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<LSubscription />}>
        <Route
          path={routePaths.adminChildren.subscriptionChildren.deleteAccount}
          element={<DeleteAccountPage />}
        />
        <Route
          path={
            routePaths.adminChildren.subscriptionChildren
              .desactivateSubscription
          }
          element={<DesactivateSubscriptionPage />}
        />
      </Route>
    </Routes>
  );
};
