import React from "react";
import { Button } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";

import { TNationality } from "../../types";

import "./Nationality.form.style.scss";

export type TNationalityFormsValues = {
  libelle: string;
  nationalite: string;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TNationalityFormsValues>(AlertMessage);

type NationalityFormProps = {
  closeSidePanel: () => void;
  refreshNationality: () => void;
  handleSubmit: (
    values: TNationalityFormsValues,
    FormikHelpers: FormikHelpers<TNationalityFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  ruccentNationality?: TNationality;
};

const NationalityForm = ({
  closeSidePanel,
  refreshNationality,
  handleSubmit,
  ruccentNationality,
}: NationalityFormProps): JSX.Element => {
  const validationSchema: any = Yup.object({
    libelle: Yup.string().required("Le libellé est requis"),
    nationalite: Yup.string().required("La nationalité est requise"),
  });

  const formik = useFormik({
    initialValues: {
      libelle: ruccentNationality?.libelle || "",
      nationalite: ruccentNationality?.nationalite || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className="form-utilisateur" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Nom du pays <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="libelle"
              autoComplete="off"
              placeholder="Libellé de la nationalité"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.libelle}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="libelle"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Nationalité <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="nationalite"
              autoComplete="off"
              placeholder="Libellé de la nationalité"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nationalite}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="nationalite"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            {!ruccentNationality && (
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                disableElevation
              >
                Annuler
              </Button>
            )}

            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {ruccentNationality ? "Modifier" : "Enregistrer"}
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default NationalityForm;
