import { AppCredentials } from '@/config/enum';
import { IApiResponse } from '@/interface';
import { axios2 } from '@/lib/axios';

import { TTypePiece } from '../types';

export const createTypePiece = (data: TTypePiece): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post('/type-pieces/add', toSend);
};

export const updateTypePiece = (
  data: TTypePiece,
  docId: string
): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/type-pieces/update/${docId}`, toSend);
};

export const deleteTypePiece = (id: string): Promise<IApiResponse> => {
  return axios2.delete(`/type-pieces/${id}`);
};

export const getAllTypePiece = (): Promise<IApiResponse> => {
  return axios2.get('/type-pieces');
};
