import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormikHelpers, useFormik } from 'formik';
import "./ParameterText.styles.scss";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import { TTextTemplate } from "../../types/types";
import * as Yup from "yup";
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  // convertToRaw,
  ContentState,
  ContentBlock,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import { ToastError } from "@/utils/toast";


export type TTextTemplateFormsValues = {
  id?: string;
  name: string,
  description: string,
};

const FormErrorDisplayer = WithFormikErrorMessage<TTextTemplateFormsValues>(AlertMessage);

type TextTemplateFormProps = {
  closeSidePanel: () => void;
  refreshTextTemplate: () => void;
  handleSubmit: (
    values: TTextTemplateFormsValues,
    FormikHelpers: FormikHelpers<TTextTemplateFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  currentTextTemplate?: TTextTemplate;
};

const ParameterTextForm: React.FC<TextTemplateFormProps> = ({handleSubmit, currentTextTemplate}) => {

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const validationSchema: any = Yup.object({
    name: Yup.string().required("Le libellé est requis"),
    // description: Yup.string().required("La description est requise"),
  });
  const formik = useFormik({
    initialValues: {
      name: currentTextTemplate?.name || "",
      description: currentTextTemplate?.description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => { 
      if(!editorState || !editorState.getCurrentContent()) {
        ToastError.fire({
          text: "Veuillez renseigner le contenu du modèle !",
          timer: 9000,
        });
      }

      values.description = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );

      handleSubmit(values,formik)
    }
  });

  const onEditorStateChange = (value: EditorState) => {
    setEditorState(value);
  }
  
  useEffect(() => {
    if (currentTextTemplate && currentTextTemplate.description) {
      let contentState: any;
      if (JSON.parse(currentTextTemplate?.description)) {
        // En ce moment nous sommes en modification
        contentState = convertFromRaw(JSON.parse(currentTextTemplate?.description));
      } else {
          contentState = ContentState.createFromText(
            currentTextTemplate?.description
          );
      }

      // const contentState = ContentState.createFromText(text);

      const newEditorState = EditorState.createWithContent(contentState);

      // const newContentState = Modifier.insertText(
      //   editorState.getCurrentContent(),
      //   editorState.getSelection(),
      //   text
      // );

      // const newEditorState = EditorState.createWithContent(newContentState);

      // const newEditorState = EditorState.set(editorState, {
      //   currentContent: newContentState,
      // });

      setEditorState(newEditorState);
    }
  }, [currentTextTemplate]);

  return (
    <>
      <form className="form-parametre-text" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Libellé <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="name"
              autoComplete="off"
              placeholder="Libellé"
              onChange={formik.handleChange}
              value={formik.values.name}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="name"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          {/* <div className="col-12 mb-3">
            <label className="bs-label-form"> Contenu </label>
            <textarea
              rows={3}
              className="form-control bs-input-custom"
              name="description"
              placeholder="Description"
              onChange={formik.handleChange}
              value={formik.values.description}
            ></textarea>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="description"
              simple={true}
              mt={5}
              mb={0}
            />
          </div> */}

          <div className="col-12">
          <label className="bs-label-form"> Contenu </label>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            toolbarClassName="rw-toolbar-custom"
            wrapperClassName="rw-wrapper"
            editorClassName="rw-editor"
            placeholder="Saisissez votre texte ici"
            toolbar={{
              options: [
                'blockType',
                'inline',
                'fontSize',
                'fontFamily',
                'textAlign',
                'list',
                'remove',
                'history',
              ],
              inline: {
                options: ['bold', 'italic', 'underline', 'strikethrough'],
              },
              list: {
                options: ['unordered', 'ordered'],
              },
            }}
          />
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              disableElevation
            >
              Annuler
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ParameterTextForm;
