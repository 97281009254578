import CListBuyerSeller from '../../containers/Dossier/ListBuyerSeller/ListBuyerSeller.container';
import { routePaths } from '@/config';
import { useNavigate } from 'react-router-dom';
import FSHeader from '../../Elements/FSHeader/FSHeader.element';
import { store } from '@/store';
import { updateCurrentFolder } from '@/store/reducers/folder/folder.action';
import { PersonSelectedContextProvider } from '../../contexts/selectedPerson.context';

const BienImmobilierPage = () => {
  const dispatch = store.dispatch;
  const navigate = useNavigate();

  return (
    <div className="fs-container">
      <FSHeader
        title="Gestion des dossiers"
        icon="ant-design:folder-open-filled"
        breadscrumbTitle="Création de dossiers"
        closeButton={true}
        onClose={() => {
          navigate(`${routePaths.admin}${routePaths.adminChildren.folder}`);
          dispatch(updateCurrentFolder(null!));
        }}
      />
      <PersonSelectedContextProvider>
        <CListBuyerSeller />
      </PersonSelectedContextProvider>
    </div>
  );
};

export default BienImmobilierPage;
