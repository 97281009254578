interface ITablePreviewDomFunc {
  topLevelParentWrapperSelector: string;
  getFonction: (queryText: string) => void;
  element: HTMLInputElement;
  //
}
// export const tablePreviewDomFunc = ({
//   inputParentWrapperSelector,
//   getFonction,
// }: ITablePreviewDomFunc) => {
//   const parentWrapper = document.querySelector(
//     inputParentWrapperSelector
//   ) as HTMLDivElement;
//   parentWrapper.addEventListener('keyup', e => {
//     //Handle KeyUp

//     const element = e.target as HTMLInputElement;
//     const currentTablePreview = element.nextSibling as HTMLElement;
//     if (
//       !currentTablePreview ||
//       !(currentTablePreview.className === 'table-preview-wrapper')
//     )
//       return;
//     console.log(element.value);
//     // element.onfocus = e => {
//     //   const element = e.target as HTMLInputElement;
//     //   element.value = '';
//     // };
//     currentTablePreview.classList.add('show-table-preview');
//     getFonction(element.value);
//   });
// };
let atOnce = false;
export const tablePreviewDomFunc = ({
  element,
  getFonction,
  topLevelParentWrapperSelector,
}: ITablePreviewDomFunc) => {
  // remove last icon separator
  if (!atOnce) {
    const lastIconSeparator = Array.from(
      document.querySelectorAll(
        '.table-preview-wrapper .MuiDataGrid-iconSeparator'
      )
    ).at(-1) as HTMLElement;
    lastIconSeparator.style.display = 'none';
    atOnce = true;
  }

  const currentTablePreview = element && (element.nextSibling as HTMLElement);
  if (
    !currentTablePreview!.classList.contains(currentTablePreview.classList[0])
  )
    return;
  currentTablePreview!.classList.add('show-table-preview');
  if (element.value) {
    getFonction(element.value);
  }

  // add Event listener on top level parent wrapper of forms , so we could be able to close table preview when click out side it.
  const topLevelParentWrapperElement = document.querySelector(
    topLevelParentWrapperSelector
  )!;
  topLevelParentWrapperElement.addEventListener('click', e => {
    const elementTarget = e.target as HTMLElement;
    const elementParentIsTablePreview = elementTarget.closest(
      `.${currentTablePreview.classList[0]}`
    );
    !elementParentIsTablePreview &&
      currentTablePreview.classList.remove(currentTablePreview.classList[1]);
  });
};
