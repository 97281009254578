import { SideBarAction } from "./sidebar.actions";
import sidebarTypes from "./sidebar.types";

interface ISidebarReducerState {
  opened: boolean;
}

const INITIAL_STATE = {
  opened: false,
};

const sidebarReducer = (
  state: ISidebarReducerState = INITIAL_STATE,
  action: SideBarAction
): ISidebarReducerState => {
  switch (action.type) {
    case sidebarTypes.TOGGLE_SIDEBAR:
      return {
        opened: !state.opened
      };

    default:
      return state;
  }
};

export default sidebarReducer;
