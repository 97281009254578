import { FC, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import styled from "styled-components";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./DeleteFolderModal.styles.scss";
import Swal from "sweetalert2";
import { cancelFolder, removeFolder } from "@/features/admin/api/folder.api";
import { result } from "lodash";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { Loader } from "@/components/Loader/Loader";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "../AlertMessage/AlertMessage.uic";
import { cancelAccountant } from "@/features/admin/api/comptabily.api";
import { toast } from "react-toastify";

interface IDialogAlertProps {
  openDialog: boolean;
  handleCloseDialog?: () => void;
  performedFunction?: (toSend: any) => Promise<void>;
  alertTitle?: string;
  alertMessage?: string;
  elementToDelete?: any;
  setRefreshDataFolder?: React.Dispatch<React.SetStateAction<string>>;

  isDeleteAccountant?: boolean;
  accountant?: any;
}

const FormErrorDisplayer = WithFormikErrorMessage<any>(AlertMessage);

interface IDeleteFormField {
  delete_reason: string;
}
const DeleteFolderModal: FC<IDialogAlertProps> = ({
  openDialog,
  handleCloseDialog,
  performedFunction,
  elementToDelete,
  setRefreshDataFolder,
  isDeleteAccountant,
  accountant,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema: any = Yup.object({
    delete_reason: Yup.string().required(
      "Le motif d'annulation est obligatoire"
    ),
  });
  // console.log({ accountant, isDeleteAccountant });
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
    console.log("open");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values: any) => {
    const { delete_reason } = values;
    Swal.fire({
      title: "Annulation",
      text: "Confirmez-vous l'annulation ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        isDeleteAccountant
          ? cancelCurrentAccountant(delete_reason)
          : cancelItemFolder();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
    handleOpen();
  };

  const cancelItemFolder = () => {
    cancelFolder({
      cancel_reason: formik.values.delete_reason,
      folder_reference:
        elementToDelete?.reference || elementToDelete?.folder_reference,
    }).then(response => {
      setIsLoading?.(false);
      if (!response.error) {
        ToastGeneric(
          "Opération effectuée avec succès !",
          "success",
          9000
        ).fire();

        formik.resetForm();
        handleCloseDialog!();
        // On rafraichir la liste des dossiers
        setRefreshDataFolder?.(new Date().getTime().toString());
      } else {
        ToastError.fire({ text: response.message, timer: 9000 });
      }
    });
  };

  const cancelCurrentAccountant = async (cancelReason: string) => {
    try {
      const response = await cancelAccountant(accountant["accountantId"], {
        cancel_reason: cancelReason,
        is_actif: 0,
      });

      if (response.error) {
        toast.error(response?.message);
        return;
      }

      toast.success(response?.message);
      handleCloseDialog!();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      delete_reason: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle className='dialog-title'>
          ANNULATION{" "}
          {isDeleteAccountant
            ? "DE LA LIGNE COMPTABLE"
            : `DE DOSSIER N° ${elementToDelete?.folder_code}`}
          <IconButton onClick={() => handleCloseDialog!()}>
            <Icon icon='clarity:window-close-line' fontSize={36} />
          </IconButton>
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <b> Détails {isDeleteAccountant ? "de la line comptable" : ""} </b>
            <div className='container-dialog'>
              <Loader isLoading={isLoading} />
              {!isDeleteAccountant && (
                <div className='row mb-3'>
                  <div className='col-12'>
                    <span>Nom du Dossier : </span>

                    <b> {elementToDelete?.label || "-"} </b>
                  </div>
                  <div className='col-12'>
                    <span>Vendeur(s) : </span>{" "}
                    <b>{elementToDelete?.completeSellerName || "-"} </b>
                  </div>
                  <div className='col-12'>
                    <span> Acheteur(s) : </span>{" "}
                    <b> {elementToDelete?.buyerComplete || "-"} </b>
                  </div>
                </div>
              )}
              {isDeleteAccountant && (
                <div className='row mb-3 delete-row'>
                  <div className='col-12'>
                    <span>Acte de vente: </span>{" "}
                    <b> {accountant?.bill_sale || "-"} </b>
                  </div>
                  <div className='col-12'>
                    <span>Type de mouvement : </span>{" "}
                    <b>{accountant?.mouvementType || "-"} </b>
                  </div>
                  <div className='col-12'>
                    <span> Montant : </span>{" "}
                    <b> {accountant?.montant || "-"} </b>
                  </div>
                  <div className='col-12'>
                    <span> Description : </span>{" "}
                    <b> {accountant?.description || "-"} </b>
                  </div>
                </div>
              )}

              <div className='row'>
                <div className='col-12'>
                  <textarea
                    name='delete_reason'
                    className='form-control bs-input-custom'
                    placeholder="Motif d'annulation"
                    rows={4}
                    onChange={formik.handleChange}
                    value={formik.values.delete_reason}
                  ></textarea>

                  <FormErrorDisplayer
                    touched={formik.touched}
                    errors={formik.errors}
                    name='delete_reason'
                    simple={true}
                    mt={5}
                    mb={0}
                  />
                </div>
              </div>

              <div className='modify-button'>
                <Button type='submit' variant='contained' color='primary'>
                  Annuler
                </Button>
              </div>
            </div>

            {/* <TextField
              autoFocus
              id="name"
              label="Motif de suppression"
              type="text"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.delete_reason}
              variant="standard"
            /> */}
          </DialogContent>
          {/* <DialogActions>
            <Button type="submit">Supprimer</Button>
          </DialogActions> */}
        </form>
      </Dialog>
      {/* <DeleteDialog
        alertTitle={`Êtes vous sûr de vouloir supprimer ce dossier`}
        elementToDelete={elementToDelete?.label}
        openDialog={open}
        handleCloseDialog={() => {
          handleClose();
          handleCloseDialog();
        }}
        performedFunction={() => {
          formik.handleSubmit();
          console.log('enter');
        }}
      /> */}
    </>
  );
};

const P = styled.p`
  margin-top: 3px;
  span {
    font-weight: bold;
  }
`;

export default DeleteFolderModal;
