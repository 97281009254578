import { Container } from "./Container.styles";

type ContainerProps = {
    children: React.ReactNode;
};

const LContainer: React.FC<ContainerProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default LContainer;
