import React, { FC, useState } from "react";
import "./BoxConfrereInfo.scss";
import iconeBalance from "@/assets/images/svg/iconImmobilier.svg";
import { InlineIcon } from "@iconify/react";
import ConfrereForm, {
  TConfrereFormsValues,
} from "../../forms/Confrere/Confrere.form";
import ModeIcon from "@mui/icons-material/Mode";
import { deleteConfrere, updateConfrere } from "../../api/confrere.api";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import _ from "lodash";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";
import Panel from "@/components/UICs/Panel/Panel.uic";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Swal from "sweetalert2";

type DropdownMenuProps = {
  currentConfrere: any;
  refreshConfrere: () => void;
  onClick: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  currentConfrere,
  refreshConfrere,
  onClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**Confirmation Suppression d'un Type d'acteur  */
  const confirmDeleteConfrere = () => {
    handleClose();
    Swal.fire({
      title: " Suppression",
      text: "Vous êtes sur le point de supprimer un confrère !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        deleteConfrereFuntion();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteConfrereFuntion = async () => {
    const response = await deleteConfrere(currentConfrere.id);
    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `Confrère "${currentConfrere.libelle}" a été supprimé avec succès`,
        "success",
        3000
      ).fire();
      refreshConfrere();
    }
  };

  const updateItem = async (
    values: TConfrereFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    // console.log({ currentConfrere });
    const toSend = {
      libelle: values.libelle,
      nbre_associe: values.nbre_associe,
      situation_geo: values.situation_geo,
      description: values.description,
    };
    // console.log("currentConfrere ", toSend);
    const response = await updateConfrere(toSend, currentConfrere.id);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Confrère modifié avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshConfrere();
      // console.table(response);
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TConfrereFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TConfrereFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        color='primary'
        variant='contained'
        title='Créer un confrère'
        endIcon={<ExpandMoreIcon sx={{ width: 20, height: 20 }} />}
      >
        Actions
      </Button>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            onClick();
            handleClose();
          }}
        >
          <ListItemIcon>
            <PersonSearchIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Détails</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        {/* Un confrere  ne peut pas être supprimé */}
        {/* <MenuItem onClick={confirmDeleteConfrere}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem> */}
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Mise à jour du confrère"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <ConfrereForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          currentConfrere={currentConfrere}
          isForUpdate={true}
          refreshConfrere={refreshConfrere}
          handleSubmit={handleSubmit}
        />
      </Panel>
      {/* <DeleteDialog
        alertTitle={`Voulez-vous supprimer ce type d'acteur ?`}
        elementToDelete={` ${currentConfrere.libelle}`}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        performedFunction={deleteConfrereFuntion}
      /> */}
    </>
  );
};

type BoxConfrereInfoProps = {
  onClick: () => void /** Item est une valeur de test pour l'envoi des données en parametre */;
  refreshConfrere: () => void /** Item est une valeur de test pour l'envoi des données en parametre */;
  rows: any;
};
const BoxConfrereInfo: React.FC<BoxConfrereInfoProps> = ({
  onClick,
  refreshConfrere,
  rows,
}) => {
  // console.log("rows ", rows)

  return (
    <div className='BoxConfrereInfoContent'>
      <div className='body-part'>
        <div className='icon-part'>
          <img src={iconeBalance} title='Image' alt='Balance' />
        </div>
        <div className='info-part'>
          <b title={rows?.libelle}>{rows?.libelle}</b>
          <small>
            {rows?.nbre_associe > 1
              ? `${rows?.nbre_associe} associés`
              : `${rows?.nbre_associe} associé`}
          </small>
          <span className='description'>
            <InlineIcon className='icon' icon={"openmoji:details"} />
            {rows?.description}
          </span>
        </div>
      </div>
      <div className='footer-part'>
        {/* <Button onClick={() => onClick()} color='primary' variant="contained" title='Créer un confrère' disableElevation endIcon={<ArrowForwardIosIcon />}>
                    Détails
                </Button> */}
        <DropdownMenu
          currentConfrere={rows}
          refreshConfrere={refreshConfrere}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

export default BoxConfrereInfo;
