import { FC, useEffect, useState } from "react";
import {
  // DataGrid,
  GridColDef,
  // frFR,
  // GridToolbar,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import {
  Box,
  // Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  DialogTitle,
  DialogContent,
  Dialog,
  // Pagination,
} from "@mui/material";
import "./HistoryClients.style.scss";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import {
  TCompanyInfo,
  // TListeFiles,
  TSellerPersonnalInfo,
  TTypePiece,
} from "@/features/admin/types";
import { formatDate } from "@/utils/formatDate";
import {
  // getFilesList,
  getPersonneMoral,
  getPersonnePhysique,
} from "@/features/admin/api/client.api";
import { Loader } from "@/components/Loader/Loader";
// import Panel from "@/components/UICs/Panel/Panel.uic";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
// import DeleteDialog from "@/components/UICs/DeleteDialog/DeleteDialog.uix";
import { Icon } from "@iconify/react";
// import ListeFiles from "../../Files/ListeFiles/ListeFiles.container";
import OwnerTable from "@/components/UICs/OwnerTable/OwnerTable";
import DetailsPhysic from "./DetailsPhysic/DetailsPhysic";
import { getAllTypePiece } from "@/features/admin/api/typePiece.api";
import UserFilesForm from "./UserFilesForm/UserFilesForm";
import DetailsMoral from "./DetailsMoral/DetailsMoral";

const rowsPerPage = 7;

type DropdownMenuProps = {
  ClientList: any;
  currentActor: any;
  refreshActor: React.Dispatch<React.SetStateAction<string>>;
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  ClientList,
  currentActor,
  refreshActor,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [clientSelected, setClientSelected] = useState<any>();
  const [actionSelected, setActionSelected] = useState<number>();
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const [listeTypePiece, setListeTypePiece] = useState<any[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const columnsfolders: GridColDef[] = [
    {
      field: "folder_name",
      headerName: "Nom du dossier",
      flex: 1,
      sortable: true,
    },
    {
      field: "folder_code",
      headerName: "Code du dossier",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_date",
      headerName: "Date de création",
      width: 180,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return formatDate(row?.created_date);
      }, //TODO : check params
    },
  ];

  const columnsComptas: GridColDef[] = [
    {
      field: "description",
      headerName: "Libellé",
      flex: 1,
      sortable: true,
    },
    {
      field: "montant",
      headerName: "Montant",
      width: 100,
      sortable: true,
    },
    {
      field: "type_compta",
      headerName: "Type",
      width: 80,
      sortable: true,
    },
    {
      field: "dossier_id",
      headerName: "Dossier",
      flex: 1,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return row?.dossier_id?.folder_name;
      }, //TODO : check params
    },
    {
      field: "created_date",
      headerName: "Créé le",
      width: 130,
      sortable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return formatDate(row?.created_date);
      }, //TODO : check params
    },
  ];

  const getTypePiece = async () => {
    const { data } = await getAllTypePiece();

    const dataWithNewObjectKeys: TTypePiece[] = data.map((type: any) => {
      return {
        id: type._id,
        name: upperFirstWordLetter(type?.name!),
      };
    });
    setListeTypePiece(dataWithNewObjectKeys);
  };

  const handleUsersActions = (item: any, action: number) => {
    setOpenPanel(true);
    handleClose();
    setActionSelected(action);
    setClientSelected(item);
  };

  useEffect(() => {
    if (openPanel) {
      getTypePiece();
    }
  }, [openPanel]);

  useEffect(() => {
    let isClientSelected = ClientList.find(
      (el: any) => el?.id === clientSelected?.id
    );
    setClientSelected(isClientSelected);
  }, [ClientList]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleUsersActions(null, 1);
          }}
        >
          <ListItemIcon>
            <PersonSearchIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Détails</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleUsersActions(currentActor, 2);
          }}
        >
          <ListItemIcon>
            <Icon icon='mdi:file-account-outline' fontSize='large' />
          </ListItemIcon>
          <ListItemText>Documents</ListItemText>
        </MenuItem>
      </Menu>
      <Dialog
        open={openPanel}
        onClose={(e, reason) => {
          if (!(reason === "backdropClick")) setOpenPanel(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='lg'
        className='buyerSeller-modal'
      >
        <DialogTitle
          id='alert-dialog-title'
          sx={{ padding: "0px 0px 0px 0px" }}
        >
          <div className='fs-modal-header'>
            <span className='fs-modal-title'>
              {actionSelected === 1 && (
                <>
                  LES DETAILS DE :{" "}
                  <b>
                    {currentActor.last_name || currentActor.denomination}{" "}
                    {currentActor.first_name}
                  </b>
                </>
              )}
              {actionSelected === 2 && (
                <>
                  LES DOCUMENTS DE :{" "}
                  <b>
                    {currentActor.last_name || currentActor.denomination}{" "}
                    {currentActor.first_name}
                  </b>
                </>
              )}
            </span>

            <button
              className='fs-modal-close-button'
              onClick={() => {
                setOpenPanel(false);
              }}
            >
              <Icon className='icon' icon='clarity:times-line' />
            </button>
          </div>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px", paddingTop: "20px!important" }}>
          {actionSelected === 1 && (
            <>
              <div className='select-container'>
                <header>
                  <span>Informations du client</span>
                </header>
                {currentActor.isPersonMoral ? (
                  <DetailsMoral user={currentActor} />
                ) : (
                  <DetailsPhysic user={currentActor} />
                )}
              </div>
              <b className='title'>Liste des dossiers</b>
              <OwnerTable
                rows={currentActor?.folders}
                columns={columnsfolders}
                // searchFilter={true}
              />
              <b className='title'>Lignes comptables</b>
              <OwnerTable
                rows={currentActor?.comptas}
                columns={columnsComptas}
                // searchFilter={true}
              />
            </>
          )}
          {actionSelected === 2 && (
            <>
              <UserFilesForm
                idClient={currentActor?.id}
                isPersonMoral={currentActor?.isPersonMoral}
                setRefreshFilesData={refreshActor}
                currentClient={clientSelected}
              />
              {/* <OwnerTable
                  rows={currentActor.files}
                  columns={columnsFiles}
                  // searchFilter={true}
                /> */}
            </>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

type THistoryClients = {
  isPersonMoral: boolean;
};

const HistoryClients: FC<THistoryClients> = ({ isPersonMoral }) => {
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [listPersonalInfo, setListPersonalInfo] = useState<
    TSellerPersonnalInfo[]
  >([]);
  const [listCompagny, setListCompagny] = useState<TCompanyInfo[]>([]);
  const [refreshActor, setRefreshActor] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const columnsPhysic: GridColDef[] = [
    {
      field: "last_name",
      headerName: "Nom",
      flex: 1,
      sortable: true,
    },
    {
      field: "first_name",
      headerName: "Prénoms",
      flex: 1,
      sortable: true,
    },
    {
      field: "phone_number",
      headerName: "N°Télephone",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DropdownMenu
            ClientList={!isPersonMoral && listPersonalInfo}
            currentActor={row}
            refreshActor={setRefreshActor}
          />
        );
      }, //TODO : check params
    },
  ];

  const columnsMoral: GridColDef[] = [
    {
      field: "denomination",
      headerName: "Raison sociale",
      flex: 1,
      sortable: true,
    },
    {
      field: "registered_office",
      headerName: "Siège social",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 80,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DropdownMenu
            ClientList={isPersonMoral && listCompagny}
            currentActor={row}
            refreshActor={setRefreshActor}
          />
        );
      }, //TODO : check params
    },
  ];

  const getClient = async () => {
    setIsLoading(true);

    if (!isPersonMoral) {
      const { data } = await getPersonnePhysique();

      // console.log(" data ", data);

      const dataWithNewObjectKeys: TSellerPersonnalInfo[] = data.map(
        (personne: any) => {
          return {
            id: personne._id,
            last_name: personne?.personal_infos?.last_name
              ? upperFirstWordLetter(personne?.personal_infos?.last_name!)
              : personne?.personal_infos?.last_name,
            first_name: personne?.personal_infos?.first_name
              ? upperFirstWordLetter(personne?.personal_infos?.first_name!)
              : personne?.personal_infos?.first_name,
            phone_number: personne?.personal_infos?.phone_number,
            comptas: personne?.comptas,
            files: personne?.files,
            folders: personne?.folders,
            personal_infos: personne?.personal_infos,
            isPersonPhysic: !isPersonMoral ? true : false,
            // created_date: formatDate(personne.personal_infos.created_date)
          };
        }
      );
      setListPersonalInfo(dataWithNewObjectKeys);
    } else {
      const { data } = await getPersonneMoral();

      // console.log(" data person moral", data);
      const dataWithNewObjectKeys: TCompanyInfo[] = data.map(
        (personne: any) => {
          return {
            id: personne?._id,
            denomination:
              personne?.legal_infos && personne?.legal_infos?.denomination
                ? upperFirstWordLetter(personne?.legal_infos?.denomination!)
                : "",
            registered_office:
              personne?.legal_infos && personne?.legal_infos?.registered_office
                ? upperFirstWordLetter(
                    personne?.legal_infos?.registered_office!
                  )
                : "",
            legal_infos: personne?.legal_infos,
            personal_infos: personne?.personal_infos,
            comptas: personne?.comptas,
            files: personne?.files,
            folders: personne?.folders,
            phone_number: personne?.legal_infos?.phone_number,
            isPersonMoral: isPersonMoral,
          };
        }
      );
      setListCompagny(dataWithNewObjectKeys);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    getClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshActor]);

  // const refreshActor = async () => {
  //   // setLoadUser(true);
  //   await getClient();
  //   // setLoadUser(false);
  // };
  return (
    <>
      <div className='c-list-users'>
        <div className='utilisateur-content'>
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div className='utilisateur-table-part'>
                  <div className='utilisateur-table-body'>
                    <Box sx={{ height: 530, width: "100%" }}>
                      <Loader isLoading={isLoading} />
                      <OwnerTable
                        rows={!isPersonMoral ? listPersonalInfo : listCompagny}
                        columns={!isPersonMoral ? columnsPhysic : columnsMoral}
                        searchFilter={true}
                      />
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default HistoryClients;
