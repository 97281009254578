import { createTheme, PaletteColorOptions } from '@mui/material';

declare module '@mui/material/styles' {
  interface Palette {
    tertiary: string;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions | undefined;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // short: true;
    // long: true;
  }
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const { palette } = createTheme();

export const muiCustomTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  palette: {
    primary: {
      main: '#006D77',
      // dark:,
      // light:,
      //contrastText:,
    },
    secondary: {
      main: '#AAA59A',
      // dark:,
      // light:,
      //contrastText:,
    },
    tertiary: palette.augmentColor({
      color: {
        main: '#F5F3EF',
        // dark:,
        // light:,
        contrastText: '#ffffff',
      },
    }),
    error: {
      main: '#872D2D',
      // dark:,
      // light:,
      //contrastText:,
    },
    warning: {
      main: '#F5B544',
      // dark:,
      // light:,
      //contrastText:,
    },
    // info: {
    //   main: '#73103E',
    // },
    success: {
      main: '#75D873',
      // dark:,
      // light:,
      //contrastText:,
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Raleway',sans-serif",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '40px',
          marginBottom: 20,

          '& fieldset': {
            borderRadius: 8,
            borderColor: '#e6e8ec',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          borderColor: '#e6e8ec',
          '& fieldset': {
            borderRadius: 8,
            borderColor: '#e6e8ec',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '100%',
          height: '40px',
          fontSize: '12px',
          fontFamily: "'Raleway',sans-serif",
        },
      },
      variants: [
        {
          props: { variant: 'contained' },
          style: {
            borderRadius: 5,
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderRadius: 5,
          },
        },
      ],
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          padding: '10px',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#F9F9F9',
          fontSize: '1.5rem',
          '&.Mui-selected': {
            color: '#009076',
            backgroundColor: '#F9F9F9',
          },
        },
      },
    },
  },
});
