import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
// import FileUploadImg from "@/assets/images/png/paper.png"
import { useFormik } from "formik";
import "./BuyerMoral.style.scss";
import {
  TBuyerPersonalInfoFormValues,
  TCompanyInfo,
  TSeller,
  TSellerPersonnalInfo,
} from "../../types";
import CompanyInfoForm from "./Steps/CompanyInfo.form";
import BuyerStepPersonnalInfoForm from "../Buyer/Steps/StepPersonalInfo.form";
import moment from "moment";
import * as _ from "lodash";
import {
  createBuyer,
  createSeller,
  updatePersonneInfo,
  updatePersonneMoralInfo,
  updateWithDataFiles,
} from "../../api/folder.api";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { Loader } from "@/components/Loader/Loader";
import { AppCredentials } from "@/config/enum";
import * as Yup from "yup";
import { formatDateInIsoDate } from "@/utils/formatDate";
import FolderDocumentForm from "../Buyer/Steps/Documents/Document.form";
import { usePersonSelectedIdContext } from "../../contexts/selectedPerson.context";
import { store } from "@/store";

export type TBuyerMoralFormStep1Values = {
  sellerMoralDenomination: string;
  sellerMoralFormeJuridique: string;
  sellerMoralCapital: string;
  sellerMoralSiege: string;
  sellerMoralNumSiren: string;
  sellerMoralRegistre: string;
  sellerMoralInfosPlus: string | null;
};

export type TBuyerMoralFormStep3Values = {
  sellerFolderFileName?: string;
  sellerFolderFileDescription?: string;
  sellerFolderFile?: File | null;
};

export type TBuyerMoralFormValues = TBuyerMoralFormStep1Values &
  TBuyerPersonalInfoFormValues &
  TBuyerMoralFormStep3Values;

interface CBuyerMoralFormProps {
  // folderType: number;
  folderReference: string;
  operation: "Create" | "Update";
  entity: "Seller" | "Buyer";
  setIdFolder?: React.Dispatch<React.SetStateAction<string>>;
  setReferenceFolder?: React.Dispatch<React.SetStateAction<string>>;
  setRefreshDataCurrentFolder?: React.Dispatch<React.SetStateAction<string>>;
  currentPersonMoralToUpdate?: TCompanyInfo;
  currentInfoDirigeantToUpdate?: TSellerPersonnalInfo;
  readOnlyInput?: boolean;
  currentDataPersonne: any;
}

const BuyerMoralForm: React.FC<CBuyerMoralFormProps> = ({
  // folderType,
  folderReference,
  entity,
  operation,
  setIdFolder,
  setReferenceFolder,
  setRefreshDataCurrentFolder,
  currentPersonMoralToUpdate,
  readOnlyInput,
  currentInfoDirigeantToUpdate,
  currentDataPersonne,
}) => {
  const { personSelectedId, setPersonSelectedId } =
    usePersonSelectedIdContext();
  const [currentIdPersonneSaved, setCurrentIdPersonneSaved] = useState<
    string | null | undefined
  >("");
  const [currentIdFolder, setCurrentIdFolder] = useState("");
  const [isFileFormOpen, setIsFileFormOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [operationValue, setOperationValue] = useState("");
  const [oldFilesPerson, setOldFilesPerson] = useState<any[]>([]);
  const [refreshCurrentFolder, setRefreshCurrentFolder] = useState<string>("");

  /** Reference du dossier */
  const [referenceFolderAfterCreate, setReferenceFolderAfterCreate] =
    useState(folderReference);
  const currentEntryPersonalInfo = "personnalInfo";

  // const validationSchema: any = Yup.object({
  //   sellerMoralDenomination: Yup.string().required('La dénomination est obligatoire !'),
  //   sellerMoralFormeJuridique: Yup.string().required('La forme juridique est obligatoire !'),
  //   sellerMoralSiege: Yup.string().required("Le siège est obligatoire !"),
  //   sellerPhoneNumber: Yup.string()
  //     .matches(
  //       /\+*\s*\d+/g,
  //       "Seul des chiffres et le symbole '+' sont attendus"
  //     )
  //     .min(10, 'Veuillez rentrer un numéro valide')
  //     .required('Le numéro est requis'),
  //     sellerMoralCapital: Yup.string()
  //     .matches(
  //       /\+*\s*\d+/g,
  //       "Seul des chiffres et le symbole '+' sont attendus"
  //     )
  //     // .min(10, 'Veuillez rentrer un numéro valide')
  //     .required('Le numéro est requis'),
  //     sellerMoralRegistre: Yup.string().required('Le numéro de registre est obligatoire !'),
  //     sellerEmail: Yup.string().required("L'email est requis"),
  // });

  const formik = useFormik({
    initialValues: {
      personnalInfo: {
        sellerCivility: "",
        sellerFirstName: "",
        sellerLastName: "",
        sellerTelephone: "",
        sellerPrefession: "",
        sellerNationality: "",
        sellerLieuNaissance: "",
        sellerDateNaissance: null,
        sellerNaturePiece: "",
        sellerNumeroPiece: "",
        sellerLieuEtablissement: "",
        sellerDateExpiration: null,
        sellerInfosPlus: "",
        sellerDateEtablissement: null,
        sellerEmail: "",
      },

      sellerMoralDenomination: "",
      sellerMoralFormeJuridique: "",
      sellerMoralCapital: "",
      sellerMoralSiege: "",
      sellerMoralNumSiren: "",
      sellerMoralRegistre: "",
      sellerMoralInfosPlus: "",
      sellerMoralEmail: "",
      sellerMoralNumber: "",

      sellerFolderFileName: "",
      sellerFolderFileDescription: "",
      sellerFolderFile: null,
    },
    onSubmit: async (values: any) => {
      console.log(" values ", values);

      /** FAIRE DES VERIFICATIONS MANUELLES AU VU DE LA COMPLEXITE DU FORMULAIRE */
      if (
        !values.sellerMoralDenomination ||
        !values.sellerMoralFormeJuridique ||
        !values.sellerMoralCapital ||
        !values.sellerMoralSiege ||
        !values.sellerMoralRegistre ||
        !values.sellerMoralEmail ||
        !values.sellerMoralNumber
      ) {
        ToastError.fire({
          text: "Veuillez renseigner les champs obligatoires à l'étape Informations entreprise !",
          timer: 9000,
        });
        setValue("1");
        return;
      }

      setIsLoading(true);

      /** Formater les informations personnelles du dirigéant */
      let personalInfo: TSellerPersonnalInfo = {
        civility: values.personnalInfo.sellerCivility,
        last_name: values.personnalInfo.sellerLastName,
        first_name: values.personnalInfo.sellerFirstName,
        phone_number: values.personnalInfo.sellerTelephone,
        job: values.personnalInfo.sellerPrefession,
        nationality: values.personnalInfo.sellerNationality,
        birth_place: values.personnalInfo.sellerLieuNaissance,
        birth_date: values.personnalInfo.sellerDateNaissance
          ? moment(values.personnalInfo.sellerDateNaissance).format(
              "DD/MM/YYYY"
            )
          : "",
        piece_type: values.personnalInfo.sellerNaturePiece,
        piece_number: values.personnalInfo.sellerNumeroPiece,
        piece_establishment_place: values.personnalInfo.sellerLieuEtablissement,
        piece_establishment_date: values.personnalInfo.sellerDateEtablissement
          ? moment(values.personnalInfo.sellerDateEtablissement).format(
              "DD/MM/YYYY"
            )
          : "",
        piece_expiration_date: values.personnalInfo.sellerDateExpiration
          ? moment(values.personnalInfo.sellerDateExpiration).format(
              "DD/MM/YYYY"
            )
          : "",
        further_informations: values.personnalInfo.sellerInfosPlus,
        email: values.personnalInfo.sellerEmail,
      };

      /** Formater les informations de la compagnie */
      let compagnyInfo: TCompanyInfo = {
        denomination: values.sellerMoralDenomination,
        legal_form: values.sellerMoralFormeJuridique,
        capital: values.sellerMoralCapital,
        registered_office: values.sellerMoralSiege,
        siren_number: values.sellerMoralNumSiren,
        registration: values.sellerMoralRegistre,
        further_informations: values.sellerMoralInfosPlus,
        email: values.sellerMoralEmail,
        phone_number: values.sellerMoralNumber,
      };

      let folderInfo: TSeller = {
        folder_reference: referenceFolderAfterCreate || folderReference,
        folder_type: "1",
        person_type: "2",
        personal_infos: personalInfo,
        company_infos: compagnyInfo,
        client_id: personSelectedId.moral || "",
      };

      if (currentIdPersonneSaved) {
        // const toSend = {
        //   id: currentIdPersonneSaved,
        //   ...compagnyInfo,
        //   legal_infos: compagnyInfo,
        //   ...personalInfo,
        //   app_secret: AppCredentials.APP_SECRET,
        //   app_id :  AppCredentials.APP_ID
        // };
        let formData = new FormData();
        let documentFormData = new FormData();
        const userId = store.getState().user.userConnected._id;

        formData.append("id", currentIdPersonneSaved);
        formData.append("company_infos", JSON.stringify(compagnyInfo));
        formData.append("legal_infos", JSON.stringify(compagnyInfo));

        console.log("Values files: ", values?.files);
        console.log("Values piece_type: ", values?.piece_type);

        if (values?.files && values?.piece_type) {
          documentFormData.append("files", values?.files);
          documentFormData.append("piece_type", values?.piece_type);
        }

        // Ajout des documents des VENDEURS et ACQUEREURS
        if (
          documentFormData.has("files") &&
          documentFormData.has("piece_type")
        ) {
          const result = await updateWithDataFiles(
            currentIdPersonneSaved,
            documentFormData
          );
          setIsLoading(false);
          if (!result.error) {
            ToastGeneric(
              "Opération effectuée avec succès !",
              "success",
              9000
            ).fire();
            setRefreshDataCurrentFolder?.(new Date().getTime().toString());
          } else {
            ToastError.fire({ text: result.message, timer: 9000 });
          }
          return;
        }

        formData.append("personal_infos", JSON.stringify(personalInfo));
        formData.append("app_secret", AppCredentials.APP_SECRET);
        formData.append("app_id", AppCredentials.APP_ID);
        formData.append("user_id", userId);
        formData.append(
          "folder_id",
          currentPersonMoralToUpdate?.folder! || currentIdFolder
        );

        updatePersonneInfo(formData, currentIdPersonneSaved)
          .then(response => {
            setIsLoading(false);
            if (!response.error) {
              ToastGeneric(
                "Opération effectuée avec succès !",
                "success",
                9000
              ).fire();
            } else {
              ToastError.fire({ text: response.message, timer: 9000 });
            }
            setRefreshDataCurrentFolder?.(new Date().getTime().toString());
          })
          .catch(error => {
            console.log(" error ", error);
            setIsLoading(false);
            if (error?.response?.data?.message) {
              ToastError.fire({
                text: error?.response?.data?.message,
                timer: 9000,
              });
            } else {
              ToastError.fire({ text: error, timer: 9000 });
            }
          });
      } else {
        /** Il s'agit de la premiere création d'un acquerreur ou un vendeur personne morale */
        let createData =
          entity?.toLowerCase() === "seller"
            ? createSeller(folderInfo)
            : createBuyer(folderInfo);

        createData
          .then(response => {
            setIsLoading(false);

            if (response.error) {
              ToastError.fire({ text: response.message, timer: 9000 });
            } else {
              ToastGeneric(
                "Opération effectuée avec succès !",
                "success",
                9000
              ).fire();

              /** Actualiser la liste des vendeurs en faisant un dispatch de la reference */
              setIdFolder?.(response.data?.id);
              setCurrentIdFolder(response.data?.id || response.data?._id);
              setReferenceFolder?.(response.data?.reference);

              if (entity?.toLowerCase() === "seller") {
                if (response.data?.seller_ids) {
                  setCurrentIdPersonneSaved(
                    response.data?.seller_ids[
                      response.data?.seller_ids.length - 1
                    ]?.company_infos?.person_id
                  );
                }
              } else {
                /** Il s'agit d'un acquereur */
                if (response.data?.buyers_ids) {
                  if (response.data?.buyers_ids) {
                    setCurrentIdPersonneSaved(
                      response.data?.buyers_ids[
                        response.data?.seller_ids.length - 1
                      ]?.company_infos?.person_id
                    );
                  }
                }
              }

              setReferenceFolderAfterCreate?.(response.data?.reference);
              setRefreshDataCurrentFolder?.(new Date().getTime().toString());
              setOperationValue("Update");
            }
          })
          .catch(error => {
            console.log(" error ", error);
            setIsLoading(false);
            if (error?.response?.data?.message) {
              ToastError.fire({
                text: error?.response?.data?.message,
                timer: 9000,
              });
            } else {
              ToastError.fire({ text: error, timer: 9000 });
            }
          });
      }
    },
  });

  // const [isOpenForm, setIsOpenForm] = React.useState(false);

  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const setDefautValuePersonnalMoralModify = (
    itemCompagnyInfo: TCompanyInfo,
    itemDirigeantInfo?: TSellerPersonnalInfo
  ) => {
    if (
      itemCompagnyInfo &&
      (itemCompagnyInfo.person_id || itemCompagnyInfo._person_id)
    ) {
      formik.setValues({
        sellerMoralDenomination: itemCompagnyInfo.denomination,
        sellerMoralFormeJuridique: itemCompagnyInfo.legal_form,
        sellerMoralCapital: itemCompagnyInfo.capital,
        sellerMoralSiege: itemCompagnyInfo.registered_office,
        sellerMoralNumSiren: itemCompagnyInfo.siren_number,
        sellerMoralRegistre: itemCompagnyInfo.registration,
        sellerMoralInfosPlus: itemCompagnyInfo.further_informations,
        sellerMoralEmail: itemCompagnyInfo.email || "",
        sellerMoralNumber: itemCompagnyInfo.phone_number || "",

        personnalInfo: {
          sellerCivility: itemDirigeantInfo?.civility!,
          sellerFirstName: itemDirigeantInfo?.first_name!,
          sellerLastName: itemDirigeantInfo?.last_name!,
          sellerTelephone: itemDirigeantInfo?.phone_number!,
          sellerPrefession: itemDirigeantInfo?.job!,
          sellerNationality: itemDirigeantInfo?.nationality!,
          sellerLieuNaissance: itemDirigeantInfo?.birth_place!,
          sellerDateNaissance: formatDateInIsoDate(
            itemDirigeantInfo?.birth_date
          ),
          sellerNaturePiece: itemDirigeantInfo?.piece_type!,
          sellerNumeroPiece: itemDirigeantInfo?.piece_number!,
          sellerDateExpiration: formatDateInIsoDate(
            itemDirigeantInfo?.piece_expiration_date
          ),
          sellerInfosPlus: itemDirigeantInfo?.further_informations!,
          sellerDateEtablissement: formatDateInIsoDate(
            itemDirigeantInfo?.piece_establishment_date
          ),
          sellerEmail: itemDirigeantInfo?.email || "",
          sellerLieuEtablissement:
            itemDirigeantInfo?.piece_establishment_place || "",
        },

        sellerFolderFileName: "",
        sellerFolderFileDescription: "",
        sellerFolderFile: null,
      });
    }
  };

  const getOldFilesPersonnalInfo = () => {
    console.log(
      " currentIdPersonneSaved in  getOldFilesPersonnalInfo",
      currentIdPersonneSaved
    );

    if (currentDataPersonne) {
      //On verifie le type si c'est un acquereur ou un vendeur
      let listeSellers = currentDataPersonne?.seller_ids;
      let listBuyers = currentDataPersonne?.buyers_ids;

      /** Je verifie dans la liste des buyers en fonction de l'id personne */
      let currentPersonne = _.find(
        entity?.toLowerCase() === "buyer" ? listBuyers : listeSellers,
        o => {
          return (
            o?.personal_infos?.person_id === currentIdPersonneSaved ||
            o?.personal_infos?.person_id ===
              currentPersonMoralToUpdate?.person_id
          );
        }
      );
      if (currentPersonne) {
        setOldFilesPerson(currentPersonne?.files);
      }
    }
    // setRefreshDataCurrentFolder?.(new Date().getTime().toString());
  };

  useEffect(() => {
    /** Pour préremplir les valeurs en modification */
    if (currentPersonMoralToUpdate) {
      let currentPersonne = _.find(
        entity?.toLowerCase() === "buyer"
          ? currentDataPersonne?.buyers_ids
          : currentDataPersonne?.seller_ids,
        o => {
          return (
            o?._id === currentIdPersonneSaved ||
            o?._id === currentPersonMoralToUpdate?.person_id
          );
        }
      );

      if (currentPersonne && currentPersonne?.company_infos) {
        setDefautValuePersonnalMoralModify(
          currentPersonne?.company_infos,
          currentPersonne.personal_infos
          // currentInfoDirigeantToUpdate
        );
      }

      if (currentPersonMoralToUpdate?.person_id) {
        setCurrentIdPersonneSaved(currentPersonMoralToUpdate?.person_id);
      }

      getOldFilesPersonnalInfo();
    }

    //eslint-disable-next-line
  }, [currentDataPersonne]);

  useEffect(() => {
    setPersonSelectedId({ moral: " ", physique: "" });
  }, []);

  // useEffect(() => {
  //   setRefreshDataCurrentFolder?.(new Date().getDate().toString());

  //   //eslint-disable-next-line
  // }, [refreshCurrentFolder]);

  return (
    <div className='fs-modal-container'>
      <div className='fs-modal-body'>
        {value !== "1" && (
          <div className='select-container'>
            <header>
              <span>Informations sur l'entreprise</span>
            </header>

            <div className='row fs-row'>
              <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                <label className='bs-label-form'>
                  Raison sociale :{" "}
                  <b>{formik.values.sellerMoralDenomination}</b>
                </label>
              </div>
              <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
                <label className='bs-label-form'>
                  Siège sociale : <b>{formik.values.sellerMoralSiege}</b>
                </label>
              </div>
            </div>
          </div>
        )}
        <div className='fs-modal-body-container'>
          <Box sx={{ width: "100%", typography: "body1" }}>
            <TabContext value={value}>
              <Box
                className='offre-box'
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <TabList
                  className='offre-tabs'
                  onChange={handleChange}
                  variant='scrollable'
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label='Personne Moral'
                >
                  <Tab label='Informations entreprise' value='1' />
                  <Tab
                    label='Dirigeant'
                    value='2'
                    disabled={operationValue === "Create"}
                  />
                  <Tab
                    label='Documents'
                    value='3'
                    disabled={operationValue === "Create"}
                  />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <Loader isLoading={isLoading} title={"Veuillez patienter"} />
                <CompanyInfoForm
                  formik={formik}
                  // setRefreshFilesList={setRefreshCurrentFolder}
                  currentIdPersonne={currentIdPersonneSaved!}
                  readOnlyInput={readOnlyInput}
                />
              </TabPanel>

              <TabPanel value='2'>
                <Loader isLoading={isLoading} title={"Veuillez patienter"} />
                <form
                  onSubmit={formik.handleSubmit}
                  name={currentEntryPersonalInfo}
                >
                  <BuyerStepPersonnalInfoForm
                    typeFile='physic'
                    currentEntryPersonalInfo={currentEntryPersonalInfo}
                    formik={formik}
                    // setRefreshFilesList={setRefreshCurrentFolder}
                    currentIdPersonne={currentIdPersonneSaved!}
                    readOnlyInput={readOnlyInput}
                  />
                </form>
              </TabPanel>

              <TabPanel value='3'>
                <Loader isLoading={isLoading} title={"Veuillez patienter"} />
                <FolderDocumentForm
                  formik={formik}
                  typeFile='moral'
                  formOpen={isFileFormOpen}
                  setRefreshFilesList={setRefreshCurrentFolder}
                  filesList={oldFilesPerson}
                  setFormOpen={value => {
                    setIsFileFormOpen(value);
                  }}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </div>
      </div>
      <div className='fs-modal-footer'></div>
    </div>
  );
};

export default BuyerMoralForm;
