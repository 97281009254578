import { Icon } from "@iconify/react";

import { FSHeaderContainer } from "./FSHeader.styles";

import "./FSHeader.extended.styles.scss";
import { IconButton } from "@mui/material";

type TProps = {
  title: string;
  icon: string;
  breadscrumbTitle: string;
  closeButton?: boolean ;
  onClose?: () => void;
};

const FSHeader: React.FC<TProps> = ({ title, icon, breadscrumbTitle, closeButton = false, onClose = () => {}}) => {

  return (
    <FSHeaderContainer className="fs-header">
      <div className="title-container">
        <Icon className="icon" icon={icon} />
        <span className="fs-title">{title}</span>
      </div>
      <div className="header-tools">
        <div className="breadscrumb">
          <span>{breadscrumbTitle}</span>
          <Icon className="icon" icon="entypo:chevron-small-right" />
        </div>
        { closeButton &&
          <div className="close-part">
            <IconButton onClick={() =>onClose()}>
              <Icon
                icon="clarity:window-close-line"
                fontSize={36}
              />
            </IconButton>
          </div>
        }
        
      </div>
    </FSHeaderContainer>
  );
};

export default FSHeader;
