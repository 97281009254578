import React, { useState } from 'react';
import { routePaths } from '@/config';
import { Button, IconButton } from '@mui/material';
import logo from '@/assets/images/png/logo_app.png';
import { useNavigate } from 'react-router-dom';
import './ResetPasswordForm.styles.scss';
import { ToastError, ToastGeneric } from '@/utils/toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InlineIcon } from '@iconify/react';
import { apiResetPasswordByUserId } from '../../api/auth.api';
import { LoadingButton } from '@mui/lab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const validationSchema: any = Yup.object({
    code: Yup.string().required('Le code OTP est requis !'),
    new_password: Yup.string().required('Le mot de passe est requis !'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('new_password'), null],
      'Veuillez confirmer votre mot de passe !'
    ),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      new_password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async value => {
      setIsloading(true);
      apiResetPasswordByUserId('users/reset-password/', {
        ...value,
        first_connection: false,
        email: localStorage.getItem('email'),
      })
        .then(response => {
          if (response?.error) {
            setIsloading(false);
            ToastError.fire({ text: response.message, timer: 9000 });
          } else {
            setIsloading(false);
            ToastGeneric(
              `Votre mot de passe a été changé, veuillez vous reconnecter pour accéder à la plateforme.`,
              'success',
              3000
            ).fire();
            // Pour afficher le formulaire de connexion
            navigate(routePaths.authChildren.signIn);
          }
        })
        .catch(error => {
          console.log(error);

          if (error?.response?.data?.message) {
            ToastError.fire({
              text: error?.response?.data?.message,
              timer: 9000,
            });
          } else {
            ToastError.fire({ text: error, timer: 9000 });
          }
        });
    },
  });

  return (
    <div className="signin-container">
      <div className="logo-part">
        <img src={logo} alt="Logo" />
      </div>
      <div className="fs-form-container">
        {/* <div className='return-container'>
          <Button className='return-button'>
            <Icon icon="clarity:times-line" className='icon-return'/>
          </Button>
        </div> */}
        <div className="header-part">
          <h1 className="title">Nouveau de mot de passe</h1>
          <p className="subtitle"> Réinitialisation </p>
          <small>Veuillez renseigner les champs ci-dessous</small>
        </div>
        <form className="form-part" onSubmit={formik.handleSubmit}>
          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                {' '}
                Code OTP <sup>*</sup>
              </label>
              <input
                type="text"
                className="form-control bs-input-custom"
                name="code"
                autoComplete="off"
                placeholder="Votre code OTP"
                value={formik.values.code}
                onChange={formik.handleChange}
              ></input>
              {formik.errors.code && (
                <small className="helper-text">
                  {' '}
                  <InlineIcon icon="ci:error-outline" /> {formik.errors.code}{' '}
                </small>
              )}
            </div>
          </div>

          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                Nouveau mot de passe <sup>*</sup>
              </label>
              <div className="input-with-show-password">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control bs-input-custom"
                  name="new_password"
                  placeholder="Votre nouveau mot de passe"
                  autoComplete="off"
                  value={formik.values.new_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />

                <IconButton
                  color="primary"
                  aria-label="toggle show password"
                  component="label"
                  className="toggle-show-password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ width: '16px', height: '16px' }} />
                  ) : (
                    <VisibilityIcon sx={{ width: '16px', height: '16px' }} />
                  )}
                </IconButton>
              </div>

              {formik.errors.new_password && (
                <small className="helper-text">
                  {' '}
                  <InlineIcon icon="ci:error-outline" />{' '}
                  {formik.errors.new_password}{' '}
                </small>
              )}
            </div>
          </div>

          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                Confirmation de mot de passe <sup>*</sup>
              </label>
              <div className="input-with-show-password">
                <input
                  type={showPassword ? 'text' : 'password'}
                  className="form-control bs-input-custom"
                  name="confirmPassword"
                  placeholder="Confirmez votre mot de passe"
                  autoComplete="off"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                />
                <IconButton
                  color="primary"
                  aria-label="toggle show password"
                  component="label"
                  className="toggle-show-password"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword ? (
                    <VisibilityOffIcon sx={{ width: '16px', height: '16px' }} />
                  ) : (
                    <VisibilityIcon sx={{ width: '16px', height: '16px' }} />
                  )}
                </IconButton>
              </div>
              {formik.errors.confirmPassword && (
                <small className="helper-text">
                  <InlineIcon icon="ci:error-outline" />
                  {formik.errors.confirmPassword}
                </small>
              )}
            </div>
          </div>
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{ marginTop: '30px' }}
            type="submit"
            loading={isLoading}
            disabled={
              !formik.values.code ||
              !formik.values.new_password ||
              !formik.values.confirmPassword
            }
          >
            RÉINITIALISER LE MOT DE PASSE
          </LoadingButton>
        </form>
        {/* <div className="footer-part">
            <p> Vous n'avez pas de compte ? 
                <Link className="link-primary"
                      to={routePaths.authChildren.signUpAbsolute}> S'inscrire </Link> 
            </p>
        </div> */}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
