import {
  Box,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./ActorForm.style.scss";
import { GridColDef, GridRenderCellParams, DataGrid } from "@mui/x-data-grid";
import { useFormik } from 'formik';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import { TActor, TFolderActor, TTypeActor } from "../../types";
import { getAllTypeActor } from "../../api/typeActor.api";
import { ToastError, ToastGeneric } from "@/utils/toast";
import * as _ from "lodash";
import { getActorByTypeActor } from "../../api/Actor.api";
import * as Yup from 'yup';
import { createActorFolder, deleteActorBuyerOrSellerInFolder } from "../../api/folder.api";
import { Loader } from "@/components/Loader/Loader";
import Swal from "sweetalert2";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";

const FormErrorDisplayer =
  WithFormikErrorMessage<TFolderActor>(AlertMessage);
  
type TActorProps = {
  folderId: string,
  folderReference? : string,
  setRefreshDataCurrentFolder?: React.Dispatch<React.SetStateAction<string>>;
  actors?: any,
  readOnlyInput?:boolean
}

const ActorFormForm: React.FC<TActorProps> = ({ folderId,folderReference, setRefreshDataCurrentFolder, actors,readOnlyInput }) => {
  
  const [listTypeActor, setListTypeActor] = useState<TTypeActor[]>([]);
  const [listTypeActoActor, setListTypeActoActor] = useState<TActor[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema: any = Yup.object({
    actor_type_id: Yup.string().required("Veuillez sélectionner le type d'acteur !"),
    folder_id: Yup.string().required("Aucun dossier n'est rataché à cet acteur !"),
    actor_id: Yup.string().required("Veuillez sélectionner un acteur !"),
  });

  const formik = useFormik({
    initialValues: {
      actor_id: '',
      actor_type_id: '',
      actor_libelle: '',
      folder_id: folderId,
    },
    validationSchema: validationSchema,
    onSubmit: values => {

      Swal.fire({
        title: " Acteur",
        text:
          "Vous êtes sur le point d'enregistrer un acteur." +
          "!\nDésirez-vous poursuivre cette action ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonColor: "#006D77",
        cancelButtonColor: "#91949c",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          saveItemFolderActor(values);
         
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });

    },
  });

  const saveItemFolderActor = (values: any) => {
    setIsLoading(true);

    let actorDataToSend: TFolderActor = {
      actor_id: (values.actor_id && values.actor_id?.toLowerCase() !== 'autre') ? values.actor_id : "",
      actor_type_id: values.actor_type_id,
      actor_libelle: (values.actor_id && values.actor_id?.toLowerCase() === 'autre') ? values.actor_libelle : "",
      folder_id: folderId
    }
    createActorFolder(actorDataToSend).then(
      (response) => {
        setIsLoading(false);
        if (!response.error) {
          ToastGeneric(
            "Opération effectuée avec succès !",
            "success",
            9000
          ).fire();

          // On reinitialise les valeur
          formik.resetForm();

          // On rafraichir la liste des acteurs
          setRefreshDataCurrentFolder?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      }
    ).catch((error) => {
      console.log(" error ", error);
      setIsLoading(false);
      if (error?.response?.data?.message) {
        ToastError.fire({
          text: error?.response?.data?.message,
          timer: 9000,
        });
      } else {
        ToastError.fire({ text: error, timer: 9000 });
      }
    });
  }

  const columns: GridColDef[] = [
    {
      field: "type_actor_libelle",
      headerName: "Type d'acteur",
      flex: 1,
      sortable: true,
    },
    {
      field: "actor_libelle",
      headerName: "Acteur",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_at",
      headerName: "Date de création",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params: GridRenderCellParams) => 
      !readOnlyInput && (<DropdownMenu actor={params.row} setIsLoading={setIsLoading}/>)
    },
  ];
  

  type TActorProps = {
    actor? : TFolderActor,
    setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>
  }

  const DropdownMenu:React.FC<TActorProps> = ({actor,setIsLoading}) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const confirmDeleteActor = () => {
      Swal.fire({
        title: " Acteur",
        text:
          "Vous êtes sur le point de supprimer un acteur du dossier." +
          "\nDésirez-vous poursuivre cette action ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
        confirmButtonColor: "#006D77",
        cancelButtonColor: "#91949c",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading?.(true);
          deleteItem(actor?._id);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
    }


    const deleteItem = (idActor : string | undefined) => {
      deleteActorBuyerOrSellerInFolder({person_id : idActor!,folder_id: actor?.folder_id,folder_reference:folderReference},'3').then(
        (response) => {
          setIsLoading?.(false);
          if (!response.error) {
            ToastGeneric(
              "Opération effectuée avec succès !",
              "success",
              9000
            ).fire();
  
            // On rafraichir la liste des acteurs
            setRefreshDataCurrentFolder?.(new Date().getTime().toString());
          } else {
            ToastError.fire({ text: response.message, timer: 9000 });
          }
        }
      ).catch((error) => {
        // console.log(" error ", error);
        setIsLoading?.(false);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
    }

    return (
      <>
        <IconButton onClick={handleClick}>
          <MoreHorizIcon />
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {/* <MenuItem onClick={ (e) => {setCurrentActorToUpdate?.(actor)}}>
            <ListItemIcon>
              <ModeIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Modifier</ListItemText>
          </MenuItem> */}
          <MenuItem onClick={(e) => {confirmDeleteActor();handleClose()}}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Supprimer</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  const getTypeActor = () => {
    getAllTypeActor().then((response) => {
      // console.log(response);

      if (response.error) {
        ToastError.fire({ text: response.message, timer: 9000 });
      } else {
        let typesActors: TTypeActor[] = response.data as TTypeActor[];
        typesActors = _.orderBy(typesActors, ['libelle'], ['asc']);
        setListTypeActor(typesActors);
      }
    }).catch((error) => {
      if (error?.response?.data?.message) {
        ToastError.fire({ text: error?.response?.data?.message, timer: 9000 });
      } else {
        ToastError.fire({ text: "Connexion momentanément interronpue !", timer: 9000 });
      }
    });
  }

  const getActorByIdType = (idTypeActeur: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions

    getActorByTypeActor(idTypeActeur).then((response) => {

      if (response.error) {
        ToastError.fire({ text: response.message, timer: 9000 });
      } else {
        let typesActorActors: TActor[] = response.data as TActor[];
        typesActorActors = _.orderBy(typesActorActors, ['libelle'], ['asc']);
        setListTypeActoActor(typesActorActors);
      }
    }).catch((error) => {
      if (error?.response?.data?.message) {
        ToastError.fire({ text: error?.response?.data?.message, timer: 9000 });
      } else {
        ToastError.fire({ text: "Connexion momentanément interronpue !", timer: 9000 });
      }
    });
  }

  useEffect(() => {
    getTypeActor();

    //eslint-disable-next-line    
  }, [!listTypeActor])

  return (
    <div className="add-acteur">
      <Loader isLoading={isLoading} />
      <form onSubmit={formik.handleSubmit}>
        <div className="row fs-row">

          <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
            <label className="bs-label-form">
              Type d'acteur <sup>*</sup>
            </label>
            <select
              id="inputState"
              name="actor_type_id"
              className="form-select bs-input-custom"
              placeholder="Type d'acteur"
              disabled={readOnlyInput}
              onChange={(e) => {
                formik.setFieldValue(
                  "actor_type_id",
                  e.currentTarget.value
                );
                getActorByIdType(e.currentTarget.value)
              }}
              value={formik.values.actor_type_id}
            >
              <option value={''}> Choisissez </option>
              {listTypeActor.map(item => <option key={item._id} value={item._id}> {item.libelle} </option>)}
            </select>

            <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name="actor_type_id"
                simple={true}
                mt={5}
                mb={0}
              />

          </div>


          <div className="col-lg-5 col-md-5 col-sm-12 mb-3">
            <div className="row">
              <div className="col-12 p-0 mb-1">
                <label className="bs-label-form">
                  Acteur<sup>*</sup>
                </label>
                <select
                  id="inputState"
                  name="actor_id"
                  className="form-select bs-input-custom"
                  placeholder="Acteur"
                  disabled={readOnlyInput}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "actor_id",
                      e.currentTarget.value
                    );
                  }}
                  value={formik.values.actor_id}
                >
                  <option value={''}> Choisissez </option>
                  {listTypeActoActor.map(item => <option key={item._id} value={item._id}> {item.libelle} </option>)}
                  <option value={'autre'}> Autre </option>
                </select>

                <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name="actor_id"
                simple={true}
                mt={5}
                mb={0}
              />

              </div>

              {
                formik.values?.actor_id?.toLowerCase() === 'autre' &&

                <div className="col-12 p-0">
                  <label className="bs-label-form">
                    Libellé <sup>*</sup>
                  </label>
                  <input
                    type="text"
                    className="form-control bs-input-custom"
                    name="actor_libelle"
                    autoComplete="off"
                    disabled={readOnlyInput}
                    placeholder="Libellé"
                    onChange={formik.handleChange}
                    value={formik.values.actor_libelle}
                  ></input>
                </div>

              }
            </div>
          </div>

          <div className="col-md-3 col-lg-3 col-sm-12 actor-button">
            <Button type="submit" variant="contained" color="primary" disabled={readOnlyInput}>
               Enregistrer
            </Button>
          </div>

        </div>
      </form>
      
      <Box sx={{ width: "100%", height: "180px", padding: "0px 13px" }}>
        <DataGrid
          rows={actors || []}
          columns={columns}
          hideFooterPagination={true}
          checkboxSelection={false}
          disableSelectionOnClick={true}
          disableColumnMenu
          sx={{
            fontFamily: "'Raleway',sans-serif !important",
            border: "none",
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#ececec",
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "var(--ui-primary)",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
            {
              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              minHeight: "5px",
            },
          }}
        />
      </Box>
    </div>
  );
};

export default ActorFormForm;
