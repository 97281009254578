import React from 'react'
import { Outlet } from 'react-router-dom';
import './Home.styles.scss';

const LHome: React.FC = () => {
  return (
    <div className="home-layout-container">
        {/* <LContainer> */}
            <div className="home-container">
              <Outlet />
            </div>
        {/* </LContainer> */}
    </div>
  )
}

export default LHome