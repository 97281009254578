import { InlineIcon } from '@iconify/react';
import React from 'react';
import './BoxStatuts.style.scss';

type TBoxProps = {
    iconStatut?: string,
    numberItem? : number,
    statut? : string
}

const BoxStatuts:React.FC<TBoxProps> = ({
    iconStatut,
    numberItem,
    statut
}) => {
  return (
    <div className='box-statut-container'>
         <InlineIcon className='icon' icon={`${iconStatut}`} />
         <span> {statut}</span>
         <b> {numberItem} </b>
    </div>
  )
}

export default BoxStatuts