import { routePaths } from '@/config';
import AdminRoutes from '@/features/admin/routes';
import { Navigate } from 'react-router-dom';

export const protectedRoute = [
  {
    path: routePaths.admin + '/*',
    element: <AdminRoutes />,
  },
  { path: '*', element: <Navigate to={routePaths.admin} /> },
];
