import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";
import { TConfrere } from "../types";

export const createConfrere = (data: TConfrere): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/confrere/add", toSend);
};

export const updateConfrere = (data: TConfrere, docId: string): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/confrere/update/${docId}`, toSend);
};

export const getAllConfrere = (): Promise<IApiResponse> => {
  return axios2.get("/confrere");
};

export const deleteConfrere = (id: string): Promise<IApiResponse> => {
  return axios2.delete(`/confrere/${id}`);
};