import { toast } from 'react-toastify';

export const tryCatchWrapper = async (
  fn: () => any,
  endLoading?: () => void
) => {
  try {
    await fn();
  } catch (err: any) {
    console.log({ err });
    const errorMessage = err?.response?.data?.errors; // todo: should be  err?.response?.data?.message
    toast.error(errorMessage);
  } finally {
    endLoading?.();
  }
};
