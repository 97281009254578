import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  // DialogContentText,
  DialogTitle,
} from "@mui/material";
// import { Link } from "react-router-dom";
// import * as Yup from "yup";
import CBuyerForm from "@/features/admin/forms/Buyer/Buyer.form.container";
import BuyerMoralForm from "@/features/admin/forms/BuyerMoral/BuyerMoral.form";
import personPhysic from "@/assets/images/svg/folder_person_physic.svg";
import personMoral from "@/assets/images/svg/folder_person_moral.svg";
import modalBackground from "@/assets/images/png/BG.png";
import DeedOfSaleForm from "@/features/admin/forms/DeedOfSale/DeedOfSale.form";
import Folders from "@/features/admin/forms/Folders/Folders.form";
import ActorForm from "@/features/admin/forms/ActorForm/ActorForm.form";
import { ConnectedProps, connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import {
  TCompanyInfo,
  TSellerMaritalStatusInfo,
  TSellerPersonnalInfo,
  // TUser,
} from "@/features/admin/types";
import Swal from "sweetalert2";
import {
  // createFolder,
  deleteActorBuyerOrSellerInFolder,
  getFolderByReference,
} from "@/features/admin/api/folder.api";
import { Loader } from "@/components/Loader/Loader";

import single from "@/assets/images/png/single-person.png";
import mariage from "@/assets/images/png/marriage.png";
import separe from "@/assets/images/png/divorce.png";
import { formatDate } from "@/utils/formatDate";
import { ToastError, ToastGeneric } from "@/utils/toast";
// import * as _ from "lodash";
// import { useFormik } from "formik";
// import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
// import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
// import { UserJobTitle } from "@/config/enum";
import { selectCurrentFolder } from "@/store/reducers/folder/folder.selector";
import ProjectFolder from "@/features/admin/forms/ProjectFolder/ProjectFolder.form";
import { tabDataButton } from "./tabsButtonData/tabsButtonData";
import ComptableView from "../ComptableView/ComptableView";
import PropertyView from "../PropertyView/PropertyView";
import "./ListBuyerSeller.styles.scss";
// import FolderDocumentForm from "@/features/admin/forms/Buyer/Steps/Documents/Document.form";

// const FormErrorDisplayer = WithFormikErrorMessage<any>(AlertMessage);

type PropsFormRedux = ConnectedProps<typeof connector>;

const ListBuyerSellerFunct: React.FC<PropsFormRedux> = ({ currentFolder }) => {
  const [open, setOpen] = React.useState(false);
  const [addActor, setAddActor] = React.useState(false);
  const [stepperIndice, setStepperIndice] = useState(1);
  const [typePerson, setTypePerson] = useState(0);
  const [operation, setOperation] = useState<"Create" | "Update">("Create");
  const [isLoading, setIsLoading] = useState(false);

  // TODO : move some functionality to a folder context , to facilitate nest props : feat : currentSelectedFolder , getCurrentFolder  , should be in this context.

  /** Varibale utilisé pour le context */
  const [idFolder, setIdFolder] = useState(currentFolder?._id!);
  const [referenceFolder, setReferenceFolder] = useState(
    currentFolder?.reference!
  );
  const [folderCode, setFolderCode] = useState("");
  const [hasSeeDetailsBuyerOrSeller, sethasSeeDetailsBuyerOrSeller] =
    useState(false);

  /** Context pour afficher les valeurs du dossier en cours de modification */
  const [currentFolderSelected, setCurrentFolderSelected] = useState<any>();
  const [refreshDataCurrentFolder, setRefreshDataCurrentFolder] = useState("");

  /** Variable de la personne à modifier */
  const [currentPersonInfoToUpdate, setCurrentPersonInfoToUpdate] =
    useState<TSellerPersonnalInfo>();
  const [currentPersonMoralToUpdate, setCurrentPersonMoralToUpdate] =
    useState<TCompanyInfo>();
  const [currentMatialStatusToUpdate, setCurrentMatialStatusToUpdate] =
    useState<TSellerMaritalStatusInfo>();
  // const [currentDataPersonne, setCurrentDataPersonne] = useState<any>()

  /** Variable pour le readonly des différents input du formulaire */
  const [seeDetailsInformation, setSeeDetailsInformation] = useState(false);

  // console.log("current folder reference: ", referenceFolder);

  const handleClickOpen = () => {
    setTypePerson(0);
    setAddActor(false);
    setOpen(true);
    let personalInfo: TSellerPersonnalInfo = {} as TSellerPersonnalInfo;
    let personMoral: TCompanyInfo = {} as TCompanyInfo;
    setCurrentPersonInfoToUpdate(personalInfo);
    setCurrentPersonMoralToUpdate(personMoral);
    setSeeDetailsInformation(false);
    sethasSeeDetailsBuyerOrSeller(false);
    setOperation("Create");
  };

  const handleClickOpenActor = () => {
    setTypePerson(0);
    setOpen(true);
    setAddActor(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const isPersonPhysic = () => {
    setTypePerson(1);
    setAddActor(false);
  };

  const isPersonMoral = () => {
    setTypePerson(2);
    setAddActor(false);
  };
  // console.log({ currentFolder });

  /** Recuperation du dossier courant (dossier entrain d'etre edité) */
  const getCurrentFolder = async () => {
    if (referenceFolder) {
      setIsLoading(true);
      await getFolderByReference(referenceFolder).then(response => {
        setIsLoading(false);

        // console.log(" selected folder ", response);

        if (!response.error) {
          // Formater les acteurs
          let listActors: any = [];
          let currentItem = response.data || null;

          if (!currentItem) return;

          if (
            currentItem &&
            currentItem.folders_actors &&
            currentItem.folders_actors.length > 0
          ) {
            currentItem.folders_actors.forEach((actor: any) => {
              listActors.push({
                _id: actor?._id,
                id: actor?._id,
                folder_id: actor?.folder_id,
                actor_id: actor?.actor_id?._id,
                actor_type_id: actor?.actor_id?.actor_type_model?._id,
                actor_libelle: actor?.actor_id?.libelle,
                type_actor_libelle: actor?.actor_id?.actor_type_model?.libelle,
                created_at: formatDate(actor?.created_date),
              });
            });

            currentItem.folders_actors = listActors;
          }

          setCurrentFolderSelected(currentItem);
        }
      });
    }
  };

  /** Ouverture du modake de modification */
  const gotoUpdateSellerOrBuyer = (
    item: any,
    isBuyer?: boolean,
    isDetailsInformation?: boolean
  ) => {
    setOpen(true);
    setOperation("Update");
    sethasSeeDetailsBuyerOrSeller(isDetailsInformation!);
    // isBuyer ? setStepperIndice(3) : setStepperIndice(2);
    // setCurrentDataPersonne(item);
    setCurrentPersonInfoToUpdate(item?.personal_infos);
    if (item.company_infos) {
      /** En ce moment c'est une personne moral */
      setCurrentPersonMoralToUpdate(item?.company_infos);
      isPersonMoral();
    } else {
      setCurrentMatialStatusToUpdate(item?.marital_status);
      isPersonPhysic();
    }
  };

  /**Confirmation Suppression d'un acquereur ou d'un vendeur */
  const confirmDeleteBuyerOrSeller = (item: any, isBuyer: boolean) => {
    Swal.fire({
      title: " Suppression",
      text:
        "Vous êtes sur le point de supprimer un " +
        (isBuyer ? "vendeur" : "acquéreur") +
        "!\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deleteItemBuyerOrSeller(item, isBuyer);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  /** Suppression vendeur ou acquereur */
  const deleteItemBuyerOrSeller = (item: any, isBuyer: boolean) => {
    deleteActorBuyerOrSellerInFolder(
      {
        person_id: item?.personal_infos?.person_id,
        folder_reference: referenceFolder,
      },
      isBuyer ? "1" : "2"
    ).then(response => {
      setIsLoading?.(false);
      if (!response.error) {
        ToastGeneric(
          "Opération effectuée avec succès !",
          "success",
          9000
        ).fire();

        // On rafraichir la liste des acteurs
        setRefreshDataCurrentFolder?.(new Date().getTime().toString());
      } else {
        ToastError.fire({ text: response.message, timer: 9000 });
      }
    });
  };

  //-- Refresh currentFolder  */
  const refreshFolder = () => {
    getCurrentFolder();
  };

  useEffect(() => {
    /** Verifier si il s'agit d'une consultation */
    setSeeDetailsInformation(false);
    if (currentFolder && currentFolder?.isConsultationFolder) {
      setSeeDetailsInformation(true);
    }

    getCurrentFolder();
    //eslint-disable-next-line
  }, [refreshDataCurrentFolder]);
  // }, [refreshDataCurrentFolder]);

  // console.log("current Folder Selected: ", currentFolderSelected);

  const tabsButton = tabDataButton({
    vendorBadgeValue: currentFolderSelected?.seller_ids?.length,
    acquereurBadgeValue: currentFolderSelected?.buyers_ids?.length,
    billSaleBadgeValue: currentFolderSelected?.bill_sale?.length,
    comptaBadgeValue: currentFolderSelected?.comptas?.length,
    biensBadgeValue: currentFolderSelected?.biens?.length,
    folderPiecesBadgeValue: currentFolderSelected?.files?.length,
    actorBadgeValue: currentFolderSelected?.folders_actors?.length,
  });

  return (
    <>
      <div className='select-container'>
        <header>
          <span>Informations sur le projet</span>
        </header>
        <ProjectFolder
          seeDetailsInformation={seeDetailsInformation}
          currentFolder={currentFolderSelected}
          setRefreshDataCurrentFolder={setRefreshDataCurrentFolder}
          setReferenceFolder={setReferenceFolder}
          setFolderCode={setFolderCode}
          setIdFolder={setIdFolder}
        />
      </div>
      <div className={`c-list-buyer-seller ${!folderCode ? "desabled" : ""}`}>
        <div className='folder-item-content'>
          <div className='folder-item-header'>
            <div className='header-title-container'>
              {folderCode && (
                <>
                  <span> Dossier N° {folderCode} </span>
                </>
              )}
            </div>
            {/* TODO : Refactor Stepper to a better use */}
            <div className='group-button-container'>
              {tabsButton &&
                tabsButton.map(({ icon, title, badge, isModal }, index) => {
                  const tabIndex = index + 1;
                  // if(title === 'Biens' || title === 'Comptabilité') return null;// ** TO HIDE PROPERTY AND COMPTA BUTTON
                  return (
                    <button
                      key={index}
                      type='button'
                      disabled={!folderCode}
                      className={` ${
                        stepperIndice === tabIndex ? "active" : ""
                      }`}
                      onClick={() =>
                        isModal
                          ? handleClickOpenActor()
                          : setStepperIndice(tabIndex)
                      }
                    >
                      {badge > 0 && <span className='fs-badge'>{badge}</span>}
                      <Icon className='icon' icon={icon} />
                      {title}
                    </button>
                  );
                })}
            </div>
          </div>

          <div className='folder-item-body'>
            <Loader isLoading={isLoading} />
            {stepperIndice === 1 && currentFolderSelected && (
              <PropertyView
                currentFolder={currentFolderSelected}
                folder_id={currentFolderSelected?._id}
                isConsultation={currentFolder?.isConsultationFolder}
              />
            )}

            {stepperIndice === 2 && (
              <>
                {currentFolderSelected?.seller_ids.map(
                  (seller: any, index: any) => (
                    <div className='menu-item details' key={index}>
                      {
                        /* Dans le cas ou c'est une personne physique */
                        !seller.company_infos && (
                          <>
                            <div className='situation-container'>
                              <div
                                className={`situation ${
                                  seller?.marital_status?.marital_status ===
                                  "célibataire"
                                    ? "single"
                                    : seller?.marital_status?.marital_status ===
                                      "marié"
                                    ? "maried"
                                    : seller?.marital_status?.marital_status ===
                                      "divorcé"
                                    ? "divorced"
                                    : seller?.marital_status?.marital_status ===
                                      "veuf"
                                    ? "veuf"
                                    : ""
                                }`}
                              >
                                {seller?.marital_status?.marital_status ===
                                "célibataire" ? (
                                  <img src={single} alt='' />
                                ) : seller?.marital_status?.marital_status ===
                                  "marié" ? (
                                  <img src={mariage} alt='' />
                                ) : seller?.marital_status?.marital_status ===
                                  "divorcé" ? (
                                  <img src={separe} alt='' />
                                ) : seller?.marital_status?.marital_status ===
                                  "veuf" ? (
                                  <Icon width={20} icon='clarity:times-line' />
                                ) : (
                                  <Icon className='icon' icon='uiw:coffee' />
                                )}
                                <span>
                                  {seller?.marital_status?.marital_status}
                                </span>
                              </div>
                            </div>

                            <ul>
                              <li>
                                <span>Nom et prénoms</span>
                                <span>
                                  {seller?.personal_infos?.last_name +
                                    " " +
                                    seller?.personal_infos?.first_name}
                                </span>
                              </li>
                              <li>
                                <span>Adresse mail:</span>
                                <span>{seller?.personal_infos?.email}</span>
                              </li>
                              <li>
                                <span>Numéro de téléphone</span>
                                <span>
                                  {seller?.personal_infos?.phone_number}
                                </span>
                              </li>
                              <li>
                                <span>Profession:</span>
                                <span>{seller?.personal_infos?.job}</span>
                              </li>
                            </ul>
                          </>
                        )
                      }
                      {
                        /* Dans le cas ou c'est une personne physique */
                        seller.company_infos && (
                          <>
                            <div className='situation-container entreprise'>
                              <div className='situation'>
                                <Icon
                                  className='icon'
                                  icon='fluent:building-home-24-regular'
                                />
                                <span>Entreprise</span>
                              </div>
                            </div>

                            <ul>
                              <li>
                                <span>Raison sociale</span>
                                <span>
                                  {seller?.company_infos?.denomination || "-"}
                                </span>
                              </li>
                              <li>
                                <span>Adresse mail:</span>
                                <span>
                                  {seller?.company_infos?.email || "-"}
                                </span>
                              </li>
                              <li>
                                <span>Numéro de téléphone</span>
                                <span>
                                  {seller?.company_infos?.phone_number || "-"}
                                </span>
                              </li>
                              <li>
                                <span>Forme juridique:</span>
                                <span>
                                  {seller?.company_infos?.legal_form || "-"}
                                </span>
                              </li>
                            </ul>
                          </>
                        )
                      }

                      <div className='file'>
                        <div className='actions'>
                          <button
                            type='button'
                            className='btn-detail'
                            title='Détails'
                            onClick={e =>
                              gotoUpdateSellerOrBuyer(seller, false, true)
                            }
                          >
                            <Icon icon='ph:eye' />
                          </button>
                          {!currentFolder?.isConsultationFolder && (
                            <>
                              <button
                                type='button'
                                className='btn-edited'
                                title='Modifier'
                                disabled={seeDetailsInformation}
                                onClick={e =>
                                  gotoUpdateSellerOrBuyer(seller, false)
                                }
                              >
                                <Icon icon='fluent:text-bullet-list-square-edit-24-regular' />
                              </button>
                              <button
                                type='button'
                                className='btn-deleted'
                                title='Supprimer'
                                disabled={seeDetailsInformation}
                                onClick={e => {
                                  confirmDeleteBuyerOrSeller(seller, false);
                                }}
                              >
                                <Icon icon='jam:trash-alt-f' />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {!currentFolder?.isConsultationFolder && (
                  <div
                    className={`menu-item border-dashed ${
                      seeDetailsInformation && !folderCode ? "desabled" : ""
                    }`}
                    onClick={e => {
                      (!seeDetailsInformation || !folderCode) &&
                        handleClickOpen();
                    }}
                  >
                    <Icon className='icon-add' icon='gridicons:user-add' />
                    <span>Ajouter un nouveau vendeur</span>
                  </div>
                )}
              </>
            )}

            {stepperIndice === 3 && (
              <>
                {currentFolderSelected?.buyers_ids.map(
                  (buyer: any, index: any) => (
                    <div className='menu-item details' key={index}>
                      {
                        /* Dans le cas ou c'est une personne physique */
                        !buyer.company_infos && (
                          <>
                            <div className='situation-container'>
                              <div
                                className={`situation ${
                                  buyer?.marital_status?.marital_status ===
                                  "célibataire"
                                    ? "single"
                                    : buyer?.marital_status?.marital_status ===
                                      "marié"
                                    ? "maried"
                                    : buyer?.marital_status?.marital_status ===
                                      "divorcé"
                                    ? "divorced"
                                    : buyer?.marital_status?.marital_status ===
                                      "veuf"
                                    ? "veuf"
                                    : ""
                                }`}
                              >
                                {buyer?.marital_status?.marital_status ===
                                "célibataire" ? (
                                  <img src={single} alt='' />
                                ) : buyer?.marital_status?.marital_status ===
                                  "marié" ? (
                                  <img src={mariage} alt='' />
                                ) : buyer?.marital_status?.marital_status ===
                                  "divorcé" ? (
                                  <img src={separe} alt='' />
                                ) : buyer?.marital_status?.marital_status ===
                                  "veuf" ? (
                                  <Icon width={20} icon='clarity:times-line' />
                                ) : (
                                  <Icon className='icon' icon='uiw:coffee' />
                                )}
                                <span>
                                  {buyer?.marital_status?.marital_status}
                                </span>
                              </div>
                            </div>

                            <ul>
                              <li>
                                <span>Nom et prénoms</span>
                                <span>
                                  {buyer?.personal_infos?.last_name +
                                    " " +
                                    buyer?.personal_infos?.first_name}
                                </span>
                              </li>
                              <li>
                                <span>Adresse mail:</span>
                                <span>{buyer?.personal_infos?.email}</span>
                              </li>
                              <li>
                                <span>Numéro de téléphone</span>
                                <span>
                                  {buyer?.personal_infos?.phone_number}
                                </span>
                              </li>
                              <li>
                                <span>Profession:</span>
                                <span>{buyer?.personal_infos?.job}</span>
                              </li>
                            </ul>
                          </>
                        )
                      }
                      {
                        /* Dans le cas ou c'est une personne physique */
                        buyer.company_infos && (
                          <>
                            {/* <div className="situation-container">
                              <div className="situation">
                                <Icon className="icon" icon="uiw:coffee" />
                                <span>Marié</span>
                              </div>
                            </div> */}

                            <div className='situation-container entreprise'>
                              <div className='situation'>
                                <Icon
                                  className='icon'
                                  icon='fluent:building-home-24-regular'
                                />
                                <span>Entreprise</span>
                              </div>
                            </div>

                            <ul>
                              <li>
                                <span>Raison sociale</span>
                                <span>
                                  {buyer?.company_infos?.denomination || "-"}
                                </span>
                              </li>
                              <li>
                                <span>Adresse mail:</span>
                                <span>
                                  {buyer?.company_infos?.email || "-"}
                                </span>
                              </li>
                              <li>
                                <span>Numéro de téléphone</span>
                                <span>
                                  {buyer?.company_infos?.phone_number || "-"}
                                </span>
                              </li>
                              <li>
                                <span>Forme juridique:</span>
                                <span>
                                  {buyer?.company_infos?.legal_form || "-"}
                                </span>
                              </li>
                            </ul>
                          </>
                        )
                      }

                      <div className='file'>
                        {/* <Link
                            className="link-primary"
                            to={routePaths.authChildren.signUpAbsolute}
                          >
                            <Icon className="icon" icon="heroicons:paper-clip" />
                            <span>4 pièces manquantes</span>
                          </Link> */}

                        <div className='actions'>
                          <button
                            type='button'
                            className='btn-detail'
                            title='Détails'
                            onClick={e =>
                              gotoUpdateSellerOrBuyer(buyer, true, true)
                            }
                          >
                            <Icon icon='ph:eye' />
                          </button>
                          {!currentFolder?.isConsultationFolder && (
                            <>
                              <button
                                type='button'
                                className='btn-edited'
                                title='Modifier'
                                disabled={seeDetailsInformation}
                                onClick={e =>
                                  gotoUpdateSellerOrBuyer(buyer, true)
                                }
                              >
                                <Icon icon='fluent:text-bullet-list-square-edit-24-regular' />
                              </button>
                              <button
                                type='button'
                                className='btn-deleted'
                                disabled={seeDetailsInformation}
                                title='Supprimer'
                                onClick={e => {
                                  confirmDeleteBuyerOrSeller(buyer, true);
                                }}
                              >
                                <Icon icon='jam:trash-alt-f' />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}
                {!currentFolder?.isConsultationFolder && (
                  <div
                    className={`menu-item border-dashed ${
                      !seeDetailsInformation && !folderCode ? "desabled" : ""
                    }`}
                    onClick={e => {
                      (!seeDetailsInformation || !folderCode) &&
                        handleClickOpen();
                    }}
                  >
                    <Icon className='icon-add' icon='gridicons:user-add' />
                    <span>Ajouter un nouvel acquéreur</span>
                  </div>
                )}
              </>
            )}

            {stepperIndice === 4 && (
              <DeedOfSaleForm
                currentFolder={currentFolderSelected}
                setRefreshDataCurrentFolder={setRefreshDataCurrentFolder}
                readOnlyInput={seeDetailsInformation}
              />
            )}
            {stepperIndice === 5 && (
              <ComptableView
                refreshFolder={refreshFolder}
                currentFolder={currentFolderSelected}
                readOnlyInput={seeDetailsInformation}
              />
            )}

            {stepperIndice === 6 && (
              <Folders
                idFolder={idFolder}
                setRefreshFilesData={setRefreshDataCurrentFolder}
                currentFolder={currentFolderSelected}
                readOnlyInput={seeDetailsInformation}
              ></Folders>
            )}
          </div>
        </div>
      </div>

      <div>
        <Dialog
          open={open}
          onClose={(e, reason) => {
            if (!(reason === "backdropClick")) handleClose();
          }}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='lg'
          className='buyerSeller-modal'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ padding: "0px 0px 0px 0px" }}
          >
            <div className='fs-modal-header'>
              {!addActor && stepperIndice === 2 && (
                <>
                  {typePerson === 0 && (
                    <span className='fs-modal-title'>TYPE DE VENDEUR</span>
                  )}
                  {typePerson === 1 && (
                    <span className='fs-modal-title'>
                      CRÉER UN VENDEUR - <em>Personne physique</em>
                    </span>
                  )}
                  {typePerson === 2 && (
                    <span className='fs-modal-title'>
                      CRÉER UN VENDEUR - <em>Personne morale</em>
                    </span>
                  )}
                </>
              )}
              {!addActor && stepperIndice === 3 && (
                <>
                  {typePerson === 0 && (
                    <span className='fs-modal-title'>TYPE D'ACQUÉREUR</span>
                  )}
                  {typePerson === 1 && (
                    <span className='fs-modal-title'>
                      CRÉER UN ACQUÉREUR - <em>Personne physique</em>
                    </span>
                  )}
                  {typePerson === 2 && (
                    <span className='fs-modal-title'>
                      CRÉER UN ACQUÉREUR - <em>Personne morale</em>
                    </span>
                  )}
                </>
              )}

              {addActor && (
                <span className='fs-modal-title'>CREER UN ACTEUR</span>
              )}

              <button className='fs-modal-close-button' onClick={handleClose}>
                <Icon className='icon' icon='clarity:times-line' />
              </button>
            </div>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px 0px 0px 0px" }}>
            {!addActor && typePerson === 0 && (
              <div className='new-folder-container'>
                <div className='background-opacity'>
                  <img src={modalBackground} alt='' />
                </div>
                <div className='new-folder-title'>
                  {stepperIndice === 2 && (
                    <span> Choisissez un type de vendeur </span>
                  )}
                  {stepperIndice === 3 && (
                    <span> Choisissez un type d'acquéreur </span>
                  )}
                  <p>
                    Pour l'enregistrement de votre dossier, veuillez choisir le
                    type : Personne morale ou physique
                  </p>
                </div>
                <div className='type-vendeur-container'>
                  <div className='type-item' onClick={isPersonPhysic}>
                    <img src={personPhysic} alt='' />
                    <span>Personne physique</span>
                  </div>
                  <div className='type-item' onClick={isPersonMoral}>
                    <img src={personMoral} alt='' />
                    <span>Personne morale</span>
                  </div>
                </div>
              </div>
            )}

            {!addActor && typePerson === 1 && (
              <CBuyerForm
                setIdFolder={setIdFolder}
                setReferenceFolder={setReferenceFolder}
                setRefreshDataCurrentFolder={setRefreshDataCurrentFolder}
                currentPersonInfoToUpdate={currentPersonInfoToUpdate}
                currentMatialStatusToUpdate={currentMatialStatusToUpdate}
                currentDataPersonne={currentFolderSelected}
                // folderType={1}
                folderReference={currentFolderSelected?.reference}
                readOnlyInput={
                  seeDetailsInformation || hasSeeDetailsBuyerOrSeller
                }
                entity={stepperIndice === 2 ? "Seller" : "Buyer"}
                operation={operation}
              />
            )}

            {!addActor && typePerson === 2 && (
              <BuyerMoralForm
                setIdFolder={setIdFolder}
                setReferenceFolder={setReferenceFolder}
                setRefreshDataCurrentFolder={setRefreshDataCurrentFolder}
                folderReference={currentFolderSelected?.reference}
                currentPersonMoralToUpdate={currentPersonMoralToUpdate}
                currentDataPersonne={currentFolderSelected}
                currentInfoDirigeantToUpdate={currentPersonInfoToUpdate}
                readOnlyInput={
                  seeDetailsInformation || hasSeeDetailsBuyerOrSeller
                }
                // folderType={1}
                entity={stepperIndice === 2 ? "Seller" : "Buyer"}
                operation={operation}
              />
            )}

            {addActor && (
              <ActorForm
                folderId={idFolder!}
                setRefreshDataCurrentFolder={setRefreshDataCurrentFolder}
                actors={currentFolderSelected?.folders_actors || []}
                folderReference={referenceFolder}
                readOnlyInput={seeDetailsInformation}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  currentFolder: selectCurrentFolder,
});

const connector = connect(mapStateToProps);
const CListBuyerSeller = connector(ListBuyerSellerFunct);

export default CListBuyerSeller;
