import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";
import { TActor } from "../types/types";

export const createActor = (data: TActor): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/actor/add", toSend);
};

export const updateActor = (data: TActor, docId: string): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/actor/update/${docId}`, toSend);
};

export const getAllActor = (): Promise<IApiResponse> => {
  return axios2.get("/actor");
};

export const getActorByTypeActor = (idTypeActor:string): Promise<IApiResponse> => {
  return axios2.get("/actor/actor-type/"+idTypeActor);
};

export const deleteActor = (docId: string): Promise<IApiResponse> => {
  return axios2.delete(`/actor/${docId}`);
};