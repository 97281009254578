import CListUsers from "../../containers/Parameters/ListUsers/ListUsers.container";
import FSHeader from "../../Elements/FSHeader/FSHeader.element";

const ManageUsersPage = () => {
  return (
    <div className="fs-container">
      <FSHeader title="Gestion des utilisateurs" icon="fa-solid:users-cog" breadscrumbTitle="Administration"/>
      <CListUsers />
    </div>
  );
};

export default ManageUsersPage;
