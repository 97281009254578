import React from "react";
import { FormikProps } from "formik";

import { Icon } from "@iconify/react";
import { Button, Divider } from "@mui/material";

import { TBuyerFormValues } from "../Buyer.form.container";

import single from "@/assets/images/png/single-person.png";
import mariage from "@/assets/images/png/marriage.png";
import separe from "@/assets/images/png/divorce.png";

import { convert_Date_to_inputDateFormate } from "@/utils";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import BuyerStepPersonnalInfoForm from "./StepPersonalInfo.form";

const FormErrorDisplayer =
  WithFormikErrorMessage<TBuyerFormValues>(AlertMessage);

interface BuyerStep2FormProps {
  formik: FormikProps<TBuyerFormValues>;
  currentIdPersonne?: string | undefined;
  matrimonialStatus: {
    celibataire: boolean;
    marie: boolean;
    divorce: boolean;
    veuf: boolean;
  };
  isCelibataire: () => void;
  isMarie: () => void;
  isDivorce: () => void;
  isVeuf: () => void;
  changeValidation: () => void;
  readOnlyInput?: boolean;
}
const StepMaritalStatusForm: React.FC<BuyerStep2FormProps> = ({
  formik,
  currentIdPersonne,
  matrimonialStatus,
  isCelibataire,
  isDivorce,
  isMarie,
  isVeuf,
  changeValidation,
  readOnlyInput,
}) => {
  const currentEntryPersonalInfo = "martialPersonalInfo";

  React.useEffect(() => {
    if (matrimonialStatus.celibataire) {
      isCelibataire();
    } else if (matrimonialStatus.divorce) {
      isDivorce();
    } else if (matrimonialStatus.marie) {
      isMarie();
    } else if (matrimonialStatus.veuf) {
      isVeuf();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    changeValidation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matrimonialStatus.celibataire,
    matrimonialStatus.divorce,
    matrimonialStatus.marie,
    matrimonialStatus.veuf,
  ]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='situation-body-container'>
        <div className='row fs-row'>
          <div className='col-12 mb-3'>
            <label className='bs-label-form'>Situation matrimoniale</label>
            <div className='situation-button'>
              <button
                disabled={readOnlyInput}
                type='button'
                className={`celibataire ${
                  matrimonialStatus.celibataire ? "active" : ""
                }`}
                onClick={isCelibataire}
              >
                <img src={single} alt='' />
                Célibataire
              </button>
              <button
                type='button'
                disabled={readOnlyInput}
                className={`marie ${matrimonialStatus.marie ? "active" : ""}`}
                onClick={isMarie}
              >
                <img src={mariage} alt='' />
                Marié(e)
              </button>
              <button
                type='button'
                disabled={readOnlyInput}
                className={`divorce ${
                  matrimonialStatus.divorce ? "active" : ""
                }`}
                onClick={isDivorce}
              >
                <img src={separe} alt='' />
                Divorcé(e)
              </button>
              <button
                type='button'
                disabled={readOnlyInput}
                className={`veuf ${matrimonialStatus.veuf ? "active" : ""}`}
                onClick={isVeuf}
              >
                <Icon width={20} icon='clarity:times-line' />
                Veuf(ve)
              </button>
            </div>
          </div>
        </div>

        {matrimonialStatus.celibataire && (
          <div className='row fs-row'>
            <div className='col-12 mb-3'>
              <label className='bs-label-form'>
                Domicilié à <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerCelibaDomicile'
                autoComplete='off'
                placeholder='Domicile'
                disabled={readOnlyInput}
                onChange={formik.handleChange}
                value={formik.values.sellerCelibaDomicile}
              ></input>
              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerCelibaDomicile'
                simple={true}
                mt={5}
                mb={0}
              />
            </div>
            <div className='col-12 mb-3'>
              <label className='bs-label-form'>
                Information complémentaires
              </label>
              <textarea
                name='sellerCelibaInfosPlus'
                disabled={readOnlyInput}
                className='form-control bs-input-custom'
                placeholder='Information complémentaires'
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.sellerCelibaInfosPlus}
              ></textarea>
            </div>
          </div>
        )}

        {matrimonialStatus.marie && (
          <div className='row fs-row'>
            <div className='col-lg-4 col-md-4 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Régime matrimonial <sup>*</sup>
              </label>

              <select
                name='sellerMarieRegime'
                id='inputState'
                className='form-select bs-input-custom'
                placeholder='Régime matrimonial'
                onChange={formik.handleChange}
                disabled={readOnlyInput}
                value={formik.values.sellerMarieRegime}
              >
                <option value={""}>Choisissez</option>
                <option value={"Régime communautaire"}>
                  Régime communautaire
                </option>
                <option value={"Régime séparatiste"}>Régime séparatiste</option>
                <option value={"Contrat"}>Contrat</option>
              </select>

              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerMarieRegime'
                simple={true}
                mt={5}
                mb={0}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Date de mariage <sup>*</sup>
              </label>
              <input
                type='date'
                className='form-control bs-input-custom'
                name='sellerMarieDate'
                autoComplete='off'
                placeholder='Date de mariage'
                disabled={readOnlyInput}
                onChange={e => {
                  formik.setFieldValue(
                    "sellerMarieDate",
                    new Date(e.currentTarget.value)
                  );
                }}
                value={
                  formik.values.sellerMarieDate
                    ? convert_Date_to_inputDateFormate(
                        formik.values.sellerMarieDate
                      )
                    : ""
                }
              ></input>
              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerMarieDate'
                simple={true}
                mt={5}
                mb={0}
              />
            </div>
            <div className='col-lg-4 col-md-4 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Lieu de mariage <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMarieLieu'
                disabled={readOnlyInput}
                autoComplete='off'
                placeholder='Lieu de mariage'
                onChange={formik.handleChange}
                value={formik.values.sellerMarieLieu}
              ></input>
              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerMarieLieu'
                simple={true}
                mt={5}
                mb={0}
              />
            </div>
            <Divider>Informations de l'époux(se)</Divider>
            <>
              <div className='row fs-row p-0'>
                <div className='col-12 p-0'>
                  <BuyerStepPersonnalInfoForm
                    formik={formik!}
                    typeFile='physic'
                    currentIdPersonne={currentIdPersonne}
                    currentEntryPersonalInfo={currentEntryPersonalInfo}
                    hiddenBoutonSubmit={true}
                    readOnlyInput={readOnlyInput}
                  />
                </div>
              </div>
            </>
          </div>
        )}

        {matrimonialStatus.divorce && (
          <div className='row fs-row'>
            <div className='col-12 mb-3'>
              <label className='bs-label-form'>
                Remarié(e) <sup>*</sup>
              </label>
              <div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    disabled={readOnlyInput}
                    name='sellerDivorceRemarie'
                    id='inlineRadio1'
                    checked={formik.values.sellerDivorceRemarie === "Oui"}
                    onChange={formik.handleChange}
                    // (e) => {
                    //   if(e.target.checked)
                    //     formik.setFieldValue('sellerDivorceRemarie', )
                    // }
                    value={"Oui"}
                  />
                  <label className='form-check-label bs-label-form'>Oui</label>
                </div>
                <div className='form-check form-check-inline'>
                  <input
                    className='form-check-input'
                    type='radio'
                    disabled={readOnlyInput}
                    name='sellerDivorceRemarie'
                    id='inlineRadio2'
                    checked={formik.values.sellerDivorceRemarie === "Non"}
                    onChange={formik.handleChange}
                    value={"Non"}
                  />
                  <label className='form-check-label bs-label-form'>Non</label>
                </div>
              </div>
              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerDivorceRemarie'
                simple={true}
                mt={0}
                mb={0}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Instance ayant prononcé le divorce <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerDivorceLieu'
                autoComplete='off'
                disabled={readOnlyInput}
                placeholder='Instance ayant prononcé le divorce'
                onChange={formik.handleChange}
                value={formik.values.sellerDivorceLieu}
              ></input>
              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerDivorceLieu'
                simple={true}
                mt={5}
                mb={0}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Date de divorce <sup>*</sup>
              </label>
              <input
                type='date'
                className='form-control bs-input-custom'
                name='sellerDivorceDate'
                autoComplete='off'
                placeholder='Date de divorce'
                disabled={readOnlyInput}
                onChange={e => {
                  formik.setFieldValue(
                    "sellerDivorceDate",
                    new Date(e.currentTarget.value)
                  );
                }}
                value={
                  formik.values.sellerDivorceDate
                    ? convert_Date_to_inputDateFormate(
                        formik.values.sellerDivorceDate
                      )
                    : ""
                }
              ></input>
              <FormErrorDisplayer
                touched={formik.touched}
                errors={formik.errors}
                name='sellerDivorceDate'
                simple={true}
                mt={5}
                mb={0}
              />
            </div>

            {formik.values.sellerDivorceRemarie === "Oui" && (
              <div className='col-12 mb-3 p-0'>
                <Divider>Informations de l'époux(se)</Divider>
                {/* <div className="col-12 p-0"> */}
                <BuyerStepPersonnalInfoForm
                  formik={formik!}
                  currentIdPersonne={currentIdPersonne}
                  currentEntryPersonalInfo={currentEntryPersonalInfo}
                  hiddenBoutonSubmit={true}
                  readOnlyInput={readOnlyInput}
                />
              </div>
            )}

            {formik.values.sellerDivorceRemarie === "Non" && (
              <div className='col-12 mb-3'>
                <label className='bs-label-form'>
                  Information complémentaires
                </label>
                <textarea
                  name='sellerDivorceInforPlus'
                  disabled={readOnlyInput}
                  className='form-control bs-input-custom'
                  placeholder='Information complémentaires'
                  rows={4}
                  onChange={formik.handleChange}
                  value={formik.values.sellerDivorceInforPlus}
                ></textarea>
              </div>
            )}
          </div>
        )}

        {matrimonialStatus.veuf && (
          <div className='row fs-row'>
            <div className='col-12 mb-3'>
              <label className='bs-label-form'>
                {" "}
                Information complémentaires
              </label>
              <textarea
                name='sellerVeufInfosPlus'
                disabled={readOnlyInput}
                className='form-control bs-input-custom'
                placeholder='Information complémentaires'
                rows={4}
                onChange={formik.handleChange}
                value={formik.values.sellerVeufInfosPlus}
              ></textarea>
            </div>
          </div>
        )}

        <div className='modify-button'>
          {!currentIdPersonne && !readOnlyInput && (
            <Button type='submit' variant='contained' color='primary'>
              ENREGISTRER & CONTINUER
            </Button>
          )}
          {currentIdPersonne && !readOnlyInput && (
            <Button type='submit' variant='contained' color='primary'>
              ENREGISTRER
            </Button>
          )}
        </div>
      </div>
    </form>
  );
};

export default StepMaritalStatusForm;
