import { Route, Routes } from "react-router-dom";

import { routePaths } from "@/config";

import LCommunautes from "../layouts/Communautes/Communautes.layout";

import ColleaguesPage from "../pages/Community/Colleagues.page";
import PartnersPage from "../pages/Community/Partners.page";

export const CommunautesRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<LCommunautes />}>
        <Route
          path={routePaths.adminChildren.communautesChildren.confrereCommunaute}
          element={<ColleaguesPage />}
        />
        <Route
          path={
            routePaths.adminChildren.communautesChildren.partenaireCommunaute
          }
          element={<PartnersPage />}
        />
      </Route>
    </Routes>
  );
};
