import { useState } from "react";
import {
  Alert,
  Box,
  Tab,
  Tabs,
} from "@mui/material";
import _ from "lodash";
import iconeTribunaux from "@/assets/images/svg/scales_of_justice.svg";
import iconeTaxi from "@/assets/images/svg/tax.svg";
import iconeHouse from "@/assets/images/svg/houses.svg";
import iconeAgentImmobilier from "@/assets/images/svg/architec.svg";
import Panel from "@/components/UICs/Panel/Panel.uic";
import PartenaireForms from "../../forms/Partenaire/Partenaire.form";
import React from "react";
import HistoryClients from "./HistoryClients/HistoryClients.container";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const CClients = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  
  return (
    <>
      <div className="partenaire-content">
        <Box sx={{marginTop: 2, marginBottom: 2}}>
          <Alert className="alert-info" severity="success" icon={false}>
            <b> Retrouvez tous vos clients</b>
            <br />
            {/* <span>
              {" "}
              Rechercher une étude à partir de son nom ou de sa situation
              géographique.
            </span> */}
          </Alert>
        </Box>
        <Box className="admin-tabs" sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'background.paper' }}>
          <Tabs
            className="setting-tabs"
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
          >
            <Tab label="Personnes physiques" />
            <Tab label="Personnes morales" />
          </Tabs>
          <TabPanel value={value} index={0}>
            <HistoryClients isPersonMoral={false} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <HistoryClients isPersonMoral={true} />
          </TabPanel>
        </Box>
      </div>

    </>
  );
};

export default CClients;
