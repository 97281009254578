import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";

export const getAllClient = (): Promise<IApiResponse> => {
  return axios2.get("/client");
};

export const getPersonneMoral = (queryText?: string): Promise<IApiResponse> => {
  return axios2.get(`/client-morale/${queryText ? queryText : ""}`);
};

export const getPersonnePhysique = (
  queryText?: string
): Promise<IApiResponse> => {
  return axios2.get(`/client-physique/${queryText ? queryText : ""}`);
};

export const getFilesList = (idUser: string): Promise<IApiResponse> => {
  return axios2.get(`/files/${idUser}`);
};

export const addFileToClient = (
  client_id: string,
  data?: any
): Promise<IApiResponse> => {
  return axios2.post(`/client/${client_id}/add-files`, data);
};
