import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";
import { TProperty } from "../types";

export const createProperty = (data: TProperty): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/bien/add", toSend);
};

export const updateProperty = (
  data: TProperty,
  bienId: string
): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/bien/update/${bienId}`, toSend);
};

export const getAllProperty = (): Promise<IApiResponse> => {
  return axios2.get("/property");
};

export const getPropertiesByFolderId = (
  folderId: string
): Promise<IApiResponse> => {
  return axios2.get(`/bien/${folderId}`);
};

// export const getActorByTypeProperty = (
//   idTypeActor: string
// ): Promise<IApiResponse> => {
//   return axios2.get("/actor/actor-type/" + idTypeActor);
// };

export const deleteProperty = (docId: string): Promise<IApiResponse> => {
  return axios2.delete(`/bien/${docId}`);
};

export const getAllTypeProperty = (): Promise<IApiResponse> => {
  return axios2.get("/property-type");
};

export const getAllCommune = (): Promise<IApiResponse> => {
  return axios2.get("/commune");
};

export const addFileToProperty = (
  property_id: string,
  data?: any
): Promise<IApiResponse> => {
  // const toSend = {
  //   ...data,
  //   app_secret: AppCredentials.APP_SECRET,
  //   app_id: AppCredentials.APP_ID,
  //   user_id: user_id,
  // };
  return axios2.post(`/bien/${property_id}/upload`, data);
};
