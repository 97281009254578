import React from "react";
import { Outlet } from "react-router-dom";

import "./Administration.styles.scss";

const LAdministration: React.FC = () => {
  return (
    <div className="ct-administration">
      <Outlet />
    </div>
  );
};

export default LAdministration;
