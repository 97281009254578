import "./ChartCircleFS.style.scss";
import { CSSProperties } from "react";

type TProps = {
  value: any;
  color: string;
  width: string;
  border: string;
};
const ChartCircle: React.FC<TProps> = ({ value, border, color, width}) => {
  return (
    <>
        <div className="pie animate" style={{ "--p": `${value}`, "--b": `${border}`, "--c": `${color}`, "--w": `${width}` } as CSSProperties}>{value}%</div>
    </>
  );
};
export default ChartCircle;
