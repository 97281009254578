import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import _ from "lodash";

import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import confrereBalanceIcon from "@/assets/images/svg/confrere_balance_icon.svg";
import confrereHatIcon from "@/assets/images/svg/confrere_hat_icon.svg";
import confrerePlumeIcon from "@/assets/images/svg/confrere_plume_icon.svg";
import BoxConfrereInfo from "../../Elements/BoxConfrereInfo/BoxConfrereInfo.element";
import Panel from "@/components/UICs/Panel/Panel.uic";
import ConfrereForms, {
  TConfrereFormsValues,
} from "../../forms/Confrere/Confrere.form";
import React from "react";
import { createConfrere, getAllConfrere } from "../../api/confrere.api";
import { TConfrere } from "../../types/types";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { formatDate } from "@/utils/formatDate";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";

const CListColleagues = () => {
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [ConfrereData, seTConfrereData] = useState<TConfrere[]>([]);
  const [ConfrereDataList, seTConfrereDataList] = useState<TConfrere[]>([]);
  const [currentConfrere, setCurrentConfrere] = useState<TConfrere>();
  const [filterValue, setFilterValue] = useState("");

  const getConfrere = async () => {
    const { data } = await getAllConfrere();
    const dataWithNewObjectKeys: TConfrere[] = data.map((confrere: any) => {
      return {
        id: confrere._id,
        libelle: upperFirstWordLetter(confrere.libelle),
        nbre_associe: confrere.nbre_associe,
        situation_geo: confrere.situation_geo,
        description: confrere.description,
        created_date: formatDate(confrere.created_date),
      };
    });
    seTConfrereData(dataWithNewObjectKeys);
    seTConfrereDataList(dataWithNewObjectKeys);
  };

  const refreshConfrere = async () => {
    await getConfrere();
  };

  useEffect(() => {
    getConfrere();
  }, []);

  const saveItem = async (
    Confrere: TConfrereFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    // console.log("Confrere ", Confrere);
    const response = await createConfrere(Confrere);

    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Type d'acteur créée avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshConfrere();
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TConfrereFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TConfrereFormsValues>
  ) => {
    // alert(JSON.stringify(values, null, 2));
    // console.log(" values ", values);
    saveItem(values, setSubmitting);
  };

  const handleFilter = (value: string) => {
    // let searchVal = value;
    const val = value?.toLowerCase();
    // filtrer vos données
    const temp = ConfrereDataList.filter((d: any) => {
      return (
        // Confrères
        (d.libelle ? d.libelle.toLowerCase().indexOf(val) !== -1 : null) || !val
      );
    });
    // Mise à des lignes du tableau
    seTConfrereData(temp);
  };

  useEffect(() => {
    if (filterValue) {
      handleFilter(filterValue);
    } else {
      seTConfrereData(ConfrereDataList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValue, ConfrereDataList]);

  console.log("filterValue: ", filterValue);
  console.log("ConfrereData: ", ConfrereDataList);

  return (
    <>
      <div className='confrere-content'>
        <Box>
          <Alert className='alert-info' severity='success' icon={false}>
            <b> Retrouvez tous vos confrères</b>
            <br />
            <span>
              Rechercher une étude à partir de son nom ou de sa situation
              géographique.
            </span>
          </Alert>
        </Box>

        <Box>
          <Grid container rowSpacing={4} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <div className='confrere-header'>
                <TextField
                  id='input-with-icon-textfield'
                  label=''
                  placeholder='Rechercher'
                  className='search-input'
                  onChange={e => setFilterValue(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  variant='standard'
                />

                <Button
                  color='primary'
                  variant='contained'
                  title='Créer un confrère'
                  onClick={e => setOpenPanel(true)}
                  disableElevation
                  startIcon={<AddCircleOutlineIcon />}
                >
                  Créer un confrère
                </Button>
              </div>

              <div className='confrere-body'>
                <div className='liste-confrere'>
                  {ConfrereData.map((item: any, index: any) => (
                    <BoxConfrereInfo
                      rows={item}
                      onClick={() => {
                        setIsOpenDetail(true);
                        setCurrentConfrere(item);
                      }}
                      key={index}
                      refreshConfrere={refreshConfrere}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création de confrère"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <ConfrereForms
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshConfrere={refreshConfrere}
          handleSubmit={handleSubmit}
        />
      </Panel>

      <Panel
        toggleOpen={isOpenDetail}
        hasBackDrop={true}
        title={"Détails de confrère"}
        closeSidePanel={() => {
          setIsOpenDetail(false);
        }}
      >
        <div className='detail-container'>
          <div className='detail-logo'>
            <img src={confrereBalanceIcon} alt='' />
          </div>
          <div className='detail-body'>
            <div className='detail-header'>
              <span className='detail-title'>{currentConfrere?.libelle}</span>
              <span>Tel : +225 01 70707070, +225 0760606060</span>
              <span>Email : etude-notariale-loba@gmail.com </span>
            </div>

            <div className='detail-member'>
              <span>Membres</span>
              <div className='member-item'>
                <img src={confrereHatIcon} alt='' />
                <div>
                  <span>Maitre LOBA Jean-Claude</span>
                  <small>Notaire</small>
                </div>
              </div>
              <div className='member-item'>
                <img src={confrerePlumeIcon} alt='' />
                <div>
                  <span>Maitre LOBA Jean-Claude</span>
                  <small>Clerc</small>
                </div>
              </div>
            </div>

            <div className='files-number'>
              <span>Nombres de dossiers traités</span>
              <div>49</div>
            </div>

            <div className='localisation'>
              <span>Localisation</span>
              <small>{currentConfrere?.situation_geo}</small>
            </div>
            <div className='localisation'>
              <span>Description</span>
              <small>{currentConfrere?.description}</small>
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

export default CListColleagues;
