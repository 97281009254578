import { Route, Routes } from "react-router-dom";

import { routePaths } from "@/config";

import LDossier from "../layouts/Dossiers/Dossier.layout";

import FolderDashboardPage from "../pages/Dossier/FolderDashboard.page";
import CreateFolderPage from "../pages/Dossier/CreateFolder.page";
import BienImmobilierPage from "../pages/Dossier/BienImmobilier.page";

const DossiersRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<LDossier />}>
        <Route path="" element={<FolderDashboardPage />}>
          <Route
            path={routePaths.adminChildren.folderChildren.createFolder.replace(
              "/",
              ""
            )}
          >
            <Route index element={<CreateFolderPage />} />
            <Route
              path={routePaths.adminChildren.folderChildren.createFolderBienImmobilier.replace(
                "/",
                ""
              )}
              element={<BienImmobilierPage />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default DossiersRoutes;
