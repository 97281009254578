import React, { FC, useEffect, useState } from "react";
import { DataGrid, frFR, GridColDef, GridRenderCellParams, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  // Pagination,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import Panel from "@/components/UICs/Panel/Panel.uic";

import UserForms, {
  TUserFormsValues,
} from "@/features/admin/forms/User/User.form";

import "./SettingBasic.style.scss";
import {
  createUser,
  getAllUsers,
  updateUser,
} from "@/features/admin/api/user.api";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { TUser } from "@/features/admin/types";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";

const rowsPerPage = 7;

type DropdownMenuProps = {
  currentUser: any;
  refreshUser: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({ currentUser, refreshUser }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteUser = () => {
    console.log(currentUser);
  };

  const updateItem = async (
    values: TUserFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log({ currentUser });
    const toSend = {
      _id: currentUser!.id,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: values.phone_number,
    };
    const response = await updateUser(toSend);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Compte modifié avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshUser();
      console.table(response);
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TUserFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TUserFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonSearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Consulter</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem onClick={deleteUser}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Mise à jour de L'utilisateur"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <UserForms
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          currentUser={currentUser}
          isForUpdate={true}
          refreshUser={refreshUser}
          handleSubmit={handleSubmit}
        />
      </Panel>
    </>
  );
};


const CSettingBasic = () => {

    const columns: GridColDef[] = [
        {
          field: "lastname",
          headerName: "Nom",
          flex: 1,
          sortable: true,
        },
        { field: "firstname", headerName: "Prénoms", flex: 1, sortable: true },
        {
          field: "email",
          headerName: "Email",
          flex: 1,
          sortable: true,
        },
        {
          field: "phone",
          headerName: "Numéro de téléphone",
          flex: 1,
          sortable: true,
        },
        {
          field: "created_at",
          headerName: "Date de création",
          flex: 1,
          sortable: true,
        },
        {
          field: "actions",
          headerName: "",
          width: 60,
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;
            return <DropdownMenu currentUser={row} refreshUser={refreshUser} />;
          }, //TODO : check params
        },
      ];
    
      const [openPanel, setOpenPanel] = useState<boolean>(false);
      // const [page, setPage] = useState(1);
      const [pageSize, setPageSize] = useState<number>(rowsPerPage);
      const [usersData, setUsersData] = useState<TUser[]>([]);
      const [loadUser, setLoadUser] = useState(true);
    
      // const pageChange = (event: any, value: any) => {
      //   setPage(value);
      // };
    
      const getUsers = async () => {
        const { data } = await getAllUsers();
        const dataWithNewObjectKeys: TUser[] = data.map((user: any) => {
          return {
            id: user._id,
            firstname: user.first_name.toUpperCase(),
            lastname: upperFirstWordLetter(user.last_name),
            email: user.email,
            phone: `${user.phone_number}`,
            profile_id: user.profile_id ? user.profile_id : null,
            create_ad: "",
          };
        });
        setUsersData(dataWithNewObjectKeys);
      };
    
      const refreshUser = async () => {
        setLoadUser(true);
        await getUsers();
        setLoadUser(false);
      };
    
      useEffect(() => {
        getUsers();
      }, [loadUser]);
    
      const saveItem = async (
        user: TUserFormsValues,
        setSubmitting: (isSubmitting: boolean) => void
      ) => {
        const response = await createUser(user);
    
        if (response.error) {
          ToastError.fire({ text: response.message, timer: 9000 });
        } else {
          ToastGeneric("Compte crée avec succès", "success", 3000).fire();
          setOpenPanel(false);
          refreshUser();
        }
        setSubmitting(false);
      };
      const handleSubmit = (
        values: TUserFormsValues,
        { resetForm, setSubmitting }: FormikHelpers<TUserFormsValues>
      ) => {
        // alert(JSON.stringify(values, null, 2));
        console.log(" values ", values);
        saveItem(values, setSubmitting);
      };

    return(
        <>
      <div className="c-list-users">
        <div className="utilisateur-content">
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div className="utilisateur-table-part">
                  <div className="utilisateur-table-body">
                    <Box sx={{ height: 500, width: "100%" }}>
                      <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        rows={usersData}
                        columns={columns}
                        // hideFooterPagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        disableColumnMenu
                        pageSize={pageSize}
                        rowsPerPageOptions={[7, 15, 30]}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        sx={{
                          fontFamily: "'Raleway',sans-serif !important",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#ececec",
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-columnHeaderTitle": {
                            color: "var(--ui-primary)",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
                            {
                              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
                            },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            minHeight: "5px",
                          },
                          "& .MuiTablePagination-toolbar": {
                            "& > p": {
                              marginBottom: 0,
                            },
                          },
                        }}
                      />
                      {/* <div className="pagination">
                        <Pagination
                          count={Math.ceil(10 / rowsPerPage)}
                          page={page}
                          onChange={pageChange}
                          showFirstButton
                          showLastButton
                          variant="outlined"
                          shape="rounded"
                        />
                      </div> */}
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création d'utilisateur"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <UserForms
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshUser={refreshUser}
          handleSubmit={handleSubmit}
        />
      </Panel>
    </>
    );
};

export default CSettingBasic;