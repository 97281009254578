import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import { TThemeColors } from "@/utils/convertThemeColorsToRootColors";

export const SideMenuHeader = styled.button`
  height: 45px;
  background: #ffffff;
  border: none;
  /* color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary}; */
  color: #AAA59A;
  font-weight: 500;
  font-size: 14px;
  width: 75%;
  text-align: left;
  cursor: pointer;
  padding-left: 18px;
`;

const sideMenuLink = css`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
  height: 45px;
  width: 75%;
  padding-left: 18px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 5px 30px #EBEBEF;
  transition: all 300ms ease-in-out;

  &:hover{
    color: var(--ui-primary)
  }
`;

export const SideMenuContainer = styled.div<{
  duration: number;
  open: boolean;
}>`
  position: relative;
  color: #fff;
  box-shadow: 0px 5px 30px #EBEBEF;
  border-radius: 8px;
  width: 75%;
  height: 45px;
  overflow: hidden;

  ${({ duration }) =>
    `transition: height ${duration / 1000}s ease, margin-bottom ${
      duration / 1000
    }s ease, margin-top 0.05s ease`};

  ${({ open }) => {
    if (open) {
      return css`
        // margin-top: 1rem;
        // margin-right: 1rem;
        background-color: ${(props) =>
          (props.theme.colors.ui as TThemeColors).light};

        ${SideMenuHeader.toString()} {
          color: ${(props) =>
            (props.theme.colors.ui as TThemeColors).secondary} !important;
        }
      `;
    }
  }}

  ${SideMenuHeader.toString()} {
    height: 45px;
    // background: #fff;
    border: none;
    // color: ${(props) => (props.theme.colors.ui as TThemeColors).tertiary};
    // color: #AAA59A;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      right: -8px;
      width: 3.6px;
      height: 72%;
      border-radius: 2.5px;
      background: ${(props) =>
        (props.theme.colors.ui as TThemeColors).primary} !important;
    }

    .start-icon {
      font-size: 18px;
      font-weight: 200;
      margin-right: 15px;
      color: var(--ui-secondary);
    }

    .end-icon {
      float: right;
      // transform: translateY(0px);
      transform: translate(-10px, 3px);
      color: var(--ui-secondary);
    }
  }

  ul {
    list-style-type: none;
    margin-bottom: 1rem;

    & > li a {
      display: block;
      color: ${(props) => (props.theme.colors.ui as TThemeColors).secondary};
      padding: 0.75rem 0;
      padding-left: 18px;
      font-size: 14px;
      font-weight: 500;
      color: var(--ui-secondary);
    }

    & > li a.active {
      font-weight: 500;
      color: ${(props) => (props.theme.colors.ui as TThemeColors).primary};
    }

    & > li:not(:last-child) {
      border-bottom: 1px solid rgba(196, 196, 196, 0.53);
    }
  }
`;

export const SideMenuLinkContainer = styled(Link)<{
  active: string;
  down_lg: string;
}>`
  ${sideMenuLink};

  ${({ active, down_lg }) =>
    down_lg === "false" && active === "true" &&
    css`
      &::after {
        content: "";
        position: absolute;
        right: -8px;
        width: 3.6px;
        height: 72%;
        border-radius: 2.5px;
        background: ${(props) =>
          (props.theme.colors.ui as TThemeColors).primary} !important;
      }

      background: #ffffff;
      box-shadow: 0px 5px 30px #EBEBEF;
      color: ${(props) =>
        (props.theme.colors.ui as TThemeColors).primary} !important;
    `};

    ${({ active, down_lg }) =>
    down_lg === "true" && active === "true" &&
    css`
      &::before {
        display:none ;
      }

      &::after {
        display:none ;
      }

      border-radius: 30px;
      background: ${(props) =>
        (props.theme.colors.ui as TThemeColors).light};
      color: ${(props) =>
        (props.theme.colors.ui as TThemeColors).medium} !important;
    `};

    svg {
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
    }
`;
