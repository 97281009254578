import {
  IconButton,
  Button,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import EditorText from "./EditorText";
import * as _ from "lodash";
import "./DeedOfSale.styles.scss";
import { useEffect, useRef, useState } from "react";
import React from "react";
import { TBillSale, TConfrere, TTextTemplate } from "../../types";
import { getAllTextTemplate } from "../../api/TextTemplate.api";
import { Loader } from "@/components/Loader/Loader";
import Swal from "sweetalert2";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { createBillSale } from "../../api/folder.api";
import { getAllConfrere } from "../../api/confrere.api";
import { Icon } from "@iconify/react";
import ListBillSaleByFolder from "../../Elements/ListBillSaleByFolder/ListBillSaleByFolder.element";
import { formatDate } from "@/utils/formatDate";
import CustomSelect from "@/components/UICs/Select/CustomSelect";

type TPropsDeedOfSale = {
  currentFolder: any;
  setRefreshDataCurrentFolder?: React.Dispatch<React.SetStateAction<string>>;
  readOnlyInput?: boolean;
};

const DeedOfSaleForm: React.FC<TPropsDeedOfSale> = ({
  currentFolder,
  setRefreshDataCurrentFolder,
  readOnlyInput,
}) => {
  const [listModelsText, setlistModelsText] = useState<TTextTemplate[]>([]);
  const listAcquereurs = currentFolder?.buyers_ids;
  const listActors = currentFolder?.folders_actors;
  const [listConfreres, setListConfreres] = useState<TConfrere[]>([]);
  const [optionsListModel, setOptionsListModel] = useState<any[]>([]);
  const [optionsListConfrere, SetOptionsListConfrere] = useState<any[]>([]);

  const [refreshBillSale, setRefreshBillSale] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formatDataToSendEditor, setFormatDataToSendEditor] = useState<any>();
  const [billSaleDataToSend, setBillSaleDataToSend] = useState<TBillSale>();
  const [billSaleToUpdate, setBillSaleToUpdate] = useState<any>();
  const [open, setOpen] = React.useState(false);
  const [isFullScreen, setIsFullScreen] = React.useState(false);

  const [isClearEditorText, setIsClearEditorText] = useState<boolean>(false);

  // default value
  const [defautlValueModelText, setDefautlValueModelText] = useState<any[]>([]);
  const [defautlValueBuyers, setDefautlValueBuyers] = useState<any[]>([]);
  const [defautlValueActors, setDefautlValueActors] = useState<any[]>([]);
  const [defautlValueConfrere, setDefautlValueConfrere] = useState<any[]>([]);

  // Create useRef to grab custom Select
  const customSelectRef = useRef([]);

  const fullScreenFunction = () => {
    setIsFullScreen(!isFullScreen);
  };
  let optionsListAcquereurs =
    listAcquereurs &&
    listAcquereurs.map((buyer: any) => {
      const { company_infos, personal_infos } = buyer;
      return {
        value: buyer,
        label: company_infos
          ? `${company_infos?.denomination}`
          : `${personal_infos?.first_name} ${personal_infos?.last_name}`,
      };
    });
  let optionsListActor =
    listActors &&
    listActors.map((actor: any) => {
      return { value: actor, label: actor.actor_libelle };
    });

  /** Recuperation des buyer selectionné */
  const handleChange = (option: any, select: any) => {
    const name = select?.name;
    console.log({ option });
    const isArray = option && Array.isArray(option);
    const options = isArray ? option : option?.value;
    let multipleValue =
      isArray &&
      options.map(({ value }: any) => {
        return value;
      });

    setFormatDataToSendEditor((values: any) => ({
      ...values,
      [name]: isArray ? JSON.stringify(multipleValue) : JSON.stringify(options),
    }));
  };

  const handleClickOpenActeVente = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTextTemplate = () => {
    getAllTextTemplate()
      .then(response => {
        if (!response.error) {
          setlistModelsText(response.data);

          const optionsListModelData = response.data.map((modelText: any) => {
            return { value: modelText, label: modelText.name };
          });
          setOptionsListModel(optionsListModelData);
        }
      })
      .then(() => {})
      .catch(error => {
        setIsLoading(false);
      });
  };

  const getConfrere = () => {
    getAllConfrere()
      .then(response => {
        if (!response.error) {
          setListConfreres(response.data);
          const optionsListConfrereData = response.data.map((confrere: any) => {
            return { value: confrere, label: confrere.libelle };
          });
          SetOptionsListConfrere(optionsListConfrereData);
          setIsLoading(false);
        }
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const confirmBillSale = () => {
    if (
      !billSaleDataToSend ||
      !billSaleDataToSend?.bill_sale_model_id ||
      !billSaleDataToSend.buyers_ids ||
      JSON.parse(formatDataToSendEditor.buyers).length === 0
    ) {
      ToastError.fire({
        text: "Veuillez sélectionner le modèle de text et les acquéreurs",
        timer: 9000,
      });
      return;
    }

    Swal.fire({
      title: "Acte de vente",
      text:
        "Vous êtes sur le point de " +
        (billSaleDataToSend?._id || billSaleDataToSend?.id
          ? "modifier"
          : "enregistrer") +
        " un acte de vente " +
        "\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        setIsLoading(true);
        saveBillSale();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const saveBillSale = () => {
    setIsLoading(true);
    if (billSaleDataToSend) {
      billSaleDataToSend.reference = billSaleToUpdate?.reference || "";
    }
    console.log({ billSaleDataToSend });

    createBillSale(billSaleDataToSend as TBillSale)
      .then(response => {
        setIsLoading(false);
        if (!response.error) {
          ToastGeneric(
            "Opération effectuée avec succès !",
            "success",
            9000
          ).fire();

          /** On recupere l'element qu'on vient d'enregistrer car l'utilisateur peux tjrs modifier */
          gotoCreateNewActe();

          // if (
          //   response.data &&
          //   response?.data?.bill_sale &&
          //   response?.data?.bill_sale?.length > 0
          // ) {
          //   // console.log(
          //   //   response?.data?.bill_sale[response?.data?.bill_sale?.length - 1]
          //   // );
          //   // setBillSaleToUpdate(
          //   //   response?.data?.bill_sale[response?.data?.bill_sale?.length - 1]
          //   // );

          //   // On vide la liste des
          //   gotoCreateNewActe();
          // }

          setRefreshDataCurrentFolder?.(new Date().getTime().toString());
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
        }
      })
      .catch(error => {
        console.log(" error ", error);
        setIsLoading(false);
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({ text: error, timer: 9000 });
        }
      });
  };

  const gotoCreateNewActe = async () => {
    // clear Text editor
    setIsClearEditorText(true);

    let currentBillSale = { ...billSaleToUpdate };
    currentBillSale.reference = "";
    currentBillSale.id = null;
    currentBillSale._id = null;
    currentBillSale.isVisualisation = false;

    setFormatDataToSendEditor({
      modeleText: "",
      buyers: "",
      actors: "",
      colleagues: [],
      reference: null || "",
      folder_reference: currentFolder?.reference,
      _id: null,
      id: null,
      description: null,
    });

    console.log(" currentBillSale ", currentBillSale);

    setBillSaleToUpdate(currentBillSale);

    // empty custom select value

    customSelectRef.current.forEach((select: any) => {
      select.clearValue();
    });
  };

  useEffect(() => {
    setIsLoading(true);
    getTextTemplate();
    getConfrere();
  }, []);

  useEffect(() => {
    setRefreshDataCurrentFolder?.(new Date().getTime().toString());
    // eslint-disable-next-line
  }, [refreshBillSale]);

  // verify if we are in update before set buyers  , actors , sellers.

  const isOnModifyMode =
    formatDataToSendEditor?.id || formatDataToSendEditor?._id;

  useEffect(() => {
    /** En ce moment nous sommes en modification */
    // console.log(" billSaleToUpdate deed of sale",billSaleToUpdate);

    let modelText = isOnModifyMode
      ? _.find(listModelsText, o => {
          return o._id === billSaleToUpdate?.bill_sale_model_id;
        })
      : "";
    let buyers =
      billSaleToUpdate?.buyers_ids &&
      isOnModifyMode &&
      billSaleToUpdate?.buyers_ids.length > 0
        ? _.filter(listAcquereurs, o => {
            return o._id === billSaleToUpdate.buyers_ids[0];
          })
        : "";
    let actors =
      billSaleToUpdate?.actors_ids &&
      isOnModifyMode &&
      billSaleToUpdate?.actors_ids.length > 0
        ? _.filter(listActors, o => {
            return o._id === billSaleToUpdate.actors_ids[0];
          })
        : "";
    let colleagues =
      billSaleToUpdate?.confreres_ids &&
      isOnModifyMode &&
      billSaleToUpdate?.confreres_ids.length > 0
        ? _.filter(listConfreres, o => {
            return o._id === billSaleToUpdate.confreres_ids[0];
          })
        : "";

    setFormatDataToSendEditor((values: any) => ({
      ...values,
      modeleText: modelText && JSON.stringify(modelText),
      buyers: buyers && JSON.stringify(buyers),
      actors: actors && JSON.stringify(actors),
      colleagues: colleagues && JSON.stringify(colleagues),
      reference: billSaleToUpdate?.reference || "",
      folder_reference: currentFolder?.reference,
      _id: billSaleToUpdate?._id,
      id: billSaleToUpdate?.id,
      description: billSaleToUpdate?.description,
    }));

    handleClose();

    // eslint-disable-next-line
  }, [billSaleToUpdate, isOnModifyMode]);

  useEffect(() => {
    // default model text value
    const currentModelText = formatDataToSendEditor?.modeleText;
    const currentBuyers = formatDataToSendEditor?.buyers;
    const currentActors = formatDataToSendEditor?.actors;
    const currentConfreres = formatDataToSendEditor?.colleagues;

    console.table(formatDataToSendEditor);

    const defaultModelTextValue = currentModelText && [
      { label: JSON.parse(currentModelText).name, value: currentModelText },
    ];
    const defaultModelBuyers =
      currentBuyers &&
      JSON.parse(currentBuyers)?.map((buyer: any) => {
        const { company_infos, personal_infos } = buyer;
        return {
          value: buyer,
          label: company_infos
            ? `${company_infos?.denomination}`
            : `${personal_infos?.first_name} ${personal_infos?.last_name}`,
        };
      });

    const defaultConfreres =
      currentConfreres &&
      JSON.parse(currentConfreres).map((confrere: any) => {
        return { value: confrere, label: confrere.libelle };
      });

    console.log(defaultModelBuyers);

    setDefautlValueModelText(defaultModelTextValue);
    setDefautlValueBuyers(defaultModelBuyers);
    setDefautlValueConfrere(defaultConfreres);
  }, [formatDataToSendEditor]);

  console.log("readOnlyInput", readOnlyInput);

  return (
    <div className='container-deedofsale'>
      <div className='selected-part'>
        <Loader isLoading={isLoading} />

        <div className='fs-row row'>
          <div className='col-lg-2 col-md-2 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Modèle de text <sup>*</sup>
            </label>

            <CustomSelect
              refValue={customSelectRef}
              values={defautlValueModelText ? defautlValueModelText : []}
              index={0}
              name='modeleText'
              options={optionsListModel}
              placeholder='Choisissez'
              isDisabled={billSaleToUpdate?.isVisualisation || readOnlyInput}
              handleChange={handleChange}
            />
          </div>

          <div className='col-lg-3 col-md-3 col-sm-12 mb-3'>
            <label className='bs-label-form'>
              Acquéreurs <sup>*</sup>
            </label>
            <CustomSelect
              refValue={customSelectRef}
              values={defautlValueBuyers}
              index={1}
              name='buyers'
              options={optionsListAcquereurs}
              placeholder='Choisissez'
              isDisabled={billSaleToUpdate?.isVisualisation || readOnlyInput}
              isMulti={true}
              handleChange={handleChange}
            />
          </div>

          <div className='col-lg-2 col-md-2 col-sm-12 mb-3'>
            <label className='bs-label-form'>Acteurs</label>
            <CustomSelect
              refValue={customSelectRef}
              index={2}
              name='actors'
              options={optionsListActor}
              placeholder='Choisissez'
              isDisabled={billSaleToUpdate?.isVisualisation || readOnlyInput}
              isMulti={true}
              handleChange={handleChange}
            />
          </div>

          <div className='col-lg-2 col-md-2 col-sm-12 mb-3'>
            <label className='bs-label-form'>Confrères</label>
            <CustomSelect
              refValue={customSelectRef}
              values={defautlValueConfrere}
              index={3}
              name='colleagues'
              options={optionsListConfrere}
              placeholder='Choisissez'
              isDisabled={billSaleToUpdate?.isVisualisation || readOnlyInput}
              isMulti={true}
              handleChange={handleChange}
            />
          </div>

          {currentFolder &&
            currentFolder?.bill_sale &&
            currentFolder?.bill_sale.length > 0 && (
              <div className='col-lg-3 col-md-3 col-sm-6 mb-3'>
                <Button
                  className='details-container'
                  type='button'
                  onClick={() => {
                    handleClickOpenActeVente();
                  }}
                  sx={{
                    fontSize: "11px",
                    fontWeight: 600,
                    marginTop: "15px",
                  }}
                  variant='contained'
                  disabled={billSaleToUpdate?.isVisualisation || readOnlyInput}
                >
                  <Icon className='icon' icon='ph:eye' />
                  Visualiser acte de vente
                </Button>
              </div>
            )}
        </div>
      </div>

      <div className={`f-deedofsale ${isFullScreen ? "full-screen" : ""}`}>
        <div className='app-header'>
          <div className='app-header-content'>
            <div className='app-header__back'>
              <IconButton aria-label='delete'>
                <ArrowBackIcon />
              </IconButton>
            </div>
            <div className='app-header__center'>
              <Typography variant='h6' component='div'>
                {billSaleToUpdate?.reference
                  ? "Acte de vente N° " + billSaleToUpdate?.reference
                  : "Redaction d'un nouvel acte de vente"}
              </Typography>
              <Typography variant='body2'>
                {billSaleToUpdate?.created_at &&
                  formatDate(billSaleToUpdate?.created_at)}
              </Typography>
            </div>
          </div>
          <div className='app-header__action'>
            <Button
              type='button'
              onClick={confirmBillSale}
              disabled={billSaleToUpdate?.isVisualisation || readOnlyInput}
              sx={{
                borderRadius: 0,
                fontSize: "11px",
                fontWeight: 600,
              }}
              variant='contained'
            >
              {formatDataToSendEditor?.id || formatDataToSendEditor?._id
                ? "Modifier l'acte de vente"
                : "Enregistrer l'acte de vente"}
            </Button>

            <Button
              type='button'
              onClick={gotoCreateNewActe}
              disabled={
                !billSaleToUpdate?.isVisualisation &&
                !(formatDataToSendEditor?.id || formatDataToSendEditor?._id)
              }
            >
              Nouvel acte de vente
            </Button>

            <div className='zoom'>
              <Button
                className='btn-zoom'
                type='button'
                onClick={fullScreenFunction}
              >
                {!isFullScreen && <Icon icon='octicon:screen-full-24' />}
                {isFullScreen && <Icon icon='octicon:screen-normal-24' />}
              </Button>
            </div>
          </div>
        </div>

        <EditorText
          dataCkeditor={formatDataToSendEditor}
          currentFolder={currentFolder}
          isReadOnly={billSaleToUpdate?.isVisualisation}
          setBillSaleDataToSend={setBillSaleDataToSend}
          isClearEditorText={isClearEditorText}
          setIsClearEditorText={setIsClearEditorText}
        />
      </div>

      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
          maxWidth='lg'
          className='buyerSeller-modal acte-vente'
        >
          <DialogTitle
            id='alert-dialog-title'
            sx={{ padding: "0px 0px 0px 0px" }}
          >
            <div className='fs-modal-header'>
              <span className='fs-modal-title'>
                Actes de vente du dossier {currentFolder?.folder_code}
              </span>
              <button
                type='button'
                className='fs-modal-close-button'
                onClick={handleClose}
              >
                <Icon className='icon' icon='clarity:times-line' />
              </button>
            </div>
          </DialogTitle>
          <DialogContent sx={{ padding: "0px" }}>
            <ListBillSaleByFolder
              readOnlyInput={readOnlyInput}
              billSales={currentFolder?.bill_sale}
              setRefreshDataCurrentFolder={setRefreshBillSale}
              setBillSaleToUpdate={setBillSaleToUpdate}
              folder_reference={currentFolder?.reference}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default DeedOfSaleForm;
