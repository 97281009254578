import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";
import { TTextTemplate } from "../types/types";

export const createTextTemplate = (data: TTextTemplate): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/billsale-models/add", toSend);
};

export const updateTextTemplate = (data: TTextTemplate, id: string): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/billsale-models/update/${id}`, toSend);
};

export const getAllTextTemplate = (): Promise<IApiResponse> => {
  return axios2.get("/billsale-models");
};

export const deleteTextTemplate = (id: string): Promise<IApiResponse> => {
  return axios2.delete(`/billsale-models/${id}`);
};