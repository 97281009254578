import { Navigate, Route, Routes } from "react-router-dom";

// import { routePaths } from "@/config";

import LMain from "../layouts/Main/Main.layout";

import DashbordPage from "../pages/Dashbord.page";
import ProspectPage from "../pages/Prospect.page";

import DossiersRoutes from "./dossiers.routes";
import { CommunautesRoutes } from "./communaute.routes";
import { SubscriptionRoutes } from "./subscription.routes";
import AdministrationRoutes from "./administration.routes";

import CreateFolderPage from "../pages/Dossier/CreateFolder.page";
import BienImmobilierPage from "../pages/Dossier/BienImmobilier.page";
import UpdatePassword from "../pages/UpdatePassword.page";
import Client from "../pages/Client.page";
import Files from "../pages/Files.page";
import { routePaths } from "@/config/routePaths";

const AdminRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="" element={<LMain />}>
        <Route
          index
          element={
            <Navigate
              to={`${routePaths.admin}${routePaths.adminChildren.home}`}
            />
          }
        />
        <Route path={routePaths.adminChildren.home} element={<DashbordPage />}>
          <Route
            path={routePaths.adminChildren.folderChildren.createFolder.replace(
              "/",
              ""
            )}
          >
            <Route index element={<CreateFolderPage />} />
            <Route
              path={routePaths.adminChildren.folderChildren.createFolderBienImmobilier.replace(
                "/",
                ""
              )}
              element={<BienImmobilierPage />}
            />
          </Route>
        </Route>
        <Route
          path={routePaths.adminChildren.prospects}
          element={<ProspectPage />}
        />
        <Route
          path={routePaths.adminChildren.folder + "/*"}
          element={<DossiersRoutes />}
        />
        <Route path={routePaths.adminChildren.client} element={<Client />} />
        <Route path={routePaths.adminChildren.files} element={<Files />} />
        <Route
          path={routePaths.adminChildren.communautes + "/*"}
          element={<CommunautesRoutes />}
        />
        <Route
          path={routePaths.adminChildren.administration + "/*"}
          element={<AdministrationRoutes />}
        />
        <Route
          path={routePaths.adminChildren.subscription + "/*"}
          element={<SubscriptionRoutes />}
        />
        <Route
          path={routePaths.adminChildren.updatePassword}
          element={<UpdatePassword />}
        />
        <Route
          path="/*"
          element={<Navigate to={`${routePaths.adminChildren.homeAbsolute}`} />}
        />
      </Route>
    </Routes>
  );
};

export default AdminRoutes;
