import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import './UpdatePasswordForm.styles.scss';
import { useFormik } from 'formik';
import { apiResetPasswordByUserId } from '@/features/auth/api/auth.api';
import { ToastError, ToastGeneric } from '@/utils/toast';
import { routePaths } from '@/config/routePaths';
import { selectUserConnected } from '@/store/reducers/user/user.selector';
import { InlineIcon } from '@iconify/react';
import { createStructuredSelector } from 'reselect';
import { connect, ConnectedProps } from 'react-redux';
import { store } from '@/store';
import { logoutUser } from '@/store/reducers/user/user.reducer';

type PropsFormRedux = ConnectedProps<typeof connector>;

type TProps = PropsFormRedux;

const ChangePasswordFunc: React.FC<TProps> = ({ userConnected }) => {
  const dispatch = store.dispatch;

  const validationSchema: any = Yup.object({
    old_password: Yup.string().required("L'ancien mot de passe est requis !"),
    new_password: Yup.string().required('Le mot de passe est requis !'),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref('new_password'), null],
      'Veuillez confirmer votre mot de passe !'
    ),
  });

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    onSubmit: async value => {
      apiResetPasswordByUserId('/users/change-password', {
        ...value,
        _id: userConnected._id,
      })
        .then(response => {
          if (response?.error) {
            ToastError.fire({ text: response.message, timer: 9000 });
          } else {
            ToastGeneric(
              `Votre mot de passe a été changé avec succès`,
              'success',
              3000
            ).fire();

            dispatch(logoutUser());
          }
        })
        .catch(error => {
          console.log(error);

          if (error?.response?.data?.message) {
            ToastError.fire({
              text: error?.response?.data?.message,
              timer: 9000,
            });
          } else {
            ToastError.fire({ text: error, timer: 9000 });
          }
        });
    },
  });

  return (
    <div className="update-password-container">
      <div className="fs-form-container">
        <div className="header-part">
          <h1 className="title">Changer son mot de passe</h1>
          <p className="subtitle"> Modifacation </p>
          <small>Veuillez renseigner les champs ci-dessous</small>
        </div>
        <form className="form-part" onSubmit={formik.handleSubmit}>
          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                {' '}
                Anciens mot de passe <sup>*</sup>
              </label>
              <input
                type="password"
                className="form-control bs-input-custom"
                name="old_password"
                autoComplete="off"
                placeholder="Votre ancien mot de passe"
                value={formik.values.old_password}
                onChange={formik.handleChange}
              ></input>
              {formik.errors.old_password && (
                <small className="helper-text">
                  {' '}
                  <InlineIcon icon="ci:error-outline" />{' '}
                  {formik.errors.old_password}{' '}
                </small>
              )}
            </div>
          </div>

          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                Nouveau mot de passe <sup>*</sup>
              </label>
              <input
                type="password"
                className="form-control bs-input-custom"
                name="new_password"
                placeholder="Votre nouveau mot de passe"
                autoComplete="off"
                value={formik.values.new_password}
                onChange={formik.handleChange}
              ></input>
              {formik.errors.new_password && (
                <small className="helper-text">
                  {' '}
                  <InlineIcon icon="ci:error-outline" />{' '}
                  {formik.errors.new_password}{' '}
                </small>
              )}
            </div>
          </div>

          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                Confirmation de mot de passe <sup>*</sup>
              </label>
              <input
                type="password"
                className="form-control bs-input-custom"
                name="confirmPassword"
                placeholder="Confirmez votre mot de passe"
                autoComplete="off"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
              ></input>
              {formik.errors.confirmPassword && (
                <small className="helper-text">
                  {' '}
                  <InlineIcon icon="ci:error-outline" />{' '}
                  {formik.errors.confirmPassword}{' '}
                </small>
              )}
            </div>
          </div>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            sx={{ marginTop: '30px' }}
            // onClick={handleSubmit}
          >
            CHANGER LE MOT DE PASSE
          </Button>
        </form>
      </div>
    </div>
  );
};

const mapStateToPrpos = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToPrpos);
const UpdatePasswordForm = connector(ChangePasswordFunc);

export default UpdatePasswordForm;
