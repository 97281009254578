import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { publicRoutes } from './public.routes';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserConnected } from '@/store/reducers/user/user.selector';
import { protectedRoute } from './protected.routes';

type PropsFromRedux = ConnectedProps<typeof connector>;

const AppRoutesFunc: FC<PropsFromRedux> = ({ userConnected }) => {
  const routes = userConnected ? protectedRoute : publicRoutes;

  const element = useRoutes([...routes]);

  return <>{element}</>;
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const AppRoutes = connector(AppRoutesFunc);

export default AppRoutes;
