import React from 'react';
import { Button } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';

import WithFormikErrorMessage from '@/components/HOCs/WithFormikErrorMessage.hoc';
import AlertMessage from '@/components/UICs/AlertMessage/AlertMessage.uic';

import { TUserProperKeys } from '../../types';

import './User.styles.scss';

export type TUserFormsValues = {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  initials: string;
  job_title: string;
  profile_id: string;
};
const FormErrorDisplayer =
  WithFormikErrorMessage<TUserFormsValues>(AlertMessage);

type UserFormProps = {
  closeSidePanel: () => void;
  refreshUser: () => void;
  handleSubmit: (
    values: TUserFormsValues,
    FormikHelpers: FormikHelpers<TUserFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  currentUser?: TUserProperKeys;
};

const UserForm = ({
  closeSidePanel,
  refreshUser,
  handleSubmit,
  currentUser,
}: UserFormProps): JSX.Element => {
  const validationSchema: any = Yup.object({
    first_name: Yup.string().required('Le prénom est requis'),
    last_name: Yup.string().required('Le nom est requis'),
    email: Yup.string().email().required("L'email est requis"),
    phone_number: Yup.string()
      .matches(
        /\+*\s*\d+/g,
        "Seul des chiffres et le symbole '+' sont attendus"
      )
      .min(10, 'Veuillez rentrer un numéro valide')
      .required('Le numéro est requis'),
    initials: Yup.string().required("L'initail est requis"),
    job_title: Yup.string().required('La fonction est requise'),
    profile_id: Yup.string().required('Le profil est requis'),
  });

  const formik = useFormik({
    initialValues: {
      first_name: currentUser?.firstname || '',
      last_name: currentUser?.lastname || '',
      email: currentUser?.email || '',
      phone_number: currentUser?.phone || '',
      initials: currentUser?.initials || '',
      job_title: currentUser?.job_title || '',
      profile_id: currentUser?.profile_id || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className="form-utilisateur" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Nom <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="last_name"
              autoComplete="off"
              placeholder="Nom(s)"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.last_name}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="last_name"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Prénoms <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="first_name"
              placeholder="Prénom(s)"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.first_name}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="first_name"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Adresse email <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="email"
              placeholder="Adresse email"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="email"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Numéro de télephone <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="phone_number"
              placeholder="Numéro de télephone"
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_number}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="phone_number"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Initial <sup>*</sup>
            </label>
            <input
              type="text"
              className="form-control bs-input-custom"
              name="initials"
              placeholder="Initial"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.initials}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="initials"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Fonction <sup>*</sup>
            </label>

            <select
              className="form-select bs-input-custom"
              name="job_title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.job_title}
            >
              <option value="" disabled>
                Selectionnez une fonction
              </option>
              <option value="clerc">Clerc</option>
              <option value="notaire">Notaire</option>
              <option value="assistant">Assistant</option>
              {/* Mettre le fonction à ajouter en attendant l'ajout dans le back */}
              <option value="formaliste" disabled>
                Formaliste
              </option>
              <option value="comptable" disabled>
                Comptable
              </option>
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="job_title"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Role <sup>*</sup>
            </label>

            <select
              className="form-select bs-input-custom"
              name="profile_id"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.profile_id}
            >
              <option value="" disabled>
                Selectionnez un rôle
              </option>
              <option value="635801913cbf0a001176e171">Administrateur</option>
              <option value="640a233dbf13e100110e2b74">Utilisateur</option>
              {/* <option value="Notaire">Notaire</option>
              <option value="Sécretaire">Sécretaire</option>
              <option value="Le claire">Le claire</option> */}
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="profile_id"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            {!currentUser && (
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                disableElevation
              >
                Annuler
              </Button>
            )}

            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {currentUser ? 'Modifier' : 'Enregistrer'}
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default UserForm;
