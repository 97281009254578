import { CSSProperties, FC, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, frFR } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import './TablePreview.styles.scss';
import { usePersonSelectedIdContext } from '../../contexts/selectedPerson.context';
import { red } from '@mui/material/colors';

interface ITablePreview {
  rows: any;
  // setPersonSelectedId: React.Dispatch<React.SetStateAction<string>>;
  customStyle?: CSSProperties;
  isForPersonPhysique?: boolean;
}

const columnsPersonPhysque: GridColDef[] = [
  { field: 'id', headerName: 'id', width: 30 },
  {
    field: 'nom',
    headerName: 'Nom',
    width: 100,
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'prenoms',
    headerName: 'Prenoms',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
  },

  {
    field: 'telephone',
    headerName: 'Téléphone',
    width: 110,
    sortable: false,
    disableColumnMenu: true,
  },
];
const columnsPersonMoral: GridColDef[] = [
  { field: 'id', headerName: 'id', width: 30 },
  {
    field: 'denomination',
    headerName: 'Dénomination',
    width: 100,
    sortable: false,
  },
  {
    field: 'registration',
    headerName: 'Registration',
    width: 110,
    sortable: false,
  },

  {
    field: 'telephone',
    headerName: 'Téléphone',
    width: 110,
    sortable: false,
  },
];

const TablePreview: FC<ITablePreview> = ({
  rows,
  // setPersonSelectedId,
  customStyle,
  isForPersonPhysique = true,
}) => {
  const { setPersonSelectedId } = usePersonSelectedIdContext();
  const columns = isForPersonPhysique
    ? columnsPersonPhysque
    : columnsPersonMoral;
  const [isRowSelected, setIsRowSelected] = useState<boolean>(false);

  useEffect(() => {
    const thisTablePreview = document.querySelector('.table-preview-wrapper');
    if (!thisTablePreview?.classList.contains('show-table-preview')) {
      setIsRowSelected(false);
      return;
    }
    thisTablePreview?.classList.remove('show-table-preview');
    setIsRowSelected(false);
  }, [isRowSelected]);

  return (
    <div className="table-preview-wrapper" style={customStyle}>
      <Box sx={{ width: '100%' }}>
        <div
          className="table-preview-wrapper__close-wrapper"
          onClick={e => {
            const target = e.target as HTMLDivElement;
            const tablePreview = target.closest('.table-preview-wrapper');
            tablePreview?.classList.remove('show-table-preview');
          }}
        >
          <CloseIcon className="table-preview-wrapper__close" />
        </div>

        <DataGrid
          disableColumnMenu={true}
          columns={columns}
          rows={rows}
          onRowClick={({ row }) => {
            setPersonSelectedId(personId => {
              if (isForPersonPhysique) {
                return { ...personId, physique: row.person_id };
              }
              return { ...personId, moral: row.person_id };
            });
            setIsRowSelected(true);
          }}
          localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
          hideFooterPagination={!(rows.length > 5)}
          rowsPerPageOptions={[5]}
          pageSize={5}
          rowHeight={35}
          autoHeight={true}
          sx={{
            fontSize: '12px',
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: 'var(--ui-primary)',
              borderBottom: 'none',
              position: 'relative',
              maxHeight: 'initial !important',
              minHeight: 'initial !important',
              height: '40px',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              color: '#ececec',
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-row': {
              cursor: 'pointer',
            },

            '& .MuiDataGrid-row:nth-of-type(even)': {
              background: '#f7f7f7',
            },
            '& .MuiDataGrid-row:hover': {
              background: '#e0edee',
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              minHeight: '5px',
            },
            '& .MuiTablePagination-toolbar': {
              '& > p': {
                marginBottom: 0,
              },
            },
            '& .MuiDataGrid-virtualScroller': {
              marginTop: '0px !important',
            },
          }}
        />
      </Box>
    </div>
  );
};

export default TablePreview;
