import React, { useState } from 'react';
import './Header.styles.scss';
import { MenuOpenRounded } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Menu,
  MenuItem,
} from '@mui/material';
// import { styled } from '@mui/material/styles';
import { toggleSidebar } from '@/store/reducers/sidebar/sidebar.actions';
import { store } from '@/store';
import { useNavigate } from 'react-router-dom';
import { routePaths } from '@/config';
import { Icon } from '@iconify/react';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserConnected } from '@/store/reducers/user/user.selector';
import { logoutUser } from '@/store/reducers/user/user.reducer';
import { ToastGeneric } from '@/utils/toast';

type PropsFormRedux = ConnectedProps<typeof connector>;

type TProps = PropsFormRedux & {
  title?: string;
  subtitle?: string;
};

const HeaderFunc: React.FC<TProps> = ({ userConnected }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openLogout, setOpenLogout] = useState(false);
  const handleClickOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = store.dispatch;

  const navigate = useNavigate();

  const logout = async () => {
    await dispatch(logoutUser());
    navigate(routePaths.auth, { replace: true });
  };

  const goTo = (route: string) => {
    navigate(route);
    handleClose();
  };

  return (
    <>
      <div className="c-header">
        <div className="mobile-header">
          {/* <img
            src={require("@/assets/images/svg/logo_app.svg")}
            alt="logo-mini"
            className="logo-mini"
          /> */}

          <div className="fs-profil-header">
            <Button
              className="profil-dropdown"
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <div className="id-photo">
                <img
                  src="https://www.habitatpresto.com/upload/devispresto/3678144__AdobeStock_216672151.jpg"
                  alt=""
                />
              </div>
              <div className="user-names">
                <span className="name">{`${userConnected.first_name} ${userConnected.last_name}`}</span>
              </div>
              <Icon width="12" icon="entypo:chevron-thin-down" />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              elevation={6}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>Mon compte</MenuItem>
              <MenuItem
                onClick={() => {
                  goTo(routePaths.adminChildren.updatePasswordAbsolute);
                }}
              >
                Changer de mot de passe
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClickOpenLogout();
                  handleClose();
                }}
              >
                Déconnexion
              </MenuItem>
            </Menu>
          </div>

          {/* <div className="title-part">
            <h2 className="title">étude de <b>MAÎTRE LOBA JEAN-CLAUDE</b></h2>
          </div> */}
          <div
            className="rounded-square-btn-head"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
          >
            <MenuOpenRounded color="secondary" />
          </div>
        </div>

        {/* <img src={require("@/assets/images/png/logo-mini.png")}
          alt="logo-mini"
          className="logo-mini-md"
        /> */}
        <div className="title-part">
          <h2 className="title">
            étude de{' '}
            <b>
              MAÎTRE {`${userConnected.first_name} ${userConnected.last_name}`}
            </b>
          </h2>
        </div>

        {/* <div className="rounded-square-btn" onClick={() => { dispatch(toggleSidebar()) }} >
          <MenuOpenRounded color="secondary" />
        </div> */}

        <div className="fs-profil">
          <Button
            className="profil-dropdown"
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <div className="id-photo">
              <img
                src="https://www.habitatpresto.com/upload/devispresto/3678144__AdobeStock_216672151.jpg"
                alt=""
              />
            </div>
            <div className="user-names">
              <span className="name">{`${userConnected.first_name} ${userConnected.last_name}`}</span>
              <span className="statut">{userConnected.job_title}</span>
            </div>
            <Icon width="20" icon="entypo:chevron-thin-down" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            elevation={6}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Mon compte</MenuItem>
            <MenuItem
              onClick={() => {
                goTo(routePaths.adminChildren.updatePasswordAbsolute);
              }}
            >
              Changer de mot de passe
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleClickOpenLogout();
                handleClose();
              }}
            >
              Déconnexion
            </MenuItem>
          </Menu>
        </div>
      </div>

      <Dialog
        open={openLogout}
        onClose={handleCloseLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Déconnexion'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Vous êtes sur le point de vous déconnecter. Êtes-vous sûr(e) de
            vouloir le faire ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={handleCloseLogout}>
            Annuler
          </Button>
          <Button color="error" onClick={logout} autoFocus>
            Déconnecter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToPrpos = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToPrpos);
const Header = connector(HeaderFunc);

export default Header;
