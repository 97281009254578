import React from "react";
import { Outlet } from "react-router-dom";
import { SwipeableDrawer } from "@mui/material";
import { useNavigate, useMatch, useResolvedPath } from "react-router-dom";

import { routePaths } from "@/config";

import CDashboardContent from "../containers/Dashboard/DashboardContent.container";
import FSHeader from "../Elements/FSHeader/FSHeader.element";

const DashbordPage: React.FC = () => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(
    `${routePaths.admin}${routePaths.adminChildren.home}${routePaths.adminChildren.folderChildren.createFolder}`
  );
  const match = useMatch({ path: resolved.pathname, end: false });

  const [onClosePanel, setOnClosePanel] = React.useState(false);

  return (
    <>
      <div className="fs-container">
        <FSHeader
          title="Tableau de bord"
          icon="ic:sharp-dashboard"
          breadscrumbTitle="Tableau de bord"
        />

        <CDashboardContent />
      </div>
      <SwipeableDrawer
        className="SwipeableDrawer-panel-container"
        anchor={"right"}
        open={!!match}
        onClose={() =>
          navigate(`${routePaths.admin}${routePaths.adminChildren.home}`)
        }
        onOpen={() => null}
        sx={{
          "& .MuiPaper-root": {
            width: "calc(100% - 280px)",
            padding: "1rem 1.5rem",
          },
        }}
      >
        <Outlet context={[onClosePanel, setOnClosePanel]} />
      </SwipeableDrawer>
    </>
  );
};

export default DashbordPage;
