import LContainer from '@/components/Layouts/Container/Container.layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import './Authentication.styles.scss';

const LAuthentication: React.FC = () => {
  return (
    <div className="authentication-layout-container">
      <div className="login-bg"></div>
        <LContainer>
            <div className="form-container">
              <Outlet />
            </div>
        </LContainer>
    </div>
  )
}

export default LAuthentication;
