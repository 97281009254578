import { useState } from "react";
import {
  Alert,
  Box,
} from "@mui/material";
import _ from "lodash";
import React from "react";
import ListeFiles from "./ListeFiles/ListeFiles.container";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const CFiles = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  
  return (
    <>
      <div className="partenaire-content">
        <Box sx={{marginTop: 2, marginBottom: 2}}>
          <Alert className="alert-info" severity="success" icon={false}>
            <b> Retrouvez tous vos Documents</b>
            <br />
            <span>
              {" "}
              Rechercher un document à partir de son nom ou du nom du 
              propriétaire.
            </span>
          </Alert>
        </Box>
        <Box className="admin-tabs" sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'background.paper' }}>
          <ListeFiles />
        </Box>
      </div>

    </>
  );
};

export default CFiles;
