import React from 'react';
import CClients from '../containers/Clients/Clients.container';
import FSHeader from '../Elements/FSHeader/FSHeader.element';


const Client: React.FC = () => {
  return (
    <div className='fs-container'>
      <FSHeader title="Gestion des clients" icon="fa6-solid:users-line" breadscrumbTitle="Clients"/>
      <CClients/>
    </div>
  )
}

export default Client;
