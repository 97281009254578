import { AppCredentials } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";

import { TNationality } from "../types";

export const createNationality = (data: TNationality): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.post("/pays/add", toSend);
};

export const updateNationality = (data: TNationality, docId: string): Promise<IApiResponse> => {
  const toSend = {
    ...data,
    app_secret: AppCredentials.APP_SECRET,
    app_id: AppCredentials.APP_ID,
  };
  return axios2.put(`/pays/update/${docId}`, toSend);
};

export const getAllNationality = (): Promise<IApiResponse> => {
  return axios2.get("/pays");
};

export const deleteNationality = (docId: string): Promise<IApiResponse> => {
  return axios2.delete(`/pays/${docId}`);
};