import React, { Suspense } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Outlet } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import classnames from "classnames";

import { SuspenseFallback } from "@/components/SuspenseFallback/SuspenseFallback";

import { useAppDispatch } from "@/store";
import { toggleSidebar } from "@/store/reducers/sidebar/sidebar.actions";
import { selectOpened } from "@/store/reducers/sidebar/sidebar.selector";

import Header from "../../containers/Header/Header.container";
import SideBar from "../../containers/Sidebar/SideBar.container";

import "./Main.styles.scss";

type TProps = {};
type PropsFromRedux = ConnectedProps<typeof connector>;

const LMain: React.FC<TProps & PropsFromRedux> = ({ sidebarOpened }) => {
  const dispatch = useAppDispatch();

  return (
    <div className="l-profile">
      <div className="fs-sidebar">
        <SideBar />
      </div>
      <div
        className={classnames("backdrop", { showed: sidebarOpened })}
        onClick={() => {
          dispatch(toggleSidebar());
        }}
      ></div>
      <div className={classnames("profile-content")}>
        <Header />
        <Suspense fallback={<SuspenseFallback />}>
          <Outlet />
        </Suspense>
        {/* <Bottombar /> */}
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
});

const connector = connect(mapStateToProps);
const LAdmin = connector(LMain);

export default LAdmin;
