import React from 'react';
import CtOffres from '../pages-content/Offres-content/Offres.content';

const Offres:React.FC = () => {
  return (
    <>
      <CtOffres />
    </>
  )
}

export default Offres