import { Outlet } from "react-router-dom";
import { SwipeableDrawer } from "@mui/material";
import { useNavigate, useMatch, useResolvedPath } from "react-router-dom";

import { routePaths } from "@/config";

import CFolderInfos from "../../containers/Dossier/FolderInfos/FolderInfos.container";
import FSHeader from "../../Elements/FSHeader/FSHeader.element";
import { store } from "@/store";
import { updateCurrentFolder } from "@/store/reducers/folder/folder.action";

const FolderDashboardPage = () => {
  const navigate = useNavigate();
  const dispatch = store.dispatch;
  const resolved = useResolvedPath(
    `${routePaths.admin}${routePaths.adminChildren.folder}${routePaths.adminChildren.folderChildren.createFolder}`
  );
  const match = useMatch({ path: resolved.pathname, end: false });

  return (
    <>
      <div className='fs-container'>
        <FSHeader
          title='Gestion des dossiers'
          icon='ant-design:folder-open-filled'
          breadscrumbTitle='Dossiers'
        />
        <CFolderInfos />
      </div>
      <SwipeableDrawer
        className='SwipeableDrawer-panel-container'
        anchor={"right"}
        open={!!match}
        onClose={() => {
          dispatch(updateCurrentFolder(null!));
          navigate(`${routePaths.admin}${routePaths.adminChildren.folder}`);
        }}
        onOpen={() => null}
      >
        <Outlet />
      </SwipeableDrawer>
    </>
  );
};

export default FolderDashboardPage;
