import {
  DataGrid,
  frFR,
  GridColDef,
  GridColumns,
  GridToolbar,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styles from "./OwnerTable.module.scss";
import { Icon } from "@iconify/react";

type TOwnerTableProps = {
  columns: GridColumns<any> | GridColDef[];
  rows: any;
  searchFilter?: boolean;
  rowsPerPage?: number;
};

const OwnerTable: React.FC<TOwnerTableProps> = ({
  columns,
  rows,
  searchFilter,
  rowsPerPage,
}) => {
  const [pageSize, setPageSize] = useState<number>(rowsPerPage! || 7);
  const [filterValue, setFilterValue] = useState("");
  const [rowsValue, setRowsValue] = useState(rows);

  const handleFilter = (value: string) => {
    // let searchVal = value;
    const val = value?.toLowerCase();
    // filtrer vos données
    const temp = rows.filter((d: any) => {
      return (
        // Dossiers
        (d.folder_code
          ? d.folder_code.toLowerCase().indexOf(val) !== -1
          : null) ||
        (d.label ? d.label.toLowerCase().indexOf(val) !== -1 : null) ||
        (d.seller ? d.seller.toLowerCase().indexOf(val) !== -1 : null) ||
        (d.buyer ? d.buyer.toLowerCase().indexOf(val) !== -1 : null) ||
        // Prospects
        (d.nom ? d.nom.toLowerCase().indexOf(val) !== -1 : null) ||
        (d.prenoms ? d.prenoms.toLowerCase().indexOf(val) !== -1 : null) ||
        (d.email ? d.email.toLowerCase().indexOf(val) !== -1 : null) ||
        (d.address ? d.address.toLowerCase().indexOf(val) !== -1 : null) ||
        // Personnes physiques
        (d.denomination
          ? d.denomination.toLowerCase().indexOf(val) !== -1
          : null) ||
        (d.registered_office
          ? d.registered_office.toLowerCase().indexOf(val) !== -1
          : null) ||
        // Personnes morales
        (d.last_name ? d.last_name.toLowerCase().indexOf(val) !== -1 : null) ||
        (d.first_name
          ? d.first_name.toLowerCase().indexOf(val) !== -1
          : null) ||
        !val
      );
    });
    // Mise à des lignes du tableau
    setRowsValue(temp);
  };

  useEffect(() => {
    if (filterValue) {
      handleFilter(filterValue);
    } else {
      setRowsValue(rows);
    }
  }, [filterValue, rows]);

  return (
    <div className={styles.TableContainer}>
      {searchFilter && (
        <div className={styles.filterContainer}>
          <div className={styles.searchIcon}>
            <Icon className={styles.icon} icon='clarity:search-line' />
          </div>
          <input
            placeholder='Rechercher...'
            value={filterValue}
            onChange={e => setFilterValue(e.target.value)}
            type='text'
          />
          {filterValue && (
            <button
              onClick={() => setFilterValue("")}
              type='button'
              className={styles.searchClear}
            >
              <Icon icon='clarity:times-line' />
            </button>
          )}
        </div>
      )}
      <DataGrid
        components={{ Toolbar: GridToolbar }}
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        rows={rowsValue || []}
        columns={columns}
        getRowId={(row: any) => (row?.id ? row?.id : row?._id)}
        // hideFooterPagination={true}
        className={styles.table}
        checkboxSelection={false}
        disableSelectionOnClick={true}
        disableColumnMenu
        pageSize={pageSize}
        rowsPerPageOptions={[5, 7, 15, 30, 100]}
        autoHeight={true}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        sx={{
          fontFamily: "'Raleway',sans-serif !important",
          border: "none",
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#ececec",
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: "var(--ui-primary)",
            fontWeight: "bold",
          },
          "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
            {
              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
            },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            minHeight: "5px",
          },
          "& .MuiTablePagination-toolbar": {
            "& > p": {
              marginBottom: 0,
            },
          },
        }}
      />
    </div>
  );
};

export default OwnerTable;
