interface ITabDataButton {
  icon: string;
  title: string;
  badge: number;
  isModal: boolean;
}

interface ITabDataButtonBadgeValue {
  vendorBadgeValue: number;
  acquereurBadgeValue: number;
  billSaleBadgeValue: number;
  comptaBadgeValue: number;
  biensBadgeValue: number;
  folderPiecesBadgeValue: number;
  actorBadgeValue: number;
}
export const tabDataButton = ({
  vendorBadgeValue,
  actorBadgeValue,
  acquereurBadgeValue,
  billSaleBadgeValue,
  comptaBadgeValue,
  folderPiecesBadgeValue,
  biensBadgeValue,
}: ITabDataButtonBadgeValue): ITabDataButton[] => {
  return [
    {
      icon: 'material-symbols:real-estate-agent',
      title: 'Biens',
      badge: biensBadgeValue,
      isModal: false,
    },
    {
      icon: 'codicon:smiley',
      title: 'Vendeur',
      badge: vendorBadgeValue,
      isModal: false,
    },
    {
      icon: 'codicon:smiley',
      title: 'Acquéreur',
      badge: acquereurBadgeValue,
      isModal: false,
    },
    {
      icon: 'dashicons:media-document',
      title: 'Actes de vente',
      badge: billSaleBadgeValue,
      isModal: false,
    },
    {
      icon: 'material-symbols:account-balance-wallet',
      title: 'Comptabilité',
      badge: comptaBadgeValue,
      isModal: false,
    },

    {
      icon: 'clarity:document-solid',
      title: 'Pièces du dossier',
      badge: folderPiecesBadgeValue,
      isModal: false,
    },
    {
      icon: 'clarity:document-solid',
      title: 'Acteurs',
      badge: actorBadgeValue,
      isModal: true,
    },
  ];
};
