import { FC, useEffect, useState } from "react";
import { DataGrid, GridColDef, frFR, GridToolbar } from "@mui/x-data-grid";
import {
  Box,
  Grid
} from "@mui/material";
import "./ListeFiles.style.scss";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { TListeFiles, TSellerPersonnalInfo } from "@/features/admin/types";
import { formatDate } from "@/utils/formatDate";
import { getPersonnePhysique } from "@/features/admin/api/client.api";
import { Loader } from "@/components/Loader/Loader";

const rowsPerPage = 7;

const ListeFiles: FC = () => {

  const columnsFiles: GridColDef[] = [
    {
      field: "last_name",
      headerName: "Nom",
      flex: 1,
      sortable: true,
    },
    {
      field: "first_name",
      headerName: "Prénoms",
      flex: 1,
      sortable: true,
    },
    {
      field: "phone_number",
      headerName: "N°Télephone",
      flex: 1,
      sortable: true,
    }
  ];

  const columnsMoral: GridColDef[] = [
    {
      field: "denomination",
      headerName: "Raison sociale",
      flex: 1,
      sortable: true,
    },
    {
      field: "registered_office",
      headerName: "Siège social",
      flex: 1,
      sortable: true,
    }
  ];

  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [listFiles, setListFiles] = useState<TListeFiles[]>([]);

  

  const [isLoading, setIsLoading] = useState(false);


  const getClient = async () => {
    setIsLoading(true);

    const { data } = await getPersonnePhysique();

    console.log(" data ",data);

    const dataWithNewObjectKeys: TListeFiles[] = data.map((file: any) => {
      return {
        id: file._id,
        owner: upperFirstWordLetter(file?.owner!),
        name: upperFirstWordLetter(file?.name!),
        doc_type_id: file?.doc_type_id,
        sharing_file_id: file?.sharing_file_id,
        created_by: file?.created_by,
        created_date: formatDate(file.created_date),
      };
    });
    setListFiles(dataWithNewObjectKeys);
    
    setIsLoading(false);
  };

  useEffect(() => {
    getClient();
  }, []);

  return (
    <>
      <div className="c-list-users">
        <div className="utilisateur-content">
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div className="utilisateur-table-part">
                 
                  <div className="utilisateur-table-body">
                    <Box sx={{ height: 500, width: "100%" }}>
                      <Loader isLoading={isLoading} />
                      <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                        rows={listFiles}
                        columns={columnsFiles}
                        // hideFooterPagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        disableColumnMenu
                        pageSize={pageSize}
                        rowsPerPageOptions={[7, 15, 30]}
                        onPageSizeChange={(newPageSize) =>
                          setPageSize(newPageSize)
                        }
                        sx={{
                          fontFamily: "'Raleway',sans-serif !important",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#ececec",
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-columnHeaderTitle": {
                            color: "var(--ui-primary)",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
                            {
                              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
                            },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            minHeight: "5px",
                          },
                          "& .MuiTablePagination-toolbar": {
                            "& > p": {
                              marginBottom: 0,
                            },
                          },
                        }}
                      />
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    </>
  );
};

export default ListeFiles;
