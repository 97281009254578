import { TMenu } from "@/features/admin/types";

import { routePaths } from "./routePaths";

export const menus1: TMenu[] = [
  {
    icon: "ic:sharp-dashboard",
    label: "Tableau de bord",
    route: routePaths.adminChildren.homeAbsolute,
    exact: true,
  },
  {
    icon: "bi:people-fill",
    label: "Prospects",
    route: routePaths.adminChildren.prospectsAbsolute,
    exact: true,
  },
  {
    icon: "mdi:folder-open",
    label: "Dossiers",
    route: routePaths.adminChildren.folderAbsolute,
    exact: true,
  },
  {
    icon: "fa6-solid:users-line",
    label: "Clients",
    route: routePaths.adminChildren.clientAbsolute,
    exact: true,
  },
  // {
  //   icon: "ph:files-fill",
  //   label: "Documents perso.",
  //   route: routePaths.adminChildren.filesAbsolute,
  //   exact: true,
  // },
  {
    icon: "fa6-solid:people-roof",
    label: "Communauté",
    route: routePaths.adminChildren.communautesAbsolute,
    children: [
      {
        icon: "fa6-solid:people-roof",
        label: "Confrères",
        route: routePaths.adminChildren.communautesChildren.confrereCommunaute,
      },
      {
        icon: "fa6-solid:people-roof",
        label: "Nos partenaires",
        route:
          routePaths.adminChildren.communautesChildren.partenaireCommunaute,
      },
    ],
  },
  {
    icon: "fa-solid:users-cog",
    label: "Administration",
    route: routePaths.adminChildren.administrationAbsolute,
    exact: true,
  },
  // {
  //   icon: "bx:bell",
  //   label: "Notifications",
  //   route: routePaths.adminChildren.notificationAbsolute,
  //   exact: true,
  // },
  // {
  //   icon:"fa-solid:user-cog",
  //   label: "Mon compte",
  //   route: routePaths.adminChildren.subscriptionAbsolute,
  //   children: [
  //     {
  //       label: "Supprimer mon compte",
  //       route: routePaths.adminChildren.subscriptionChildren.deleteAccount,
  //     },
  //     {
  //       label: "Mon abonnement",
  //       route: routePaths.adminChildren.subscriptionChildren.desactivateSubscription,
  //     },
  //   ],
  // },
];
