import React, { useState } from 'react';
import { menus1 } from '@/config/sidebarMenus';
import SideMenu from './SideMenu/SideMenu.element';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Slide, useMediaQuery } from '@mui/material';
import { selectOpened } from '@/store/reducers/sidebar/sidebar.selector';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import classNames from 'classnames';
// import { useAppDispatch } from "@/store";
import { useTheme } from '@mui/material/styles';
import './SideBar.styles.scss';
// import { routePaths } from "@/config";
// import { useNavigate } from "react-router-dom";
import CModal from '@/components/Modal/Modal';

type PropsFromRedux = ConnectedProps<typeof connector>;

const CSideBarFunction: React.FC<PropsFromRedux> = ({ sidebarOpened }) => {
  const [indexMenuOpen, setIndexMenuOpen] = useState<number | null>(null);
  // const dispatch =useAppDispatch();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));

  const [openHelp, setOpenHelp] = useState(false);
  // const handleOpenHelp = () => setOpenHelp(true);
  const handleCloseHelp = () => setOpenHelp(false);

  // const navigate = useNavigate();

  // const logout = () => {
  //     navigate(routePaths.auth,{replace:true});
  // }

  return (
    <>
      <Slide
        direction="right"
        in={matches || sidebarOpened}
        mountOnEnter
        unmountOnExit
      >
        <div className={classNames('c-sidebar', { opened: sidebarOpened })}>
          {/* <div className="sidebar-top">
            <div className="sidebar-top__header-btn-container">
                <div  className="rounded-square"
                      onClick={()=>{
                        dispatch( toggleSidebar() )
                      }}>
                  <ArrowBackRounded color="tertiary" />
                </div>
                <div  className="rounded-square"
                      onClick={handleOpenHelp}>
                  <HelpOutlineRounded color="tertiary" />
                </div>
                <div  className="rounded-square"
                      onClick={logout}>
                  <PowerSettingsNewRounded color="tertiary" />
                </div>
            </div>
            <div className="sidebar-top__logo-part">
                  <img
                    src={require("@/assets/images/png/logo-white.png")}
                    alt="sidebar-logo"
                    className="sidebar-top__logo-part__logo"
                  />
            </div>

            <img
              src={require("@/assets/images/png/avatar.png")}
              alt="sidebar-logo"
              className="sidebar-top__avatar"
            />
            <p className="sidebar-top__username">Landry YAMB</p>
            <p className="sidebar-top__identity_number">ID: 12345678</p>
            <div className="sidebar-top__other-infos">
              <div className="sidebar-top__other-infos__infos-bloc">
                <InlineIcon icon="icon-park-outline:personal-privacy" />
                <span> Admin </span>
              </div>
            </div>
          </div> */}
          <PerfectScrollbar className="sidebar-menu">
            {menus1.map((menu, idx) => (
              <SideMenu
                menu={menu}
                key={idx}
                myIndex={idx}
                indexMenuOpen={indexMenuOpen}
                setIndexMenuOpen={setIndexMenuOpen}
              />
            ))}
          </PerfectScrollbar>
        </div>
      </Slide>
      <CModal
        open={openHelp}
        onClose={handleCloseHelp}
        closeClick={handleCloseHelp}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  sidebarOpened: selectOpened,
});

const connector = connect(mapStateToProps);
const CSideBar = connector(CSideBarFunction);

export default CSideBar;
