import React, { FC, useEffect, useState } from "react";
import Panel from "@/components/UICs/Panel/Panel.uic";
import {
  DataGrid,
  frFR,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Pagination,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";

import ProspectForms, {
  TProspectFormsValues,
} from "../../forms/Prospect/Prospect.form";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import "./ListProspects.styles.scss";
import { TProspect } from "../../types/types";
import {
  createProspect,
  deleteProspect,
  getAllProspect,
  updateProspect,
} from "../../api/prospect.api";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { FormikHelpers } from "formik";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { formatDate } from "@/utils/formatDate";
import { getFolderStat } from "../../api/folder.api";
import Swal from "sweetalert2";
import OwnerTable from "@/components/UICs/OwnerTable/OwnerTable";

const rowsPerPage = 7;

type DropdownMenuProps = {
  currentProspect: any;
  refreshProspect: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  currentProspect,
  refreshProspect,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**Confirmation Suppression d'un Type d'acteur  */
  const confirmDeleteProspect = () => {
    handleClose();
    Swal.fire({
      title: " Suppression",
      text: "Vous êtes sur le point de supprimer un prospect !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        deleteProspectFuntion();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteProspectFuntion = async () => {
    // console.log("ruccentActor.id ", ruccentActor.id)
    const response = await deleteProspect(currentProspect.id);
    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    } else {
      ToastGeneric(
        `Prospect "${currentProspect.nom} ${currentProspect.prenoms}" a été supprimé avec succès`,
        "success",
        3000
      ).fire();
      refreshProspect();
    }
  };

  const updateItem = async (
    values: TProspectFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    console.log({ currentProspect });
    const toSend = {
      _id: currentProspect!.id,
      nom: values.nom,
      prenoms: values.prenoms,
      email: values.email,
      telephone: values.telephone,
      address: values.address,
      objet_visite: values.objet_visite,
      commentaire: values.commentaire,
    };
    const response = await updateProspect(toSend, currentProspect!.id);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Prospect modifié avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshProspect();
      // console.table(response);
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TProspectFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TProspectFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem onClick={confirmDeleteProspect}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Mise à jour de L'utilisateur"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <ProspectForms
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          currentProspect={currentProspect}
          isForUpdate={true}
          refreshProspect={refreshProspect}
          handleSubmit={handleSubmit}
        />
      </Panel>
      {/* <DeleteDialog
        alertTitle={`Voulez-vous supprimer ce prospect ?`}
        elementToDelete={` ${currentProspect.nom} ${currentProspect.prenoms}`}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        performedFunction={deleteProspectFuntion}
      /> */}
    </>
  );
};

const CListProspects = () => {
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [prospectData, setProspectData] = useState<TProspect[]>([]);
  const [prospectstats, setProspectStats] = useState<any>();
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);

  const getProspectStats = async () => {
    await getFolderStat().then((response: any) => {
      let data: any = response.data;
      setProspectStats({
        en_cours: data.prospect.en_cours,
        cloture: data.prospect.cloture,
        total: data.prospect.total,
        percent_en_cours: (data.prospect.en_cours / data.prospect.tatal) * 100,
        percent_cloture: (data.prospect.cloture / data.prospect.tatal) * 100,
      });
    });
  };

  useEffect(() => {
    getProspectStats();
    //eslint-disable-next-line
  }, []);

  const columns: GridColDef[] = [
    { field: "nom", headerName: "Nom", flex: 1, sortable: true },
    {
      field: "prenoms",
      headerName: "Prénoms",
      flex: 1,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      sortable: true,
    },
    {
      field: "telephone",
      headerName: "Téléphone",
      flex: 1,
      sortable: true,
    },
    {
      field: "address",
      headerName: "Localisation",
      flex: 1,
      sortable: true,
    },
    {
      field: "objet_visite",
      headerName: "Objet visite",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_date",
      headerName: "Date création",
      flex: 1,
      sortable: true,
    },
    {
      field: "client_since_date",
      headerName: "Client depuis",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DropdownMenu
            currentProspect={row}
            refreshProspect={refreshProspect}
          />
        );
      },
    },
  ];

  const getProspects = async () => {
    const { data } = await getAllProspect();
    const dataWithNewObjectKeys: TProspect[] = data.map((prospect: any) => {
      return {
        id: prospect._id,
        nom: prospect.nom.toUpperCase(),
        prenoms: upperFirstWordLetter(prospect.prenoms),
        email: prospect.email,
        telephone: `${prospect.telephone}`,
        address: prospect.address,
        objet_visite: prospect.objet_visite,
        client_since_date: formatDate(prospect?.client_since_date),
        commentaire: prospect.commentaire,
        created_date: formatDate(prospect.created_date),
      };
    });
    setProspectData(dataWithNewObjectKeys);
  };

  const refreshProspect = async () => {
    await getProspects();
  };

  useEffect(() => {
    getProspects();
  }, []);

  const saveItem = async (
    user: TProspectFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const response = await createProspect(user);

    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Prospect créé avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshProspect();
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TProspectFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TProspectFormsValues>
  ) => {
    saveItem(values, setSubmitting);
  };

  return (
    <>
      <div className='prospect-content'>
        <Box>
          <Grid container rowSpacing={4} columnSpacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <div className='prospect-table-part'>
                <div
                  className='prospect-table-header'
                  style={{ height: "55px" }}
                >
                  <Button
                    color='primary'
                    onClick={e => setOpenPanel(true)}
                    variant='contained'
                    title='Créer un prospect'
                    startIcon={<AddCircleOutlineIcon />}
                  >
                    Créer un prospect
                  </Button>

                  {/* <a  onClick={(e) => setOpenPanel(true)} style={{marginLeft:'auto'}}>test</a> */}
                </div>
                <div className='prospect-table-body'>
                  {/* <CustomTableFct
                    enabledSearch={true}
                    enabledPagination={true}
                    title={"Liste des prospects"}
                    headers={headers}
                    rows={rows}
                    rowsPerPage={10}
                  /> */}
                  <Box sx={{ height: 500, width: "100%" }}>
                    <OwnerTable
                      rows={prospectData}
                      columns={columns}
                      searchFilter={true}
                    />
                  </Box>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>

      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création de prospect"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <ProspectForms
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshProspect={refreshProspect}
          handleSubmit={handleSubmit}
        />
      </Panel>
    </>
  );
};

export default CListProspects;
