import React from 'react';
import classnames from 'classnames';
// import "./Loader.styles.scss";
import { CircularProgress } from '@mui/material';
import { LoaderContainer } from './Loader.styles';


export const Loader:React.FC<{isLoading:boolean,title?:string}> = ({isLoading,title}) => {

  return (
    <LoaderContainer className={classnames({'active':isLoading})}>
            <span> {title ? title : ''} </span>
            <CircularProgress />
    </LoaderContainer>
  )
}
