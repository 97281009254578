// import { PersonneTypeId } from "@/config/enum";
import { IApiResponse } from "@/interface";
import { axios2 } from "@/lib/axios";

// export const getTypeDocumentPersonneMorale = (): Promise<IApiResponse> => {
//   return axios2.get("/document-types/" + PersonneTypeId.PersonneMorale);
// };

// export const getTypeDocumentPersonnePhysique = (): Promise<IApiResponse> => {
//   return axios2.get("/document-types/" + PersonneTypeId.PersonnePhysique);
// };

export const getTypeDocument = (): Promise<IApiResponse> => {
  return axios2.get("/type-pieces");
};
