import React from "react";
import CProspects from "../containers/Prospects/ListProspects.container";
import FSHeader from "../Elements/FSHeader/FSHeader.element";

const ProspectPage: React.FC = () => {
  return (
    <div className="fs-container">
      <FSHeader title="Gestion des prospects" icon="bi:people-fill" breadscrumbTitle="Prospects"/>

      <CProspects />
    </div>
  );
};

export default ProspectPage;
