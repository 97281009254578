import { InlineIcon } from "@iconify/react";
import { Box, Button, Grid } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { routePaths } from "@/config";
import { useNavigate } from "react-router-dom";

import BoxStatuts from "../../Elements/BoxStatuts/BoxStatuts.element";
import ChartEvolution from "../../Elements/ChartEvolution/ChartEvolution.element";

import "./DashboardContent.styles.scss";
import ListFolder from "../../Elements/ListFolder/ListFolder.element";
import { ToastError } from "@/utils/toast";
import { msgConnexionInterrompue } from "@/config/enum";
import { useEffect, useState } from "react";
import { getFolderStat } from "../../api/folder.api";

const CDashboardContent = () => {
  const navigate = useNavigate();
  const [folderstats, setFolderStats] = useState<any>();

  const getStats = async () => {
    await getFolderStat()
      .then((response: any) => {
        let data: any = response.data;

        setFolderStats({
          en_cours: data?.dossier?.en_cours,
          cloture: data?.dossier?.cloture,
          annule: data?.dossier?.annule,
          suspendu: data?.dossier?.suspendu,
          total: data?.dossier?.total,
          opdened_expired_month: data?.dossier?.opdened_expired_month,
          percent_en_cours: (data.dossier.en_cours / data.dossier.tatal) * 100,
          percent_cloture: (data.dossier.cloture / data.dossier.tatal) * 100,
          percent_suspendu: (data.dossier.suspendu / data.dossier.tatal) * 100,
        });
      })
      .catch(error => {
        if (error?.response?.data?.message) {
          ToastError.fire({
            text: error?.response?.data?.message,
            timer: 9000,
          });
        } else {
          ToastError.fire({
            text: msgConnexionInterrompue.connexionInterrompue,
            timer: 9000,
          });
        }
      });
  };

  useEffect(() => {
    getStats();
    //eslint-disable-next-line
  }, []);

  return (
    <div className='c-dashboard-content'>
      <div className='fs-content'>
        <div className='box-and-graph-part'>
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid className='kpi-container' item xs={12} lg={5}>
                <div className='row-statuts-part'>
                  <Grid container rowSpacing={4} columnSpacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={6} rowSpacing={24}>
                      <BoxStatuts
                        numberItem={folderstats?.en_cours || 0}
                        iconStatut='mdi:folder-sync-outline'
                        statut='Dossiers en cours'
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={6} rowSpacing={24}>
                      <BoxStatuts
                        numberItem={folderstats?.cloture || 0}
                        iconStatut='mdi:folder-check-outline'
                        statut='Dossiers cloturés'
                      />
                    </Grid>
                  </Grid>
                </div>

                <div className='row-statuts-part'>
                  <Grid container rowSpacing={4} columnSpacing={2}>
                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <BoxStatuts
                        numberItem={folderstats?.annule || 0}
                        iconStatut='mdi:folder-remove-outline'
                        statut='Dossiers désistés'
                      />
                    </Grid>

                    <Grid item xs={12} md={6} sm={6} lg={6}>
                      <BoxStatuts
                        numberItem={folderstats?.suspendu || 0}
                        iconStatut='mdi:folder-cancel-outline'
                        statut='Dossiers suspendus'
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <Grid item xs={12} md={12} sm={12} lg={7}>
                <div className='row-evolution-part'>
                  <ChartEvolution />
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>

        <div className='table-and-create-folder-part'>
          <div className='recent-data-container'>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={6} lg={8}>
                <div className='dashbord-table-part'>
                  <div
                    className='dashbord-table-header'
                    style={{ height: "55px" }}
                  >
                    <b> Dossiers récents </b>
                  </div>
                  <div className='dashbord-table-body'>
                    <Box sx={{ height: 500, width: "100%" }}>
                      <ListFolder isDashBoradPage={true} rowsPerPage={5} />
                    </Box>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <div className='create-folder-part'>
                  <div className='body-create-folder'>
                    <InlineIcon
                      className='icon-folder'
                      icon='ant-design:folder-filled'
                    />
                    <Button
                      color='primary'
                      variant='contained'
                      onClick={() =>
                        navigate(
                          `${routePaths.admin}${routePaths.adminChildren.home}/create-folder`
                        )
                      }
                      disableElevation
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Créer un dossier
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CDashboardContent;
