import { Button } from "@mui/material";
import React from "react";
import { useFormik } from 'formik';
import "./Partenaire.style.scss";

const PartenaireForm: React.FC = () => {

  const formik = useFormik({
    initialValues: {
      partnerLabel: '',
      partnerContent: '',
    },
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <form className="form-partenaire" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Libellé <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="partnerLabel"
              autoComplete="off"
              placeholder="Nom"
              onChange={formik.handleChange}
              value={formik.values.partnerLabel}
            ></input>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form"> Description </label>
            <textarea
              rows={3}
              className="form-control bs-input-custom"
              name="partnerContent"
              placeholder="Description"
              onChange={formik.handleChange}
              value={formik.values.partnerContent}
            ></textarea>
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              disableElevation
            >
              Annuler
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default PartenaireForm;
