import { routePaths } from '@/config/routePaths';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import logo from "@/assets/images/png/logo_app.png";
import balance from "@/assets/images/svg/balance.svg";
import firstProfessionImg from "@/assets/images/png/first_profession.png";
import secondProfessionImg from "@/assets/images/png/second_profession.png";
import thirdProfessionImg from "@/assets/images/png/third_profession.png";
import chambreNotaire from "@/assets/images/png/chambre_notaire.png";
import notariatFrancophone from "@/assets/images/png/notaire_francophone.png";
import logoDGI from "@/assets/images/png/logoDGI.png";
import ministereFinance from "@/assets/images/png/Ministere_des_finances.png";
import article from "@/assets/images/png/article.png";
import solutionMetierImg from "@/assets/images/gif/solution_metier.gif";
import infrastrutureImg from "@/assets/images/gif/infrastructure.gif";
import juridiqueImg from "@/assets/images/gif/juridique.gif";
import './Home.content.style.scss';
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';

const CtHome:React.FC = () => {

    const navigate = useNavigate();
    
    const gotoSigninPage = () => {
        navigate( routePaths.auth ,{replace: true});
    }

  return (
    <div className="home-content-container">
      <div className="primary-background">
        <div className="header-section">
          <div className="fs-logo-part">
            <img src={logo} alt="Logo"/>
          </div>

          {/* header après le logo principal */}
          <div className="header">
            <div className="header-texts">
              <h1>Gérer votre étude l'esprit tranquille.</h1>
              <small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec molestie, erat nec ultricies posuere, mi ligula tempus eros, at euismod eros nunc quis orci. Donec accumsan tempor pretium. In hac habitasse platea dictumst. Quisque placerat sodales turpis, quis aliquam dolor scelerisque ac. Vivamus tempor diam elit
              </small>
              <Button type='button' onClick={gotoSigninPage} color='primary' variant="contained" disableElevation>
                Démarrer maintenant
              </Button>
            </div>
            <div className="header-image">
              <img src={balance} alt=""/>
            </div>
          </div>

          {/* Le block de gestion des metiers */}
          <div className="section fs-box">
            <div className="profession-header">
              <h5>Nous vous accompagnons dans toutes les dimensions essentielles de votre métier</h5>
              <div className="profession-description">
                <p>modules complémentaires pour accéder aux dossiers, documents, clients et partenaires de l’ensemble des études d’un groupement</p>
                <p>Consulter les dossiers, suivre juridiquement et modifier si besoin, nous vous accompagnons. Consultez instantanément tous les dossiers, clients et partenaires des différents offices du groupement</p>
              </div>
            </div>

            <div className="profession-first">
              <div className="profession-image">
                <img src={firstProfessionImg} alt="" />
              </div>
              <div className="profession-content">
                <h6>Aide a la rédaction des actes notariés</h6>
                <p>Les solutions métier à la pointe de l'innovation pour faciliter le quotidien des études. Les solutions métier à la pointe de l'innovation pour faciliter le quotidien des études.</p>
                <ul>
                  <li><Icon icon="fe:users" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="ic:baseline-security" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="fa-solid:clock" width="36" /><span>adipiscing elit, sed do</span></li>
                </ul>
                <Button type='button' onClick={gotoSigninPage} color='primary' variant="contained" >
                  Démarrer maintenant
                </Button>
              </div>
            </div>

            <div className="profession-second">
              <div className="profession-content">
                <h6>Consultez instantanément tous les dossiers</h6>
                <p>Consulter les dossiers, suivre juridiquement et modifier si besoin, nous vous accompagnons. Consultez instantanément tous les dossiers, clients et partenaires des différents offices du groupement</p>
                <ul>
                  <li><Icon icon="fe:users" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="ic:baseline-security" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="fa-solid:clock" width="36" /><span>adipiscing elit, sed do</span></li>
                </ul>
                <Button type='button' onClick={gotoSigninPage} color='primary' variant="contained" disableElevation>
                  Démarrer maintenant
                </Button>
              </div>
              <div className="profession-image">
                <img src={secondProfessionImg} alt="" />
              </div>
            </div>

            <div className="profession-third">
              <div className="profession-image">
                <img src={thirdProfessionImg} alt="" />
              </div>
              <div className="profession-content">
                <h6>Donner une nouvelle dimension à votre étude</h6>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                <ul>
                  <li><Icon icon="fe:users" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="ic:baseline-security" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="fa-solid:clock" width="36" /><span>adipiscing elit, sed do</span></li>
                </ul>
                <Button type='button' onClick={gotoSigninPage} color='primary' variant="contained" disableElevation>
                  Démarrer maintenant
                </Button>
              </div>
            </div>
          </div>


          {/* Le block de gestion des cabinets */}
          <div className="section fs-box">
            <div className="profession-header">
              <h5>Gerer votre cabinet l'esprit tranquille.</h5>
              <div className="profession-description">
                <p>Une écoute permanente et des conseils de qualité : assistance technique, formation et support au client.</p>
              </div>
            </div>
            
            <div className="office-container">
              <div className="office-box">
                <div className="office-image">
                  <img src={solutionMetierImg} alt=""/>
                </div>
                <h6>Solutions métiers</h6>
                <p>Les solutions métier à la pointe de l'innovation pour faciliter le quotidien des études.</p>
                <ul>
                  <li><Icon icon="fe:users" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="ic:baseline-security" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="fa-solid:clock" width="36" /><span>adipiscing elit, sed do</span></li>
                </ul>
              </div>
  
              <div className="office-box">
                <div className="office-image">
                  <img src={infrastrutureImg} alt="" />
                </div>
                <h6>Infrastructure</h6>
                <p>Des applications connectées au sein d'une infrastructure souple, évolutive et sécurisée.</p>
                <ul>
                  <li><Icon icon="fe:users" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="ic:baseline-security" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="fa-solid:clock" width="36" /><span>adipiscing elit, sed do</span></li>
                </ul>
              </div>
  
              <div className="office-box">
                <div className="office-image">
                  <img src={juridiqueImg} alt="" />
                </div>
                <h6>Bible juridique</h6>
                <p>Un service juridique dédié à l'élaboration d'une bible riche et perfectionnée, régulièrement mise à jour.</p>
                <ul>
                  <li><Icon icon="fe:users" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="ic:baseline-security" width="36" /><span>adipiscing elit, sed do</span></li>
                  <li><Icon icon="fa-solid:clock" width="36" /><span>adipiscing elit, sed do</span></li>
                </ul>
              </div>
            </div>
          </div>

          {/* Le block de gestion des partenaires */}
          <div className="section fs-box">
            <div className="profession-header">
              <h5>Nos partenaires</h5>
              <div className="profession-description">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
              </div>
            </div>

            <div className="partner-list">
              <img src={chambreNotaire} alt=""/>
              <img src={notariatFrancophone} alt=""/>
              <img src={logoDGI} alt=""/>
              <img src={ministereFinance} alt=""/>
              <img src={article} alt=""/>
            </div>
          </div>

          {/* Le block gestion de Copy Right  */}
          <div className="copy-right">
            <span>Copyright © 2022. Réalisé avec le <Icon className='copy-right-heart' icon="mdi:cards-heart" /> par <b>Everest</b></span>
          </div>
        </div>
      </div>
      <div className="secondary-background"></div>
      <div className="tertiary-background"></div>
        {/* <button type='button' onClick={gotoSigninPage}> Demarer maintenant</button> */}
    </div>
  )
}

export default CtHome