import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import folderReducer from './folder/folder.reducer';
import sidebarReducer from './sidebar/sidebar.reducer';
import userReducer from './user/user.reducer';

export const persistConfig = {
  key: 'app_react_ts_redux_mui_starter',
  storage,
  whitelist: ['sidebar', 'user','folder'],
  blacklist: [],
};

const topReducer = combineReducers({
  sidebar: sidebarReducer,
  user: userReducer,
  folder: folderReducer
});

const rootReducer = (state: any, action: any) => {
  // when RESET action is dispatched it will reset redux state
  // if (action.type === authTypes.LOGOUT) {
  //   state = undefined;
  // }
  return topReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
