// import { routePaths } from '@/config';
import { routePaths } from '@/config';
import { Icon } from '@iconify/react';
import { Box, Button, Step, StepLabel, Stepper} from '@mui/material';
import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import confetis from "@/assets/images/gif/confetis_not_bg.gif";
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link, useNavigate } from 'react-router-dom';
import './SignupForm.styles.scss';


const SignupForm: React.FC = () => {

  const navigate = useNavigate();
  const goTo = (route: string) => {
    navigate(route, { replace: true });
  }

  const steps = ['Informations personnelles', 'Informations du cabinet', 'Résumé d\'informations'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [etapeLabel, setEtapeLabel] = useState(steps[0]);
  const [displayCongratulationPage, setDisplayCongratulationPage] = useState(false);
  const [displayConfetti, setDisplayConfetti] = useState(true);


  const handleNext = () => {
    let newSkipped = skipped;


    if (activeStep === steps.length - 1) {
      // Alors il s'agit de l'enregistrement
      console.log(" je dois enregistrer ");

      setDisplayCongratulationPage(true);

      setTimeout(() => {
        // Desactive le confetti
        setDisplayConfetti(false);
      }, 5000);

    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
    console.log(" activeStep ", activeStep);

    setEtapeLabel(steps[activeStep + 1]);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setEtapeLabel(steps[activeStep - 1]);
  };

  function createData(
    label: string,
    value: string,
  ) {
    return { label, value };
  }

  const rows = [
    createData("Nom et prénoms :", "Koffi Edouard"),
    createData("adresse mail :", "koffi.edouard@notariat.com"),
    createData("Numéro de téléphone :", "+225 01 24 35 48 97"),
    createData("Nom du cabinet :", "Cabinet Kof'Ard"),
    createData("Matricule UNIL :", "Not8215479630"),
    createData("Situation géographique :", "Cocody, vallons rue des jardins immeuble Justice"),
  ];


  return (
    <div className="signup-container">
      <div className="signup-img"></div>
      <div className='fs-form-container'>
        <div className='return-container'>
          <Button className='return-button' type='button' onClick={(e) => { goTo('routePaths.authChildren.signInAbsolute') }}>
            <Icon icon="clarity:times-line" className='icon-return' />
          </Button>
        </div>

        {!displayCongratulationPage ?
          <>
            <div className="header-part">
              <h1 className="title">S'inscrire</h1>
              <div className='subtitle-conatiner'>
                <p className="subtitle"> Bienvenue sur Afri-Norariat </p>
                <small>Inscrivez-vous pour accédez à votre espace.</small>
              </div>
            </div>
            <div className="form-part">
              <Box sx={{ width: '100%' }}>
                <div className='stepper-label'>
                  Etape : <b>{etapeLabel} </b>
                </div>
                <Stepper activeStep={activeStep}>
                  {
                    steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};

                      return (
                        <Step key={index} {...stepProps}>
                          <StepLabel {...labelProps}>
                            {/* {label} */}
                          </StepLabel>
                        </Step>
                      );
                    })}
                </Stepper>

                <div className='stepper-body'>

                  {activeStep === 0 &&
                    /*** Partie une */
                    <div className='stepper-item'>
                      <div className='row fs-row'>
                          <div className='col-12 mb-3'>
                              <label className='bs-label-form'> Nom et nos prénoms <sup>*</sup></label>
                              <input type="text" className='form-control bs-input-custom' name='nom' autoComplete='off' placeholder='Votre nom et nos prénoms'></input>
                          </div>
                      </div>

                      <div className='row fs-row'>
                          <div className='col-12 mb-3'>
                              <label className='bs-label-form'>Adresse mail <sup>*</sup></label>
                              <input type="email" className='form-control bs-input-custom' name='prenoms' placeholder='Votre adresse mail' autoComplete='off' ></input>
                          </div>
                      </div>

                      <div className='row fs-row'>
                          <div className='col-12 mb-3'>
                              <label className='bs-label-form'> Numéro de téléphone <sup>*</sup></label>
                              <input type="text" className='form-control bs-input-custom' name='nom' autoComplete='off' placeholder='Votre numéro de téléphone'></input>
                          </div>
                      </div>

                      {/* <TextField placeholder="Votre nom et nos prénoms"
                        variant="outlined"
                        label="Nom et prénoms"
                        type="text"
                        size="small"
                        sx={{ marginTop: '20px', marginBottom: '0px' }} />
                      <TextField placeholder="Votre adresse mail"
                        variant="outlined"
                        label="Adresse mail"
                        type="text"
                        sx={{ marginTop: '20px', marginBottom: '0px' }}
                        size="small" />
                      <TextField placeholder="Votre numéro de téléphone"
                        variant="outlined"
                        label="Numéro de téléphone"
                        type="text"
                        sx={{ marginTop: '20px', marginBottom: '0px' }}
                        size="small" /> */}
                    </div>

                    /** Fin partie une */
                  }

                  {activeStep === 1 &&
                    /*** Partie deux */
                    <div className='stepper-item'>
                      <div className='row fs-row'>
                          <div className='col-12 mb-3'>
                              <label className='bs-label-form'> Nom du cabinet <sup>*</sup></label>
                              <input type="text" className='form-control bs-input-custom' name='nom' autoComplete='off' placeholder='Votre nom du cabinet'></input>
                          </div>
                      </div>

                      <div className='row fs-row'>
                          <div className='col-12 mb-3'>
                              <label className='bs-label-form'>Matricule UNIL <sup>*</sup></label>
                              <input type="text" className='form-control bs-input-custom' name='prenoms' placeholder='Votre matricule UNIL' autoComplete='off' ></input>
                          </div>
                      </div>

                      <div className='row fs-row'>
                          <div className='col-12 mb-3'>
                              <label className='bs-label-form'>Situation géographique</label>
                              <textarea className="form-control bs-input-custom" placeholder='Votre situation géographique' rows={3}></textarea>
                          </div>
                      </div>

                      {/* <TextField placeholder="Votre nom du cabinet"
                        className='fs-input'
                        variant="outlined"
                        label="Nom du cabinet"
                        type="text"
                        sx={{ marginTop: '20px', marginBottom: '0px' }}
                        size="small" />
                      <TextField placeholder="Votre matricule UNIL"
                        className='fs-input'
                        variant="outlined"
                        label="matricule UNIL"
                        type="text"
                        sx={{ marginTop: '20px', marginBottom: '0px' }}
                        size="small" />
                      <TextField
                        className='textarea'
                        id="outlined-multiline-static"
                        label="Situation géographique"
                        multiline
                        rows={3}
                        sx={{ marginTop: '20px' }} /> */}
                    </div>
                    /** Fin partie deux */
                  }

                  {(activeStep === 2 ) &&
                    /*** Partie trois */
                    <div className='stepper-item'>
                      <TableContainer component={Paper}>
                        <Table sx={{ backgroundColor: "transparent !important" }} aria-label="simple table">
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow
                                key={row.label}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  {row.label}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                    /** Fin partie trois */
                  }

                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    {
                      (activeStep !== 0) &&
                      <Button
                        className='stepper-return'
                        variant="contained"
                        color='primary'
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        <Icon icon="bi:arrow-left" className='icon-return' />
                      </Button>
                    }
                    <Box sx={{ flex: '1 1 auto' }} />
                    {
                      <Button onClick={handleNext}
                        variant="contained"
                        color='primary'
                        className={`${activeStep === 0 ? "fullbutton" : ""}`}
                      >
                        {activeStep === steps.length - 1 ? 'Enregistrer' : 'Continuer'}
                      </Button>
                       
                    }
                  </Box>
                </div>

              </Box>
            </div>
          </>
          :
          <>
            <div className="acces-offres">
              <div className="header-part">
                <h1 className="title">
                😉
                </h1>
                <p className="subtitle"> Bienvenue sur Afri-Norariat </p>
                <small>Vous avez créés votre compte avec succès</small>
                <small>Pour commencer à profiter de notre application choisissez une offre</small>
                <small>Obtenez des offres plus compétitives sur des services comparables</small>
              </div>
              <Button onClick={() => goTo(routePaths.portailChildren.offresAbsolute)}
                      variant="contained"
                      color='primary'
                    >
                Profiter des offres
              </Button>
              {displayConfetti && <img src={confetis} alt="" />} 
            </div>
          </>
        }


        <div className="footer-part">
          <p> Vous avez déjà un compte ?
            <Link className="link-primary"
              to={routePaths.authChildren.signInAbsolute}> Connectez-vous </Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default SignupForm;
