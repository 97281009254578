import React, { FC, useEffect, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  frFR,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  // Pagination,
} from "@mui/material";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
// import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ModeIcon from "@mui/icons-material/Mode";
import DeleteIcon from "@mui/icons-material/Delete";
import Panel from "@/components/UICs/Panel/Panel.uic";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import "./DocumentType.style.scss";
import { upperFirstWordLetter } from "@/utils/firstLetterUpper";
import { TTypePiece } from "@/features/admin/types";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { FormikHelpers } from "formik";
import DocumentTypeForm, {
  TTypePieceFormsValues,
} from "@/features/admin/forms/DocumentType/DocumentType.form";
import {
  createTypePiece,
  deleteTypePiece,
  getAllTypePiece,
  updateTypePiece,
} from "@/features/admin/api/typePiece.api";
import { formatDate } from "@/utils/formatDate";
import DeleteDialog from "@/components/UICs/DeleteDialog/DeleteDialog.uix";
import Swal from "sweetalert2";
import OwnerTable from "@/components/UICs/OwnerTable/OwnerTable";

const rowsPerPage = 7;

type DropdownMenuProps = {
  currentDocType: any;
  refreshDocument: () => void;
};

const DropdownMenu: FC<DropdownMenuProps> = ({
  currentDocType,
  refreshDocument,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openPanel, setOpenPanel] = useState<boolean>(false);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**Confirmation Suppression d'un Type de document  */
  const confirmDeleteDocType = () => {
    handleClose();
    Swal.fire({
      title: " Suppression",
      text: "Vous êtes sur le point de supprimer un Type de document !\nDésirez-vous poursuivre cette action ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      confirmButtonColor: "#006D77",
      cancelButtonColor: "#91949c",
      reverseButtons: true,
    }).then(result => {
      if (result.isConfirmed) {
        // setIsLoading(true);
        deleteDocType();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  };

  const deleteDocType = async () => {
    const response = await deleteTypePiece(currentDocType.id);
    const { error, message } = response;
    if (error) {
      ToastError.fire({
        text: message,
        timer: 9000,
      });
      return;
    }
    ToastGeneric(
      `le type de document "${currentDocType.name}" a été supprimé avec succès`,
      "success",
      3000
    ).fire();
    refreshDocument();
  };

  const updateItem = async (
    values: TTypePieceFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    // console.log({ currentDocType });
    const toSend = {
      name: values.name,
      moral_person: values.moral_person,
      physic_person: values.physic_person,
      bien: values.bien,
      folder: values.folder,
    };
    const response = await updateTypePiece(toSend, currentDocType.id);
    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric(
        "Libellé du type de document  modifié avec succès",
        "success",
        3000
      ).fire();
      setOpenPanel(false);
      refreshDocument();
    }
    setSubmitting(false);
  };

  const handleSubmit = (
    values: TTypePieceFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TTypePieceFormsValues>
  ) => {
    updateItem(values, setSubmitting);
  };
  // console.log("currentDocType: ", currentDocType);
  return (
    <>
      <IconButton onClick={handleClick}>
        <MoreHorizIcon />
        <div></div>
      </IconButton>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonSearchIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Consulter</ListItemText>
        </MenuItem> */}
        <MenuItem
          onClick={() => {
            setOpenPanel(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <ModeIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Modifier</ListItemText>
        </MenuItem>
        <MenuItem onClick={confirmDeleteDocType}>
          <ListItemIcon>
            <DeleteIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>Supprimer</ListItemText>
        </MenuItem>
      </Menu>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Modifier le libelle du document"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <DocumentTypeForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          currentDocType={currentDocType}
          isForUpdate={true}
          refreshDocument={refreshDocument}
          handleSubmit={handleSubmit}
        />
      </Panel>
      {/* <DeleteDialog
        alertTitle={`Voulez-vous supprimer le type de document`}
        elementToDelete={` ${currentDocType.name}`}
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        performedFunction={deleteDocType}
      /> */}
    </>
  );
};

const CDocumentType = () => {
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Libelle",
      flex: 1,
      sortable: true,
    },
    {
      field: "moral_person_display",
      headerName: "Type moral",
      flex: 1,
      sortable: true,
    },
    {
      field: "physic_person_display",
      headerName: "Type physique",
      flex: 1,
      sortable: true,
    },
    {
      field: "bien_display",
      headerName: "Type bien",
      flex: 1,
      sortable: true,
    },
    {
      field: "folder_display",
      headerName: "Type dossier",
      flex: 1,
      sortable: true,
    },
    {
      field: "created_date",
      headerName: "Date de création",
      flex: 1,
      sortable: true,
    },
    {
      field: "is_actif",
      headerName: "Statut",
      flex: 1,
      sortable: true,
    },
    {
      field: "actions",
      headerName: "",
      width: 60,
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <DropdownMenu
            currentDocType={row}
            refreshDocument={refreshDocument}
          />
        );
      }, //TODO : check params
    },
  ];

  const [openPanel, setOpenPanel] = useState<boolean>(false);
  // const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(rowsPerPage);
  const [typePieceData, setTypePieceData] = useState<TTypePiece[]>([]);
  const [loadDocType, setLoadDocType] = useState(true);

  // const pageChange = (event: any, value: any) => {
  //   setPage(value);
  // };

  const getTypePiece = async () => {
    const { data } = await getAllTypePiece();
    const dataWithNewObjectKeys: TTypePiece[] = data.map((typePiece: any) => {
      return {
        id: typePiece._id,
        name: upperFirstWordLetter(typePiece.name),
        moral_person: typePiece.moral_person,
        physic_person: typePiece.physic_person,
        bien: typePiece.bien,
        folder: typePiece.folder,
        moral_person_display: typePiece.moral_person ? "Oui" : "Non",
        physic_person_display: typePiece.physic_person ? "Oui" : "Non",
        bien_display: typePiece.bien ? "Oui" : "Non",
        folder_display: typePiece.folder ? "Oui" : "Non",
        created_date: formatDate(typePiece.created_date),
        is_actif: typePiece.is_actif === 1 ? "Actif" : "Inactif",
      };
    });
    setTypePieceData(dataWithNewObjectKeys);
  };

  const refreshDocument = async () => {
    setLoadDocType(true);
    await getTypePiece();
    setLoadDocType(false);
  };

  useEffect(() => {
    getTypePiece();
  }, [loadDocType]);

  const saveItem = async (
    typePiece: TTypePieceFormsValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const response = await createTypePiece(typePiece);

    if (response.error) {
      ToastError.fire({ text: response.message, timer: 9000 });
    } else {
      ToastGeneric("Type de document crée avec succès", "success", 3000).fire();
      setOpenPanel(false);
      refreshDocument();
    }
    setSubmitting(false);
  };
  const handleSubmit = (
    values: TTypePieceFormsValues,
    { resetForm, setSubmitting }: FormikHelpers<TTypePieceFormsValues>
  ) => {
    console.log(" values ", values);
    saveItem(values, setSubmitting);
  };

  return (
    <>
      <div className='c-list-users'>
        <div className='utilisateur-content'>
          <Box>
            <Grid container rowSpacing={4} columnSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <div className='utilisateur-table-part'>
                  <div
                    className='utilisateur-table-header'
                    style={{ height: "55px" }}
                  >
                    <Button
                      color='primary'
                      onClick={e => setOpenPanel(true)}
                      variant='contained'
                      title='Créer un type de document'
                      disableElevation
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Créer un type de document
                    </Button>
                  </div>
                  <div className='utilisateur-table-body'>
                    <Box sx={{ height: 500, width: "100%" }}>
                      <OwnerTable columns={columns} rows={typePieceData} />
                      {/* <DataGrid
                        components={{ Toolbar: GridToolbar }}
                        localeText={
                          frFR.components.MuiDataGrid.defaultProps.localeText
                        }
                        rows={typePieceData}
                        columns={columns}
                        // hideFooterPagination={true}
                        checkboxSelection={false}
                        disableSelectionOnClick={true}
                        disableColumnMenu
                        pageSize={pageSize}
                        rowsPerPageOptions={[7, 15, 30]}
                        onPageSizeChange={newPageSize =>
                          setPageSize(newPageSize)
                        }
                        sx={{
                          fontFamily: "'Raleway',sans-serif !important",
                          border: "none",
                          "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#ececec",
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-columnHeaderTitle": {
                            color: "var(--ui-primary)",
                            fontWeight: "bold",
                          },
                          "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row:nth-of-type(even)":
                            {
                              backgroundColor: "rgba(var(--ui-light-rgb), 0.7)",
                            },
                          "& .MuiDataGrid-cell": {
                            borderBottom: "none",
                          },
                          "& .MuiDataGrid-footerContainer": {
                            borderTop: "none",
                            minHeight: "5px",
                          },
                          "& .MuiTablePagination-toolbar": {
                            "& > p": {
                              marginBottom: 0,
                            },
                          },
                        }}
                      /> */}
                      {/* <div className="pagination">
                        <Pagination
                          count={Math.ceil(10 / rowsPerPage)}
                          page={page}
                          onChange={pageChange}
                          showFirstButton
                          showLastButton
                          variant="outlined"
                          shape="rounded"
                        />
                      </div> */}
                    </Box>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
      <Panel
        toggleOpen={openPanel}
        hasBackDrop={true}
        title={"Création de type de document"}
        closeSidePanel={() => {
          setOpenPanel(false);
        }}
      >
        <DocumentTypeForm
          closeSidePanel={() => {
            setOpenPanel(false);
          }}
          refreshDocument={refreshDocument}
          handleSubmit={handleSubmit}
        />
      </Panel>
    </>
  );
};

export default CDocumentType;
