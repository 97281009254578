import moment from "moment";

export const formatDate = (newDate: any) => {
  const d = newDate;

  let dateSplitByT = d?.toString().split("T");
  let finalDate: any;

  if (dateSplitByT && dateSplitByT.length > 1) {
    let dateSplitFirstPart = dateSplitByT[0]?.split("-"); // On split les text part les - pr avoir jr mois annee
    let dateSplitSecondPart = dateSplitByT[1]?.split(":");

    finalDate = moment(
      new Date(
        dateSplitFirstPart[0],
        dateSplitFirstPart[1] - 1,
        dateSplitFirstPart[2],
        dateSplitSecondPart[0],
        dateSplitSecondPart[1]
      )
    ).format("DD/MM/YYYY hh:mm");
  }

  return finalDate;
};

export const formatDateInIsoDate = (date: any) => {
  let dateReturn: any;

  if (date) {
    let firstSplit = date?.split("/");
    dateReturn = new Date(firstSplit[2], firstSplit[1] - 1, firstSplit[0]);
  }

  return dateReturn;
};
