import { routePaths } from "@/config";
import { store } from "@/store";
import { updateCurrentFolder } from "@/store/reducers/folder/folder.action";
import { useNavigate } from "react-router-dom";
import CChooseTypeFolder from "../../containers/Dossier/ChooseTypeFolder/ChooseTypeFolder.container";

import FSHeader from "../../Elements/FSHeader/FSHeader.element";

const CreateFolderPage = () => {
  const navigate = useNavigate();
  const dispatch = store.dispatch;
  
  return (
    <div className="fs-container">
      <FSHeader
        title="Gestion des dossiers"
        icon="ant-design:folder-open-filled"
        breadscrumbTitle="Types de dossiers"
        closeButton={true}
        onClose={() =>{
          navigate(`${routePaths.admin}${routePaths.adminChildren.folder}`);
          dispatch(updateCurrentFolder(null!));
        }
        }
      />

      <CChooseTypeFolder />
    </div>
  );
};

export default CreateFolderPage;
