import { useLocation, useNavigate } from "react-router-dom";

import { routePaths } from "@/config";

import iconImmobilier from "@/assets/images/svg/iconeImmobilierCreationDossier.svg";
import iconAffaire from "@/assets/images/svg/iconeAffaireCreationDossier.svg";
import iconFamille from "@/assets/images/svg/iconFamilleCreationDossier.svg";

import "./ChooseTypeFolder.styles.scss";

const CChooseTypeFolder = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const gotoCreateFolder = (route: string) => {
  //   navigate(route, { replace: true });
  // };

  return (
    <div className='folder-content'>
      <div className='folder-header'>
        <span> Quel type de dossier voulez-vous ouvrir ? </span>
        <p>Faites votre choix dans l’ensemble des types suivants</p>
      </div>

      <div className='folder-body'>
        <div
          className='menu-item'
          onClick={() =>
            navigate(
              `${location.pathname}${routePaths.adminChildren.folderChildren.createFolderBienImmobilier}`
            )
          }
        >
          <span>Immobiliers</span>
          <img src={iconImmobilier} alt='' />
        </div>
        <div className='menu-item desabled'>
          <span>Famille</span>
          <img src={iconFamille} alt='' />
        </div>
        <div className='menu-item desabled'>
          <span>Affaires</span>
          <img src={iconAffaire} alt='' />
        </div>
      </div>
      {/* <button type='button' onClick={() => gotoCreateFolder(routePaths.adminChildren.folderChildren.createFolderBienImmobilierAbsolute)}> Bien immobilier </button> */}
    </div>
  );
};

export default CChooseTypeFolder;
