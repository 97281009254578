import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import CDocumentType from "../../containers/Parameters/DocumentType/DocumentType.container";
import CListTexts from "../../containers/Parameters/ListTexts/ListTexts.container";
import Nationality from "../../containers/Parameters/Nationality/Nationality.container";
import CSettingBasic from "../../containers/Parameters/SettingBasic/SettingBasic.container";

import FSHeader from "../../Elements/FSHeader/FSHeader.element";


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const ManageTextsPage = () => {

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  
  return (
    <div className="fs-container">
      <FSHeader title="Gestion des paramètres" icon="fa-solid:users-cog" breadscrumbTitle="Administration"/>
      <Box className="admin-tabs" sx={{ maxWidth: { xs: "100%", sm: "100%" }, bgcolor: 'background.paper' }}>
        <Tabs
          className="setting-tabs"
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          <Tab label="Type de document" />
          <Tab label="Nationalités" />
          <Tab label="Modèle d'acte" />
          <Tab label="Paramètres de base" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <CDocumentType />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Nationality />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CListTexts />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <CSettingBasic />
        </TabPanel>
      </Box>
    </div>
  );
};

export default ManageTextsPage;
