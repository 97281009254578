import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import * as Yup from "yup";
import { useFormik } from "formik";
import "./Property.style.scss";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import {
  createProperty,
  getAllCommune,
  getAllTypeProperty,
  updateProperty,
} from "../../api/Property.api";
import { TProperty } from "../../types";
import { ToastError, ToastGeneric } from "@/utils/toast";
import { Loader } from "@/components/Loader/Loader";

export type TPropertyFormsValues = {
  property_type_id: string;
  commune_id: string;
  neighborhood: string;
  nom_lotissement: string;
  number_lot: string;
  number_ilot: string;
  number_title_land: string;
  mortage_situation: string;
  relative_effect: string;
  office: string;
  folder_id: string;
};

export type TTypeProperty = {
  id: string;
  label: string;
};
export type TTypeCommune = {
  id: string;
  label: string;
};

type TPropertyFormProps = {
  property?: TProperty;
  folder_id?: string;
  action: number | undefined;
  // isUpdated?: boolean;
  // onlyReading?: boolean;
  handleClose: React.Dispatch<React.SetStateAction<boolean>>;
  refreshPropertyList: React.Dispatch<React.SetStateAction<string>>;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TPropertyFormsValues>(AlertMessage);

const PropertyForm: React.FC<TPropertyFormProps> = ({
  property,
  folder_id,
  action,
  // isUpdated,
  // onlyReading,
  handleClose,
  refreshPropertyList,
}) => {
  const [typePropertyData, setTypePropertyData] = useState<TTypeProperty[]>([]);
  const [communeData, setCommuneData] = useState<TTypeCommune[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [onlyReading, setOnlyReading] = useState(false);

  const validationSchema: any = Yup.object({
    property_type_id: Yup.string().required("Le type de bien est requis"),
    commune_id: Yup.string().required("La commune est requise"),
    libelle: Yup.string().required("Le libelle du bien est requise"),
    neighborhood: Yup.string().required("Le quartier est requis"),
    nom_lotissement: Yup.string().required("Le nom du lotissement est requis"),
    number_lot: Yup.string().required("Le numéro du lot est requis"),
    number_ilot: Yup.string().required("Le numéro du ilot est requis"),
    property_prix_vente: Yup.string().required("Le prix du bien est requis"),
    number_title_land: Yup.string().required(
      "Le  numéro du titre foncier est requis"
    ),
    num_etat_foncier: Yup.string().required(
      "Le numéro etat foncier est requis"
    ),
    date_etatblissement: Yup.string().required(
      "La date d'établissement est requise"
    ),
    autorite: Yup.string().required("L'autorité est requise"),
    mortage_situation: Yup.string().required(
      "La situation hypothécaire est requise"
    ),
    relative_effect: Yup.string().required("L'effet relatif est requis"),
    office: Yup.string().required("Le bureau de la conservation est requis"),
  });

  // console.log({ property });

  const formik = useFormik({
    initialValues: {
      property_type_id: property?.type_bien_id || "",
      property_prix_vente: property?.prix_vente || "",
      libelle: property?.libelle || "",
      commune_id: property?.commune_id || "",
      neighborhood: property?.quartier || "",
      nom_lotissement: property?.nom_lotissement || "",
      number_lot: property?.num_lot || "",
      number_ilot: property?.num_ilot || "",
      number_title_land: property?.num_foncier || "",
      num_etat_foncier: property?.num_etat_foncier || "",
      date_etatblissement: property?.date_etatblissement || "",
      autorite: property?.autorite || "",
      mortage_situation: property?.situation_hypot || "",
      relative_effect: property?.effet_relatif || "",
      office: property?.bureau || "",
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      const propertyToSend = {
        libelle: values?.libelle,
        type_bien: values?.property_type_id,
        prix_vente: values?.property_prix_vente,
        num_titre_foncier: values?.number_title_land,
        quartier: values?.neighborhood,
        nom_lotissement: values?.nom_lotissement,
        commune_id: values?.commune_id,
        numero_lot: values?.number_lot,
        numero_ilot: values?.number_ilot,
        effet_relatif: values?.relative_effect,
        situation_hypothecaire: values?.mortage_situation,
        bureau_conservation: values?.office,
        etat_foncier: {
          num_etat_foncier: values?.num_etat_foncier,
          date_etatblissement: values?.date_etatblissement,
          autorite: values?.autorite,
        },
      };
      // alert(JSON.stringify(values, null, 2));
      const dataToSend = { ...propertyToSend, dossier_id: folder_id };
      setIsLoading(true);
      const response = property?.id
        ? await updateProperty(dataToSend, property.id)
        : await createProperty(dataToSend);
      setIsLoading(false);
      if (response.error) {
        ToastError.fire({ text: response.message, timer: 9000 });
      } else {
        !property?.id
          ? ToastGeneric("Bien créé avec succès", "success", 10000).fire()
          : ToastGeneric(
              "Bien mis à jour avec succès",
              "success",
              10000
            ).fire();
        handleClose(false);
        refreshPropertyList?.(new Date().getTime().toString());
      }
    },
  });

  // La liste des types des biens
  const getTypeProperty = async () => {
    const { data } = await getAllTypeProperty();
    const dataWithNewObjectKeys: TTypeProperty[] = data.map(
      (typeProperty: any) => {
        return {
          id: typeProperty._id,
          label: typeProperty.libelle,
        };
      }
    );
    setTypePropertyData(dataWithNewObjectKeys);
  };

  // La liste des types des biens
  const getCommune = async () => {
    const { data } = await getAllCommune();
    const dataWithNewObjectKeys: TTypeProperty[] = data.map((commune: any) => {
      return {
        id: commune._id,
        label: commune.libelle,
      };
    });

    setCommuneData(dataWithNewObjectKeys);
  };

  useEffect(() => {
    getTypeProperty();
    getCommune();
    if (action === 3) {
      setOnlyReading(true);
    }
  }, [action]);

  return (
    <>
      <form className='form-utilisateur' onSubmit={formik.handleSubmit}>
        <Loader isLoading={isLoading} />
        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Type de bien <sup>*</sup>
            </label>
            <select
              id='inputState'
              name='property_type_id'
              className='form-select bs-input-custom'
              placeholder='Selectionner le type de bien'
              disabled={onlyReading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.property_type_id}
            >
              <option value={""}>Choisissez</option>
              {/* <option value="terrain_nu">Terrain nu</option>
              <option value="bien_construit">Bien construit</option> */}
              {typePropertyData.map(item => (
                <option key={item.id} value={item.id}>
                  {item?.label}
                </option>
              ))}
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='property_type_id'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Commune <sup>*</sup>
            </label>
            <select
              id='inputState'
              name='commune_id'
              className='form-select bs-input-custom'
              placeholder='Selectionner la commune'
              disabled={onlyReading}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.commune_id}
            >
              <option value={""}>Choisissez</option>
              {/* <option value="terrain_nu">Terrain nu</option>
              <option value="bien_construit">Bien construit</option> */}
              {communeData.map(item => (
                <option key={item.id} value={item.id}>
                  {item.label}
                </option>
              ))}
            </select>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='commune_id'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Libelle du Bien <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='libelle'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer le libelle du bien'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.libelle}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='libelle'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Prix de Bien <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='property_prix_vente'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer le prix du bien'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.property_prix_vente}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='property_prix_vente'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Quartier <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='neighborhood'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer le quartier'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.neighborhood}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='neighborhood'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>Nom du lotissement</label>
            <input
              className='form-control bs-input-custom'
              name='nom_lotissement'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer le nom du lotissement'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.nom_lotissement}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='nom_lotissement'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Numéro du lot <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='number_lot'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer le numéro du lot'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.number_lot}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='number_lot'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Numéro de l'îlot <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='number_ilot'
              autoComplete='off'
              disabled={onlyReading}
              placeholder="Entrer le numéro de l'ilot"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.number_ilot}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='number_ilot'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Numéro du Titre Foncier <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='number_title_land'
              disabled={onlyReading}
              autoComplete='off'
              placeholder='Entrer le numéro du titre foncier'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.number_title_land}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='number_title_land'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Numéro d'état Foncier <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='num_etat_foncier'
              disabled={onlyReading}
              autoComplete='off'
              placeholder="Entrer le numéro de l'état foncier"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.num_etat_foncier}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='num_etat_foncier'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Date d'établissement <sup>*</sup>
            </label>
            <input
              type='date'
              className='form-control bs-input-custom'
              name='date_etatblissement'
              disabled={onlyReading}
              autoComplete='off'
              placeholder="Entrer la date d'établissement de l'état foncier"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.date_etatblissement}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='date_etatblissement'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Autorité <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='autorite'
              disabled={onlyReading}
              autoComplete='off'
              placeholder="Entrer l'autorité"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.autorite}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='autorite'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Situation hypothécaire (si le bien fait l'objet d'une hypothèque
              ou tout autre inscription)<sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='mortage_situation'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer la situation hypothécaire'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mortage_situation}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='mortage_situation'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Effet relatif (comment le propriétaire en a eu la propriété).
              <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='relative_effect'
              autoComplete='off'
              disabled={onlyReading}
              placeholder="Entrer l'effet relatif"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.relative_effect}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='relative_effect'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>
        <div className='row fs-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 mb-3 px-3'>
            <label className='bs-label-form'>
              Bureau de la conservation du lieu de situation <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='office'
              autoComplete='off'
              disabled={onlyReading}
              placeholder='Entrer le bureau de la conservation du lieu de situation'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.office}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='office'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className='modify-button'>
          {property?.id && action === 4 && (
            <Button type='submit' variant='contained' color='primary'>
              MODIFIER
            </Button>
          )}
          {!property?.id && (
            <Button type='submit' variant='contained' color='primary'>
              ENREGISTRER
            </Button>
          )}
        </div>
      </form>
    </>
  );
};
export default PropertyForm;
