import React from 'react';
import { Button } from '@mui/material';
import { FormikHelpers, useFormik } from 'formik';
import * as Yup from 'yup';
import './Confrere.styles.scss';
import WithFormikErrorMessage from '@/components/HOCs/WithFormikErrorMessage.hoc';
import AlertMessage from '@/components/UICs/AlertMessage/AlertMessage.uic';
import { TConfrere } from '../../types/types';

export type TConfrereFormsValues = {
  libelle: string;
  nbre_associe: number;
  situation_geo: string;
  description: string;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TConfrereFormsValues>(AlertMessage);

type ConfrereFormProps = {
  closeSidePanel: () => void;
  refreshConfrere: () => void;
  handleSubmit: (
    values: TConfrereFormsValues,
    FormikHelpers: FormikHelpers<TConfrereFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  currentConfrere?: TConfrere;
};

const ConfrereForm: React.FC<ConfrereFormProps> = ({
  handleSubmit,
  currentConfrere,
}) => {
  const validationSchema: any = Yup.object({
    libelle: Yup.string().required('Le libellé est requis'),
    nbre_associe: Yup.number().required("Le nombre d'associé est requis"),
    situation_geo: Yup.string().required(
      'La situation géographique est requise'
    ),
    description: Yup.string().required('La description est requise'),
  });
  const formik = useFormik({
    initialValues: {
      libelle: currentConfrere?.libelle || '',
      nbre_associe: currentConfrere?.nbre_associe || 0,
      situation_geo: currentConfrere?.situation_geo || '',
      description: currentConfrere?.description || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className="form-confreres" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Libellé <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="libelle"
              autoComplete="off"
              placeholder="Libellé"
              onChange={formik.handleChange}
              value={formik.values.libelle}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="libelle"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Nombre d'associé <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="nbre_associe"
              placeholder="Nombre d'associé"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.nbre_associe}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="nbre_associe"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {' '}
              Situation géographique <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="situation_geo"
              placeholder="Situation géographique"
              autoComplete="off"
              onChange={formik.handleChange}
              value={formik.values.situation_geo}
            ></input>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="situation_geo"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form"> Description </label>
            <textarea
              rows={3}
              className="form-control bs-input-custom"
              name="description"
              placeholder="Description "
              onChange={formik.handleChange}
              value={formik.values.description}
            ></textarea>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="description"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            <Button
              type="button"
              color="secondary"
              variant="outlined"
              disableElevation
            >
              Annuler
            </Button>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ConfrereForm;
