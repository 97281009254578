import React from "react";
import { Button } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";

import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";

import { TTypePieceProperKeys } from "../../types";

import "./DocumentType.form.style.scss";

export type TTypePieceFormsValues = {
  name: string;
  moral_person: boolean;
  physic_person: boolean;
  bien: boolean;
  folder: boolean;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TTypePieceFormsValues>(AlertMessage);

type TipePieceFormProps = {
  closeSidePanel: () => void;
  refreshDocument: () => void;
  handleSubmit: (
    values: TTypePieceFormsValues,
    FormikHelpers: FormikHelpers<TTypePieceFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  currentDocType?: TTypePieceProperKeys;
};

const DocumentTypeForm = ({
  closeSidePanel,
  refreshDocument,
  handleSubmit,
  currentDocType,
}: TipePieceFormProps): JSX.Element => {
  const validationSchema: any = Yup.object({
    name: Yup.string().required("Le libellé est requis"),
    moral_person: Yup.boolean(),
    physic_person: Yup.boolean(),
    bien: Yup.boolean(),
    folder: Yup.boolean(),
  }).test(
    "at-least-one-checked",
    "Au moins une case doit être cochée",
    function (values) {
      const { moral_person, physic_person, bien, folder } = values;
      if (!moral_person && !physic_person && !bien && !folder) {
        return this.createError({
          path: "bien", // Vous pouvez choisir n'importe quel chemin ici
          message: "Au moins une case doit être cochée",
        });
      }
      return false;
    }
  );

  const formik = useFormik({
    initialValues: {
      name: currentDocType?.name || "",
      moral_person: currentDocType?.moral_person || false,
      physic_person: currentDocType?.physic_person || false,
      bien: currentDocType?.bien || false,
      folder: currentDocType?.folder || false,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    // onSubmit: values => {
    //   // Soumettre les valeurs du formulaire si la validation réussit
    //   console.log(values);
    // },
  });

  console.log("currentDocType: ", currentDocType);

  return (
    <>
      <form className='form-utilisateur' onSubmit={formik.handleSubmit}>
        <div className='row'>
          <div className='col-12 mb-3'>
            <label className='bs-label-form'>
              Libellé du document <sup>*</sup>
            </label>
            <input
              className='form-control bs-input-custom'
              name='name'
              autoComplete='off'
              placeholder='Libellé type de document'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='name'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-12 mb-3'>
            <div className='checkbox-container'>
              <input
                type='checkbox'
                name='moral_person'
                id=''
                checked={formik.values.moral_person}
                onChange={formik.handleChange}
              />
              <label htmlFor=''>
                Cette pièce peut être utiliser pour les personnes morales ?
              </label>
            </div>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='moral_person'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-12 mb-3'>
            <div className='checkbox-container'>
              <input
                type='checkbox'
                name='physic_person'
                id=''
                checked={formik.values.physic_person}
                onChange={formik.handleChange}
              />
              <label htmlFor=''>
                Cette pièce peut être utiliser pour les personnes Physiques ?
              </label>
            </div>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='physic_person'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-12 mb-3'>
            <div className='checkbox-container'>
              <input
                type='checkbox'
                name='bien'
                id=''
                checked={formik.values.bien}
                onChange={formik.handleChange}
              />
              <label htmlFor=''>
                Cette pièce peut être utiliser pour les biens ?
              </label>
            </div>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='bien'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
          <div className='col-12 mb-3'>
            <div className='checkbox-container'>
              <input
                type='checkbox'
                name='folder'
                id=''
                checked={formik.values.folder}
                onChange={formik.handleChange}
              />
              <label htmlFor=''>
                Cette pièce peut être utiliser pour les dossiers ?
              </label>
            </div>
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name='folder'
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className='row'>
          <div className='btn-manager'>
            {!currentDocType && (
              <Button
                type='button'
                color='secondary'
                variant='outlined'
                disableElevation
              >
                Annuler
              </Button>
            )}

            <LoadingButton
              type='submit'
              color='primary'
              variant='contained'
              disableElevation
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {currentDocType ? "Modifier" : "Enregistrer"}
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default DocumentTypeForm;
