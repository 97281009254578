import React from "react";

import CListPartners from "../../containers/Community/ListPartners.container";

import FSHeader from "../../Elements/FSHeader/FSHeader.element";

const PartnersPage = () => {
  return (
    <div className="fs-container">
      <FSHeader title="Partenaires" icon="fa6-solid:people-roof" breadscrumbTitle="Communauté"/>

      <CListPartners />
    </div>
  );
};

export default PartnersPage;
