import { routePaths } from '@/config';
import { FC } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import LHome from '../layouts/Home.layout';
import Home from '../pages/Home.page';
import Offres from '../pages/Offres.page';
import { connect, ConnectedProps } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserConnected } from '@/store/reducers/user/user.selector';

type PropsFromRedux = ConnectedProps<typeof connector>;

const PortailRoutesFunc: FC<PropsFromRedux> = ({ userConnected }) => {
  const navigate = useNavigate();
  if (userConnected) navigate(routePaths.admin);
  return (
    <Routes>
      <Route path="" element={<LHome />}>
        <Route index element={<Home />} />
        <Route path={routePaths.portailChildren.offres} element={<Offres />} />
      </Route>
    </Routes>
  );
};

const mapStateToProps = createStructuredSelector({
  userConnected: selectUserConnected,
});

const connector = connect(mapStateToProps);
const PortailRoutes = connector(PortailRoutesFunc);

export default PortailRoutes;
