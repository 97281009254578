import React from "react";
import { Outlet } from "react-router-dom";

import "./Dossier.styles.scss";

const LDossier: React.FC = () => {
  return (
    <div className="ct-dossier">
      <Outlet />
    </div>
  );
};

export default LDossier;
