import React from "react";
import { Button } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import WithFormikErrorMessage from "@/components/HOCs/WithFormikErrorMessage.hoc";
import AlertMessage from "@/components/UICs/AlertMessage/AlertMessage.uic";
import { TTypeActor } from "../../types";
import "./TypeActor.form.style.scss";


export type TTypeActorFormsValues = {
  libelle: string;
};

const FormErrorDisplayer =
  WithFormikErrorMessage<TTypeActorFormsValues>(AlertMessage);

type TypeActorFormProps = {
  closeSidePanel: () => void;
  refreshTypeActor: () => void;
  handleSubmit: (
    values: TTypeActorFormsValues,
    FormikHelpers: FormikHelpers<TTypeActorFormsValues>
  ) => void | Promise<any>;
  isForUpdate?: boolean;
  ruccentTypeActor?: TTypeActor;
};

const TypeActorForm = ({
  handleSubmit,
  ruccentTypeActor,
}: TypeActorFormProps): JSX.Element => {
  const validationSchema: any = Yup.object({
    libelle: Yup.string().required("Le libellé est requis"),
  });

  const formik = useFormik({
    initialValues: {
      libelle: ruccentTypeActor?.libelle || "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <form className="form-utilisateur" onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <label className="bs-label-form">
              {" "}
              Libellé Acteur <sup>*</sup>
            </label>
            <input
              className="form-control bs-input-custom"
              name="libelle"
              autoComplete="off"
              placeholder="Libellé de l'acteur"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.libelle}
            />
            <FormErrorDisplayer
              touched={formik.touched}
              errors={formik.errors}
              name="libelle"
              simple={true}
              mt={5}
              mb={0}
            />
          </div>
        </div>

        <div className="row">
          <div className="btn-manager">
            {!ruccentTypeActor && (
              <Button
                type="button"
                color="secondary"
                variant="outlined"
                disableElevation
              >
                Annuler
              </Button>
            )}

            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              loading={formik.isSubmitting}
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {ruccentTypeActor ? "Modifier" : "Enregistrer"}
            </LoadingButton>
          </div>
        </div>
      </form>
    </>
  );
};

export default TypeActorForm;
