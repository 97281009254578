import React, { useState } from 'react';
import { routePaths } from '@/config';
import { Button } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logo from '@/assets/images/png/logo_app.png';
import './ForgottenPasswordForm.styles.scss';
import { ToastError, ToastGeneric } from '@/utils/toast';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InlineIcon } from '@iconify/react';
import { apiResetPasswordSendMail } from '../../api/auth.api';
import { LoadingButton } from '@mui/lab';

type TForgottenPasswordForm = {
  email: string;
};

const ForgottenPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);

  const validationSchema: any = Yup.object({
    email: Yup.string()
      .max(100, 'La limite de caractère est ateinte')
      .trim()
      .required('Votre email est réquis'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: TForgottenPasswordForm) => {
      setIsloading(true);
      const user = {
        email: values.email,
      };
      apiResetPasswordSendMail({ email: user.email }).then(response => {
        if (!response.error) {
          setIsloading(false);
          ToastGeneric(
            `Code de modification de mot passe envoyé, veuillez consulter votre adresse email ${values.email} `,
            'success',
            5000
          ).fire();
          localStorage.setItem('email', values.email);
          navigate(routePaths.authChildren.resetPasswordAbsolute);
        } else {
          ToastError.fire({ text: response.message, timer: 9000 });
          setIsloading(false);
        }
      });
    },
  });

  return (
    <div className="signin-container">
      <div className="logo-part">
        <img src={logo} alt="Logo" />
      </div>
      <div className="fs-form-container">
        {/* <div className='return-container'>
          <Button className='return-button'>
            <Icon icon="clarity:times-line" className='icon-return'/>
          </Button>
        </div> */}
        <div className="header-part">
          {/* <h1 className="title">Demande de mot de passe</h1> */}
          <h1 className="title">Mot de passe oublié ?</h1>
          {/* <p className="subtitle"> Mot de passe oublié </p> */}
          <small>
            Saisissez votre adresse mail afin de réccupérer votre compte
          </small>
        </div>
        <form className="form-part" onSubmit={formik.handleSubmit}>
          <div className="row fs-row">
            <div className="col-12 mb-3">
              <label className="bs-label-form">
                {' '}
                Adresse mail <sup>*</sup>
              </label>
              <input
                type="email"
                className="form-control bs-input-custom"
                name="email"
                autoComplete="off"
                placeholder="Votre adresse mail"
                value={formik.values.email}
                onChange={formik.handleChange}
              ></input>
              {formik.errors.email && (
                <small className="helper-text">
                  {' '}
                  <InlineIcon icon="ci:error-outline" /> {formik.errors.email}{' '}
                </small>
              )}
            </div>
          </div>

          <LoadingButton
            variant="contained"
            color="primary"
            sx={{ marginTop: '30px' }}
            type="submit"
            loading={isLoading}
            disabled={!formik.values.email}
          >
            Envoyer votre demande de réinitialisation
          </LoadingButton>
          <p className="se-connecter">
            J'ai déja un compte :{' '}
            <Link to={routePaths.authChildren.signInAbsolute}>
              Se connecter
            </Link>
          </p>
        </form>
        {/* <div className="footer-part">
            <p> Vous n'avez pas de compte ? 
                <Link className="link-primary"
                      to={routePaths.authChildren.signUpAbsolute}> S'inscrire </Link> 
            </p>
        </div> */}
      </div>
    </div>
  );
};

export default ForgottenPasswordForm;
