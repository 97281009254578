// import WithFormikErrorMessage from '@/components/HOCs/WithFormikErrorMessage.hoc';
// import AlertMessage from '@/components/UICs/AlertMessage/AlertMessage.uic';
import TablePreview from "@/features/admin/Elements/TablePreview/TablePreview";
import { getPersonneMoral } from "@/features/admin/api/client.api";
import { usePersonSelectedIdContext } from "@/features/admin/contexts/selectedPerson.context";
import { tablePreviewDomFunc } from "@/utils/tablePreviewDomFunc";
import { Button } from "@mui/material";
import { FormikProps } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as _ from "lodash";
import React, { useEffect, useState } from "react";
// import { TBuyerFormValues } from '../../Buyer/Buyer.form.container';

// const FormErrorDisplayer =
//     WithFormikErrorMessage<TBuyerFormValues>(AlertMessage);

interface BuyerMoralStep1FormProps {
  formik: FormikProps<any>;
  currentIdPersonne?: string | undefined;
  readOnlyInput?: boolean;
  setRefreshFilesList?: React.Dispatch<React.SetStateAction<string>>;
}

const CompanyInfoForm: React.FC<BuyerMoralStep1FormProps> = ({
  formik,
  currentIdPersonne,
  readOnlyInput,
  setRefreshFilesList,
}) => {
  // console.log('  currentIdPersonne ', currentIdPersonne);

  const [moralPersonInfo, setMoralPersonInfo] = useState<any>({});
  const [currentPhysicalPersonInfo, setCurrentPhysicalPersonInfo] =
    useState<any>({});
  const [moralPersonRows, setMoralPersonRows] = useState<any>([]);
  //   const [personSelectedId, setPersonSelectedId] = useState<string>('');
  const { personSelectedId, setPersonSelectedId } =
    usePersonSelectedIdContext();

  const getInfoForMoralPerson = async (queryText: string) => {
    const response = await getPersonneMoral(queryText);
    const { data: listMoralPerson } = response;
    const createTablePreviewRow = listMoralPerson.map(
      (person: any, index: number) => {
        const { _id, legal_infos } = person;
        const { denomination, registration, phone_number } = legal_infos;

        return {
          id: index + 1,
          person_id: _id,
          denomination,
          registration,
          telephone: phone_number,
        };
      }
    );
    setMoralPersonRows(createTablePreviewRow);
    setMoralPersonInfo(listMoralPerson);
    // setRefreshFilesList?.(new Date().getTime().toString());
  };

  const allowWrittingInsideInput = () => {
    setPersonSelectedId({ moral: "", physique: "" });
  };

  useEffect(() => {
    // tablePreviewDomFunc({
    //   inputParentWrapperSelector: '.information-body-container',
    //   getFonction: getInfoForMoralPerson,
    // });
    const selectedPerson =
      personSelectedId.moral &&
      _.find(moralPersonInfo, { _id: personSelectedId.moral });
    setCurrentPhysicalPersonInfo(selectedPerson);

    if (selectedPerson) {
      formik.values.sellerMoralDenomination =
        selectedPerson["legal_infos"]?.denomination;
      formik.values.sellerMoralFormeJuridique =
        selectedPerson["legal_infos"]?.legal_form;
      formik.values.sellerMoralSiege =
        selectedPerson["legal_infos"]?.registered_office;
      formik.values.sellerMoralCapital = selectedPerson["legal_infos"]?.capital;
      formik.values.sellerMoralRegistre =
        selectedPerson["legal_infos"]?.registration ||
        selectedPerson["legal_infos"]?.siren_number;
      formik.values.sellerMoralEmail = selectedPerson["legal_infos"]?.email;
      formik.values.sellerMoralNumber =
        selectedPerson["legal_infos"]?.phone_number;
      formik.values.sellerMoralInfosPlus =
        selectedPerson["legal_infos"]?.further_informations;
    }
  }, [personSelectedId, moralPersonInfo, formik.values]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='information-body-container'>
          <div className='row fs-row'>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3 table-preview-parent'>
              <label className='bs-label-form'>
                Dénomination de la société <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMoralDenomination'
                autoComplete='off'
                placeholder='Dénomination de la société'
                onFocus={allowWrittingInsideInput}
                onChange={formik.handleChange}
                onKeyUp={e => {
                  const element = e.target as HTMLInputElement;
                  tablePreviewDomFunc({
                    topLevelParentWrapperSelector:
                      ".information-body-container",
                    element,
                    getFonction: getInfoForMoralPerson,
                  });
                }}
                disabled={readOnlyInput}
                value={formik.values.sellerMoralDenomination}
              ></input>
              <TablePreview
                isForPersonPhysique={false}
                rows={moralPersonRows}
                customStyle={{ left: ".8rem", right: "-4.5rem" }}
              />
            </div>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3 '>
              <label className='bs-label-form'>
                Forme juridique <sup>*</sup>
              </label>
              <select
                name='sellerMoralFormeJuridique'
                id='inputState'
                className='form-select bs-input-custom'
                placeholder='Forme juridique'
                onChange={formik.handleChange}
                onFocus={allowWrittingInsideInput}
                disabled={readOnlyInput}
                value={formik.values.sellerMoralFormeJuridique}
              >
                <option value={""}>Choisissez</option>
                <option value={"SA"}>SA</option>
                <option value={"SAS"}>SAS</option>
                <option value={"Entreprise Individuelle"}>
                  Entreprise Individuelle
                </option>
                <option value={"SARL"}>SARL</option>
              </select>
            </div>
          </div>

          <div className='row fs-row'>
            <div className='col-lg-12 col-md-12 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Siège social <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMoralSiege'
                autoComplete='off'
                placeholder='Siège social'
                disabled={readOnlyInput}
                onChange={formik.handleChange}
                onFocus={allowWrittingInsideInput}
                value={formik.values.sellerMoralSiege}
              ></input>
            </div>
          </div>

          <div className='row fs-row'>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Capital <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMoralCapital'
                autoComplete='off'
                placeholder='Capital'
                disabled={readOnlyInput}
                onChange={formik.handleChange}
                onFocus={allowWrittingInsideInput}
                value={formik.values.sellerMoralCapital}
              ></input>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Numéro SIREN ou Registre du Commerce <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMoralRegistre'
                autoComplete='off'
                placeholder='Numéro SIREN ou Registre du C ommerce'
                onChange={formik.handleChange}
                onFocus={allowWrittingInsideInput}
                disabled={readOnlyInput}
                value={formik.values.sellerMoralRegistre}
              ></input>
            </div>
          </div>

          <div className='row fs-row'>
            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Email <sup>*</sup>
              </label>
              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMoralEmail'
                autoComplete='off'
                placeholder='Email'
                disabled={readOnlyInput}
                onChange={formik.handleChange}
                onFocus={allowWrittingInsideInput}
                value={formik.values.sellerMoralEmail}
              ></input>
            </div>

            <div className='col-lg-6 col-md-6 col-sm-12 mb-3'>
              <label className='bs-label-form'>
                Numéro de télephone <sup>*</sup>
              </label>
              {/* <PhoneInput
                    placeholder="Numéro de téléphone"
                    country={"ci"}
                    regions={['africa', 'america', 'asia', 'europe', 'oceania']}
                    enableSearch
                    inputProps={{
                    name: 'sellerMoralNumber',
                    required: true,
                    }}
                    value={formik.values.sellerMoralNumber}
                    onChange={formik.handleChange}
                    disabled={readOnlyInput}
                    onFocus={allowWrittingInsideInput}
                /> */}

              <input
                type='text'
                className='form-control bs-input-custom'
                name='sellerMoralNumber'
                autoComplete='off'
                placeholder='Numéro télephone'
                onChange={formik.handleChange}
                disabled={readOnlyInput}
                value={formik.values.sellerMoralNumber}
              ></input>
            </div>
          </div>

          <div className='row fs-row'>
            <div className='col-12 mb-3'>
              <label className='bs-label-form'>
                Information complémentaires
              </label>
              <textarea
                name='sellerMoralInfosPlus'
                className='form-control bs-input-custom'
                placeholder='Information complémentaires'
                rows={4}
                disabled={readOnlyInput}
                onChange={formik.handleChange}
                onFocus={allowWrittingInsideInput}
                value={formik.values.sellerMoralInfosPlus}
              ></textarea>
            </div>
          </div>
          {!readOnlyInput && (
            <div className='modify-button'>
              {!currentIdPersonne && (
                <Button type='submit' variant='contained' color='primary'>
                  ENREGISTRER & CONTINUER
                </Button>
              )}
              {currentIdPersonne && (
                <Button type='submit' variant='contained' color='primary'>
                  ENREGISTRER
                </Button>
              )}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default CompanyInfoForm;
