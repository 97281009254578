import { ContentState, EditorState, Modifier } from "draft-js";

export const insertVariable = (
  contentStateWithEntity: ContentState,
  editorState: EditorState,
  infoToInsert: { label: string; value: string }
) => {
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  const newContentState = Modifier.insertText(
    contentStateWithEntity,
    editorState.getSelection(),
    infoToInsert.value, //transformTextToInsert(textToInsert),
    editorState.getCurrentInlineStyle(),
    entityKey
  );

  return newContentState;
};

export const transformTextToInsert = (info: {
  label: string;
  value: string;
}) => {
  const stringToReplace = "VARIABLE";
  const variableTemplate = `[[${stringToReplace}]]`;
  let value = info.value;

  if (info.value.length < info.label.length) {
    const diff = info.label.length - info.value.length;
    Array(diff)
      .fill("·")
      .forEach((char) => {
        value += char;
      });
  }

  return variableTemplate.replace(stringToReplace, value);
};

export const moveSelectionToEndWithSpace = (
  currentContentState: ContentState,
  currentEditorState: EditorState,
  forceSelection = true
) => {
  let newEditorState = EditorState.set(currentEditorState, {
    currentContent: currentContentState,
  });

  newEditorState = EditorState.moveSelectionToEnd(newEditorState);

  currentContentState = Modifier.insertText(
    newEditorState.getCurrentContent(),
    newEditorState.getSelection(),
    " "
  );

  newEditorState = EditorState.set(newEditorState, {
    currentContent: currentContentState,
  });

  newEditorState = EditorState.moveSelectionToEnd(newEditorState);

  if (forceSelection) {
    newEditorState = EditorState.forceSelection(
      newEditorState,
      newEditorState.getSelection()
    );

    return { editorState: newEditorState, contentState: currentContentState };
  }

  return { editorState: newEditorState, contentState: currentContentState };
};
